import { useState } from "react";

export const ordersMockData =
    [
        {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa62",
            createdAt: "2024-04-01T08:30:00Z",
            updatedAt: "2024-04-01T08:30:00Z",
            spec: {
                name: "Order 1",
                geofeature: {
                    features: [
                        {
                            id: "2b0f720d-dfa3-4a48-a473-8124aaa91f5c",
                            geometry: {
                                type: "Polygon",
                                coordinates: [
                                    [
                                        42.03984579793553,
                                        14.35221932704252
                                    ],
                                    [
                                        45.116017672935584,
                                        9.79839490489013
                                    ],
                                    [
                                        61.639455172936465,
                                        20.79977927559247
                                    ],
                                    [
                                        59.178517672936806,
                                        24.44965986946312
                                    ],
                                    [
                                        42.03984579793553,
                                        14.35221932704252
                                    ],
                                ],
                            },
                            properties: {}
                        }
                    ]
                },
                attributes: {
                    priorityLane: "Normal",
                    imagingSpectrum: "Hyper-S",
                    imagerADCSDetails: {
                        gsd: { min: 0, max: 1 },
                        nadirAngle: { min: 0, max: 30 },
                        sunAngle: { min: 0, max: 45 }
                    },
                    cloudCoverage: 50,
                    resolution: "0.1m - 1m (Very high)",
                    recurring: true
                },
                tipncue: {}
            },
            status: {
                state: "Current",
                coverage: {
                    coveredStrips: [
                        [
                            [
                                46.74192873718151,
                                17.18109408243627
                            ],
                            [
                                46.868054863307634,
                                17.18109408243627
                            ],
                            [
                                46.868054863307634,
                                17.054967956310147
                            ],
                            [
                                46.74192873718151,
                                17.054967956310147
                            ]
                        ],
                        [
                            [
                                46.72808934129506,
                                17.11795749940532
                            ],
                            [
                                46.854215467421184,
                                17.11795749940532
                            ],
                            [
                                46.854215467421184,
                                16.991831373279197
                            ],
                            [
                                46.72808934129506,
                                16.991831373279197
                            ]
                        ],
                        [
                            [
                                46.71425641402321,
                                17.054820007672163
                            ],
                            [
                                46.840382540149335,
                                17.054820007672163
                            ],
                            [
                                46.840382540149335,
                                16.92869388154604
                            ],
                            [
                                46.71425641402321,
                                16.92869388154604
                            ]
                        ],
                        [
                            [
                                46.70042992440969,
                                16.991681610936133
                            ],
                            [
                                46.826556050535814,
                                16.991681610936133
                            ],
                            [
                                46.826556050535814,
                                16.86555548481001
                            ],
                            [
                                46.70042992440969,
                                16.86555548481001
                            ]
                        ],
                        [
                            [
                                46.68660984418971,
                                16.92854231289413
                            ],
                            [
                                46.812735970315835,
                                16.92854231289413
                            ],
                            [
                                46.812735970315835,
                                16.802416186768006
                            ],
                            [
                                46.68660984418971,
                                16.802416186768006
                            ]
                        ],
                        [
                            [
                                46.672796142542595,
                                16.865402117240674
                            ],
                            [
                                46.79892226866872,
                                16.865402117240674
                            ],
                            [
                                46.79892226866872,
                                16.73927599111455
                            ],
                            [
                                46.672796142542595,
                                16.73927599111455
                            ]
                        ],
                        [
                            [
                                46.65898879134503,
                                16.80226102766792
                            ],
                            [
                                46.785114917471155,
                                16.80226102766792
                            ],
                            [
                                46.785114917471155,
                                16.676134901541797
                            ],
                            [
                                46.65898879134503,
                                16.676134901541797
                            ]
                        ],
                        [
                            [
                                46.645187759908886,
                                16.739119047865703
                            ],
                            [
                                46.77131388603501,
                                16.739119047865703
                            ],
                            [
                                46.77131388603501,
                                16.61299292173958
                            ],
                            [
                                46.645187759908886,
                                16.61299292173958
                            ]
                        ],
                        [
                            [
                                46.6313930202441,
                                16.67597618152147
                            ],
                            [
                                46.75751914637022,
                                16.67597618152147
                            ],
                            [
                                46.75751914637022,
                                16.549850055395346
                            ],
                            [
                                46.6313930202441,
                                16.549850055395346
                            ]
                        ],
                        [
                            [
                                46.617604541808205,
                                16.61283243232042
                            ],
                            [
                                46.74373066793433,
                                16.61283243232042
                            ],
                            [
                                46.74373066793433,
                                16.486706306194296
                            ],
                            [
                                46.617604541808205,
                                16.486706306194296
                            ]
                        ],
                        [
                            [
                                46.603822296739786,
                                16.54968780394542
                            ],
                            [
                                46.72994842286591,
                                16.54968780394542
                            ],
                            [
                                46.72994842286591,
                                16.423561677819297
                            ],
                            [
                                46.603822296739786,
                                16.423561677819297
                            ]
                        ],
                        [
                            [
                                46.59004625463229,
                                16.486542300077073
                            ],
                            [
                                46.716172380758415,
                                16.486542300077073
                            ],
                            [
                                46.716172380758415,
                                16.36041617395095
                            ],
                            [
                                46.59004625463229,
                                16.36041617395095
                            ]
                        ],
                        [
                            [
                                46.57627638775795,
                                16.423395924393763
                            ],
                            [
                                46.702402513884074,
                                16.423395924393763
                            ],
                            [
                                46.702402513884074,
                                16.29726979826764
                            ],
                            [
                                46.57627638775795,
                                16.29726979826764
                            ]
                        ],
                        [
                            [
                                46.56251266584146,
                                16.36024868057155
                            ],
                            [
                                46.688638791967584,
                                16.36024868057155
                            ],
                            [
                                46.688638791967584,
                                16.234122554445428
                            ],
                            [
                                46.56251266584146,
                                16.234122554445428
                            ]
                        ],
                        [
                            [
                                46.54875506129202,
                                16.29710057228437
                            ],
                            [
                                46.674881187418144,
                                16.29710057228437
                            ],
                            [
                                46.674881187418144,
                                16.170974446158247
                            ],
                            [
                                46.54875506129202,
                                16.170974446158247
                            ]
                        ],
                        [
                            [
                                46.53500354396087,
                                16.23395160320389
                            ],
                            [
                                46.66112967008699,
                                16.23395160320389
                            ],
                            [
                                46.66112967008699,
                                16.107825477077768
                            ],
                            [
                                46.53500354396087,
                                16.107825477077768
                            ]
                        ],
                        [
                            [
                                46.521258086387846,
                                16.17080177699962
                            ],
                            [
                                46.64738421251397,
                                16.17080177699962
                            ],
                            [
                                46.64738421251397,
                                16.044675650873497
                            ],
                            [
                                46.521258086387846,
                                16.044675650873497
                            ]
                        ],
                        [
                            [
                                46.507518658560656,
                                16.107651097338913
                            ],
                            [
                                46.63364478468678,
                                16.107651097338913
                            ],
                            [
                                46.63364478468678,
                                15.981524971212787
                            ],
                            [
                                46.507518658560656,
                                15.981524971212787
                            ]
                        ],
                        [
                            [
                                46.49378523314015,
                                16.04449956788695
                            ],
                            [
                                46.619911359266275,
                                16.04449956788695
                            ],
                            [
                                46.619911359266275,
                                15.918373441760826
                            ],
                            [
                                46.49378523314015,
                                15.918373441760826
                            ]
                        ],
                        [
                            [
                                46.48005778024246,
                                15.981347192306783
                            ],
                            [
                                46.606183906368585,
                                15.981347192306783
                            ],
                            [
                                46.606183906368585,
                                15.855221066180656
                            ],
                            [
                                46.48005778024246,
                                15.855221066180656
                            ]
                        ],
                        [
                            [
                                46.46633627266275,
                                15.918193974259363
                            ],
                            [
                                46.592462398788875,
                                15.918193974259363
                            ],
                            [
                                46.592462398788875,
                                15.792067848133236
                            ],
                            [
                                46.46633627266275,
                                15.792067848133236
                            ]
                        ],
                        [
                            [
                                46.452620680642525,
                                15.855039917403523
                            ],
                            [
                                46.57874680676865,
                                15.855039917403523
                            ],
                            [
                                46.57874680676865,
                                15.728913791277396
                            ],
                            [
                                46.452620680642525,
                                15.728913791277396
                            ]
                        ],
                        [
                            [
                                46.43891097710006,
                                15.791885025396024
                            ],
                            [
                                46.56503710322618,
                                15.791885025396024
                            ],
                            [
                                46.56503710322618,
                                15.665758899269896
                            ],
                            [
                                46.43891097710006,
                                15.665758899269896
                            ]
                        ],
                        [
                            [
                                46.425207132404005,
                                15.728729301891564
                            ],
                            [
                                46.55133325853013,
                                15.728729301891564
                            ],
                            [
                                46.55133325853013,
                                15.602603175765436
                            ],
                            [
                                46.425207132404005,
                                15.602603175765436
                            ]
                        ],
                        [
                            [
                                46.41150911959751,
                                15.665572750542793
                            ],
                            [
                                46.537635245723635,
                                15.665572750542793
                            ],
                            [
                                46.537635245723635,
                                15.539446624416666
                            ],
                            [
                                46.41150911959751,
                                15.539446624416666
                            ]
                        ],
                        [
                            [
                                46.397816909170245,
                                15.602415375000323
                            ],
                            [
                                46.52394303529637,
                                15.602415375000323
                            ],
                            [
                                46.52394303529637,
                                15.476289248874195
                            ],
                            [
                                46.397816909170245,
                                15.476289248874195
                            ]
                        ],
                        [
                            [
                                46.38413047429524,
                                15.539257178912743
                            ],
                            [
                                46.510256600421364,
                                15.539257178912743
                            ],
                            [
                                46.510256600421364,
                                15.413131052786616
                            ],
                            [
                                46.38413047429524,
                                15.413131052786616
                            ]
                        ],
                        [
                            [
                                46.37044978558335,
                                15.476098165926674
                            ],
                            [
                                46.49657591170947,
                                15.476098165926674
                            ],
                            [
                                46.49657591170947,
                                15.349972039800546
                            ],
                            [
                                46.37044978558335,
                                15.349972039800546
                            ]
                        ],
                        [
                            [
                                46.35677481632799,
                                15.412938339686724
                            ],
                            [
                                46.482900942454116,
                                15.412938339686724
                            ],
                            [
                                46.482900942454116,
                                15.286812213560596
                            ],
                            [
                                46.35677481632799,
                                15.286812213560596
                            ]
                        ],
                        [
                            [
                                46.343105537259795,
                                15.349777703835533
                            ],
                            [
                                46.46923166338592,
                                15.349777703835533
                            ],
                            [
                                46.46923166338592,
                                15.223651577709406
                            ],
                            [
                                46.343105537259795,
                                15.223651577709406
                            ]
                        ],
                        [
                            [
                                46.329441921136635,
                                15.286616262013803
                            ],
                            [
                                46.45556804726276,
                                15.286616262013803
                            ],
                            [
                                46.45556804726276,
                                15.160490135887676
                            ],
                            [
                                46.329441921136635,
                                15.160490135887676
                            ]
                        ],
                        [
                            [
                                46.31578394078108,
                                15.223454017860314
                            ],
                            [
                                46.441910066907205,
                                15.223454017860314
                            ],
                            [
                                46.441910066907205,
                                15.097327891734187
                            ],
                            [
                                46.31578394078108,
                                15.097327891734187
                            ]
                        ],
                        [
                            [
                                46.302131567099956,
                                15.160290975011893
                            ],
                            [
                                46.42825769322608,
                                15.160290975011893
                            ],
                            [
                                46.42825769322608,
                                15.034164848885766
                            ],
                            [
                                46.302131567099956,
                                15.034164848885766
                            ]
                        ],
                        [
                            [
                                46.288484773692325,
                                15.097127137103485
                            ],
                            [
                                46.41461089981845,
                                15.097127137103485
                            ],
                            [
                                46.41461089981845,
                                14.971001010977357
                            ],
                            [
                                46.288484773692325,
                                14.971001010977357
                            ]
                        ],
                        [
                            [
                                46.2748435315796,
                                15.033962507768134
                            ],
                            [
                                46.40096965770572,
                                15.033962507768134
                            ],
                            [
                                46.40096965770572,
                                14.907836381642007
                            ],
                            [
                                46.2748435315796,
                                14.907836381642007
                            ]
                        ],
                        [
                            [
                                46.26120781448144,
                                14.970797090637024
                            ],
                            [
                                46.38733394060756,
                                14.970797090637024
                            ],
                            [
                                46.38733394060756,
                                14.844670964510897
                            ],
                            [
                                46.26120781448144,
                                14.844670964510897
                            ]
                        ],
                        [
                            [
                                46.24757759353092,
                                14.907630889339474
                            ],
                            [
                                46.373703719657044,
                                14.907630889339474
                            ],
                            [
                                46.373703719657044,
                                14.781504763213347
                            ],
                            [
                                46.24757759353092,
                                14.781504763213347
                            ]
                        ],
                        [
                            [
                                46.233952842566836,
                                14.844463907502954
                            ],
                            [
                                46.36007896869296,
                                14.844463907502954
                            ],
                            [
                                46.36007896869296,
                                14.718337781376826
                            ],
                            [
                                46.233952842566836,
                                14.718337781376826
                            ]
                        ],
                        [
                            [
                                46.220333532839106,
                                14.781296148753114
                            ],
                            [
                                46.34645965896523,
                                14.781296148753114
                            ],
                            [
                                46.34645965896523,
                                14.655170022626987
                            ],
                            [
                                46.220333532839106,
                                14.655170022626987
                            ]
                        ],
                        [
                            [
                                46.2067196383027,
                                14.718127616713794
                            ],
                            [
                                46.332845764428825,
                                14.718127616713794
                            ],
                            [
                                46.332845764428825,
                                14.592001490587666
                            ],
                            [
                                46.2067196383027,
                                14.592001490587666
                            ]
                        ],
                        [
                            [
                                46.193111130316545,
                                14.654958315007034
                            ],
                            [
                                46.31923725644267,
                                14.654958315007034
                            ],
                            [
                                46.31923725644267,
                                14.528832188880907
                            ],
                            [
                                46.193111130316545,
                                14.528832188880907
                            ]
                        ],
                        [
                            [
                                46.17950798294394,
                                14.591788247253074
                            ],
                            [
                                46.30563410907006,
                                14.591788247253074
                            ],
                            [
                                46.30563410907006,
                                14.465662121126947
                            ],
                            [
                                46.17950798294394,
                                14.465662121126947
                            ]
                        ],
                        [
                            [
                                46.165910167667796,
                                14.528617417070414
                            ],
                            [
                                46.29203629379392,
                                14.528617417070414
                            ],
                            [
                                46.29203629379392,
                                14.402491290944287
                            ],
                            [
                                46.165910167667796,
                                14.402491290944287
                            ]
                        ],
                        [
                            [
                                46.152317658658376,
                                14.465445828075774
                            ],
                            [
                                46.2784437847845,
                                14.465445828075774
                            ],
                            [
                                46.2784437847845,
                                14.339319701949647
                            ],
                            [
                                46.152317658658376,
                                14.339319701949647
                            ]
                        ],
                        [
                            [
                                46.1387304275115,
                                14.402273483884164
                            ],
                            [
                                46.26485655363762,
                                14.402273483884164
                            ],
                            [
                                46.26485655363762,
                                14.276147357758036
                            ],
                            [
                                46.1387304275115,
                                14.276147357758036
                            ]
                        ],
                        [
                            [
                                46.12514844850474,
                                14.339100388108834
                            ],
                            [
                                46.25127457463086,
                                14.339100388108834
                            ],
                            [
                                46.25127457463086,
                                14.212974261982707
                            ],
                            [
                                46.12514844850474,
                                14.212974261982707
                            ]
                        ],
                        [
                            [
                                46.111571693343805,
                                14.275926544361333
                            ],
                            [
                                46.23769781946993,
                                14.275926544361333
                            ],
                            [
                                46.23769781946993,
                                14.149800418235206
                            ],
                            [
                                46.111571693343805,
                                14.149800418235206
                            ]
                        ],
                        [
                            [
                                46.0980001364189,
                                14.212751956251534
                            ],
                            [
                                46.22412626254502,
                                14.212751956251534
                            ],
                            [
                                46.22412626254502,
                                14.086625830125406
                            ],
                            [
                                46.0980001364189,
                                14.086625830125406
                            ]
                        ],
                        [
                            [
                                46.08443374954269,
                                14.149576627387603
                            ],
                            [
                                46.210559875668814,
                                14.149576627387603
                            ],
                            [
                                46.210559875668814,
                                14.023450501261475
                            ],
                            [
                                46.08443374954269,
                                14.023450501261475
                            ]
                        ],
                        [
                            [
                                46.07087250721336,
                                14.086400561376054
                            ],
                            [
                                46.19699863333948,
                                14.086400561376054
                            ],
                            [
                                46.19699863333948,
                                13.960274435249927
                            ],
                            [
                                46.07087250721336,
                                13.960274435249927
                            ]
                        ],
                        [
                            [
                                46.05731638135257,
                                14.023223761821724
                            ],
                            [
                                46.183442507478695,
                                14.023223761821724
                            ],
                            [
                                46.183442507478695,
                                13.897097635695596
                            ],
                            [
                                46.05731638135257,
                                13.897097635695596
                            ]
                        ],
                        [
                            [
                                46.043765346563546,
                                13.960046232327823
                            ],
                            [
                                46.16989147268967,
                                13.960046232327823
                            ],
                            [
                                46.16989147268967,
                                13.833920106201695
                            ],
                            [
                                46.043765346563546,
                                13.833920106201695
                            ]
                        ],
                        [
                            [
                                46.03021937486924,
                                13.896867976495944
                            ],
                            [
                                46.156345500995364,
                                13.896867976495944
                            ],
                            [
                                46.156345500995364,
                                13.770741850369816
                            ],
                            [
                                46.03021937486924,
                                13.770741850369816
                            ]
                        ],
                        [
                            [
                                46.01667844098636,
                                13.833688997926053
                            ],
                            [
                                46.142804567112485,
                                13.833688997926053
                            ],
                            [
                                46.142804567112485,
                                13.707562871799926
                            ],
                            [
                                46.01667844098636,
                                13.707562871799926
                            ]
                        ],
                        [
                            [
                                46.003142517039386,
                                13.770509300216494
                            ],
                            [
                                46.12926864316551,
                                13.770509300216494
                            ],
                            [
                                46.12926864316551,
                                13.644383174090366
                            ],
                            [
                                46.003142517039386,
                                13.644383174090366
                            ]
                        ],
                        [
                            [
                                45.98961157785095,
                                13.707328886964055
                            ],
                            [
                                46.115737703977075,
                                13.707328886964055
                            ],
                            [
                                46.115737703977075,
                                13.581202760837927
                            ],
                            [
                                45.98961157785095,
                                13.581202760837927
                            ]
                        ],
                        [
                            [
                                45.97608559564732,
                                13.644147761763945
                            ],
                            [
                                46.10221172177344,
                                13.644147761763945
                            ],
                            [
                                46.10221172177344,
                                13.518021635637817
                            ],
                            [
                                45.97608559564732,
                                13.518021635637817
                            ]
                        ],
                        [
                            [
                                45.96256454534923,
                                13.580965928209803
                            ],
                            [
                                46.088690671475355,
                                13.580965928209803
                            ],
                            [
                                46.088690671475355,
                                13.454839802083676
                            ],
                            [
                                45.96256454534923,
                                13.454839802083676
                            ]
                        ],
                        [
                            [
                                45.94904839929342,
                                13.517783389893713
                            ],
                            [
                                46.07517452541954,
                                13.517783389893713
                            ],
                            [
                                46.07517452541954,
                                13.391657263767586
                            ],
                            [
                                45.94904839929342,
                                13.391657263767586
                            ]
                        ],
                        [
                            [
                                45.935537132498936,
                                13.454600150406243
                            ],
                            [
                                46.06166325862506,
                                13.454600150406243
                            ],
                            [
                                46.06166325862506,
                                13.328474024280116
                            ],
                            [
                                45.935537132498936,
                                13.328474024280116
                            ]
                        ],
                        [
                            [
                                45.92203071740365,
                                13.391416213336413
                            ],
                            [
                                46.048156843529775,
                                13.391416213336413
                            ],
                            [
                                46.048156843529775,
                                13.265290087210285
                            ],
                            [
                                45.92203071740365,
                                13.265290087210285
                            ]
                        ],
                        [
                            [
                                45.908529129128645,
                                13.328231582271764
                            ],
                            [
                                46.03465525525477,
                                13.328231582271764
                            ],
                            [
                                46.03465525525477,
                                13.202105456145636
                            ],
                            [
                                45.908529129128645,
                                13.202105456145636
                            ]
                        ],
                        [
                            [
                                45.89503234021005,
                                13.265046260798323
                            ],
                            [
                                46.021158466336175,
                                13.265046260798323
                            ],
                            [
                                46.021158466336175,
                                13.138920134672196
                            ],
                            [
                                45.89503234021005,
                                13.138920134672196
                            ]
                        ],
                        [
                            [
                                45.88154032586486,
                                13.201860252500623
                            ],
                            [
                                46.007666451990985,
                                13.201860252500623
                            ],
                            [
                                46.007666451990985,
                                13.075734126374496
                            ],
                            [
                                45.88154032586486,
                                13.075734126374496
                            ]
                        ],
                        [
                            [
                                45.868053058732585,
                                13.138673560961744
                            ],
                            [
                                45.99417918485871,
                                13.138673560961744
                            ],
                            [
                                45.99417918485871,
                                13.012547434835616
                            ],
                            [
                                45.868053058732585,
                                13.012547434835616
                            ]
                        ],
                        [
                            [
                                45.85457051412678,
                                13.075486189763284
                            ],
                            [
                                45.9806966402529,
                                13.075486189763284
                            ],
                            [
                                45.9806966402529,
                                12.949360063637156
                            ],
                            [
                                45.85457051412678,
                                12.949360063637156
                            ]
                        ],
                        [
                            [
                                45.84109266478429,
                                13.012298142485424
                            ],
                            [
                                45.967218790910415,
                                13.012298142485424
                            ],
                            [
                                45.967218790910415,
                                12.886172016359296
                            ],
                            [
                                45.84109266478429,
                                12.886172016359296
                            ]
                        ],
                        [
                            [
                                45.827619486117,
                                12.949109422706893
                            ],
                            [
                                45.953745612243125,
                                12.949109422706893
                            ],
                            [
                                45.953745612243125,
                                12.822983296580766
                            ],
                            [
                                45.827619486117,
                                12.822983296580766
                            ]
                        ],
                        [
                            [
                                45.8141509509515,
                                12.885920034004984
                            ],
                            [
                                45.940277077077624,
                                12.885920034004984
                            ],
                            [
                                45.940277077077624,
                                12.759793907878857
                            ],
                            [
                                45.8141509509515,
                                12.759793907878857
                            ]
                        ],
                        [
                            [
                                45.800687034803346,
                                12.822729979955614
                            ],
                            [
                                45.92681316092947,
                                12.822729979955614
                            ],
                            [
                                45.92681316092947,
                                12.696603853829487
                            ],
                            [
                                45.800687034803346,
                                12.696603853829487
                            ]
                        ],
                        [
                            [
                                45.78722771059252,
                                12.759539264133254
                            ],
                            [
                                45.91335383671864,
                                12.759539264133254
                            ],
                            [
                                45.91335383671864,
                                12.633413138007127
                            ],
                            [
                                45.78722771059252,
                                12.633413138007127
                            ]
                        ],
                        [
                            [
                                45.773772953269486,
                                12.696347890111044
                            ],
                            [
                                45.89989907939561,
                                12.696347890111044
                            ],
                            [
                                45.89989907939561,
                                12.570221763984916
                            ],
                            [
                                45.773772953269486,
                                12.570221763984916
                            ]
                        ],
                        [
                            [
                                45.760322737816786,
                                12.633155861460693
                            ],
                            [
                                45.88644886394291,
                                12.633155861460693
                            ],
                            [
                                45.88644886394291,
                                12.507029735334566
                            ],
                            [
                                45.760322737816786,
                                12.507029735334566
                            ]
                        ],
                        [
                            [
                                45.74687703731089,
                                12.569963181752593
                            ],
                            [
                                45.87300316343701,
                                12.569963181752593
                            ],
                            [
                                45.87300316343701,
                                12.443837055626465
                            ],
                            [
                                45.74687703731089,
                                12.443837055626465
                            ]
                        ],
                        [
                            [
                                45.73343582748652,
                                12.506769854555744
                            ],
                            [
                                45.85956195361264,
                                12.506769854555744
                            ],
                            [
                                45.85956195361264,
                                12.380643728429616
                            ],
                            [
                                45.73343582748652,
                                12.380643728429616
                            ]
                        ],
                        [
                            [
                                45.719999081508945,
                                12.443575883437834
                            ],
                            [
                                45.84612520763507,
                                12.443575883437834
                            ],
                            [
                                45.84612520763507,
                                12.317449757311707
                            ],
                            [
                                45.719999081508945,
                                12.317449757311707
                            ]
                        ],
                        [
                            [
                                45.70656677520268,
                                12.380381271965224
                            ],
                            [
                                45.832692901328805,
                                12.380381271965224
                            ],
                            [
                                45.832692901328805,
                                12.254255145839096
                            ],
                            [
                                45.70656677520268,
                                12.254255145839096
                            ]
                        ],
                        [
                            [
                                45.69313888182549,
                                12.317186023702924
                            ],
                            [
                                45.819265007951614,
                                12.317186023702924
                            ],
                            [
                                45.819265007951614,
                                12.191059897576796
                            ],
                            [
                                45.69313888182549,
                                12.191059897576796
                            ]
                        ],
                        [
                            [
                                45.67971537729215,
                                12.253990142214684
                            ],
                            [
                                45.80584150341827,
                                12.253990142214684
                            ],
                            [
                                45.80584150341827,
                                12.127864016088557
                            ],
                            [
                                45.67971537729215,
                                12.127864016088557
                            ]
                        ],
                        [
                            [
                                45.66629623494856,
                                12.190793631062935
                            ],
                            [
                                45.792422361074685,
                                12.190793631062935
                            ],
                            [
                                45.792422361074685,
                                12.064667504936807
                            ],
                            [
                                45.66629623494856,
                                12.064667504936807
                            ]
                        ],
                        [
                            [
                                45.65288143080185,
                                12.127596493808813
                            ],
                            [
                                45.77900755692797,
                                12.127596493808813
                            ],
                            [
                                45.77900755692797,
                                12.001470367682685
                            ],
                            [
                                45.65288143080185,
                                12.001470367682685
                            ]
                        ],
                        [
                            [
                                45.639470938284795,
                                12.064398734012194
                            ],
                            [
                                45.76559706441092,
                                12.064398734012194
                            ],
                            [
                                45.76559706441092,
                                11.938272607886066
                            ],
                            [
                                45.639470938284795,
                                11.938272607886066
                            ]
                        ],
                        [
                            [
                                45.626064733494246,
                                12.001200355231713
                            ],
                            [
                                45.75219085962037,
                                12.001200355231713
                            ],
                            [
                                45.75219085962037,
                                11.875074229105586
                            ],
                            [
                                45.626064733494246,
                                11.875074229105586
                            ]
                        ],
                        [
                            [
                                45.612662789947045,
                                11.938001361024723
                            ],
                            [
                                45.73878891607317,
                                11.938001361024723
                            ],
                            [
                                45.73878891607317,
                                11.811875234898595
                            ],
                            [
                                45.612662789947045,
                                11.811875234898595
                            ]
                        ],
                        [
                            [
                                45.599265083826864,
                                11.874801754947324
                            ],
                            [
                                45.72539120995299,
                                11.874801754947324
                            ],
                            [
                                45.72539120995299,
                                11.748675628821196
                            ],
                            [
                                45.599265083826864,
                                11.748675628821196
                            ]
                        ],
                        [
                            [
                                45.58587158874019,
                                11.811601540554435
                            ],
                            [
                                45.711997714866314,
                                11.811601540554435
                            ],
                            [
                                45.711997714866314,
                                11.685475414428307
                            ],
                            [
                                45.58587158874019,
                                11.685475414428307
                            ]
                        ],
                        [
                            [
                                45.57248228095469,
                                11.748400721399733
                            ],
                            [
                                45.69860840708081,
                                11.748400721399733
                            ],
                            [
                                45.69860840708081,
                                11.622274595273606
                            ],
                            [
                                45.57248228095469,
                                11.622274595273606
                            ]
                        ],
                        [
                            [
                                45.55909713416198,
                                11.685199301035674
                            ],
                            [
                                45.685223260288105,
                                11.685199301035674
                            ],
                            [
                                45.685223260288105,
                                11.559073174909546
                            ],
                            [
                                45.55909713416198,
                                11.559073174909546
                            ]
                        ],
                        [
                            [
                                45.545716124719235,
                                11.621997283013533
                            ],
                            [
                                45.67184225084536,
                                11.621997283013533
                            ],
                            [
                                45.67184225084536,
                                11.495871156887405
                            ],
                            [
                                45.545716124719235,
                                11.495871156887405
                            ]
                        ],
                        [
                            [
                                45.53233922639395,
                                11.558794670883394
                            ],
                            [
                                45.658465352520075,
                                11.558794670883394
                            ],
                            [
                                45.658465352520075,
                                11.432668544757266
                            ],
                            [
                                45.53233922639395,
                                11.432668544757266
                            ]
                        ],
                        [
                            [
                                45.51896641562353,
                                11.495591468194164
                            ],
                            [
                                45.645092541749655,
                                11.495591468194164
                            ],
                            [
                                45.645092541749655,
                                11.369465342068036
                            ],
                            [
                                45.51896641562353,
                                11.369465342068036
                            ]
                        ],
                        [
                            [
                                45.505597666268045,
                                11.432387678493583
                            ],
                            [
                                45.63172379239417,
                                11.432387678493583
                            ],
                            [
                                45.63172379239417,
                                11.306261552367456
                            ],
                            [
                                45.505597666268045,
                                11.306261552367456
                            ]
                        ],
                        [
                            [
                                45.49223295484572,
                                11.369183305328233
                            ],
                            [
                                45.61835908097184,
                                11.369183305328233
                            ],
                            [
                                45.61835908097184,
                                11.243057179202106
                            ],
                            [
                                45.49223295484572,
                                11.243057179202106
                            ]
                        ],
                        [
                            [
                                45.47887225529845,
                                11.305978352243553
                            ],
                            [
                                45.60499838142457,
                                11.305978352243553
                            ],
                            [
                                45.60499838142457,
                                11.179852226117426
                            ],
                            [
                                45.47887225529845,
                                11.179852226117426
                            ]
                        ],
                        [
                            [
                                45.46551554422073,
                                11.242772822783843
                            ],
                            [
                                45.59164167034685,
                                11.242772822783843
                            ],
                            [
                                45.59164167034685,
                                11.116646696657716
                            ],
                            [
                                45.46551554422073,
                                11.116646696657716
                            ]
                        ],
                        [
                            [
                                45.45216279563851,
                                11.179566720492284
                            ],
                            [
                                45.57828892176463,
                                11.179566720492284
                            ],
                            [
                                45.57828892176463,
                                11.053440594366156
                            ],
                            [
                                45.45216279563851,
                                11.053440594366156
                            ]
                        ],
                        [
                            [
                                45.43881398622964,
                                11.116360048910934
                            ],
                            [
                                45.564940112355764,
                                11.116360048910934
                            ],
                            [
                                45.564940112355764,
                                10.990233922784807
                            ],
                            [
                                45.43881398622964,
                                10.990233922784807
                            ]
                        ],
                        [
                            [
                                45.425469090094765,
                                11.053152811580723
                            ],
                            [
                                45.55159521622089,
                                11.053152811580723
                            ],
                            [
                                45.55159521622089,
                                10.927026685454596
                            ],
                            [
                                45.425469090094765,
                                10.927026685454596
                            ]
                        ],
                        [
                            [
                                45.4121280839925,
                                10.989945012041554
                            ],
                            [
                                45.53825421011862,
                                10.989945012041554
                            ],
                            [
                                45.53825421011862,
                                10.863818885915427
                            ],
                            [
                                45.4121280839925,
                                10.863818885915427
                            ]
                        ],
                        [
                            [
                                45.398790942103595,
                                10.926736653832164
                            ],
                            [
                                45.52491706822972,
                                10.926736653832164
                            ],
                            [
                                45.52491706822972,
                                10.800610527706036
                            ],
                            [
                                45.398790942103595,
                                10.800610527706036
                            ]
                        ],
                        [
                            [
                                45.3854576412614,
                                10.863527740490264
                            ],
                            [
                                45.51158376738752,
                                10.863527740490264
                            ],
                            [
                                45.51158376738752,
                                10.737401614364137
                            ],
                            [
                                45.3854576412614,
                                10.737401614364137
                            ]
                        ],
                        [
                            [
                                45.37212815572412,
                                10.800318275552454
                            ],
                            [
                                45.498254281850244,
                                10.800318275552454
                            ],
                            [
                                45.498254281850244,
                                10.674192149426327
                            ],
                            [
                                45.37212815572412,
                                10.674192149426327
                            ]
                        ],
                        [
                            [
                                45.358802462410075,
                                10.737108262554344
                            ],
                            [
                                45.4849285885362,
                                10.737108262554344
                            ],
                            [
                                45.4849285885362,
                                10.610982136428216
                            ],
                            [
                                45.358802462410075,
                                10.610982136428216
                            ]
                        ],
                        [
                            [
                                45.34548053563931,
                                10.673897705030404
                            ],
                            [
                                45.471606661765435,
                                10.673897705030404
                            ],
                            [
                                45.471606661765435,
                                10.547771578904277
                            ],
                            [
                                45.34548053563931,
                                10.547771578904277
                            ]
                        ],
                        [
                            [
                                45.33216235241732,
                                10.610686606514143
                            ],
                            [
                                45.458288478543444,
                                10.610686606514143
                            ],
                            [
                                45.458288478543444,
                                10.484560480388016
                            ],
                            [
                                45.33216235241732,
                                10.484560480388016
                            ]
                        ],
                        [
                            [
                                45.31884788713778,
                                10.547474970538024
                            ],
                            [
                                45.4449740132639,
                                10.547474970538024
                            ],
                            [
                                45.4449740132639,
                                10.421348844411897
                            ],
                            [
                                45.31884788713778,
                                10.421348844411897
                            ]
                        ],
                        [
                            [
                                45.30553711687452,
                                10.484262800633454
                            ],
                            [
                                45.43166324300064,
                                10.484262800633454
                            ],
                            [
                                45.43166324300064,
                                10.358136674507326
                            ],
                            [
                                45.30553711687452,
                                10.358136674507326
                            ]
                        ],
                        [
                            [
                                45.29223001610356,
                                10.421050100330854
                            ],
                            [
                                45.41835614222968,
                                10.421050100330854
                            ],
                            [
                                45.41835614222968,
                                10.294923974204726
                            ],
                            [
                                45.29223001610356,
                                10.294923974204726
                            ]
                        ],
                        [
                            [
                                45.27892656196758,
                                10.357836873159643
                            ],
                            [
                                45.405052688093704,
                                10.357836873159643
                            ],
                            [
                                45.405052688093704,
                                10.231710747033516
                            ],
                            [
                                45.27892656196758,
                                10.231710747033516
                            ]
                        ],
                        [
                            [
                                45.26562672902063,
                                10.294623122648243
                            ],
                            [
                                45.391752855146756,
                                10.294623122648243
                            ],
                            [
                                45.391752855146756,
                                10.168496996522116
                            ],
                            [
                                45.26562672902063,
                                10.168496996522116
                            ]
                        ],
                        [
                            [
                                45.25233049381857,
                                10.231408852324103
                            ],
                            [
                                45.37845661994469,
                                10.231408852324103
                            ],
                            [
                                45.37845661994469,
                                10.105282726197975
                            ],
                            [
                                45.25233049381857,
                                10.105282726197975
                            ]
                        ],
                        [
                            [
                                45.23903783295475,
                                10.168194065713674
                            ],
                            [
                                45.365163959080874,
                                10.168194065713674
                            ],
                            [
                                45.365163959080874,
                                10.042067939587547
                            ],
                            [
                                45.23903783295475,
                                10.042067939587547
                            ]
                        ],
                        [
                            [
                                45.22574872108787,
                                10.104978766342445
                            ],
                            [
                                45.35187484721399,
                                10.104978766342445
                            ],
                            [
                                45.35187484721399,
                                9.978852640216317
                            ],
                            [
                                45.22574872108787,
                                9.978852640216317
                            ]
                        ],
                        [
                            [
                                45.212463135543516,
                                10.041762957734965
                            ],
                            [
                                45.33858926166964,
                                10.041762957734965
                            ],
                            [
                                45.33858926166964,
                                9.915636831608838
                            ],
                            [
                                45.212463135543516,
                                9.915636831608838
                            ]
                        ],
                        [
                            [
                                45.19918105104772,
                                9.978546643414822
                            ],
                            [
                                45.32530717717384,
                                9.978546643414822
                            ],
                            [
                                45.32530717717384,
                                9.852420517288694
                            ],
                            [
                                45.19918105104772,
                                9.852420517288694
                            ]
                        ],
                        [
                            [
                                55.81048934185217,
                                22.53893370919226
                            ],
                            [
                                55.93661546797829,
                                22.53893370919226
                            ],
                            [
                                55.93661546797829,
                                22.412807583066137
                            ],
                            [
                                55.81048934185217,
                                22.412807583066137
                            ]
                        ],
                        [
                            [
                                55.79601004192049,
                                22.47597779380856
                            ],
                            [
                                55.92213616804661,
                                22.47597779380856
                            ],
                            [
                                55.92213616804661,
                                22.349851667682437
                            ],
                            [
                                55.79601004192049,
                                22.349851667682437
                            ]
                        ],
                        [
                            [
                                55.781540018928226,
                                22.413020828632224
                            ],
                            [
                                55.90766614505435,
                                22.413020828632224
                            ],
                            [
                                55.90766614505435,
                                22.2868947025061
                            ],
                            [
                                55.781540018928226,
                                22.2868947025061
                            ]
                        ],
                        [
                            [
                                55.76707923716826,
                                22.35006281776864
                            ],
                            [
                                55.893205363294385,
                                22.35006281776864
                            ],
                            [
                                55.893205363294385,
                                22.223936691642518
                            ],
                            [
                                55.76707923716826,
                                22.223936691642518
                            ]
                        ],
                        [
                            [
                                55.75262765841132,
                                22.287103765318363
                            ],
                            [
                                55.878753784537444,
                                22.287103765318363
                            ],
                            [
                                55.878753784537444,
                                22.16097763919224
                            ],
                            [
                                55.75262765841132,
                                22.16097763919224
                            ]
                        ],
                        [
                            [
                                55.738185247168275,
                                22.22414367537713
                            ],
                            [
                                55.8643113732944,
                                22.22414367537713
                            ],
                            [
                                55.8643113732944,
                                22.098017549251008
                            ],
                            [
                                55.738185247168275,
                                22.098017549251008
                            ]
                        ],
                        [
                            [
                                55.7237519654284,
                                22.161182552035953
                            ],
                            [
                                55.84987809155452,
                                22.161182552035953
                            ],
                            [
                                55.84987809155452,
                                22.03505642590983
                            ],
                            [
                                55.7237519654284,
                                22.03505642590983
                            ]
                        ],
                        [
                            [
                                55.70932777791517,
                                22.098220399381052
                            ],
                            [
                                55.835453904041294,
                                22.098220399381052
                            ],
                            [
                                55.835453904041294,
                                21.97209427325493
                            ],
                            [
                                55.70932777791517,
                                21.97209427325493
                            ]
                        ],
                        [
                            [
                                55.69491264683242,
                                22.035257221493982
                            ],
                            [
                                55.821038772958545,
                                22.035257221493982
                            ],
                            [
                                55.821038772958545,
                                21.909131095367858
                            ],
                            [
                                55.69491264683242,
                                21.909131095367858
                            ]
                        ],
                        [
                            [
                                55.68050653712208,
                                21.972293022451574
                            ],
                            [
                                55.8066326632482,
                                21.972293022451574
                            ],
                            [
                                55.8066326632482,
                                21.84616689632545
                            ],
                            [
                                55.68050653712208,
                                21.84616689632545
                            ]
                        ],
                        [
                            [
                                55.66610941119558,
                                21.90932780632605
                            ],
                            [
                                55.7922355373217,
                                21.90932780632605
                            ],
                            [
                                55.7922355373217,
                                21.783201680199927
                            ],
                            [
                                55.66610941119558,
                                21.783201680199927
                            ]
                        ],
                        [
                            [
                                55.651721234207756,
                                21.846361577185032
                            ],
                            [
                                55.77784736033388,
                                21.846361577185032
                            ],
                            [
                                55.77784736033388,
                                21.720235451058908
                            ],
                            [
                                55.651721234207756,
                                21.720235451058908
                            ]
                        ],
                        [
                            [
                                55.63734196878029,
                                21.783394339091533
                            ],
                            [
                                55.763468094906415,
                                21.783394339091533
                            ],
                            [
                                55.763468094906415,
                                21.65726821296541
                            ],
                            [
                                55.63734196878029,
                                21.65726821296541
                            ]
                        ],
                        [
                            [
                                55.622971580277145,
                                21.720426096104042
                            ],
                            [
                                55.74909770640327,
                                21.720426096104042
                            ],
                            [
                                55.74909770640327,
                                21.594299969977918
                            ],
                            [
                                55.622971580277145,
                                21.594299969977918
                            ]
                        ],
                        [
                            [
                                55.60861003152803,
                                21.657456852276532
                            ],
                            [
                                55.734736157654154,
                                21.657456852276532
                            ],
                            [
                                55.734736157654154,
                                21.531330726150408
                            ],
                            [
                                55.60861003152803,
                                21.531330726150408
                            ]
                        ],
                        [
                            [
                                55.59425728810394,
                                21.59448661165847
                            ],
                            [
                                55.720383414230064,
                                21.59448661165847
                            ],
                            [
                                55.720383414230064,
                                21.468360485532347
                            ],
                            [
                                55.59425728810394,
                                21.468360485532347
                            ]
                        ],
                        [
                            [
                                55.57991331304041,
                                21.531515378294873
                            ],
                            [
                                55.70603943916653,
                                21.531515378294873
                            ],
                            [
                                55.70603943916653,
                                21.40538925216875
                            ],
                            [
                                55.57991331304041,
                                21.40538925216875
                            ]
                        ],
                        [
                            [
                                55.56557807211162,
                                21.46854315622637
                            ],
                            [
                                55.691704198237744,
                                21.46854315622637
                            ],
                            [
                                55.691704198237744,
                                21.342417030100247
                            ],
                            [
                                55.56557807211162,
                                21.342417030100247
                            ]
                        ],
                        [
                            [
                                55.55125152856173,
                                21.405569949489163
                            ],
                            [
                                55.67737765468785,
                                21.405569949489163
                            ],
                            [
                                55.67737765468785,
                                21.27944382336304
                            ],
                            [
                                55.55125152856173,
                                21.27944382336304
                            ]
                        ],
                        [
                            [
                                55.53693364770649,
                                21.342595762115092
                            ],
                            [
                                55.663059773832614,
                                21.342595762115092
                            ],
                            [
                                55.663059773832614,
                                21.216469635988968
                            ],
                            [
                                55.53693364770649,
                                21.216469635988968
                            ]
                        ],
                        [
                            [
                                55.522624394962136,
                                21.27962059813167
                            ],
                            [
                                55.64875052108826,
                                21.27962059813167
                            ],
                            [
                                55.64875052108826,
                                21.153494472005548
                            ],
                            [
                                55.522624394962136,
                                21.153494472005548
                            ]
                        ],
                        [
                            [
                                55.508323733876146,
                                21.216644461562122
                            ],
                            [
                                55.63444986000227,
                                21.216644461562122
                            ],
                            [
                                55.63444986000227,
                                21.090518335436
                            ],
                            [
                                55.508323733876146,
                                21.090518335436
                            ]
                        ],
                        [
                            [
                                55.49403163071889,
                                21.153667356425373
                            ],
                            [
                                55.62015775684501,
                                21.153667356425373
                            ],
                            [
                                55.62015775684501,
                                21.02754123029925
                            ],
                            [
                                55.49403163071889,
                                21.02754123029925
                            ]
                        ],
                        [
                            [
                                55.479748049239625,
                                21.090689286736122
                            ],
                            [
                                55.60587417536575,
                                21.090689286736122
                            ],
                            [
                                55.60587417536575,
                                20.96456316061
                            ],
                            [
                                55.479748049239625,
                                20.96456316061
                            ]
                        ],
                        [
                            [
                                55.46547295590461,
                                21.02771025650486
                            ],
                            [
                                55.59159908203073,
                                21.02771025650486
                            ],
                            [
                                55.59159908203073,
                                20.901584130378737
                            ],
                            [
                                55.46547295590461,
                                20.901584130378737
                            ]
                        ],
                        [
                            [
                                55.45120631466107,
                                20.96473026973787
                            ],
                            [
                                55.577332440787195,
                                20.96473026973787
                            ],
                            [
                                55.577332440787195,
                                20.838604143611747
                            ],
                            [
                                55.45120631466107,
                                20.838604143611747
                            ]
                        ],
                        [
                            [
                                55.43694809216577,
                                20.9017493304373
                            ],
                            [
                                55.563074218291895,
                                20.9017493304373
                            ],
                            [
                                55.563074218291895,
                                20.775623204311177
                            ],
                            [
                                55.43694809216577,
                                20.775623204311177
                            ]
                        ],
                        [
                            [
                                55.422698252563656,
                                20.83876744260117
                            ],
                            [
                                55.54882437868978,
                                20.83876744260117
                            ],
                            [
                                55.54882437868978,
                                20.712641316475047
                            ],
                            [
                                55.422698252563656,
                                20.712641316475047
                            ]
                        ],
                        [
                            [
                                55.408456762708006,
                                20.775784610223383
                            ],
                            [
                                55.53458288883413,
                                20.775784610223383
                            ],
                            [
                                55.53458288883413,
                                20.64965848409726
                            ],
                            [
                                55.408456762708006,
                                20.64965848409726
                            ]
                        ],
                        [
                            [
                                55.39422358693121,
                                20.71280083729378
                            ],
                            [
                                55.520349713057335,
                                20.71280083729378
                            ],
                            [
                                55.520349713057335,
                                20.586674711167657
                            ],
                            [
                                55.39422358693121,
                                20.586674711167657
                            ]
                        ],
                        [
                            [
                                55.37999869228123,
                                20.64981612779815
                            ],
                            [
                                55.50612481840735,
                                20.64981612779815
                            ],
                            [
                                55.50612481840735,
                                20.523690001672026
                            ],
                            [
                                55.37999869228123,
                                20.523690001672026
                            ]
                        ],
                        [
                            [
                                55.36578204327749,
                                20.586830485718313
                            ],
                            [
                                55.491908169403615,
                                20.586830485718313
                            ],
                            [
                                55.491908169403615,
                                20.46070435959219
                            ],
                            [
                                55.36578204327749,
                                20.46070435959219
                            ]
                        ],
                        [
                            [
                                55.351573607158976,
                                20.52384391503201
                            ],
                            [
                                55.4776997332851,
                                20.52384391503201
                            ],
                            [
                                55.4776997332851,
                                20.397717788905886
                            ],
                            [
                                55.351573607158976,
                                20.397717788905886
                            ]
                        ],
                        [
                            [
                                55.337373348633385,
                                20.46085641971313
                            ],
                            [
                                55.46349947475951,
                                20.46085641971313
                            ],
                            [
                                55.46349947475951,
                                20.334730293587008
                            ],
                            [
                                55.337373348633385,
                                20.334730293587008
                            ]
                        ],
                        [
                            [
                                55.323181235127066,
                                20.397868003731542
                            ],
                            [
                                55.44930736125319,
                                20.397868003731542
                            ],
                            [
                                55.44930736125319,
                                20.271741877605418
                            ],
                            [
                                55.323181235127066,
                                20.271741877605418
                            ]
                        ],
                        [
                            [
                                55.30899723153578,
                                20.33487867105326
                            ],
                            [
                                55.4351233576619,
                                20.33487867105326
                            ],
                            [
                                55.4351233576619,
                                20.208752544927137
                            ],
                            [
                                55.30899723153578,
                                20.208752544927137
                            ]
                        ],
                        [
                            [
                                55.294821305467956,
                                20.27188842564039
                            ],
                            [
                                55.42094743159408,
                                20.27188842564039
                            ],
                            [
                                55.42094743159408,
                                20.145762299514267
                            ],
                            [
                                55.294821305467956,
                                20.145762299514267
                            ]
                        ],
                        [
                            [
                                55.28065342200386,
                                20.20889727145122
                            ],
                            [
                                55.40677954812998,
                                20.20889727145122
                            ],
                            [
                                55.40677954812998,
                                20.082771145325097
                            ],
                            [
                                55.28065342200386,
                                20.082771145325097
                            ]
                        ],
                        [
                            [
                                55.26649354893692,
                                20.1459052124402
                            ],
                            [
                                55.392619675063045,
                                20.1459052124402
                            ],
                            [
                                55.392619675063045,
                                20.019779086314077
                            ],
                            [
                                55.26649354893692,
                                20.019779086314077
                            ]
                        ],
                        [
                            [
                                55.25234165153158,
                                20.082912252557943
                            ],
                            [
                                55.378467777657704,
                                20.082912252557943
                            ],
                            [
                                55.378467777657704,
                                19.95678612643182
                            ],
                            [
                                55.25234165153158,
                                19.95678612643182
                            ]
                        ],
                        [
                            [
                                55.238197697761166,
                                20.01991839575135
                            ],
                            [
                                55.36432382388729,
                                20.01991839575135
                            ],
                            [
                                55.36432382388729,
                                19.893792269625227
                            ],
                            [
                                55.238197697761166,
                                19.893792269625227
                            ]
                        ],
                        [
                            [
                                55.224061653072305,
                                19.95692364596353
                            ],
                            [
                                55.35018777919843,
                                19.95692364596353
                            ],
                            [
                                55.35018777919843,
                                19.830797519837407
                            ],
                            [
                                55.224061653072305,
                                19.830797519837407
                            ]
                        ],
                        [
                            [
                                55.209933485614755,
                                19.893928007133933
                            ],
                            [
                                55.33605961174088,
                                19.893928007133933
                            ],
                            [
                                55.33605961174088,
                                19.76780188100781
                            ],
                            [
                                55.209933485614755,
                                19.76780188100781
                            ]
                        ],
                        [
                            [
                                55.195813161013746,
                                19.830931483198203
                            ],
                            [
                                55.32193928713987,
                                19.830931483198203
                            ],
                            [
                                55.32193928713987,
                                19.70480535707208
                            ],
                            [
                                55.195813161013746,
                                19.70480535707208
                            ]
                        ],
                        [
                            [
                                55.18170064760013,
                                19.767934078088413
                            ],
                            [
                                55.307826773726255,
                                19.767934078088413
                            ],
                            [
                                55.307826773726255,
                                19.64180795196229
                            ],
                            [
                                55.18170064760013,
                                19.64180795196229
                            ]
                        ],
                        [
                            [
                                55.16759591117589,
                                19.704935795732982
                            ],
                            [
                                55.29372203730201,
                                19.704935795732982
                            ],
                            [
                                55.29372203730201,
                                19.57880966960686
                            ],
                            [
                                55.16759591117589,
                                19.57880966960686
                            ]
                        ],
                        [
                            [
                                55.15349892024443,
                                19.641936640056674
                            ],
                            [
                                55.279625046370555,
                                19.641936640056674
                            ],
                            [
                                55.279625046370555,
                                19.51581051393055
                            ],
                            [
                                55.15349892024443,
                                19.51581051393055
                            ]
                        ],
                        [
                            [
                                55.139409640784365,
                                19.57893661498069
                            ],
                            [
                                55.26553576691049,
                                19.57893661498069
                            ],
                            [
                                55.26553576691049,
                                19.452810488854567
                            ],
                            [
                                55.139409640784365,
                                19.452810488854567
                            ]
                        ],
                        [
                            [
                                55.12532804147155,
                                19.51593572442261
                            ],
                            [
                                55.25145416759767,
                                19.51593572442261
                            ],
                            [
                                55.25145416759767,
                                19.389809598296488
                            ],
                            [
                                55.12532804147155,
                                19.389809598296488
                            ]
                        ],
                        [
                            [
                                55.111254088459276,
                                19.452933972296552
                            ],
                            [
                                55.2373802145854,
                                19.452933972296552
                            ],
                            [
                                55.2373802145854,
                                19.32680784617043
                            ],
                            [
                                55.111254088459276,
                                19.32680784617043
                            ]
                        ],
                        [
                            [
                                55.09718775060044,
                                19.389931362513053
                            ],
                            [
                                55.223313876726564,
                                19.389931362513053
                            ],
                            [
                                55.223313876726564,
                                19.26380523638693
                            ],
                            [
                                55.09718775060044,
                                19.26380523638693
                            ]
                        ],
                        [
                            [
                                55.08312899420988,
                                19.32692789897916
                            ],
                            [
                                55.209255120336,
                                19.32692789897916
                            ],
                            [
                                55.209255120336,
                                19.200801772853037
                            ],
                            [
                                55.08312899420988,
                                19.200801772853037
                            ]
                        ],
                        [
                            [
                                55.06907778831898,
                                19.26392358559849
                            ],
                            [
                                55.1952039144451,
                                19.26392358559849
                            ],
                            [
                                55.1952039144451,
                                19.137797459472367
                            ],
                            [
                                55.06907778831898,
                                19.137797459472367
                            ]
                        ],
                        [
                            [
                                55.0550340994088,
                                19.200918426271173
                            ],
                            [
                                55.181160225534924,
                                19.200918426271173
                            ],
                            [
                                55.181160225534924,
                                19.07479230014505
                            ],
                            [
                                55.0550340994088,
                                19.07479230014505
                            ]
                        ],
                        [
                            [
                                55.04099789667439,
                                19.137912424893923
                            ],
                            [
                                55.167124022800515,
                                19.137912424893923
                            ],
                            [
                                55.167124022800515,
                                19.0117862987678
                            ],
                            [
                                55.04099789667439,
                                19.0117862987678
                            ]
                        ],
                        [
                            [
                                55.02696914677261,
                                19.074905585360053
                            ],
                            [
                                55.15309527289873,
                                19.074905585360053
                            ],
                            [
                                55.15309527289873,
                                18.94877945923393
                            ],
                            [
                                55.02696914677261,
                                18.94877945923393
                            ]
                        ],
                        [
                            [
                                55.01294781906207,
                                19.011897911559533
                            ],
                            [
                                55.139073945188194,
                                19.011897911559533
                            ],
                            [
                                55.139073945188194,
                                18.88577178543341
                            ],
                            [
                                55.01294781906207,
                                18.88577178543341
                            ]
                        ],
                        [
                            [
                                54.998933880365506,
                                18.948889407378932
                            ],
                            [
                                55.12506000649163,
                                18.948889407378932
                            ],
                            [
                                55.12506000649163,
                                18.822763281252808
                            ],
                            [
                                54.998933880365506,
                                18.822763281252808
                            ]
                        ],
                        [
                            [
                                54.98492729955193,
                                18.885880076701532
                            ],
                            [
                                55.11105342567805,
                                18.885880076701532
                            ],
                            [
                                55.11105342567805,
                                18.75975395057541
                            ],
                            [
                                54.98492729955193,
                                18.75975395057541
                            ]
                        ],
                        [
                            [
                                54.97092804557539,
                                18.82286992340726
                            ],
                            [
                                55.097054171701515,
                                18.82286992340726
                            ],
                            [
                                55.097054171701515,
                                18.696743797281137
                            ],
                            [
                                54.97092804557539,
                                18.696743797281137
                            ]
                        ],
                        [
                            [
                                54.95693608549767,
                                18.75985895137283
                            ],
                            [
                                55.08306221162379,
                                18.75985895137283
                            ],
                            [
                                55.08306221162379,
                                18.633732825246707
                            ],
                            [
                                54.95693608549767,
                                18.633732825246707
                            ]
                        ],
                        [
                            [
                                54.942951389091455,
                                18.69684716447162
                            ],
                            [
                                55.06907751521758,
                                18.69684716447162
                            ],
                            [
                                55.06907751521758,
                                18.570721038345496
                            ],
                            [
                                54.942951389091455,
                                18.570721038345496
                            ]
                        ],
                        [
                            [
                                54.92897392358175,
                                18.63383456657382
                            ],
                            [
                                55.055100049707875,
                                18.63383456657382
                            ],
                            [
                                55.055100049707875,
                                18.507708440447697
                            ],
                            [
                                54.92897392358175,
                                18.507708440447697
                            ]
                        ],
                        [
                            [
                                54.91500365890522,
                                18.570821161546373
                            ],
                            [
                                55.041129785031345,
                                18.570821161546373
                            ],
                            [
                                55.041129785031345,
                                18.44469503542025
                            ],
                            [
                                54.91500365890522,
                                18.44469503542025
                            ]
                        ],
                        [
                            [
                                54.90104056244168,
                                18.507806953253073
                            ],
                            [
                                55.0271666885678,
                                18.507806953253073
                            ],
                            [
                                55.0271666885678,
                                18.38168082712695
                            ],
                            [
                                54.90104056244168,
                                18.38168082712695
                            ]
                        ],
                        [
                            [
                                54.887084604286756,
                                18.444791945554492
                            ],
                            [
                                55.01321073041288,
                                18.444791945554492
                            ],
                            [
                                55.01321073041288,
                                18.318665819428368
                            ],
                            [
                                54.887084604286756,
                                18.318665819428368
                            ]
                        ],
                        [
                            [
                                54.873135751981636,
                                18.38177614230809
                            ],
                            [
                                54.99926187810776,
                                18.38177614230809
                            ],
                            [
                                54.99926187810776,
                                18.255650016181967
                            ],
                            [
                                54.873135751981636,
                                18.255650016181967
                            ]
                        ],
                        [
                            [
                                54.859193975779185,
                                18.31875954736818
                            ],
                            [
                                54.98532010190531,
                                18.31875954736818
                            ],
                            [
                                54.98532010190531,
                                18.192633421242057
                            ],
                            [
                                54.859193975779185,
                                18.192633421242057
                            ]
                        ],
                        [
                            [
                                54.84525924337219,
                                18.25574216458596
                            ],
                            [
                                54.971385369498314,
                                18.25574216458596
                            ],
                            [
                                54.971385369498314,
                                18.129616038459837
                            ],
                            [
                                54.84525924337219,
                                18.129616038459837
                            ]
                        ],
                        [
                            [
                                54.831331525175486,
                                18.192723997809583
                            ],
                            [
                                54.95745765130161,
                                18.192723997809583
                            ],
                            [
                                54.95745765130161,
                                18.06659787168346
                            ],
                            [
                                54.831331525175486,
                                18.06659787168346
                            ]
                        ],
                        [
                            [
                                54.81741078903342,
                                18.12970505088409
                            ],
                            [
                                54.94353691515954,
                                18.12970505088409
                            ],
                            [
                                54.94353691515954,
                                18.003578924757967
                            ],
                            [
                                54.81741078903342,
                                18.003578924757967
                            ]
                        ],
                        [
                            [
                                54.803497005508206,
                                18.06668532765153
                            ],
                            [
                                54.92962313163433,
                                18.06668532765153
                            ],
                            [
                                54.92962313163433,
                                17.940559201525407
                            ],
                            [
                                54.803497005508206,
                                17.940559201525407
                            ]
                        ],
                        [
                            [
                                54.7895901426102,
                                18.00366483195087
                            ],
                            [
                                54.915716268736325,
                                18.00366483195087
                            ],
                            [
                                54.915716268736325,
                                17.877538705824747
                            ],
                            [
                                54.7895901426102,
                                17.877538705824747
                            ]
                        ],
                        [
                            [
                                54.775690171047486,
                                17.940643567618153
                            ],
                            [
                                54.90181629717361,
                                17.940643567618153
                            ],
                            [
                                54.90181629717361,
                                17.81451744149203
                            ],
                            [
                                54.775690171047486,
                                17.81451744149203
                            ]
                        ],
                        [
                            [
                                54.76179705898017,
                                17.87762153848639
                            ],
                            [
                                54.887923185106295,
                                17.87762153848639
                            ],
                            [
                                54.887923185106295,
                                17.751495412360267
                            ],
                            [
                                54.76179705898017,
                                17.751495412360267
                            ]
                        ],
                        [
                            [
                                54.7479107772685,
                                17.81459874838563
                            ],
                            [
                                54.874036903394625,
                                17.81459874838563
                            ],
                            [
                                54.874036903394625,
                                17.688472622259507
                            ],
                            [
                                54.7479107772685,
                                17.688472622259507
                            ]
                        ],
                        [
                            [
                                54.73403129422085,
                                17.751575201143023
                            ],
                            [
                                54.86015742034697,
                                17.751575201143023
                            ],
                            [
                                54.86015742034697,
                                17.6254490750169
                            ],
                            [
                                54.73403129422085,
                                17.6254490750169
                            ]
                        ],
                        [
                            [
                                54.72015858084974,
                                17.688550900582783
                            ],
                            [
                                54.846284706975865,
                                17.688550900582783
                            ],
                            [
                                54.846284706975865,
                                17.56242477445666
                            ],
                            [
                                54.72015858084974,
                                17.56242477445666
                            ]
                        ],
                        [
                            [
                                54.70629260560999,
                                17.62552585052619
                            ],
                            [
                                54.83241873173611,
                                17.62552585052619
                            ],
                            [
                                54.83241873173611,
                                17.499399724400067
                            ],
                            [
                                54.70629260560999,
                                17.499399724400067
                            ]
                        ],
                        [
                            [
                                54.69243333966153,
                                17.562500054791713
                            ],
                            [
                                54.81855946578765,
                                17.562500054791713
                            ],
                            [
                                54.81855946578765,
                                17.43637392866559
                            ],
                            [
                                54.69243333966153,
                                17.43637392866559
                            ]
                        ],
                        [
                            [
                                54.6785807516074,
                                17.49947351719491
                            ],
                            [
                                54.80470687773352,
                                17.49947351719491
                            ],
                            [
                                54.80470687773352,
                                17.373347391068787
                            ],
                            [
                                54.6785807516074,
                                17.373347391068787
                            ]
                        ],
                        [
                            [
                                54.66473481275009,
                                17.436446241548563
                            ],
                            [
                                54.790860938876214,
                                17.436446241548563
                            ],
                            [
                                54.790860938876214,
                                17.31032011542244
                            ],
                            [
                                54.66473481275009,
                                17.31032011542244
                            ]
                        ],
                        [
                            [
                                54.65089549183763,
                                17.373418231662562
                            ],
                            [
                                54.777021617963754,
                                17.373418231662562
                            ],
                            [
                                54.777021617963754,
                                17.247292105536438
                            ],
                            [
                                54.65089549183763,
                                17.247292105536438
                            ]
                        ],
                        [
                            [
                                54.6370627603199,
                                17.310389491344043
                            ],
                            [
                                54.763188886446024,
                                17.310389491344043
                            ],
                            [
                                54.763188886446024,
                                17.18426336521792
                            ],
                            [
                                54.6370627603199,
                                17.18426336521792
                            ]
                        ],
                        [
                            [
                                54.62323658708679,
                                17.247360024397352
                            ],
                            [
                                54.74936271321291,
                                17.247360024397352
                            ],
                            [
                                54.74936271321291,
                                17.121233898271228
                            ],
                            [
                                54.62323658708679,
                                17.121233898271228
                            ]
                        ],
                        [
                            [
                                54.609416943735695,
                                17.1843298346241
                            ],
                            [
                                54.73554306986182,
                                17.1843298346241
                            ],
                            [
                                54.73554306986182,
                                17.058203708497977
                            ],
                            [
                                54.609416943735695,
                                17.058203708497977
                            ]
                        ],
                        [
                            [
                                54.59560379929018,
                                17.1212989258231
                            ],
                            [
                                54.721729925416305,
                                17.1212989258231
                            ],
                            [
                                54.721729925416305,
                                16.995172799696977
                            ],
                            [
                                54.59560379929018,
                                16.995172799696977
                            ]
                        ],
                        [
                            [
                                54.581797125485465,
                                17.0582673017905
                            ],
                            [
                                54.70792325161159,
                                17.0582673017905
                            ],
                            [
                                54.70792325161159,
                                16.932141175664377
                            ],
                            [
                                54.581797125485465,
                                16.932141175664377
                            ]
                        ],
                        [
                            [
                                54.5679968914952,
                                16.99523496631972
                            ],
                            [
                                54.69412301762132,
                                16.99523496631972
                            ],
                            [
                                54.69412301762132,
                                16.869108840193597
                            ],
                            [
                                54.5679968914952,
                                16.869108840193597
                            ]
                        ],
                        [
                            [
                                54.55420306919254,
                                16.932201923201504
                            ],
                            [
                                54.68032919531866,
                                16.932201923201504
                            ],
                            [
                                54.68032919531866,
                                16.80607579707538
                            ],
                            [
                                54.55420306919254,
                                16.80607579707538
                            ]
                        ],
                        [
                            [
                                54.54041562788991,
                                16.869168176223923
                            ],
                            [
                                54.66654175401603,
                                16.869168176223923
                            ],
                            [
                                54.66654175401603,
                                16.7430420500978
                            ],
                            [
                                54.54041562788991,
                                16.7430420500978
                            ]
                        ],
                        [
                            [
                                54.526634539595186,
                                16.8061337291724
                            ],
                            [
                                54.65276066572131,
                                16.8061337291724
                            ],
                            [
                                54.65276066572131,
                                16.680007603046278
                            ],
                            [
                                54.526634539595186,
                                16.680007603046278
                            ]
                        ],
                        [
                            [
                                54.512859773758095,
                                16.743098585829742
                            ],
                            [
                                54.63898589988422,
                                16.743098585829742
                            ],
                            [
                                54.63898589988422,
                                16.616972459703618
                            ],
                            [
                                54.512859773758095,
                                16.616972459703618
                            ]
                        ],
                        [
                            [
                                54.499091302526246,
                                16.68006274997613
                            ],
                            [
                                54.62521742865237,
                                16.68006274997613
                            ],
                            [
                                54.62521742865237,
                                16.553936623850007
                            ],
                            [
                                54.499091302526246,
                                16.553936623850007
                            ]
                        ],
                        [
                            [
                                54.48532909548343,
                                16.617026225389182
                            ],
                            [
                                54.61145522160955,
                                16.617026225389182
                            ],
                            [
                                54.61145522160955,
                                16.490900099263058
                            ],
                            [
                                54.48532909548343,
                                16.490900099263058
                            ]
                        ],
                        [
                            [
                                54.47157312491235,
                                16.553989015843893
                            ],
                            [
                                54.59769925103847,
                                16.553989015843893
                            ],
                            [
                                54.59769925103847,
                                16.42786288971777
                            ],
                            [
                                54.47157312491235,
                                16.42786288971777
                            ]
                        ],
                        [
                            [
                                54.45782336053096,
                                16.490951125112762
                            ],
                            [
                                54.58394948665708,
                                16.490951125112762
                            ],
                            [
                                54.58394948665708,
                                16.364824998986638
                            ],
                            [
                                54.45782336053096,
                                16.364824998986638
                            ]
                        ],
                        [
                            [
                                54.444079774099215,
                                16.42791255696569
                            ],
                            [
                                54.57020590022534,
                                16.42791255696569
                            ],
                            [
                                54.57020590022534,
                                16.301786430839567
                            ],
                            [
                                54.444079774099215,
                                16.301786430839567
                            ]
                        ],
                        [
                            [
                                54.43034233743669,
                                16.3648733151701
                            ],
                            [
                                54.556468463562815,
                                16.3648733151701
                            ],
                            [
                                54.556468463562815,
                                16.238747189043977
                            ],
                            [
                                54.43034233743669,
                                16.238747189043977
                            ]
                        ],
                        [
                            [
                                54.416611020468146,
                                16.301833403490882
                            ],
                            [
                                54.54273714659427,
                                16.301833403490882
                            ],
                            [
                                54.54273714659427,
                                16.175707277364758
                            ],
                            [
                                54.416611020468146,
                                16.175707277364758
                            ]
                        ],
                        [
                            [
                                54.40288579579748,
                                16.238792825690453
                            ],
                            [
                                54.529011921923605,
                                16.238792825690453
                            ],
                            [
                                54.529011921923605,
                                16.11266669956433
                            ],
                            [
                                54.40288579579748,
                                16.11266669956433
                            ]
                        ],
                        [
                            [
                                54.38916663348165,
                                16.175751585528772
                            ],
                            [
                                54.515292759607775,
                                16.175751585528772
                            ],
                            [
                                54.515292759607775,
                                16.049625459402648
                            ],
                            [
                                54.38916663348165,
                                16.049625459402648
                            ]
                        ],
                        [
                            [
                                54.37545350625437,
                                16.112709686763342
                            ],
                            [
                                54.50157963238049,
                                16.112709686763342
                            ],
                            [
                                54.50157963238049,
                                15.986583560637216
                            ],
                            [
                                54.37545350625437,
                                15.986583560637216
                            ]
                        ],
                        [
                            [
                                45.097700533651775,
                                9.962910452213494
                            ],
                            [
                                45.2238266597779,
                                9.962910452213494
                            ],
                            [
                                45.2238266597779,
                                9.836784326087367
                            ],
                            [
                                45.097700533651775,
                                9.836784326087367
                            ]
                        ],
                        [
                            [
                                45.08442522467638,
                                10.02611619958894
                            ],
                            [
                                45.210551350802504,
                                10.02611619958894
                            ],
                            [
                                45.210551350802504,
                                9.899990073462812
                            ],
                            [
                                45.08442522467638,
                                9.899990073462812
                            ]
                        ],
                        [
                            [
                                45.071146419692575,
                                10.089321441789613
                            ],
                            [
                                45.1972725458187,
                                10.089321441789613
                            ],
                            [
                                45.1972725458187,
                                9.963195315663485
                            ],
                            [
                                45.071146419692575,
                                9.963195315663485
                            ]
                        ],
                        [
                            [
                                45.05786409608394,
                                10.152526175303864
                            ],
                            [
                                45.18399022221006,
                                10.152526175303864
                            ],
                            [
                                45.18399022221006,
                                10.026400049177736
                            ],
                            [
                                45.05786409608394,
                                10.026400049177736
                            ]
                        ],
                        [
                            [
                                45.04457822857227,
                                10.215730396619094
                            ],
                            [
                                45.170704354698394,
                                10.215730396619094
                            ],
                            [
                                45.170704354698394,
                                10.089604270492966
                            ],
                            [
                                45.04457822857227,
                                10.089604270492966
                            ]
                        ],
                        [
                            [
                                45.03128879447393,
                                10.278934102221744
                            ],
                            [
                                45.157414920600054,
                                10.278934102221744
                            ],
                            [
                                45.157414920600054,
                                10.152807976095616
                            ],
                            [
                                45.03128879447393,
                                10.152807976095616
                            ]
                        ],
                        [
                            [
                                45.01799576843817,
                                10.342137288597293
                            ],
                            [
                                45.14412189456429,
                                10.342137288597293
                            ],
                            [
                                45.14412189456429,
                                10.216011162471165
                            ],
                            [
                                45.01799576843817,
                                10.216011162471165
                            ]
                        ],
                        [
                            [
                                45.004699127711525,
                                10.405339952230264
                            ],
                            [
                                45.13082525383765,
                                10.405339952230264
                            ],
                            [
                                45.13082525383765,
                                10.279213826104137
                            ],
                            [
                                45.004699127711525,
                                10.279213826104137
                            ]
                        ],
                        [
                            [
                                44.99139884686972,
                                10.468542089604174
                            ],
                            [
                                45.117524972995845,
                                10.468542089604174
                            ],
                            [
                                45.117524972995845,
                                10.342415963478047
                            ],
                            [
                                44.99139884686972,
                                10.342415963478047
                            ]
                        ],
                        [
                            [
                                44.978094903083516,
                                10.531743697201565
                            ],
                            [
                                45.10422102920964,
                                10.531743697201565
                            ],
                            [
                                45.10422102920964,
                                10.405617571075437
                            ],
                            [
                                44.978094903083516,
                                10.405617571075437
                            ]
                        ],
                        [
                            [
                                44.96478727085882,
                                10.594944771503993
                            ],
                            [
                                45.090913396984945,
                                10.594944771503993
                            ],
                            [
                                45.090913396984945,
                                10.468818645377866
                            ],
                            [
                                44.96478727085882,
                                10.468818645377866
                            ]
                        ],
                        [
                            [
                                44.95147592729296,
                                10.658145308991994
                            ],
                            [
                                45.077602053419085,
                                10.658145308991994
                            ],
                            [
                                45.077602053419085,
                                10.532019182865866
                            ],
                            [
                                44.95147592729296,
                                10.532019182865866
                            ]
                        ],
                        [
                            [
                                44.938160846819414,
                                10.721345306145073
                            ],
                            [
                                45.06428697294554,
                                10.721345306145073
                            ],
                            [
                                45.06428697294554,
                                10.595219180018946
                            ],
                            [
                                44.938160846819414,
                                10.595219180018946
                            ]
                        ],
                        [
                            [
                                44.9248420064641,
                                10.784544759441724
                            ],
                            [
                                45.05096813259022,
                                10.784544759441724
                            ],
                            [
                                45.05096813259022,
                                10.658418633315597
                            ],
                            [
                                44.9248420064641,
                                10.658418633315597
                            ]
                        ],
                        [
                            [
                                44.911519380574035,
                                10.847743665359424
                            ],
                            [
                                45.03764550670016,
                                10.847743665359424
                            ],
                            [
                                45.03764550670016,
                                10.721617539233296
                            ],
                            [
                                44.911519380574035,
                                10.721617539233296
                            ]
                        ],
                        [
                            [
                                44.898192946107635,
                                10.910942020374574
                            ],
                            [
                                45.02431907223376,
                                10.910942020374574
                            ],
                            [
                                45.02431907223376,
                                10.784815894248446
                            ],
                            [
                                44.898192946107635,
                                10.784815894248446
                            ]
                        ],
                        [
                            [
                                44.88486267733572,
                                10.974139820962554
                            ],
                            [
                                45.01098880346184,
                                10.974139820962554
                            ],
                            [
                                45.01098880346184,
                                10.848013694836427
                            ],
                            [
                                44.88486267733572,
                                10.848013694836427
                            ]
                        ],
                        [
                            [
                                44.87152855113351,
                                11.037337063597654
                            ],
                            [
                                44.99765467725963,
                                11.037337063597654
                            ],
                            [
                                44.99765467725963,
                                10.911210937471527
                            ],
                            [
                                44.87152855113351,
                                10.911210937471527
                            ]
                        ],
                        [
                            [
                                44.85819054170427,
                                11.100533744753085
                            ],
                            [
                                44.98431666783039,
                                11.100533744753085
                            ],
                            [
                                44.98431666783039,
                                10.974407618626957
                            ],
                            [
                                44.85819054170427,
                                10.974407618626957
                            ]
                        ],
                        [
                            [
                                44.84484862583897,
                                11.163729860901004
                            ],
                            [
                                44.970974751965095,
                                11.163729860901004
                            ],
                            [
                                44.970974751965095,
                                11.037603734774876
                            ],
                            [
                                44.84484862583897,
                                11.037603734774876
                            ]
                        ],
                        [
                            [
                                44.83150277766257,
                                11.226925408512454
                            ],
                            [
                                44.957628903788695,
                                11.226925408512454
                            ],
                            [
                                44.957628903788695,
                                11.100799282386326
                            ],
                            [
                                44.83150277766257,
                                11.100799282386326
                            ]
                        ],
                        [
                            [
                                44.818152973892005,
                                11.290120384057383
                            ],
                            [
                                44.94427910001813,
                                11.290120384057383
                            ],
                            [
                                44.94427910001813,
                                11.163994257931256
                            ],
                            [
                                44.818152973892005,
                                11.163994257931256
                            ]
                        ],
                        [
                            [
                                44.80479918856799,
                                11.353314784004654
                            ],
                            [
                                44.930925314694115,
                                11.353314784004654
                            ],
                            [
                                44.930925314694115,
                                11.227188657878527
                            ],
                            [
                                44.80479918856799,
                                11.227188657878527
                            ]
                        ],
                        [
                            [
                                44.79144139832747,
                                11.416508604821964
                            ],
                            [
                                44.917567524453595,
                                11.416508604821964
                            ],
                            [
                                44.917567524453595,
                                11.290382478695836
                            ],
                            [
                                44.79144139832747,
                                11.290382478695836
                            ]
                        ],
                        [
                            [
                                44.7780795771355,
                                11.479701842975894
                            ],
                            [
                                44.904205703261624,
                                11.479701842975894
                            ],
                            [
                                44.904205703261624,
                                11.353575716849766
                            ],
                            [
                                44.7780795771355,
                                11.353575716849766
                            ]
                        ],
                        [
                            [
                                44.76471370154315,
                                11.542894494931884
                            ],
                            [
                                44.890839827669275,
                                11.542894494931884
                            ],
                            [
                                44.890839827669275,
                                11.416768368805757
                            ],
                            [
                                44.76471370154315,
                                11.416768368805757
                            ]
                        ],
                        [
                            [
                                44.75134374543387,
                                11.606086557154255
                            ],
                            [
                                44.877469871559995,
                                11.606086557154255
                            ],
                            [
                                44.877469871559995,
                                11.479960431028127
                            ],
                            [
                                44.75134374543387,
                                11.479960431028127
                            ]
                        ],
                        [
                            [
                                44.737969685279815,
                                11.669278026106104
                            ],
                            [
                                44.86409581140594,
                                11.669278026106104
                            ],
                            [
                                44.86409581140594,
                                11.543151899979977
                            ],
                            [
                                44.737969685279815,
                                11.543151899979977
                            ]
                        ],
                        [
                            [
                                44.7245914948801,
                                11.732468898249394
                            ],
                            [
                                44.850717621006225,
                                11.732468898249394
                            ],
                            [
                                44.850717621006225,
                                11.606342772123266
                            ],
                            [
                                44.7245914948801,
                                11.606342772123266
                            ]
                        ],
                        [
                            [
                                44.711209150626885,
                                11.795659170044924
                            ],
                            [
                                44.83733527675301,
                                11.795659170044924
                            ],
                            [
                                44.83733527675301,
                                11.669533043918797
                            ],
                            [
                                44.711209150626885,
                                11.669533043918797
                            ]
                        ],
                        [
                            [
                                44.69782262622732,
                                11.858848837952253
                            ],
                            [
                                44.823948752353445,
                                11.858848837952253
                            ],
                            [
                                44.823948752353445,
                                11.732722711826126
                            ],
                            [
                                44.69782262622732,
                                11.732722711826126
                            ]
                        ],
                        [
                            [
                                44.684431897994045,
                                11.922037898429753
                            ],
                            [
                                44.81055802412017,
                                11.922037898429753
                            ],
                            [
                                44.81055802412017,
                                11.795911772303626
                            ],
                            [
                                44.684431897994045,
                                11.795911772303626
                            ]
                        ],
                        [
                            [
                                44.67103693955258,
                                11.985226347934605
                            ],
                            [
                                44.7971630656787,
                                11.985226347934605
                            ],
                            [
                                44.7971630656787,
                                11.859100221808477
                            ],
                            [
                                44.67103693955258,
                                11.859100221808477
                            ]
                        ],
                        [
                            [
                                44.65763772712351,
                                12.048414182922754
                            ],
                            [
                                44.78376385324963,
                                12.048414182922754
                            ],
                            [
                                44.78376385324963,
                                11.922288056796626
                            ],
                            [
                                44.65763772712351,
                                11.922288056796626
                            ]
                        ],
                        [
                            [
                                44.64423423425116,
                                12.111601399848874
                            ],
                            [
                                44.77036036037728,
                                12.111601399848874
                            ],
                            [
                                44.77036036037728,
                                11.985475273722747
                            ],
                            [
                                44.64423423425116,
                                11.985475273722747
                            ]
                        ],
                        [
                            [
                                44.63082643641329,
                                12.174787995166454
                            ],
                            [
                                44.75695256253941,
                                12.174787995166454
                            ],
                            [
                                44.75695256253941,
                                12.048661869040327
                            ],
                            [
                                44.63082643641329,
                                12.048661869040327
                            ]
                        ],
                        [
                            [
                                44.617414309032505,
                                12.237973965327683
                            ],
                            [
                                44.74354043515863,
                                12.237973965327683
                            ],
                            [
                                44.74354043515863,
                                12.111847839201555
                            ],
                            [
                                44.617414309032505,
                                12.111847839201555
                            ]
                        ],
                        [
                            [
                                44.60399782552999,
                                12.301159306783493
                            ],
                            [
                                44.73012395165611,
                                12.301159306783493
                            ],
                            [
                                44.73012395165611,
                                12.175033180657366
                            ],
                            [
                                44.60399782552999,
                                12.175033180657366
                            ]
                        ],
                        [
                            [
                                44.590576961899565,
                                12.364344015983544
                            ],
                            [
                                44.71670308802569,
                                12.364344015983544
                            ],
                            [
                                44.71670308802569,
                                12.238217889857417
                            ],
                            [
                                44.590576961899565,
                                12.238217889857417
                            ]
                        ],
                        [
                            [
                                44.57715169147187,
                                12.427528089376194
                            ],
                            [
                                44.70327781759799,
                                12.427528089376194
                            ],
                            [
                                44.70327781759799,
                                12.301401963250067
                            ],
                            [
                                44.57715169147187,
                                12.301401963250067
                            ]
                        ],
                        [
                            [
                                44.56372199015448,
                                12.490711523408503
                            ],
                            [
                                44.6898481162806,
                                12.490711523408503
                            ],
                            [
                                44.6898481162806,
                                12.364585397282376
                            ],
                            [
                                44.56372199015448,
                                12.364585397282376
                            ]
                        ],
                        [
                            [
                                44.55028783118145,
                                12.553894314526223
                            ],
                            [
                                44.676413957307574,
                                12.553894314526223
                            ],
                            [
                                44.676413957307574,
                                12.427768188400096
                            ],
                            [
                                44.55028783118145,
                                12.427768188400096
                            ]
                        ],
                        [
                            [
                                44.536849190369686,
                                12.617076459173784
                            ],
                            [
                                44.66297531649581,
                                12.617076459173784
                            ],
                            [
                                44.66297531649581,
                                12.490950333047657
                            ],
                            [
                                44.536849190369686,
                                12.490950333047657
                            ]
                        ],
                        [
                            [
                                44.523406040868565,
                                12.680257953794273
                            ],
                            [
                                44.64953216699469,
                                12.680257953794273
                            ],
                            [
                                44.64953216699469,
                                12.554131827668146
                            ],
                            [
                                44.523406040868565,
                                12.554131827668146
                            ]
                        ],
                        [
                            [
                                44.50995835839993,
                                12.743438794829434
                            ],
                            [
                                44.63608448452605,
                                12.743438794829434
                            ],
                            [
                                44.63608448452605,
                                12.617312668703306
                            ],
                            [
                                44.50995835839993,
                                12.617312668703306
                            ]
                        ],
                        [
                            [
                                44.496506116020726,
                                12.806618978719653
                            ],
                            [
                                44.62263224214685,
                                12.806618978719653
                            ],
                            [
                                44.62263224214685,
                                12.680492852593526
                            ],
                            [
                                44.496506116020726,
                                12.680492852593526
                            ]
                        ],
                        [
                            [
                                44.4830492893614,
                                12.869798501903924
                            ],
                            [
                                44.60917541548752,
                                12.869798501903924
                            ],
                            [
                                44.60917541548752,
                                12.743672375777797
                            ],
                            [
                                44.4830492893614,
                                12.743672375777797
                            ]
                        ],
                        [
                            [
                                44.46958785138215,
                                12.932977360819914
                            ],
                            [
                                44.595713977508275,
                                12.932977360819914
                            ],
                            [
                                44.595713977508275,
                                12.806851234693786
                            ],
                            [
                                44.46958785138215,
                                12.806851234693786
                            ]
                        ],
                        [
                            [
                                44.456121777622485,
                                12.996155551903835
                            ],
                            [
                                44.58224790374861,
                                12.996155551903835
                            ],
                            [
                                44.58224790374861,
                                12.870029425777707
                            ],
                            [
                                44.456121777622485,
                                12.870029425777707
                            ]
                        ],
                        [
                            [
                                44.442651040944526,
                                13.059333071590514
                            ],
                            [
                                44.56877716707065,
                                13.059333071590514
                            ],
                            [
                                44.56877716707065,
                                12.933206945464386
                            ],
                            [
                                44.442651040944526,
                                12.933206945464386
                            ]
                        ],
                        [
                            [
                                44.42917561679083,
                                13.122509916313374
                            ],
                            [
                                44.55530174291695,
                                13.122509916313374
                            ],
                            [
                                44.55530174291695,
                                12.996383790187247
                            ],
                            [
                                44.42917561679083,
                                12.996383790187247
                            ]
                        ],
                        [
                            [
                                44.415695477935735,
                                13.185686082504414
                            ],
                            [
                                44.54182160406186,
                                13.185686082504414
                            ],
                            [
                                44.54182160406186,
                                13.059559956378287
                            ],
                            [
                                44.415695477935735,
                                13.059559956378287
                            ]
                        ],
                        [
                            [
                                44.402210599718686,
                                13.248861566594144
                            ],
                            [
                                44.52833672584481,
                                13.248861566594144
                            ],
                            [
                                44.52833672584481,
                                13.122735440468016
                            ],
                            [
                                44.402210599718686,
                                13.122735440468016
                            ]
                        ],
                        [
                            [
                                44.388720954816876,
                                13.312036365011673
                            ],
                            [
                                44.514847080943,
                                13.312036365011673
                            ],
                            [
                                44.514847080943,
                                13.185910238885546
                            ],
                            [
                                44.388720954816876,
                                13.185910238885546
                            ]
                        ],
                        [
                            [
                                44.37522651847526,
                                13.375210474184623
                            ],
                            [
                                44.50135264460138,
                                13.375210474184623
                            ],
                            [
                                44.50135264460138,
                                13.249084348058496
                            ],
                            [
                                44.37522651847526,
                                13.249084348058496
                            ]
                        ],
                        [
                            [
                                44.36172726327097,
                                13.438383890539123
                            ],
                            [
                                44.487853389397095,
                                13.438383890539123
                            ],
                            [
                                44.487853389397095,
                                13.312257764412996
                            ],
                            [
                                44.36172726327097,
                                13.312257764412996
                            ]
                        ],
                        [
                            [
                                44.34822316435007,
                                13.501556610499835
                            ],
                            [
                                44.47434929047619,
                                13.501556610499835
                            ],
                            [
                                44.47434929047619,
                                13.375430484373707
                            ],
                            [
                                44.34822316435007,
                                13.375430484373707
                            ]
                        ],
                        [
                            [
                                44.33471419419167,
                                13.564728630489874
                            ],
                            [
                                44.46084032031779,
                                13.564728630489874
                            ],
                            [
                                44.46084032031779,
                                13.438602504363747
                            ],
                            [
                                44.33471419419167,
                                13.438602504363747
                            ]
                        ],
                        [
                            [
                                44.32120032783672,
                                13.627899946930905
                            ],
                            [
                                44.447326453962845,
                                13.627899946930905
                            ],
                            [
                                44.447326453962845,
                                13.501773820804777
                            ],
                            [
                                44.32120032783672,
                                13.501773820804777
                            ]
                        ],
                        [
                            [
                                44.30768153766683,
                                13.691070556242984
                            ],
                            [
                                44.433807663792955,
                                13.691070556242984
                            ],
                            [
                                44.433807663792955,
                                13.564944430116856
                            ],
                            [
                                44.30768153766683,
                                13.564944430116856
                            ]
                        ],
                        [
                            [
                                44.29415779862478,
                                13.754240454844684
                            ],
                            [
                                44.420283924750905,
                                13.754240454844684
                            ],
                            [
                                44.420283924750905,
                                13.628114328718556
                            ],
                            [
                                44.29415779862478,
                                13.628114328718556
                            ]
                        ],
                        [
                            [
                                44.28062908298682,
                                13.817409639153004
                            ],
                            [
                                44.406755209112944,
                                13.817409639153004
                            ],
                            [
                                44.406755209112944,
                                13.691283513026876
                            ],
                            [
                                44.28062908298682,
                                13.691283513026876
                            ]
                        ],
                        [
                            [
                                44.267095365593015,
                                13.880578105583364
                            ],
                            [
                                44.39322149171914,
                                13.880578105583364
                            ],
                            [
                                44.39322149171914,
                                13.754451979457237
                            ],
                            [
                                44.267095365593015,
                                13.754451979457237
                            ]
                        ],
                        [
                            [
                                44.25355661861316,
                                13.943745850549604
                            ],
                            [
                                44.379682744739284,
                                13.943745850549604
                            ],
                            [
                                44.379682744739284,
                                13.817619724423476
                            ],
                            [
                                44.25355661861316,
                                13.817619724423476
                            ]
                        ],
                        [
                            [
                                44.24001281678657,
                                14.006912870463983
                            ],
                            [
                                44.36613894291269,
                                14.006912870463983
                            ],
                            [
                                44.36613894291269,
                                13.880786744337856
                            ],
                            [
                                44.24001281678657,
                                13.880786744337856
                            ]
                        ],
                        [
                            [
                                44.22646393218338,
                                14.070079161737134
                            ],
                            [
                                44.3525900583095,
                                14.070079161737134
                            ],
                            [
                                44.3525900583095,
                                13.943953035611006
                            ],
                            [
                                44.22646393218338,
                                13.943953035611006
                            ]
                        ],
                        [
                            [
                                44.21290993942958,
                                14.133244720778073
                            ],
                            [
                                44.3390360655557,
                                14.133244720778073
                            ],
                            [
                                44.3390360655557,
                                14.007118594651946
                            ],
                            [
                                44.21290993942958,
                                14.007118594651946
                            ]
                        ],
                        [
                            [
                                44.19935081048945,
                                14.196409543994173
                            ],
                            [
                                44.32547693661557,
                                14.196409543994173
                            ],
                            [
                                44.32547693661557,
                                14.070283417868046
                            ],
                            [
                                44.19935081048945,
                                14.070283417868046
                            ]
                        ],
                        [
                            [
                                44.18578651989072,
                                14.259573627791184
                            ],
                            [
                                44.31191264601684,
                                14.259573627791184
                            ],
                            [
                                44.31191264601684,
                                14.133447501665056
                            ],
                            [
                                44.18578651989072,
                                14.133447501665056
                            ]
                        ],
                        [
                            [
                                44.17221703948402,
                                14.322736968573153
                            ],
                            [
                                44.29834316561014,
                                14.322736968573153
                            ],
                            [
                                44.29834316561014,
                                14.196610842447026
                            ],
                            [
                                44.17221703948402,
                                14.196610842447026
                            ]
                        ],
                        [
                            [
                                44.158642343689365,
                                14.385899562742473
                            ],
                            [
                                44.28476846981549,
                                14.385899562742473
                            ],
                            [
                                44.28476846981549,
                                14.259773436616346
                            ],
                            [
                                44.158642343689365,
                                14.259773436616346
                            ]
                        ],
                        [
                            [
                                44.1450624042522,
                                14.449061406699844
                            ],
                            [
                                44.271188530378325,
                                14.449061406699844
                            ],
                            [
                                44.271188530378325,
                                14.322935280573716
                            ],
                            [
                                44.1450624042522,
                                14.322935280573716
                            ]
                        ],
                        [
                            [
                                44.13147719548192,
                                14.512222496844243
                            ],
                            [
                                44.257603321608045,
                                14.512222496844243
                            ],
                            [
                                44.257603321608045,
                                14.386096370718116
                            ],
                            [
                                44.13147719548192,
                                14.386096370718116
                            ]
                        ],
                        [
                            [
                                44.11788668901596,
                                14.575382829572954
                            ],
                            [
                                44.244012815142085,
                                14.575382829572954
                            ],
                            [
                                44.244012815142085,
                                14.449256703446826
                            ],
                            [
                                44.11788668901596,
                                14.449256703446826
                            ]
                        ],
                        [
                            [
                                44.10429085839541,
                                14.638542401281525
                            ],
                            [
                                44.23041698452153,
                                14.638542401281525
                            ],
                            [
                                44.23041698452153,
                                14.512416275155397
                            ],
                            [
                                44.10429085839541,
                                14.512416275155397
                            ]
                        ],
                        [
                            [
                                44.090689677112316,
                                14.701701208363714
                            ],
                            [
                                44.21681580323844,
                                14.701701208363714
                            ],
                            [
                                44.21681580323844,
                                14.575575082237586
                            ],
                            [
                                44.090689677112316,
                                14.575575082237586
                            ]
                        ],
                        [
                            [
                                44.07708311663061,
                                14.764859247211565
                            ],
                            [
                                44.203209242756735,
                                14.764859247211565
                            ],
                            [
                                44.203209242756735,
                                14.638733121085437
                            ],
                            [
                                44.07708311663061,
                                14.638733121085437
                            ]
                        ],
                        [
                            [
                                44.06347115098786,
                                14.828016514215314
                            ],
                            [
                                44.18959727711398,
                                14.828016514215314
                            ],
                            [
                                44.18959727711398,
                                14.701890388089186
                            ],
                            [
                                44.06347115098786,
                                14.701890388089186
                            ]
                        ],
                        [
                            [
                                44.04985375153509,
                                14.891173005763413
                            ],
                            [
                                44.17597987766121,
                                14.891173005763413
                            ],
                            [
                                44.17597987766121,
                                14.765046879637286
                            ],
                            [
                                44.04985375153509,
                                14.765046879637286
                            ]
                        ],
                        [
                            [
                                44.036230892193025,
                                14.954328718242524
                            ],
                            [
                                44.16235701831915,
                                14.954328718242524
                            ],
                            [
                                44.16235701831915,
                                14.828202592116396
                            ],
                            [
                                44.036230892193025,
                                14.828202592116396
                            ]
                        ],
                        [
                            [
                                44.02260254419836,
                                15.017483648037464
                            ],
                            [
                                44.14872867032448,
                                15.017483648037464
                            ],
                            [
                                44.14872867032448,
                                14.891357521911337
                            ],
                            [
                                44.02260254419836,
                                14.891357521911337
                            ]
                        ],
                        [
                            [
                                44.008968681358475,
                                15.080637791531204
                            ],
                            [
                                44.1350948074846,
                                15.080637791531204
                            ],
                            [
                                44.1350948074846,
                                14.954511665405077
                            ],
                            [
                                44.008968681358475,
                                14.954511665405077
                            ]
                        ],
                        [
                            [
                                43.99532927479609,
                                15.143791145104904
                            ],
                            [
                                44.121455400922216,
                                15.143791145104904
                            ],
                            [
                                44.121455400922216,
                                15.017665018978777
                            ],
                            [
                                43.99532927479609,
                                15.017665018978777
                            ]
                        ],
                        [
                            [
                                43.98168429820231,
                                15.206943705137814
                            ],
                            [
                                44.107810424328434,
                                15.206943705137814
                            ],
                            [
                                44.107810424328434,
                                15.080817579011686
                            ],
                            [
                                43.98168429820231,
                                15.080817579011686
                            ]
                        ],
                        [
                            [
                                43.96803372258121,
                                15.270095468007323
                            ],
                            [
                                44.094159848707335,
                                15.270095468007323
                            ],
                            [
                                44.094159848707335,
                                15.143969341881196
                            ],
                            [
                                43.96803372258121,
                                15.143969341881196
                            ]
                        ],
                        [
                            [
                                43.95437752150626,
                                15.333246430088924
                            ],
                            [
                                44.08050364763238,
                                15.333246430088924
                            ],
                            [
                                44.08050364763238,
                                15.207120303962796
                            ],
                            [
                                43.95437752150626,
                                15.207120303962796
                            ]
                        ],
                        [
                            [
                                43.94071566585833,
                                15.396396587756174
                            ],
                            [
                                44.06684179198445,
                                15.396396587756174
                            ],
                            [
                                44.06684179198445,
                                15.270270461630046
                            ],
                            [
                                43.94071566585833,
                                15.270270461630046
                            ]
                        ],
                        [
                            [
                                43.92704812909998,
                                15.459545937380724
                            ],
                            [
                                44.053174255226104,
                                15.459545937380724
                            ],
                            [
                                44.053174255226104,
                                15.333419811254597
                            ],
                            [
                                43.92704812909998,
                                15.333419811254597
                            ]
                        ],
                        [
                            [
                                43.913374881992425,
                                15.522694475332283
                            ],
                            [
                                44.03950100811855,
                                15.522694475332283
                            ],
                            [
                                44.03950100811855,
                                15.396568349206156
                            ],
                            [
                                43.913374881992425,
                                15.396568349206156
                            ]
                        ],
                        [
                            [
                                52.00545716250035,
                                14.503223344257425
                            ],
                            [
                                52.13158328862647,
                                14.503223344257425
                            ],
                            [
                                52.13158328862647,
                                14.377097218131297
                            ],
                            [
                                52.00545716250035,
                                14.377097218131297
                            ]
                        ],
                        [
                            [
                                51.991856606422026,
                                14.566458164286974
                            ],
                            [
                                52.11798273254815,
                                14.566458164286974
                            ],
                            [
                                52.11798273254815,
                                14.440332038160847
                            ],
                            [
                                51.991856606422026,
                                14.440332038160847
                            ]
                        ],
                        [
                            [
                                51.978250719834556,
                                14.629692203924744
                            ],
                            [
                                52.10437684596068,
                                14.629692203924744
                            ],
                            [
                                52.10437684596068,
                                14.503566077798617
                            ],
                            [
                                51.978250719834556,
                                14.503566077798617
                            ]
                        ],
                        [
                            [
                                51.96463947681786,
                                14.692925459467064
                            ],
                            [
                                52.09076560294398,
                                14.692925459467064
                            ],
                            [
                                52.09076560294398,
                                14.566799333340937
                            ],
                            [
                                51.96463947681786,
                                14.566799333340937
                            ]
                        ],
                        [
                            [
                                51.951022848765646,
                                14.756157927208573
                            ],
                            [
                                52.07714897489177,
                                14.756157927208573
                            ],
                            [
                                52.07714897489177,
                                14.630031801082445
                            ],
                            [
                                51.951022848765646,
                                14.630031801082445
                            ]
                        ],
                        [
                            [
                                51.93740080964198,
                                14.819389603442135
                            ],
                            [
                                52.0635269357681,
                                14.819389603442135
                            ],
                            [
                                52.0635269357681,
                                14.693263477316007
                            ],
                            [
                                51.93740080964198,
                                14.693263477316007
                            ]
                        ],
                        [
                            [
                                51.92377333072745,
                                14.882620484458844
                            ],
                            [
                                52.049899456853574,
                                14.882620484458844
                            ],
                            [
                                52.049899456853574,
                                14.756494358332716
                            ],
                            [
                                51.92377333072745,
                                14.756494358332716
                            ]
                        ],
                        [
                            [
                                51.91014038587702,
                                14.945850566548025
                            ],
                            [
                                52.036266512003145,
                                14.945850566548025
                            ],
                            [
                                52.036266512003145,
                                14.819724440421897
                            ],
                            [
                                51.91014038587702,
                                14.819724440421897
                            ]
                        ],
                        [
                            [
                                51.89650194625177,
                                15.009079845997233
                            ],
                            [
                                52.02262807237789,
                                15.009079845997233
                            ],
                            [
                                52.02262807237789,
                                14.882953719871105
                            ],
                            [
                                51.89650194625177,
                                14.882953719871105
                            ]
                        ],
                        [
                            [
                                51.882857985591436,
                                15.072308319092214
                            ],
                            [
                                52.00898411171756,
                                15.072308319092214
                            ],
                            [
                                52.00898411171756,
                                14.946182192966086
                            ],
                            [
                                51.882857985591436,
                                14.946182192966086
                            ]
                        ],
                        [
                            [
                                51.869208474946085,
                                15.135535982116854
                            ],
                            [
                                51.99533460107221,
                                15.135535982116854
                            ],
                            [
                                51.99533460107221,
                                15.009409855990727
                            ],
                            [
                                51.869208474946085,
                                15.009409855990727
                            ]
                        ],
                        [
                            [
                                51.85555338793396,
                                15.198762831353253
                            ],
                            [
                                51.98167951406008,
                                15.198762831353253
                            ],
                            [
                                51.98167951406008,
                                15.072636705227126
                            ],
                            [
                                51.85555338793396,
                                15.072636705227126
                            ]
                        ],
                        [
                            [
                                51.841892695489435,
                                15.261988863081633
                            ],
                            [
                                51.96801882161556,
                                15.261988863081633
                            ],
                            [
                                51.96801882161556,
                                15.135862736955506
                            ],
                            [
                                51.841892695489435,
                                15.135862736955506
                            ]
                        ],
                        [
                            [
                                51.82822637111442,
                                15.325214073580364
                            ],
                            [
                                51.95435249724054,
                                15.325214073580364
                            ],
                            [
                                51.95435249724054,
                                15.199087947454236
                            ],
                            [
                                51.82822637111442,
                                15.199087947454236
                            ]
                        ],
                        [
                            [
                                51.81455438561818,
                                15.388438459125924
                            ],
                            [
                                51.940680511744304,
                                15.388438459125924
                            ],
                            [
                                51.940680511744304,
                                15.262312332999796
                            ],
                            [
                                51.81455438561818,
                                15.262312332999796
                            ]
                        ],
                        [
                            [
                                51.80087671239302,
                                15.451662015992904
                            ],
                            [
                                51.927002838519144,
                                15.451662015992904
                            ],
                            [
                                51.927002838519144,
                                15.325535889866776
                            ],
                            [
                                51.80087671239302,
                                15.325535889866776
                            ]
                        ],
                        [
                            [
                                51.787193322123265,
                                15.514884740453963
                            ],
                            [
                                51.91331944824939,
                                15.514884740453963
                            ],
                            [
                                51.91331944824939,
                                15.388758614327836
                            ],
                            [
                                51.787193322123265,
                                15.388758614327836
                            ]
                        ],
                        [
                            [
                                51.77350418807569,
                                15.578106628779853
                            ],
                            [
                                51.899630314201815,
                                15.578106628779853
                            ],
                            [
                                51.899630314201815,
                                15.451980502653726
                            ],
                            [
                                51.77350418807569,
                                15.451980502653726
                            ]
                        ],
                        [
                            [
                                51.75980928082128,
                                15.641327677239383
                            ],
                            [
                                51.885935406947404,
                                15.641327677239383
                            ],
                            [
                                51.885935406947404,
                                15.515201551113256
                            ],
                            [
                                51.75980928082128,
                                15.515201551113256
                            ]
                        ],
                        [
                            [
                                51.74610857350146,
                                15.704547882099394
                            ],
                            [
                                51.87223469962758,
                                15.704547882099394
                            ],
                            [
                                51.87223469962758,
                                15.578421755973267
                            ],
                            [
                                51.74610857350146,
                                15.578421755973267
                            ]
                        ],
                        [
                            [
                                51.73240203656444,
                                15.767767239624733
                            ],
                            [
                                51.85852816269056,
                                15.767767239624733
                            ],
                            [
                                51.85852816269056,
                                15.641641113498606
                            ],
                            [
                                51.73240203656444,
                                15.641641113498606
                            ]
                        ],
                        [
                            [
                                51.718689643029705,
                                15.830985746078284
                            ],
                            [
                                51.84481576915583,
                                15.830985746078284
                            ],
                            [
                                51.84481576915583,
                                15.704859619952156
                            ],
                            [
                                51.718689643029705,
                                15.704859619952156
                            ]
                        ],
                        [
                            [
                                51.704971363216345,
                                15.894203397720904
                            ],
                            [
                                51.83109748934247,
                                15.894203397720904
                            ],
                            [
                                51.83109748934247,
                                15.768077271594777
                            ],
                            [
                                51.704971363216345,
                                15.768077271594777
                            ]
                        ],
                        [
                            [
                                51.69124717002223,
                                15.957420190811433
                            ],
                            [
                                51.817373296148354,
                                15.957420190811433
                            ],
                            [
                                51.817373296148354,
                                15.831294064685306
                            ],
                            [
                                51.69124717002223,
                                15.831294064685306
                            ]
                        ],
                        [
                            [
                                51.677517033644065,
                                16.020636121606664
                            ],
                            [
                                51.80364315977019,
                                16.020636121606664
                            ],
                            [
                                51.80364315977019,
                                15.894509995480536
                            ],
                            [
                                51.677517033644065,
                                15.894509995480536
                            ]
                        ],
                        [
                            [
                                51.66378092685006,
                                16.08385118636131
                            ],
                            [
                                51.78990705297618,
                                16.08385118636131
                            ],
                            [
                                51.78990705297618,
                                15.957725060235186
                            ],
                            [
                                51.66378092685006,
                                15.957725060235186
                            ]
                        ],
                        [
                            [
                                51.650038819709835,
                                16.147065381328062
                            ],
                            [
                                51.77616494583596,
                                16.147065381328062
                            ],
                            [
                                51.77616494583596,
                                16.020939255201938
                            ],
                            [
                                51.650038819709835,
                                16.020939255201938
                            ]
                        ],
                        [
                            [
                                51.636290684204326,
                                16.21027870275747
                            ],
                            [
                                51.76241681033045,
                                16.21027870275747
                            ],
                            [
                                51.76241681033045,
                                16.084152576631347
                            ],
                            [
                                51.636290684204326,
                                16.084152576631347
                            ]
                        ],
                        [
                            [
                                51.62253649224664,
                                16.273491146898003
                            ],
                            [
                                51.74866261837276,
                                16.273491146898003
                            ],
                            [
                                51.74866261837276,
                                16.14736502077188
                            ],
                            [
                                51.62253649224664,
                                16.14736502077188
                            ]
                        ],
                        [
                            [
                                51.60877621372127,
                                16.336702709995983
                            ],
                            [
                                51.734902339847395,
                                16.336702709995983
                            ],
                            [
                                51.734902339847395,
                                16.21057658386986
                            ],
                            [
                                51.60877621372127,
                                16.21057658386986
                            ]
                        ],
                        [
                            [
                                51.59500982106603,
                                16.39991338829559
                            ],
                            [
                                51.72113594719215,
                                16.39991338829559
                            ],
                            [
                                51.72113594719215,
                                16.273787262169467
                            ],
                            [
                                51.59500982106603,
                                16.273787262169467
                            ]
                        ],
                        [
                            [
                                51.58123728403473,
                                16.463123178038863
                            ],
                            [
                                51.70736341016085,
                                16.463123178038863
                            ],
                            [
                                51.70736341016085,
                                16.33699705191274
                            ],
                            [
                                51.58123728403473,
                                16.33699705191274
                            ]
                        ],
                        [
                            [
                                51.56745857493857,
                                16.52633207546562
                            ],
                            [
                                51.693584701064694,
                                16.52633207546562
                            ],
                            [
                                51.693584701064694,
                                16.400205949339497
                            ],
                            [
                                51.56745857493857,
                                16.400205949339497
                            ]
                        ],
                        [
                            [
                                51.55367366338939,
                                16.589540076813552
                            ],
                            [
                                51.67979978951551,
                                16.589540076813552
                            ],
                            [
                                51.67979978951551,
                                16.46341395068743
                            ],
                            [
                                51.55367366338939,
                                16.46341395068743
                            ]
                        ],
                        [
                            [
                                51.539882521565495,
                                16.65274717831804
                            ],
                            [
                                51.66600864769162,
                                16.65274717831804
                            ],
                            [
                                51.66600864769162,
                                16.526621052191917
                            ],
                            [
                                51.539882521565495,
                                16.526621052191917
                            ]
                        ],
                        [
                            [
                                51.526085118954775,
                                16.715953376212333
                            ],
                            [
                                51.6522112450809,
                                16.715953376212333
                            ],
                            [
                                51.6522112450809,
                                16.58982725008621
                            ],
                            [
                                51.526085118954775,
                                16.58982725008621
                            ]
                        ],
                        [
                            [
                                51.5122814275963,
                                16.77915866672734
                            ],
                            [
                                51.63840755372242,
                                16.77915866672734
                            ],
                            [
                                51.63840755372242,
                                16.653032540601217
                            ],
                            [
                                51.5122814275963,
                                16.653032540601217
                            ]
                        ],
                        [
                            [
                                51.498471416844346,
                                16.842363046091762
                            ],
                            [
                                51.62459754297047,
                                16.842363046091762
                            ],
                            [
                                51.62459754297047,
                                16.716236919965638
                            ],
                            [
                                51.498471416844346,
                                16.716236919965638
                            ]
                        ],
                        [
                            [
                                51.484655058598825,
                                16.905566510531983
                            ],
                            [
                                51.61078118472495,
                                16.905566510531983
                            ],
                            [
                                51.61078118472495,
                                16.77944038440586
                            ],
                            [
                                51.484655058598825,
                                16.77944038440586
                            ]
                        ],
                        [
                            [
                                51.470832322078955,
                                16.9687690562721
                            ],
                            [
                                51.59695844820508,
                                16.9687690562721
                            ],
                            [
                                51.59695844820508,
                                16.842642930145978
                            ],
                            [
                                51.470832322078955,
                                16.842642930145978
                            ]
                        ],
                        [
                            [
                                51.45700317904717,
                                17.03197067953387
                            ],
                            [
                                51.583129305173294,
                                17.03197067953387
                            ],
                            [
                                51.583129305173294,
                                16.905844553407746
                            ],
                            [
                                51.45700317904717,
                                16.905844553407746
                            ]
                        ],
                        [
                            [
                                51.44316759858286,
                                17.09517137653671
                            ],
                            [
                                51.569293724708984,
                                17.09517137653671
                            ],
                            [
                                51.569293724708984,
                                16.969045250410588
                            ],
                            [
                                51.44316759858286,
                                16.969045250410588
                            ]
                        ],
                        [
                            [
                                51.42932555231274,
                                17.158371143497693
                            ],
                            [
                                51.555451678438864,
                                17.158371143497693
                            ],
                            [
                                51.555451678438864,
                                17.03224501737157
                            ],
                            [
                                51.42932555231274,
                                17.03224501737157
                            ]
                        ],
                        [
                            [
                                51.415477009176435,
                                17.22156997663147
                            ],
                            [
                                51.54160313530256,
                                17.22156997663147
                            ],
                            [
                                51.54160313530256,
                                17.095443850505347
                            ],
                            [
                                51.415477009176435,
                                17.095443850505347
                            ]
                        ],
                        [
                            [
                                51.401621940656916,
                                17.28476787215035
                            ],
                            [
                                51.52774806678304,
                                17.28476787215035
                            ],
                            [
                                51.52774806678304,
                                17.158641746024227
                            ],
                            [
                                51.401621940656916,
                                17.158641746024227
                            ]
                        ],
                        [
                            [
                                51.38776031555088,
                                17.347964826264153
                            ],
                            [
                                51.513886441677,
                                17.347964826264153
                            ],
                            [
                                51.513886441677,
                                17.22183870013803
                            ],
                            [
                                51.38776031555088,
                                17.22183870013803
                            ]
                        ],
                        [
                            [
                                51.373892105205606,
                                17.411160835180333
                            ],
                            [
                                51.50001823133173,
                                17.411160835180333
                            ],
                            [
                                51.50001823133173,
                                17.28503470905421
                            ],
                            [
                                51.373892105205606,
                                17.28503470905421
                            ]
                        ],
                        [
                            [
                                51.36001727827342,
                                17.47435589510383
                            ],
                            [
                                51.48614340439954,
                                17.47435589510383
                            ],
                            [
                                51.48614340439954,
                                17.348229768977706
                            ],
                            [
                                51.36001727827342,
                                17.348229768977706
                            ]
                        ],
                        [
                            [
                                51.34613580595631,
                                17.537550002237133
                            ],
                            [
                                51.47226193208243,
                                17.537550002237133
                            ],
                            [
                                51.47226193208243,
                                17.41142387611101
                            ],
                            [
                                51.34613580595631,
                                17.41142387611101
                            ]
                        ],
                        [
                            [
                                51.332247656760465,
                                17.600743152780222
                            ],
                            [
                                51.45837378288659,
                                17.600743152780222
                            ],
                            [
                                51.45837378288659,
                                17.474617026654098
                            ],
                            [
                                51.332247656760465,
                                17.474617026654098
                            ]
                        ],
                        [
                            [
                                51.31835280174265,
                                17.66393534293057
                            ],
                            [
                                51.44447892786877,
                                17.66393534293057
                            ],
                            [
                                51.44447892786877,
                                17.537809216804447
                            ],
                            [
                                51.31835280174265,
                                17.537809216804447
                            ]
                        ],
                        [
                            [
                                51.30445120926953,
                                17.7271265688831
                            ],
                            [
                                51.430577335395654,
                                17.7271265688831
                            ],
                            [
                                51.430577335395654,
                                17.601000442756977
                            ],
                            [
                                51.30445120926953,
                                17.601000442756977
                            ]
                        ],
                        [
                            [
                                51.290542850242915,
                                17.790316826830193
                            ],
                            [
                                51.41666897636904,
                                17.790316826830193
                            ],
                            [
                                51.41666897636904,
                                17.66419070070407
                            ],
                            [
                                51.290542850242915,
                                17.66419070070407
                            ]
                        ],
                        [
                            [
                                51.27662769288181,
                                17.853506112961643
                            ],
                            [
                                51.402753819007934,
                                17.853506112961643
                            ],
                            [
                                51.402753819007934,
                                17.72737998683552
                            ],
                            [
                                51.27662769288181,
                                17.72737998683552
                            ]
                        ],
                        [
                            [
                                51.262705707947696,
                                17.91669442346465
                            ],
                            [
                                51.38883183407382,
                                17.91669442346465
                            ],
                            [
                                51.38883183407382,
                                17.790568297338528
                            ],
                            [
                                51.262705707947696,
                                17.790568297338528
                            ]
                        ],
                        [
                            [
                                51.2487768635038,
                                17.97988175452378
                            ],
                            [
                                51.37490298962992,
                                17.97988175452378
                            ],
                            [
                                51.37490298962992,
                                17.853755628397657
                            ],
                            [
                                51.2487768635038,
                                17.853755628397657
                            ]
                        ],
                        [
                            [
                                51.23484113016315,
                                18.043068102320973
                            ],
                            [
                                51.36096725628927,
                                18.043068102320973
                            ],
                            [
                                51.36096725628927,
                                17.91694197619485
                            ],
                            [
                                51.23484113016315,
                                17.91694197619485
                            ]
                        ],
                        [
                            [
                                51.22089847583809,
                                18.106253463035493
                            ],
                            [
                                51.347024601964215,
                                18.106253463035493
                            ],
                            [
                                51.347024601964215,
                                17.98012733690937
                            ],
                            [
                                51.22089847583809,
                                17.98012733690937
                            ]
                        ],
                        [
                            [
                                51.20694887098827,
                                18.16943783284395
                            ],
                            [
                                51.333074997114394,
                                18.16943783284395
                            ],
                            [
                                51.333074997114394,
                                18.043311706717827
                            ],
                            [
                                51.20694887098827,
                                18.043311706717827
                            ]
                        ],
                        [
                            [
                                51.19299228337839,
                                18.23262120792022
                            ],
                            [
                                51.31911840950451,
                                18.23262120792022
                            ],
                            [
                                51.31911840950451,
                                18.106495081794098
                            ],
                            [
                                51.19299228337839,
                                18.106495081794098
                            ]
                        ],
                        [
                            [
                                51.179028683309845,
                                18.295803584435482
                            ],
                            [
                                51.30515480943597,
                                18.295803584435482
                            ],
                            [
                                51.30515480943597,
                                18.16967745830936
                            ],
                            [
                                51.179028683309845,
                                18.16967745830936
                            ]
                        ],
                        [
                            [
                                51.16505803839308,
                                18.358984958558153
                            ],
                            [
                                51.2911841645192,
                                18.358984958558153
                            ],
                            [
                                51.2911841645192,
                                18.23285883243203
                            ],
                            [
                                51.16505803839308,
                                18.23285883243203
                            ]
                        ],
                        [
                            [
                                51.15108031877771,
                                18.42216532645387
                            ],
                            [
                                51.27720644490383,
                                18.42216532645387
                            ],
                            [
                                51.27720644490383,
                                18.296039200327748
                            ],
                            [
                                51.15108031877771,
                                18.296039200327748
                            ]
                        ],
                        [
                            [
                                51.13709549191666,
                                18.48534468428551
                            ],
                            [
                                51.263221618042785,
                                18.48534468428551
                            ],
                            [
                                51.263221618042785,
                                18.359218558159387
                            ],
                            [
                                51.13709549191666,
                                18.359218558159387
                            ]
                        ],
                        [
                            [
                                51.12310352780448,
                                18.548523028213133
                            ],
                            [
                                51.249229653930605,
                                18.548523028213133
                            ],
                            [
                                51.249229653930605,
                                18.42239690208701
                            ],
                            [
                                51.12310352780448,
                                18.42239690208701
                            ]
                        ],
                        [
                            [
                                51.109104393733205,
                                18.611700354393903
                            ],
                            [
                                51.23523051985933,
                                18.611700354393903
                            ],
                            [
                                51.23523051985933,
                                18.48557422826778
                            ],
                            [
                                51.109104393733205,
                                18.48557422826778
                            ]
                        ],
                        [
                            [
                                51.09509805954219,
                                18.674876658982242
                            ],
                            [
                                51.22122418566831,
                                18.674876658982242
                            ],
                            [
                                51.22122418566831,
                                18.54875053285612
                            ],
                            [
                                51.09509805954219,
                                18.54875053285612
                            ]
                        ],
                        [
                            [
                                51.08108449235771,
                                18.7380519381296
                            ],
                            [
                                51.207210618483835,
                                18.7380519381296
                            ],
                            [
                                51.207210618483835,
                                18.611925812003477
                            ],
                            [
                                51.08108449235771,
                                18.611925812003477
                            ]
                        ],
                        [
                            [
                                51.067063661207555,
                                18.80122618798456
                            ],
                            [
                                51.19318978733368,
                                18.80122618798456
                            ],
                            [
                                51.19318978733368,
                                18.675100061858437
                            ],
                            [
                                51.067063661207555,
                                18.675100061858437
                            ]
                        ],
                        [
                            [
                                51.05303553503392,
                                18.864399404692783
                            ],
                            [
                                51.179161661160045,
                                18.864399404692783
                            ],
                            [
                                51.179161661160045,
                                18.73827327856666
                            ],
                            [
                                51.05303553503392,
                                18.73827327856666
                            ]
                        ],
                        [
                            [
                                51.03900008072089,
                                18.927571584396983
                            ],
                            [
                                51.16512620684701,
                                18.927571584396983
                            ],
                            [
                                51.16512620684701,
                                18.80144545827086
                            ],
                            [
                                51.03900008072089,
                                18.80144545827086
                            ]
                        ],
                        [
                            [
                                51.02495726770904,
                                18.990742723236902
                            ],
                            [
                                51.151083393835165,
                                18.990742723236902
                            ],
                            [
                                51.151083393835165,
                                18.86461659711078
                            ],
                            [
                                51.02495726770904,
                                18.86461659711078
                            ]
                        ],
                        [
                            [
                                51.010907062718815,
                                19.053912817349282
                            ],
                            [
                                51.13703318884494,
                                19.053912817349282
                            ],
                            [
                                51.13703318884494,
                                18.927786691223158
                            ],
                            [
                                51.010907062718815,
                                18.927786691223158
                            ]
                        ],
                        [
                            [
                                50.99684943502471,
                                19.117081862867852
                            ],
                            [
                                51.12297556115083,
                                19.117081862867852
                            ],
                            [
                                51.12297556115083,
                                18.99095573674173
                            ],
                            [
                                50.99684943502471,
                                18.99095573674173
                            ]
                        ],
                        [
                            [
                                50.98278435118494,
                                19.180249855923332
                            ],
                            [
                                51.10891047731106,
                                19.180249855923332
                            ],
                            [
                                51.10891047731106,
                                19.054123729797208
                            ],
                            [
                                50.98278435118494,
                                19.054123729797208
                            ]
                        ],
                        [
                            [
                                50.968711780307736,
                                19.243416792643313
                            ],
                            [
                                51.09483790643386,
                                19.243416792643313
                            ],
                            [
                                51.09483790643386,
                                19.11729066651719
                            ],
                            [
                                50.968711780307736,
                                19.11729066651719
                            ]
                        ],
                        [
                            [
                                50.954631688780935,
                                19.306582669152352
                            ],
                            [
                                51.08075781490706,
                                19.306582669152352
                            ],
                            [
                                51.08075781490706,
                                19.180456543026228
                            ],
                            [
                                50.954631688780935,
                                19.180456543026228
                            ]
                        ],
                        [
                            [
                                50.94054404554462,
                                19.36974748157188
                            ],
                            [
                                51.066670171670744,
                                19.36974748157188
                            ],
                            [
                                51.066670171670744,
                                19.243621355445757
                            ],
                            [
                                50.94054404554462,
                                19.243621355445757
                            ]
                        ],
                        [
                            [
                                50.926448816822585,
                                19.43291122602019
                            ],
                            [
                                51.05257494294871,
                                19.43291122602019
                            ],
                            [
                                51.05257494294871,
                                19.306785099894068
                            ],
                            [
                                50.926448816822585,
                                19.306785099894068
                            ]
                        ],
                        [
                            [
                                50.91234597138013,
                                19.496073898612433
                            ],
                            [
                                51.03847209750625,
                                19.496073898612433
                            ],
                            [
                                51.03847209750625,
                                19.36994777248631
                            ],
                            [
                                50.91234597138013,
                                19.36994777248631
                            ]
                        ],
                        [
                            [
                                50.89823547527024,
                                19.55923549546054
                            ],
                            [
                                51.024361601396365,
                                19.55923549546054
                            ],
                            [
                                51.024361601396365,
                                19.433109369334417
                            ],
                            [
                                50.89823547527024,
                                19.433109369334417
                            ]
                        ],
                        [
                            [
                                50.88411729709305,
                                19.622396012673242
                            ],
                            [
                                51.01024342321917,
                                19.622396012673242
                            ],
                            [
                                51.01024342321917,
                                19.49626988654712
                            ],
                            [
                                50.88411729709305,
                                19.49626988654712
                            ]
                        ],
                        [
                            [
                                51.9894195265005,
                                20.295491321736662
                            ],
                            [
                                52.11554565262662,
                                20.295491321736662
                            ],
                            [
                                52.11554565262662,
                                20.16936519561054
                            ],
                            [
                                51.9894195265005,
                                20.16936519561054
                            ]
                        ],
                        [
                            [
                                51.975091973173676,
                                20.232361597365433
                            ],
                            [
                                52.1012180992998,
                                20.232361597365433
                            ],
                            [
                                52.1012180992998,
                                20.10623547123931
                            ],
                            [
                                51.975091973173676,
                                20.10623547123931
                            ]
                        ],
                        [
                            [
                                51.96077255121066,
                                20.169230735460612
                            ],
                            [
                                52.086898677336784,
                                20.169230735460612
                            ],
                            [
                                52.086898677336784,
                                20.04310460933449
                            ],
                            [
                                51.96077255121066,
                                20.04310460933449
                            ]
                        ],
                        [
                            [
                                51.946461227862706,
                                20.106098740011703
                            ],
                            [
                                52.07258735398883,
                                20.106098740011703
                            ],
                            [
                                52.07258735398883,
                                19.97997261388558
                            ],
                            [
                                51.946461227862706,
                                19.97997261388558
                            ]
                        ],
                        [
                            [
                                51.93215796785786,
                                20.042965615004622
                            ],
                            [
                                52.058284093983985,
                                20.042965615004622
                            ],
                            [
                                52.058284093983985,
                                19.916839488878498
                            ],
                            [
                                51.93215796785786,
                                19.916839488878498
                            ]
                        ],
                        [
                            [
                                51.91786273862462,
                                19.979831364421603
                            ],
                            [
                                52.043988864750744,
                                19.979831364421603
                            ],
                            [
                                52.043988864750744,
                                19.85370523829548
                            ],
                            [
                                51.91786273862462,
                                19.85370523829548
                            ]
                        ],
                        [
                            [
                                51.903575505073775,
                                19.91669599224135
                            ],
                            [
                                52.0297016311999,
                                19.91669599224135
                            ],
                            [
                                52.0297016311999,
                                19.790569866115227
                            ],
                            [
                                51.903575505073775,
                                19.790569866115227
                            ]
                        ],
                        [
                            [
                                51.889296234822005,
                                19.853559502439023
                            ],
                            [
                                52.01542236094813,
                                19.853559502439023
                            ],
                            [
                                52.01542236094813,
                                19.7274333763129
                            ],
                            [
                                51.889296234822005,
                                19.7274333763129
                            ]
                        ],
                        [
                            [
                                51.875024892957676,
                                19.79042189898622
                            ],
                            [
                                52.0011510190838,
                                19.79042189898622
                            ],
                            [
                                52.0011510190838,
                                19.664295772860097
                            ],
                            [
                                51.875024892957676,
                                19.664295772860097
                            ]
                        ],
                        [
                            [
                                51.86076144727573,
                                19.72728318585106
                            ],
                            [
                                51.98688757340185,
                                19.72728318585106
                            ],
                            [
                                51.98688757340185,
                                19.601157059724937
                            ],
                            [
                                51.86076144727573,
                                19.601157059724937
                            ]
                        ],
                        [
                            [
                                51.84650586304821,
                                19.66414336699817
                            ],
                            [
                                51.97263198917433,
                                19.66414336699817
                            ],
                            [
                                51.97263198917433,
                                19.538017240872048
                            ],
                            [
                                51.84650586304821,
                                19.538017240872048
                            ]
                        ],
                        [
                            [
                                51.83225810824479,
                                19.601002446388772
                            ],
                            [
                                51.95838423437091,
                                19.601002446388772
                            ],
                            [
                                51.95838423437091,
                                19.47487632026265
                            ],
                            [
                                51.83225810824479,
                                19.47487632026265
                            ]
                        ],
                        [
                            [
                                51.81801814831126,
                                19.537860427980593
                            ],
                            [
                                51.944144274437384,
                                19.537860427980593
                            ],
                            [
                                51.944144274437384,
                                19.41173430185447
                            ],
                            [
                                51.81801814831126,
                                19.41173430185447
                            ]
                        ],
                        [
                            [
                                51.80378595139986,
                                19.474717315728043
                            ],
                            [
                                51.929912077525984,
                                19.474717315728043
                            ],
                            [
                                51.929912077525984,
                                19.34859118960192
                            ],
                            [
                                51.80378595139986,
                                19.34859118960192
                            ]
                        ],
                        [
                            [
                                51.789561483128224,
                                19.411573113582122
                            ],
                            [
                                51.91568760925435,
                                19.411573113582122
                            ],
                            [
                                51.91568760925435,
                                19.285446987455998
                            ],
                            [
                                51.789561483128224,
                                19.285446987455998
                            ]
                        ],
                        [
                            [
                                51.77534471182119,
                                19.348427825490504
                            ],
                            [
                                51.901470837947315,
                                19.348427825490504
                            ],
                            [
                                51.901470837947315,
                                19.22230169936438
                            ],
                            [
                                51.77534471182119,
                                19.22230169936438
                            ]
                        ],
                        [
                            [
                                51.761135603269665,
                                19.285281455397502
                            ],
                            [
                                51.88726172939579,
                                19.285281455397502
                            ],
                            [
                                51.88726172939579,
                                19.15915532927138
                            ],
                            [
                                51.761135603269665,
                                19.15915532927138
                            ]
                        ],
                        [
                            [
                                51.74693412597241,
                                19.222134007244183
                            ],
                            [
                                51.87306025209853,
                                19.222134007244183
                            ],
                            [
                                51.87306025209853,
                                19.09600788111806
                            ],
                            [
                                51.74693412597241,
                                19.09600788111806
                            ]
                        ],
                        [
                            [
                                51.73274024589344,
                                19.15898548496834
                            ],
                            [
                                51.85886637201956,
                                19.15898548496834
                            ],
                            [
                                51.85886637201956,
                                19.032859358842217
                            ],
                            [
                                51.73274024589344,
                                19.032859358842217
                            ]
                        ],
                        [
                            [
                                51.718553931700406,
                                19.095835892504493
                            ],
                            [
                                51.84468005782653,
                                19.095835892504493
                            ],
                            [
                                51.84468005782653,
                                18.96970976637837
                            ],
                            [
                                51.718553931700406,
                                18.96970976637837
                            ]
                        ],
                        [
                            [
                                51.704375149526925,
                                19.032685233783983
                            ],
                            [
                                51.83050127565305,
                                19.032685233783983
                            ],
                            [
                                51.83050127565305,
                                18.90655910765786
                            ],
                            [
                                51.704375149526925,
                                18.90655910765786
                            ]
                        ],
                        [
                            [
                                51.6902038682126,
                                18.969533512734913
                            ],
                            [
                                51.81632999433872,
                                18.969533512734913
                            ],
                            [
                                51.81632999433872,
                                18.84340738660879
                            ],
                            [
                                51.6902038682126,
                                18.84340738660879
                            ]
                        ],
                        [
                            [
                                51.676040054052365,
                                18.906380733282262
                            ],
                            [
                                51.80216618017849,
                                18.906380733282262
                            ],
                            [
                                51.80216618017849,
                                18.780254607156138
                            ],
                            [
                                51.676040054052365,
                                18.780254607156138
                            ]
                        ],
                        [
                            [
                                51.661883675394805,
                                18.843226899347812
                            ],
                            [
                                51.78800980152093,
                                18.843226899347812
                            ],
                            [
                                51.78800980152093,
                                18.717100773221688
                            ],
                            [
                                51.661883675394805,
                                18.717100773221688
                            ]
                        ],
                        [
                            [
                                51.6477347006811,
                                18.780072014850262
                            ],
                            [
                                51.77386082680722,
                                18.780072014850262
                            ],
                            [
                                51.77386082680722,
                                18.653945888724138
                            ],
                            [
                                51.6477347006811,
                                18.653945888724138
                            ]
                        ],
                        [
                            [
                                51.633593096447996,
                                18.71691608370522
                            ],
                            [
                                51.75971922257412,
                                18.71691608370522
                            ],
                            [
                                51.75971922257412,
                                18.590789957579098
                            ],
                            [
                                51.633593096447996,
                                18.590789957579098
                            ]
                        ],
                        [
                            [
                                51.619458831960465,
                                18.65375910982518
                            ],
                            [
                                51.74558495808659,
                                18.65375910982518
                            ],
                            [
                                51.74558495808659,
                                18.527632983699057
                            ],
                            [
                                51.619458831960465,
                                18.527632983699057
                            ]
                        ],
                        [
                            [
                                51.60533187391865,
                                18.59060109711962
                            ],
                            [
                                51.731458000044775,
                                18.59060109711962
                            ],
                            [
                                51.731458000044775,
                                18.464474970993496
                            ],
                            [
                                51.60533187391865,
                                18.464474970993496
                            ]
                        ],
                        [
                            [
                                51.59121219174181,
                                18.527442049494983
                            ],
                            [
                                51.717338317867934,
                                18.527442049494983
                            ],
                            [
                                51.717338317867934,
                                18.40131592336886
                            ],
                            [
                                51.59121219174181,
                                18.40131592336886
                            ]
                        ],
                        [
                            [
                                51.57709975229988,
                                18.464281970854703
                            ],
                            [
                                51.703225878426004,
                                18.464281970854703
                            ],
                            [
                                51.703225878426004,
                                18.33815584472858
                            ],
                            [
                                51.57709975229988,
                                18.33815584472858
                            ]
                        ],
                        [
                            [
                                51.56299452517286,
                                18.401120865099223
                            ],
                            [
                                51.689120651298985,
                                18.401120865099223
                            ],
                            [
                                51.689120651298985,
                                18.2749947389731
                            ],
                            [
                                51.56299452517286,
                                18.2749947389731
                            ]
                        ],
                        [
                            [
                                51.54889647738895,
                                18.33795873612604
                            ],
                            [
                                51.675022603515075,
                                18.33795873612604
                            ],
                            [
                                51.675022603515075,
                                18.211832609999917
                            ],
                            [
                                51.54889647738895,
                                18.211832609999917
                            ]
                        ],
                        [
                            [
                                51.53480557868884,
                                18.274795587829722
                            ],
                            [
                                51.66093170481496,
                                18.274795587829722
                            ],
                            [
                                51.66093170481496,
                                18.148669461703598
                            ],
                            [
                                51.53480557868884,
                                18.148669461703598
                            ]
                        ],
                        [
                            [
                                51.520721796253966,
                                18.21163142410192
                            ],
                            [
                                51.64684792238009,
                                18.21163142410192
                            ],
                            [
                                51.64684792238009,
                                18.085505297975796
                            ],
                            [
                                51.520721796253966,
                                18.085505297975796
                            ]
                        ],
                        [
                            [
                                51.50664509998559,
                                18.148466248831394
                            ],
                            [
                                51.632771226111714,
                                18.148466248831394
                            ],
                            [
                                51.632771226111714,
                                18.02234012270527
                            ],
                            [
                                51.50664509998559,
                                18.02234012270527
                            ]
                        ],
                        [
                            [
                                51.492575457224895,
                                18.08530006590401
                            ],
                            [
                                51.61870158335102,
                                18.08530006590401
                            ],
                            [
                                51.61870158335102,
                                17.959173939777887
                            ],
                            [
                                51.492575457224895,
                                17.959173939777887
                            ]
                        ],
                        [
                            [
                                51.478512838023896,
                                18.022132879202854
                            ],
                            [
                                51.60463896415002,
                                18.022132879202854
                            ],
                            [
                                51.60463896415002,
                                17.89600675307673
                            ],
                            [
                                51.478512838023896,
                                17.89600675307673
                            ]
                        ],
                        [
                            [
                                51.464457209881814,
                                17.958964692608113
                            ],
                            [
                                51.59058333600794,
                                17.958964692608113
                            ],
                            [
                                51.59058333600794,
                                17.83283856648199
                            ],
                            [
                                51.464457209881814,
                                17.83283856648199
                            ]
                        ],
                        [
                            [
                                51.450408543004585,
                                17.89579550999722
                            ],
                            [
                                51.57653466913071,
                                17.89579550999722
                            ],
                            [
                                51.57653466913071,
                                17.769669383871097
                            ],
                            [
                                51.450408543004585,
                                17.769669383871097
                            ]
                        ],
                        [
                            [
                                51.436366805036386,
                                17.832625335244813
                            ],
                            [
                                51.56249293116251,
                                17.832625335244813
                            ],
                            [
                                51.56249293116251,
                                17.70649920911869
                            ],
                            [
                                51.436366805036386,
                                17.70649920911869
                            ]
                        ],
                        [
                            [
                                51.42233196634363,
                                17.7694541722228
                            ],
                            [
                                51.54845809246975,
                                17.7694541722228
                            ],
                            [
                                51.54845809246975,
                                17.643328046096677
                            ],
                            [
                                51.42233196634363,
                                17.643328046096677
                            ]
                        ],
                        [
                            [
                                51.40830399472518,
                                17.70628202480028
                            ],
                            [
                                51.534430120851304,
                                17.70628202480028
                            ],
                            [
                                51.534430120851304,
                                17.580155898674157
                            ],
                            [
                                51.40830399472518,
                                17.580155898674157
                            ]
                        ],
                        [
                            [
                                51.394282860688286,
                                17.643108896843714
                            ],
                            [
                                51.52040898681441,
                                17.643108896843714
                            ],
                            [
                                51.52040898681441,
                                17.51698277071759
                            ],
                            [
                                51.394282860688286,
                                17.51698277071759
                            ]
                        ],
                        [
                            [
                                51.38026853218983,
                                17.57993479221682
                            ],
                            [
                                51.506394658315955,
                                17.57993479221682
                            ],
                            [
                                51.506394658315955,
                                17.453808666090698
                            ],
                            [
                                51.38026853218983,
                                17.453808666090698
                            ]
                        ],
                        [
                            [
                                51.366260979879435,
                                17.51675971478066
                            ],
                            [
                                51.49238710600556,
                                17.51675971478066
                            ],
                            [
                                51.49238710600556,
                                17.390633588654538
                            ],
                            [
                                51.366260979879435,
                                17.390633588654538
                            ]
                        ],
                        [
                            [
                                51.35226017186387,
                                17.453583668393662
                            ],
                            [
                                51.47838629798999,
                                17.453583668393662
                            ],
                            [
                                51.47838629798999,
                                17.32745754226754
                            ],
                            [
                                51.35226017186387,
                                17.32745754226754
                            ]
                        ],
                        [
                            [
                                51.33826607894669,
                                17.390406656911573
                            ],
                            [
                                51.46439220507281,
                                17.390406656911573
                            ],
                            [
                                51.46439220507281,
                                17.26428053078545
                            ],
                            [
                                51.33826607894669,
                                17.26428053078545
                            ]
                        ],
                        [
                            [
                                51.32427866937463,
                                17.32722868418758
                            ],
                            [
                                51.45040479550075,
                                17.32722868418758
                            ],
                            [
                                51.45040479550075,
                                17.201102558061457
                            ],
                            [
                                51.32427866937463,
                                17.201102558061457
                            ]
                        ],
                        [
                            [
                                51.310297914100346,
                                17.264049754072232
                            ],
                            [
                                51.43642404022647,
                                17.264049754072232
                            ],
                            [
                                51.43642404022647,
                                17.13792362794611
                            ],
                            [
                                51.310297914100346,
                                17.13792362794611
                            ]
                        ],
                        [
                            [
                                51.29632378151227,
                                17.20086987041354
                            ],
                            [
                                51.422449907638395,
                                17.20086987041354
                            ],
                            [
                                51.422449907638395,
                                17.074743744287417
                            ],
                            [
                                51.29632378151227,
                                17.074743744287417
                            ]
                        ],
                        [
                            [
                                51.28235624270721,
                                17.137689037056983
                            ],
                            [
                                51.40848236883333,
                                17.137689037056983
                            ],
                            [
                                51.40848236883333,
                                17.01156291093086
                            ],
                            [
                                51.28235624270721,
                                17.01156291093086
                            ]
                        ],
                        [
                            [
                                51.26839526622022,
                                17.074507257845422
                            ],
                            [
                                51.39452139234634,
                                17.074507257845422
                            ],
                            [
                                51.39452139234634,
                                16.948381131719298
                            ],
                            [
                                51.26839526622022,
                                16.948381131719298
                            ]
                        ],
                        [
                            [
                                51.25444082328908,
                                17.011324536619323
                            ],
                            [
                                51.380566949415204,
                                17.011324536619323
                            ],
                            [
                                51.380566949415204,
                                16.8851984104932
                            ],
                            [
                                51.25444082328908,
                                16.8851984104932
                            ]
                        ],
                        [
                            [
                                51.24049288259399,
                                16.94814087721658
                            ],
                            [
                                51.366619008720114,
                                16.94814087721658
                            ],
                            [
                                51.366619008720114,
                                16.822014751090457
                            ],
                            [
                                51.24049288259399,
                                16.822014751090457
                            ]
                        ],
                        [
                            [
                                51.226551415505575,
                                16.884956283472633
                            ],
                            [
                                51.3526775416317,
                                16.884956283472633
                            ],
                            [
                                51.3526775416317,
                                16.75883015734651
                            ],
                            [
                                51.226551415505575,
                                16.75883015734651
                            ]
                        ],
                        [
                            [
                                51.212616390849135,
                                16.82177075922048
                            ],
                            [
                                51.33874251697526,
                                16.82177075922048
                            ],
                            [
                                51.33874251697526,
                                16.695644633094357
                            ],
                            [
                                51.212616390849135,
                                16.695644633094357
                            ]
                        ],
                        [
                            [
                                51.19868778013804,
                                16.758584308290672
                            ],
                            [
                                51.32481390626416,
                                16.758584308290672
                            ],
                            [
                                51.32481390626416,
                                16.632458182164548
                            ],
                            [
                                51.19868778013804,
                                16.632458182164548
                            ]
                        ],
                        [
                            [
                                51.184765552331456,
                                16.695396934511393
                            ],
                            [
                                51.31089167845758,
                                16.695396934511393
                            ],
                            [
                                51.31089167845758,
                                16.56927080838527
                            ],
                            [
                                51.184765552331456,
                                16.56927080838527
                            ]
                        ],
                        [
                            [
                                51.170849679080675,
                                16.632208641708353
                            ],
                            [
                                51.2969758052068,
                                16.632208641708353
                            ],
                            [
                                51.2969758052068,
                                16.50608251558223
                            ],
                            [
                                51.170849679080675,
                                16.50608251558223
                            ]
                        ],
                        [
                            [
                                51.15694012948693,
                                16.56901943370494
                            ],
                            [
                                51.28306625561305,
                                16.56901943370494
                            ],
                            [
                                51.28306625561305,
                                16.442893307578817
                            ],
                            [
                                51.15694012948693,
                                16.442893307578817
                            ]
                        ],
                        [
                            [
                                51.14303687533302,
                                16.50582931432218
                            ],
                            [
                                51.26916300145914,
                                16.50582931432218
                            ],
                            [
                                51.26916300145914,
                                16.379703188196057
                            ],
                            [
                                51.14303687533302,
                                16.379703188196057
                            ]
                        ],
                        [
                            [
                                51.12913988585578,
                                16.442638287378742
                            ],
                            [
                                51.2552660119819,
                                16.442638287378742
                            ],
                            [
                                51.2552660119819,
                                16.316512161252618
                            ],
                            [
                                51.12913988585578,
                                16.316512161252618
                            ]
                        ],
                        [
                            [
                                51.115249132319995,
                                16.37944635669099
                            ],
                            [
                                51.24137525844612,
                                16.37944635669099
                            ],
                            [
                                51.24137525844612,
                                16.253320230564867
                            ],
                            [
                                51.115249132319995,
                                16.253320230564867
                            ]
                        ],
                        [
                            [
                                51.10136458606543,
                                16.316253526073
                            ],
                            [
                                51.22749071219155,
                                16.316253526073
                            ],
                            [
                                51.22749071219155,
                                16.190127399946878
                            ],
                            [
                                51.10136458606543,
                                16.190127399946878
                            ]
                        ],
                        [
                            [
                                51.08748621651977,
                                16.253059799336533
                            ],
                            [
                                51.213612342645895,
                                16.253059799336533
                            ],
                            [
                                51.213612342645895,
                                16.12693367321041
                            ],
                            [
                                51.08748621651977,
                                16.12693367321041
                            ]
                        ],
                        [
                            [
                                51.073613995808465,
                                16.189865180291083
                            ],
                            [
                                51.19974012193459,
                                16.189865180291083
                            ],
                            [
                                51.19974012193459,
                                16.06373905416496
                            ],
                            [
                                51.073613995808465,
                                16.06373905416496
                            ]
                        ],
                        [
                            [
                                51.05974789349444,
                                16.12666967274395
                            ],
                            [
                                51.18587401962056,
                                16.12666967274395
                            ],
                            [
                                51.18587401962056,
                                16.000543546617827
                            ],
                            [
                                51.05974789349444,
                                16.000543546617827
                            ]
                        ],
                        [
                            [
                                51.04588788183751,
                                16.06347328050014
                            ],
                            [
                                51.17201400796363,
                                16.06347328050014
                            ],
                            [
                                51.17201400796363,
                                15.937347154374015
                            ],
                            [
                                51.04588788183751,
                                15.937347154374015
                            ]
                        ],
                        [
                            [
                                51.03203393052611,
                                16.000276007362494
                            ],
                            [
                                51.158160056652235,
                                16.000276007362494
                            ],
                            [
                                51.158160056652235,
                                15.874149881236367
                            ],
                            [
                                51.03203393052611,
                                15.874149881236367
                            ]
                        ],
                        [
                            [
                                51.01818601194979,
                                15.937077857131634
                            ],
                            [
                                51.14431213807591,
                                15.937077857131634
                            ],
                            [
                                51.14431213807591,
                                15.810951731005506
                            ],
                            [
                                51.01818601194979,
                                15.810951731005506
                            ]
                        ],
                        [
                            [
                                51.00434409593086,
                                15.873878833606003
                            ],
                            [
                                51.130470222056985,
                                15.873878833606003
                            ],
                            [
                                51.130470222056985,
                                15.747752707479876
                            ],
                            [
                                51.00434409593086,
                                15.747752707479876
                            ]
                        ],
                        [
                            [
                                50.99050815498382,
                                15.810678940581903
                            ],
                            [
                                51.116634281109945,
                                15.810678940581903
                            ],
                            [
                                51.116634281109945,
                                15.684552814455776
                            ],
                            [
                                50.99050815498382,
                                15.684552814455776
                            ]
                        ],
                        [
                            [
                                50.97667815905363,
                                15.747478181853493
                            ],
                            [
                                51.10280428517975,
                                15.747478181853493
                            ],
                            [
                                51.10280428517975,
                                15.621352055727366
                            ],
                            [
                                50.97667815905363,
                                15.621352055727366
                            ]
                        ],
                        [
                            [
                                50.962854080789725,
                                15.684276561212783
                            ],
                            [
                                51.08898020691585,
                                15.684276561212783
                            ],
                            [
                                51.08898020691585,
                                15.558150435086656
                            ],
                            [
                                50.962854080789725,
                                15.558150435086656
                            ]
                        ],
                        [
                            [
                                50.949035890259815,
                                15.621074082449713
                            ],
                            [
                                51.07516201638594,
                                15.621074082449713
                            ],
                            [
                                51.07516201638594,
                                15.494947956323585
                            ],
                            [
                                50.949035890259815,
                                15.494947956323585
                            ]
                        ],
                        [
                            [
                                50.935223560240445,
                                15.557870749352103
                            ],
                            [
                                51.06134968636657,
                                15.557870749352103
                            ],
                            [
                                51.06134968636657,
                                15.431744623225976
                            ],
                            [
                                50.935223560240445,
                                15.431744623225976
                            ]
                        ],
                        [
                            [
                                50.92141706092228,
                                15.494666565705703
                            ],
                            [
                                51.047543187048404,
                                15.494666565705703
                            ],
                            [
                                51.047543187048404,
                                15.368540439579576
                            ],
                            [
                                50.92141706092228,
                                15.368540439579576
                            ]
                        ],
                        [
                            [
                                50.90761636520086,
                                15.431461535294234
                            ],
                            [
                                51.03374249132698,
                                15.431461535294234
                            ],
                            [
                                51.03374249132698,
                                15.305335409168107
                            ],
                            [
                                50.90761636520086,
                                15.305335409168107
                            ]
                        ],
                        [
                            [
                                50.89382144339828,
                                15.368255661899324
                            ],
                            [
                                51.019947569524405,
                                15.368255661899324
                            ],
                            [
                                51.019947569524405,
                                15.242129535773197
                            ],
                            [
                                50.89382144339828,
                                15.242129535773197
                            ]
                        ],
                        [
                            [
                                50.8800322685277,
                                15.305048949300623
                            ],
                            [
                                51.00615839465382,
                                15.305048949300623
                            ],
                            [
                                51.00615839465382,
                                15.178922823174496
                            ],
                            [
                                50.8800322685277,
                                15.178922823174496
                            ]
                        ],
                        [
                            [
                                50.866248811037906,
                                15.241841401275764
                            ],
                            [
                                50.99237493716403,
                                15.241841401275764
                            ],
                            [
                                50.99237493716403,
                                15.115715275149636
                            ],
                            [
                                50.866248811037906,
                                15.115715275149636
                            ]
                        ],
                        [
                            [
                                50.85247104406158,
                                15.178633021600364
                            ],
                            [
                                50.978597170187705,
                                15.178633021600364
                            ],
                            [
                                50.978597170187705,
                                15.052506895474236
                            ],
                            [
                                50.85247104406158,
                                15.052506895474236
                            ]
                        ],
                        [
                            [
                                50.838698938161436,
                                15.115423814048114
                            ],
                            [
                                50.96482506428756,
                                15.115423814048114
                            ],
                            [
                                50.96482506428756,
                                14.989297687921987
                            ],
                            [
                                50.838698938161436,
                                14.989297687921987
                            ]
                        ],
                        [
                            [
                                50.82493246659473,
                                15.052213782390695
                            ],
                            [
                                50.951058592720855,
                                15.052213782390695
                            ],
                            [
                                50.951058592720855,
                                14.926087656264567
                            ],
                            [
                                50.82493246659473,
                                14.926087656264567
                            ]
                        ],
                        [
                            [
                                50.811171600044865,
                                14.989002930397843
                            ],
                            [
                                50.93729772617099,
                                14.989002930397843
                            ],
                            [
                                50.93729772617099,
                                14.862876804271716
                            ],
                            [
                                50.811171600044865,
                                14.862876804271716
                            ]
                        ],
                        [
                            [
                                50.7974163118858,
                                14.925791261837423
                            ],
                            [
                                50.92354243801192,
                                14.925791261837423
                            ],
                            [
                                50.92354243801192,
                                14.799665135711296
                            ],
                            [
                                50.7974163118858,
                                14.799665135711296
                            ]
                        ],
                        [
                            [
                                50.78366657292024,
                                14.862578780475344
                            ],
                            [
                                50.90979269904636,
                                14.862578780475344
                            ],
                            [
                                50.90979269904636,
                                14.736452654349216
                            ],
                            [
                                50.78366657292024,
                                14.736452654349216
                            ]
                        ],
                        [
                            [
                                50.7699223566375,
                                14.799365490075614
                            ],
                            [
                                50.896048482763625,
                                14.799365490075614
                            ],
                            [
                                50.896048482763625,
                                14.673239363949486
                            ],
                            [
                                50.7699223566375,
                                14.673239363949486
                            ]
                        ],
                        [
                            [
                                50.75618363395335,
                                14.736151394400373
                            ],
                            [
                                50.88230976007947,
                                14.736151394400373
                            ],
                            [
                                50.88230976007947,
                                14.610025268274246
                            ],
                            [
                                50.75618363395335,
                                14.610025268274246
                            ]
                        ],
                        [
                            [
                                50.74245037847762,
                                14.672936497209914
                            ],
                            [
                                50.868576504603745,
                                14.672936497209914
                            ],
                            [
                                50.868576504603745,
                                14.546810371083787
                            ],
                            [
                                50.74245037847762,
                                14.546810371083787
                            ]
                        ],
                        [
                            [
                                50.72872256123779,
                                14.609720802262634
                            ],
                            [
                                50.85484868736391,
                                14.609720802262634
                            ],
                            [
                                50.85484868736391,
                                14.483594676136507
                            ],
                            [
                                50.72872256123779,
                                14.483594676136507
                            ]
                        ],
                        [
                            [
                                50.71500015596116,
                                14.546504313315134
                            ],
                            [
                                50.84112628208728,
                                14.546504313315134
                            ],
                            [
                                50.84112628208728,
                                14.420378187189007
                            ],
                            [
                                50.71500015596116,
                                14.420378187189007
                            ]
                        ],
                        [
                            [
                                50.70128313379046,
                                14.483287034122183
                            ],
                            [
                                50.827409259916585,
                                14.483287034122183
                            ],
                            [
                                50.827409259916585,
                                14.357160907996056
                            ],
                            [
                                50.70128313379046,
                                14.357160907996056
                            ]
                        ],
                        [
                            [
                                50.6875714685579,
                                14.420068968436743
                            ],
                            [
                                50.81369759468402,
                                14.420068968436743
                            ],
                            [
                                50.81369759468402,
                                14.293942842310615
                            ],
                            [
                                50.6875714685579,
                                14.293942842310615
                            ]
                        ],
                        [
                            [
                                50.673865131524884,
                                14.356850120009994
                            ],
                            [
                                50.79999125765101,
                                14.356850120009994
                            ],
                            [
                                50.79999125765101,
                                14.230723993883867
                            ],
                            [
                                50.673865131524884,
                                14.230723993883867
                            ]
                        ],
                        [
                            [
                                50.660164096633615,
                                14.293630492591303
                            ],
                            [
                                50.78629022275974,
                                14.293630492591303
                            ],
                            [
                                50.78629022275974,
                                14.167504366465176
                            ],
                            [
                                50.660164096633615,
                                14.167504366465176
                            ]
                        ],
                        [
                            [
                                50.646468335254916,
                                14.230410089928343
                            ],
                            [
                                50.77259446138104,
                                14.230410089928343
                            ],
                            [
                                50.77259446138104,
                                14.104283963802215
                            ],
                            [
                                50.646468335254916,
                                14.104283963802215
                            ]
                        ],
                        [
                            [
                                50.63277782144614,
                                14.167188915766964
                            ],
                            [
                                50.758903947572264,
                                14.167188915766964
                            ],
                            [
                                50.758903947572264,
                                14.041062789640836
                            ],
                            [
                                50.63277782144614,
                                14.041062789640836
                            ]
                        ],
                        [
                            [
                                50.619092526682785,
                                14.103966973851344
                            ],
                            [
                                50.74521865280891,
                                14.103966973851344
                            ],
                            [
                                50.74521865280891,
                                13.977840847725217
                            ],
                            [
                                50.619092526682785,
                                13.977840847725217
                            ]
                        ],
                        [
                            [
                                50.60541242513128,
                                14.040744267923914
                            ],
                            [
                                50.7315385512574,
                                14.040744267923914
                            ],
                            [
                                50.7315385512574,
                                13.914618141797787
                            ],
                            [
                                50.60541242513128,
                                13.914618141797787
                            ]
                        ],
                        [
                            [
                                50.59173748837874,
                                13.977520801725404
                            ],
                            [
                                50.717863614504864,
                                13.977520801725404
                            ],
                            [
                                50.717863614504864,
                                13.851394675599277
                            ],
                            [
                                50.59173748837874,
                                13.851394675599277
                            ]
                        ],
                        [
                            [
                                50.5780676906976,
                                13.914296578994835
                            ],
                            [
                                50.704193816823725,
                                13.914296578994835
                            ],
                            [
                                50.704193816823725,
                                13.788170452868707
                            ],
                            [
                                50.5780676906976,
                                13.788170452868707
                            ]
                        ],
                        [
                            [
                                50.56440300378049,
                                13.851071603469613
                            ],
                            [
                                50.690529129906615,
                                13.851071603469613
                            ],
                            [
                                50.690529129906615,
                                13.724945477343486
                            ],
                            [
                                50.56440300378049,
                                13.724945477343486
                            ]
                        ],
                        [
                            [
                                50.55074340135478,
                                13.787845878885413
                            ],
                            [
                                50.6768695274809,
                                13.787845878885413
                            ],
                            [
                                50.6768695274809,
                                13.661719752759286
                            ],
                            [
                                50.55074340135478,
                                13.661719752759286
                            ]
                        ],
                        [
                            [
                                50.53708885718445,
                                13.724619408976313
                            ],
                            [
                                50.663214983310574,
                                13.724619408976313
                            ],
                            [
                                50.663214983310574,
                                13.598493282850185
                            ],
                            [
                                50.53708885718445,
                                13.598493282850185
                            ]
                        ],
                        [
                            [
                                50.523439343129965,
                                13.661392197474713
                            ],
                            [
                                50.64956546925609,
                                13.661392197474713
                            ],
                            [
                                50.64956546925609,
                                13.535266071348586
                            ],
                            [
                                50.523439343129965,
                                13.535266071348586
                            ]
                        ],
                        [
                            [
                                50.50979483372091,
                                13.598164248111434
                            ],
                            [
                                50.635920959847034,
                                13.598164248111434
                            ],
                            [
                                50.635920959847034,
                                13.472038121985307
                            ],
                            [
                                50.50979483372091,
                                13.472038121985307
                            ]
                        ],
                        [
                            [
                                50.496155300921686,
                                13.534935564615644
                            ],
                            [
                                50.62228142704781,
                                13.534935564615644
                            ],
                            [
                                50.62228142704781,
                                13.408809438489516
                            ],
                            [
                                50.496155300921686,
                                13.408809438489516
                            ]
                        ],
                        [
                            [
                                60.39013241280216,
                                22.59447728821307
                            ],
                            [
                                60.516258538928284,
                                22.59447728821307
                            ],
                            [
                                60.516258538928284,
                                22.468351162086947
                            ],
                            [
                                60.39013241280216,
                                22.468351162086947
                            ]
                        ],
                        [
                            [
                                60.37549498180649,
                                22.531450083998223
                            ],
                            [
                                60.501621107932614,
                                22.531450083998223
                            ],
                            [
                                60.501621107932614,
                                22.4053239578721
                            ],
                            [
                                60.37549498180649,
                                22.4053239578721
                            ]
                        ],
                        [
                            [
                                60.36086697776114,
                                22.468421628985833
                            ],
                            [
                                60.486993103887265,
                                22.468421628985833
                            ],
                            [
                                60.486993103887265,
                                22.34229550285971
                            ],
                            [
                                60.36086697776114,
                                22.34229550285971
                            ]
                        ],
                        [
                            [
                                60.346248364295775,
                                22.40539192723078
                            ],
                            [
                                60.4723744904219,
                                22.40539192723078
                            ],
                            [
                                60.4723744904219,
                                22.279265801104657
                            ],
                            [
                                60.346248364295775,
                                22.279265801104657
                            ]
                        ],
                        [
                            [
                                60.33163910252464,
                                22.342360982783163
                            ],
                            [
                                60.457765228650764,
                                22.342360982783163
                            ],
                            [
                                60.457765228650764,
                                22.21623485665704
                            ],
                            [
                                60.33163910252464,
                                22.21623485665704
                            ]
                        ],
                        [
                            [
                                60.317039156292715,
                                22.279328799688322
                            ],
                            [
                                60.44316528241884,
                                22.279328799688322
                            ],
                            [
                                60.44316528241884,
                                22.1532026735622
                            ],
                            [
                                60.317039156292715,
                                22.1532026735622
                            ]
                        ],
                        [
                            [
                                60.3024484869382,
                                22.216295381986843
                            ],
                            [
                                60.42857461306432,
                                22.216295381986843
                            ],
                            [
                                60.42857461306432,
                                22.09016925586072
                            ],
                            [
                                60.3024484869382,
                                22.09016925586072
                            ]
                        ],
                        [
                            [
                                60.28786705786782,
                                22.153260733714582
                            ],
                            [
                                60.413993183993945,
                                22.153260733714582
                            ],
                            [
                                60.413993183993945,
                                22.027134607588458
                            ],
                            [
                                60.28786705786782,
                                22.027134607588458
                            ]
                        ],
                        [
                            [
                                60.27329483260378,
                                22.09022485890275
                            ],
                            [
                                60.399420958729905,
                                22.09022485890275
                            ],
                            [
                                60.399420958729905,
                                21.964098732776627
                            ],
                            [
                                60.27329483260378,
                                21.964098732776627
                            ]
                        ],
                        [
                            [
                                60.25873177280448,
                                22.02718776157793
                            ],
                            [
                                60.384857898930605,
                                22.02718776157793
                            ],
                            [
                                60.384857898930605,
                                21.901061635451807
                            ],
                            [
                                60.25873177280448,
                                21.901061635451807
                            ]
                        ],
                        [
                            [
                                60.244177842867806,
                                21.96414944576206
                            ],
                            [
                                60.37030396899393,
                                21.96414944576206
                            ],
                            [
                                60.37030396899393,
                                21.838023319635937
                            ],
                            [
                                60.244177842867806,
                                21.838023319635937
                            ]
                        ],
                        [
                            [
                                60.229633004660705,
                                21.90110991547253
                            ],
                            [
                                60.35575913078683,
                                21.90110991547253
                            ],
                            [
                                60.35575913078683,
                                21.774983789346408
                            ],
                            [
                                60.229633004660705,
                                21.774983789346408
                            ]
                        ],
                        [
                            [
                                60.215097222799905,
                                21.838069174722182
                            ],
                            [
                                60.34122334892603,
                                21.838069174722182
                            ],
                            [
                                60.34122334892603,
                                21.71194304859606
                            ],
                            [
                                60.215097222799905,
                                21.71194304859606
                            ]
                        ],
                        [
                            [
                                60.20057045936349,
                                21.775027227519402
                            ],
                            [
                                60.326696585489614,
                                21.775027227519402
                            ],
                            [
                                60.326696585489614,
                                21.648901101393278
                            ],
                            [
                                60.20057045936349,
                                21.648901101393278
                            ]
                        ],
                        [
                            [
                                60.18605267917983,
                                21.711984077868003
                            ],
                            [
                                60.312178805305955,
                                21.711984077868003
                            ],
                            [
                                60.312178805305955,
                                21.58585795174188
                            ],
                            [
                                60.18605267917983,
                                21.58585795174188
                            ]
                        ],
                        [
                            [
                                60.17154384454092,
                                21.64893972976744
                            ],
                            [
                                60.297669970667044,
                                21.64893972976744
                            ],
                            [
                                60.297669970667044,
                                21.522813603641318
                            ],
                            [
                                60.17154384454092,
                                21.522813603641318
                            ]
                        ],
                        [
                            [
                                60.15704392047646,
                                21.58589418721274
                            ],
                            [
                                60.283170046602585,
                                21.58589418721274
                            ],
                            [
                                60.283170046602585,
                                21.459768061086617
                            ],
                            [
                                60.15704392047646,
                                21.459768061086617
                            ]
                        ],
                        [
                            [
                                60.142552869491745,
                                21.52284745419456
                            ],
                            [
                                60.26867899561787,
                                21.52284745419456
                            ],
                            [
                                60.26867899561787,
                                21.396721328068438
                            ],
                            [
                                60.142552869491745,
                                21.396721328068438
                            ]
                        ],
                        [
                            [
                                60.128070656827006,
                                21.459799534699194
                            ],
                            [
                                60.25419678295313,
                                21.459799534699194
                            ],
                            [
                                60.25419678295313,
                                21.33367340857307
                            ],
                            [
                                60.128070656827006,
                                21.33367340857307
                            ]
                        ],
                        [
                            [
                                60.11359724518888,
                                21.396750432708632
                            ],
                            [
                                60.239723371315,
                                21.396750432708632
                            ],
                            [
                                60.239723371315,
                                21.27062430658251
                            ],
                            [
                                60.11359724518888,
                                21.27062430658251
                            ]
                        ],
                        [
                            [
                                60.09913260002439,
                                21.333700152200592
                            ],
                            [
                                60.225258726150514,
                                21.333700152200592
                            ],
                            [
                                60.225258726150514,
                                21.207574026074468
                            ],
                            [
                                60.09913260002439,
                                21.207574026074468
                            ]
                        ],
                        [
                            [
                                60.08467668424425,
                                21.27064869714855
                            ],
                            [
                                60.210802810370375,
                                21.27064869714855
                            ],
                            [
                                60.210802810370375,
                                21.144522571022428
                            ],
                            [
                                60.08467668424425,
                                21.144522571022428
                            ]
                        ],
                        [
                            [
                                60.070229463496815,
                                21.20759607152173
                            ],
                            [
                                60.19635558962294,
                                21.20759607152173
                            ],
                            [
                                60.19635558962294,
                                21.081469945395607
                            ],
                            [
                                60.070229463496815,
                                21.081469945395607
                            ]
                        ],
                        [
                            [
                                60.05579090090133,
                                21.144542279285222
                            ],
                            [
                                60.18191702702745,
                                21.144542279285222
                            ],
                            [
                                60.18191702702745,
                                21.018416153159098
                            ],
                            [
                                60.05579090090133,
                                21.018416153159098
                            ]
                        ],
                        [
                            [
                                60.04136096229726,
                                21.081487324399912
                            ],
                            [
                                60.167487088423385,
                                21.081487324399912
                            ],
                            [
                                60.167487088423385,
                                20.95536119827379
                            ],
                            [
                                60.04136096229726,
                                20.95536119827379
                            ]
                        ],
                        [
                            [
                                60.02693961100853,
                                21.0184312108226
                            ],
                            [
                                60.15306573713465,
                                21.0184312108226
                            ],
                            [
                                60.15306573713465,
                                20.892305084696478
                            ],
                            [
                                60.02693961100853,
                                20.892305084696478
                            ]
                        ],
                        [
                            [
                                60.012526813075056,
                                20.95537394250596
                            ],
                            [
                                60.13865293920118,
                                20.95537394250596
                            ],
                            [
                                60.13865293920118,
                                20.829247816379837
                            ],
                            [
                                60.012526813075056,
                                20.829247816379837
                            ]
                        ],
                        [
                            [
                                59.998122532015245,
                                20.89231552339861
                            ],
                            [
                                60.12424865814137,
                                20.89231552339861
                            ],
                            [
                                60.12424865814137,
                                20.766189397272488
                            ],
                            [
                                59.998122532015245,
                                20.766189397272488
                            ]
                        ],
                        [
                            [
                                59.98372673407395,
                                20.82925595744514
                            ],
                            [
                                60.10985286020007,
                                20.82925595744514
                            ],
                            [
                                60.10985286020007,
                                20.703129831319018
                            ],
                            [
                                59.98372673407395,
                                20.703129831319018
                            ]
                        ],
                        [
                            [
                                59.96933938295542,
                                20.766195248586143
                            ],
                            [
                                60.09546550908154,
                                20.766195248586143
                            ],
                            [
                                60.09546550908154,
                                20.64006912246002
                            ],
                            [
                                59.96933938295542,
                                20.64006912246002
                            ]
                        ],
                        [
                            [
                                59.954960445095885,
                                20.703133400758222
                            ],
                            [
                                60.08108657122201,
                                20.703133400758222
                            ],
                            [
                                60.08108657122201,
                                20.5770072746321
                            ],
                            [
                                59.954960445095885,
                                20.5770072746321
                            ]
                        ],
                        [
                            [
                                59.94058988440308,
                                20.640070417894023
                            ],
                            [
                                60.066716010529206,
                                20.640070417894023
                            ],
                            [
                                60.066716010529206,
                                20.5139442917679
                            ],
                            [
                                59.94058988440308,
                                20.5139442917679
                            ]
                        ],
                        [
                            [
                                59.92622766750582,
                                20.577006303922303
                            ],
                            [
                                60.052353793631944,
                                20.577006303922303
                            ],
                            [
                                60.052353793631944,
                                20.45088017779618
                            ],
                            [
                                59.92622766750582,
                                20.45088017779618
                            ]
                        ],
                        [
                            [
                                59.911873758501855,
                                20.513941062767913
                            ],
                            [
                                60.03799988462798,
                                20.513941062767913
                            ],
                            [
                                60.03799988462798,
                                20.38781493664179
                            ],
                            [
                                59.911873758501855,
                                20.38781493664179
                            ]
                        ],
                        [
                            [
                                59.897528124204065,
                                20.45087469835186
                            ],
                            [
                                60.02365425033019,
                                20.45087469835186
                            ],
                            [
                                60.02365425033019,
                                20.324748572225737
                            ],
                            [
                                59.897528124204065,
                                20.324748572225737
                            ]
                        ],
                        [
                            [
                                59.88319072890465,
                                20.387807214591312
                            ],
                            [
                                60.009316855030775,
                                20.387807214591312
                            ],
                            [
                                60.009316855030775,
                                20.26168108846519
                            ],
                            [
                                59.88319072890465,
                                20.26168108846519
                            ]
                        ],
                        [
                            [
                                59.86886153960686,
                                20.32473861539961
                            ],
                            [
                                59.994987665732985,
                                20.32473861539961
                            ],
                            [
                                59.994987665732985,
                                20.198612489273486
                            ],
                            [
                                59.86886153960686,
                                20.198612489273486
                            ]
                        ],
                        [
                            [
                                59.854540520788746,
                                20.26166890468637
                            ],
                            [
                                59.98066664691487,
                                20.26166890468637
                            ],
                            [
                                59.98066664691487,
                                20.135542778560247
                            ],
                            [
                                59.854540520788746,
                                20.135542778560247
                            ]
                        ],
                        [
                            [
                                59.84022763964019,
                                20.19859808635742
                            ],
                            [
                                59.966353765766314,
                                20.19859808635742
                            ],
                            [
                                59.966353765766314,
                                20.072471960231297
                            ],
                            [
                                59.84022763964019,
                                20.072471960231297
                            ]
                        ],
                        [
                            [
                                59.82592286082666,
                                20.135526164314893
                            ],
                            [
                                59.95204898695278,
                                20.135526164314893
                            ],
                            [
                                59.95204898695278,
                                20.00940003818877
                            ],
                            [
                                59.82592286082666,
                                20.00940003818877
                            ]
                        ],
                        [
                            [
                                59.81162615172095,
                                20.07245314245722
                            ],
                            [
                                59.937752277847075,
                                20.07245314245722
                            ],
                            [
                                59.937752277847075,
                                19.946327016331097
                            ],
                            [
                                59.81162615172095,
                                19.946327016331097
                            ]
                        ],
                        [
                            [
                                59.79733747716901,
                                20.00937902467918
                            ],
                            [
                                59.92346360329513,
                                20.00937902467918
                            ],
                            [
                                59.92346360329513,
                                19.883252898553057
                            ],
                            [
                                59.79733747716901,
                                19.883252898553057
                            ]
                        ],
                        [
                            [
                                59.7830568047296,
                                19.94630381487191
                            ],
                            [
                                59.90918293085572,
                                19.94630381487191
                            ],
                            [
                                59.90918293085572,
                                19.820177688745787
                            ],
                            [
                                59.7830568047296,
                                19.820177688745787
                            ]
                        ],
                        [
                            [
                                59.76878409943207,
                                19.883227516922933
                            ],
                            [
                                59.89491022555819,
                                19.883227516922933
                            ],
                            [
                                59.89491022555819,
                                19.75710139079681
                            ],
                            [
                                59.76878409943207,
                                19.75710139079681
                            ]
                        ],
                        [
                            [
                                59.7545193290144,
                                19.8201501347162
                            ],
                            [
                                59.880645455140524,
                                19.8201501347162
                            ],
                            [
                                59.880645455140524,
                                19.694024008590077
                            ],
                            [
                                59.7545193290144,
                                19.694024008590077
                            ]
                        ],
                        [
                            [
                                59.74026245868896,
                                19.757071672132103
                            ],
                            [
                                59.866388584815084,
                                19.757071672132103
                            ],
                            [
                                59.866388584815084,
                                19.63094554600598
                            ],
                            [
                                59.74026245868896,
                                19.63094554600598
                            ]
                        ],
                        [
                            [
                                59.72601345571152,
                                19.6939921330475
                            ],
                            [
                                59.85213958183764,
                                19.6939921330475
                            ],
                            [
                                59.85213958183764,
                                19.567866006921378
                            ],
                            [
                                59.72601345571152,
                                19.567866006921378
                            ]
                        ],
                        [
                            [
                                59.71177228743796,
                                19.630911521335744
                            ],
                            [
                                59.83789841356408,
                                19.630911521335744
                            ],
                            [
                                59.83789841356408,
                                19.50478539520962
                            ],
                            [
                                59.71177228743796,
                                19.50478539520962
                            ]
                        ],
                        [
                            [
                                55.963770488241096,
                                17.139022163179863
                            ],
                            [
                                56.08989661436722,
                                17.139022163179863
                            ],
                            [
                                56.08989661436722,
                                17.01289603705374
                            ],
                            [
                                55.963770488241096,
                                17.01289603705374
                            ]
                        ],
                        [
                            [
                                55.95005218167705,
                                17.202163446347832
                            ],
                            [
                                56.076178307803175,
                                17.202163446347832
                            ],
                            [
                                56.076178307803175,
                                17.076037320221708
                            ],
                            [
                                55.95005218167705,
                                17.076037320221708
                            ]
                        ],
                        [
                            [
                                55.936327448463125,
                                17.26530382568632
                            ],
                            [
                                56.06245357458925,
                                17.26530382568632
                            ],
                            [
                                56.06245357458925,
                                17.139177699560197
                            ],
                            [
                                55.936327448463125,
                                17.139177699560197
                            ]
                        ],
                        [
                            [
                                55.92259625784828,
                                17.328443297552013
                            ],
                            [
                                56.0487223839744,
                                17.328443297552013
                            ],
                            [
                                56.0487223839744,
                                17.20231717142589
                            ],
                            [
                                55.92259625784828,
                                17.20231717142589
                            ]
                        ],
                        [
                            [
                                55.90885858163157,
                                17.391581858299283
                            ],
                            [
                                56.034984707757694,
                                17.391581858299283
                            ],
                            [
                                56.034984707757694,
                                17.26545573217316
                            ],
                            [
                                55.90885858163157,
                                17.26545573217316
                            ]
                        ],
                        [
                            [
                                55.89511438891818,
                                17.45471950428012
                            ],
                            [
                                56.021240515044305,
                                17.45471950428012
                            ],
                            [
                                56.021240515044305,
                                17.328593378153997
                            ],
                            [
                                55.89511438891818,
                                17.328593378153997
                            ]
                        ],
                        [
                            [
                                55.88136365136577,
                                17.517856231844164
                            ],
                            [
                                56.00748977749189,
                                17.517856231844164
                            ],
                            [
                                56.00748977749189,
                                17.39173010571804
                            ],
                            [
                                55.88136365136577,
                                17.39173010571804
                            ]
                        ],
                        [
                            [
                                55.86760633794063,
                                17.58099203733862
                            ],
                            [
                                55.99373246406675,
                                17.58099203733862
                            ],
                            [
                                55.99373246406675,
                                17.454865911212497
                            ],
                            [
                                55.86760633794063,
                                17.454865911212497
                            ]
                        ],
                        [
                            [
                                55.85384241949972,
                                17.64412691710828
                            ],
                            [
                                55.979968545625844,
                                17.64412691710828
                            ],
                            [
                                55.979968545625844,
                                17.518000790982157
                            ],
                            [
                                55.85384241949972,
                                17.518000790982157
                            ]
                        ],
                        [
                            [
                                55.84007186683598,
                                17.70726086749552
                            ],
                            [
                                55.9661979929621,
                                17.70726086749552
                            ],
                            [
                                55.9661979929621,
                                17.581134741369397
                            ],
                            [
                                55.84007186683598,
                                17.581134741369397
                            ]
                        ],
                        [
                            [
                                55.82629464868989,
                                17.77039388484023
                            ],
                            [
                                55.95242077481601,
                                17.77039388484023
                            ],
                            [
                                55.95242077481601,
                                17.644267758714108
                            ],
                            [
                                55.82629464868989,
                                17.644267758714108
                            ]
                        ],
                        [
                            [
                                55.81251073636544,
                                17.83352596547986
                            ],
                            [
                                55.93863686249156,
                                17.83352596547986
                            ],
                            [
                                55.93863686249156,
                                17.707399839353737
                            ],
                            [
                                55.81251073636544,
                                17.707399839353737
                            ]
                        ],
                        [
                            [
                                55.79872009846025,
                                17.896657105749313
                            ],
                            [
                                55.924846224586375,
                                17.896657105749313
                            ],
                            [
                                55.924846224586375,
                                17.77053097962319
                            ],
                            [
                                55.79872009846025,
                                17.77053097962319
                            ]
                        ],
                        [
                            [
                                55.78492270613144,
                                17.959787301981024
                            ],
                            [
                                55.91104883225756,
                                17.959787301981024
                            ],
                            [
                                55.91104883225756,
                                17.8336611758549
                            ],
                            [
                                55.78492270613144,
                                17.8336611758549
                            ]
                        ],
                        [
                            [
                                55.77111852782577,
                                18.022916550504853
                            ],
                            [
                                55.897244653951894,
                                18.022916550504853
                            ],
                            [
                                55.897244653951894,
                                17.89679042437873
                            ],
                            [
                                55.77111852782577,
                                17.89679042437873
                            ]
                        ],
                        [
                            [
                                55.75730753455511,
                                18.08604484764811
                            ],
                            [
                                55.883433660681234,
                                18.08604484764811
                            ],
                            [
                                55.883433660681234,
                                17.959918721521987
                            ],
                            [
                                55.75730753455511,
                                17.959918721521987
                            ]
                        ],
                        [
                            [
                                55.74348969461209,
                                18.149172189735562
                            ],
                            [
                                55.86961582073821,
                                18.149172189735562
                            ],
                            [
                                55.86961582073821,
                                18.02304606360944
                            ],
                            [
                                55.74348969461209,
                                18.02304606360944
                            ]
                        ],
                        [
                            [
                                55.72966497886175,
                                18.21229857308932
                            ],
                            [
                                55.85579110498787,
                                18.21229857308932
                            ],
                            [
                                55.85579110498787,
                                18.086172446963197
                            ],
                            [
                                55.72966497886175,
                                18.086172446963197
                            ]
                        ],
                        [
                            [
                                55.71583335545063,
                                18.275423994028912
                            ],
                            [
                                55.84195948157675,
                                18.275423994028912
                            ],
                            [
                                55.84195948157675,
                                18.14929786790279
                            ],
                            [
                                55.71583335545063,
                                18.14929786790279
                            ]
                        ],
                        [
                            [
                                55.701994795082385,
                                18.338548448871233
                            ],
                            [
                                55.82812092120851,
                                18.338548448871233
                            ],
                            [
                                55.82812092120851,
                                18.21242232274511
                            ],
                            [
                                55.701994795082385,
                                18.21242232274511
                            ]
                        ],
                        [
                            [
                                55.68814926575748,
                                18.40167193393049
                            ],
                            [
                                55.814275391883605,
                                18.40167193393049
                            ],
                            [
                                55.814275391883605,
                                18.275545807804367
                            ],
                            [
                                55.68814926575748,
                                18.275545807804367
                            ]
                        ],
                        [
                            [
                                55.674296738024516,
                                18.464794445518223
                            ],
                            [
                                55.80042286415064,
                                18.464794445518223
                            ],
                            [
                                55.80042286415064,
                                18.3386683193921
                            ],
                            [
                                55.674296738024516,
                                18.3386683193921
                            ]
                        ],
                        [
                            [
                                55.66043717972818,
                                18.527915979943252
                            ],
                            [
                                55.7865633058543,
                                18.527915979943252
                            ],
                            [
                                55.7865633058543,
                                18.401789853817128
                            ],
                            [
                                55.66043717972818,
                                18.401789853817128
                            ]
                        ],
                        [
                            [
                                55.64657056126528,
                                18.591036533511723
                            ],
                            [
                                55.7726966873914,
                                18.591036533511723
                            ],
                            [
                                55.7726966873914,
                                18.4649104073856
                            ],
                            [
                                55.64657056126528,
                                18.4649104073856
                            ]
                        ],
                        [
                            [
                                55.63269685032295,
                                18.65415610252696
                            ],
                            [
                                55.758822976449075,
                                18.65415610252696
                            ],
                            [
                                55.758822976449075,
                                18.528029976400838
                            ],
                            [
                                55.63269685032295,
                                18.528029976400838
                            ]
                        ],
                        [
                            [
                                55.618816017142976,
                                18.71727468328958
                            ],
                            [
                                55.7449421432691,
                                18.71727468328958
                            ],
                            [
                                55.7449421432691,
                                18.591148557163457
                            ],
                            [
                                55.618816017142976,
                                18.591148557163457
                            ]
                        ],
                        [
                            [
                                55.60492802925166,
                                18.78039227209739
                            ],
                            [
                                55.731054155377784,
                                18.78039227209739
                            ],
                            [
                                55.731054155377784,
                                18.654266145971267
                            ],
                            [
                                55.60492802925166,
                                18.654266145971267
                            ]
                        ],
                        [
                            [
                                55.59103285673234,
                                18.84350886524535
                            ],
                            [
                                55.717158982858464,
                                18.84350886524535
                            ],
                            [
                                55.717158982858464,
                                18.717382739119227
                            ],
                            [
                                55.59103285673234,
                                18.717382739119227
                            ]
                        ],
                        [
                            [
                                55.57713046695531,
                                18.90662445902567
                            ],
                            [
                                55.703256593081434,
                                18.90662445902567
                            ],
                            [
                                55.703256593081434,
                                18.780498332899548
                            ],
                            [
                                55.57713046695531,
                                18.780498332899548
                            ]
                        ],
                        [
                            [
                                55.563220829840475,
                                18.96973904972761
                            ],
                            [
                                55.6893469559666,
                                18.96973904972761
                            ],
                            [
                                55.6893469559666,
                                18.843612923601487
                            ],
                            [
                                55.563220829840475,
                                18.843612923601487
                            ]
                        ],
                        [
                            [
                                55.54930391260046,
                                19.032852633637614
                            ],
                            [
                                55.67543003872658,
                                19.032852633637614
                            ],
                            [
                                55.67543003872658,
                                18.90672650751149
                            ],
                            [
                                55.54930391260046,
                                18.90672650751149
                            ]
                        ],
                        [
                            [
                                55.535379684994915,
                                19.095965207039182
                            ],
                            [
                                55.66150581112104,
                                19.095965207039182
                            ],
                            [
                                55.66150581112104,
                                18.969839080913058
                            ],
                            [
                                55.535379684994915,
                                18.969839080913058
                            ]
                        ],
                        [
                            [
                                55.52144811406886,
                                19.15907676621295
                            ],
                            [
                                55.647574240194984,
                                19.15907676621295
                            ],
                            [
                                55.647574240194984,
                                19.032950640086828
                            ],
                            [
                                55.52144811406886,
                                19.032950640086828
                            ]
                        ],
                        [
                            [
                                55.50750916941834,
                                19.22218730743652
                            ],
                            [
                                55.633635295544465,
                                19.22218730743652
                            ],
                            [
                                55.633635295544465,
                                19.096061181310397
                            ],
                            [
                                55.50750916941834,
                                19.096061181310397
                            ]
                        ],
                        [
                            [
                                55.493562817922275,
                                19.285296826984602
                            ],
                            [
                                55.6196889440484,
                                19.285296826984602
                            ],
                            [
                                55.6196889440484,
                                19.15917070085848
                            ],
                            [
                                55.493562817922275,
                                19.15917070085848
                            ]
                        ],
                        [
                            [
                                55.479609029017865,
                                19.348405321128862
                            ],
                            [
                                55.60573515514399,
                                19.348405321128862
                            ],
                            [
                                55.60573515514399,
                                19.222279195002738
                            ],
                            [
                                55.479609029017865,
                                19.222279195002738
                            ]
                        ],
                        [
                            [
                                55.46564776941775,
                                19.411512786137983
                            ],
                            [
                                55.591773895543874,
                                19.411512786137983
                            ],
                            [
                                55.591773895543874,
                                19.28538666001186
                            ],
                            [
                                55.46564776941775,
                                19.28538666001186
                            ]
                        ],
                        [
                            [
                                55.45167900838393,
                                19.474619218277592
                            ],
                            [
                                55.57780513451005,
                                19.474619218277592
                            ],
                            [
                                55.57780513451005,
                                19.348493092151468
                            ],
                            [
                                55.45167900838393,
                                19.348493092151468
                            ]
                        ],
                        [
                            [
                                55.437702712467456,
                                19.537724613810262
                            ],
                            [
                                55.56382883859358,
                                19.537724613810262
                            ],
                            [
                                55.56382883859358,
                                19.411598487684138
                            ],
                            [
                                55.437702712467456,
                                19.411598487684138
                            ]
                        ],
                        [
                            [
                                55.4237188507598,
                                19.600828968995422
                            ],
                            [
                                55.54984497688592,
                                19.600828968995422
                            ],
                            [
                                55.54984497688592,
                                19.4747028428693
                            ],
                            [
                                55.4237188507598,
                                19.4747028428693
                            ]
                        ],
                        [
                            [
                                55.40972738964055,
                                19.66393228008949
                            ],
                            [
                                55.535853515766675,
                                19.66393228008949
                            ],
                            [
                                55.535853515766675,
                                19.537806153963366
                            ],
                            [
                                55.40972738964055,
                                19.537806153963366
                            ]
                        ],
                        [
                            [
                                55.39572829803371,
                                19.72703454334568
                            ],
                            [
                                55.521854424159834,
                                19.72703454334568
                            ],
                            [
                                55.521854424159834,
                                19.600908417219557
                            ],
                            [
                                55.39572829803371,
                                19.600908417219557
                            ]
                        ],
                        [
                            [
                                55.38172154214721,
                                19.79013575501404
                            ],
                            [
                                55.507847668273335,
                                19.79013575501404
                            ],
                            [
                                55.507847668273335,
                                19.664009628887918
                            ],
                            [
                                55.38172154214721,
                                19.664009628887918
                            ]
                        ],
                        [
                            [
                                55.36770709072924,
                                19.85323591134147
                            ],
                            [
                                55.493833216855364,
                                19.85323591134147
                            ],
                            [
                                55.493833216855364,
                                19.727109785215347
                            ],
                            [
                                55.36770709072924,
                                19.727109785215347
                            ]
                        ],
                        [
                            [
                                55.35368490981427,
                                19.91633500857161
                            ],
                            [
                                55.47981103594039,
                                19.91633500857161
                            ],
                            [
                                55.47981103594039,
                                19.790208882445487
                            ],
                            [
                                55.35368490981427,
                                19.790208882445487
                            ]
                        ],
                        [
                            [
                                55.3396549679793,
                                19.979433042944923
                            ],
                            [
                                55.465781094105424,
                                19.979433042944923
                            ],
                            [
                                55.465781094105424,
                                19.8533069168188
                            ],
                            [
                                55.3396549679793,
                                19.8533069168188
                            ]
                        ],
                        [
                            [
                                55.325617231083505,
                                20.04253001069858
                            ],
                            [
                                55.45174335720963,
                                20.04253001069858
                            ],
                            [
                                55.45174335720963,
                                19.916403884572457
                            ],
                            [
                                55.325617231083505,
                                19.916403884572457
                            ]
                        ],
                        [
                            [
                                55.31157166686972,
                                20.105625908066443
                            ],
                            [
                                55.43769779299584,
                                20.105625908066443
                            ],
                            [
                                55.43769779299584,
                                19.97949978194032
                            ],
                            [
                                55.31157166686972,
                                19.97949978194032
                            ]
                        ],
                        [
                            [
                                55.29751824298546,
                                20.168720731279123
                            ],
                            [
                                55.423644369111585,
                                20.168720731279123
                            ],
                            [
                                55.423644369111585,
                                20.042594605153
                            ],
                            [
                                55.29751824298546,
                                20.042594605153
                            ]
                        ],
                        [
                            [
                                55.283456925029896,
                                20.23181447656385
                            ],
                            [
                                55.40958305115602,
                                20.23181447656385
                            ],
                            [
                                55.40958305115602,
                                20.105688350437728
                            ],
                            [
                                55.283456925029896,
                                20.105688350437728
                            ]
                        ],
                        [
                            [
                                55.26938768113095,
                                20.294907140144513
                            ],
                            [
                                55.395513807257075,
                                20.294907140144513
                            ],
                            [
                                55.395513807257075,
                                20.16878101401839
                            ],
                            [
                                55.26938768113095,
                                20.16878101401839
                            ]
                        ],
                        [
                            [
                                55.25531047669963,
                                20.357998718241582
                            ],
                            [
                                55.38143660282575,
                                20.357998718241582
                            ],
                            [
                                55.38143660282575,
                                20.231872592115458
                            ],
                            [
                                55.25531047669963,
                                20.231872592115458
                            ]
                        ],
                        [
                            [
                                55.24122527968933,
                                20.421089207072132
                            ],
                            [
                                55.367351405815455,
                                20.421089207072132
                            ],
                            [
                                55.367351405815455,
                                20.294963080946008
                            ],
                            [
                                55.24122527968933,
                                20.294963080946008
                            ]
                        ],
                        [
                            [
                                55.22713205533568,
                                20.484178602849823
                            ],
                            [
                                55.353258181461804,
                                20.484178602849823
                            ],
                            [
                                55.353258181461804,
                                20.3580524767237
                            ],
                            [
                                55.22713205533568,
                                20.3580524767237
                            ]
                        ],
                        [
                            [
                                55.21303077139455,
                                20.547266901784813
                            ],
                            [
                                55.339156897520674,
                                20.547266901784813
                            ],
                            [
                                55.339156897520674,
                                20.42114077565869
                            ],
                            [
                                55.21303077139455,
                                20.42114077565869
                            ]
                        ],
                        [
                            [
                                55.19892139292086,
                                20.61035410008377
                            ],
                            [
                                55.32504751904698,
                                20.61035410008377
                            ],
                            [
                                55.32504751904698,
                                20.484227973957648
                            ],
                            [
                                55.19892139292086,
                                20.484227973957648
                            ]
                        ],
                        [
                            [
                                55.18480388749218,
                                20.67344019394986
                            ],
                            [
                                55.310930013618304,
                                20.67344019394986
                            ],
                            [
                                55.310930013618304,
                                20.547314067823738
                            ],
                            [
                                55.18480388749218,
                                20.547314067823738
                            ]
                        ],
                        [
                            [
                                55.170678219974285,
                                20.736525179582703
                            ],
                            [
                                55.29680434610041,
                                20.736525179582703
                            ],
                            [
                                55.29680434610041,
                                20.61039905345658
                            ],
                            [
                                55.170678219974285,
                                20.61039905345658
                            ]
                        ],
                        [
                            [
                                55.15654435775967,
                                20.79960905317829
                            ],
                            [
                                55.282670483885795,
                                20.79960905317829
                            ],
                            [
                                55.282670483885795,
                                20.673482927052167
                            ],
                            [
                                55.15654435775967,
                                20.673482927052167
                            ]
                        ],
                        [
                            [
                                55.14240226552461,
                                20.862691810929082
                            ],
                            [
                                55.26852839165073,
                                20.862691810929082
                            ],
                            [
                                55.26852839165073,
                                20.736565684802958
                            ],
                            [
                                55.14240226552461,
                                20.736565684802958
                            ]
                        ],
                        [
                            [
                                55.12825191046949,
                                20.925773449023882
                            ],
                            [
                                55.25437803659561,
                                20.925773449023882
                            ],
                            [
                                55.25437803659561,
                                20.79964732289776
                            ],
                            [
                                55.12825191046949,
                                20.79964732289776
                            ]
                        ],
                        [
                            [
                                55.11409325708418,
                                20.988853963647813
                            ],
                            [
                                55.2402193832103,
                                20.988853963647813
                            ],
                            [
                                55.2402193832103,
                                20.86272783752169
                            ],
                            [
                                55.11409325708418,
                                20.86272783752169
                            ]
                        ],
                        [
                            [
                                55.09992627238146,
                                21.051933350982363
                            ],
                            [
                                55.22605239850758,
                                21.051933350982363
                            ],
                            [
                                55.22605239850758,
                                20.92580722485624
                            ],
                            [
                                55.09992627238146,
                                20.92580722485624
                            ]
                        ],
                        [
                            [
                                55.08575092065455,
                                21.115011607205243
                            ],
                            [
                                55.21187704678067,
                                21.115011607205243
                            ],
                            [
                                55.21187704678067,
                                20.98888548107912
                            ],
                            [
                                55.08575092065455,
                                20.98888548107912
                            ]
                        ],
                        [
                            [
                                55.071567168728265,
                                21.178088728490472
                            ],
                            [
                                55.19769329485439,
                                21.178088728490472
                            ],
                            [
                                55.19769329485439,
                                21.05196260236435
                            ],
                            [
                                55.071567168728265,
                                21.05196260236435
                            ]
                        ],
                        [
                            [
                                55.057374980692266,
                                21.24116471100827
                            ],
                            [
                                55.18350110681839,
                                21.24116471100827
                            ],
                            [
                                55.18350110681839,
                                21.115038584882146
                            ],
                            [
                                55.057374980692266,
                                21.115038584882146
                            ]
                        ],
                        [
                            [
                                55.04317432318146,
                                21.304239550925093
                            ],
                            [
                                55.169300449307585,
                                21.304239550925093
                            ],
                            [
                                55.169300449307585,
                                21.17811342479897
                            ],
                            [
                                55.04317432318146,
                                21.17811342479897
                            ]
                        ],
                        [
                            [
                                55.02896516009929,
                                21.367313244403523
                            ],
                            [
                                55.155091286225414,
                                21.367313244403523
                            ],
                            [
                                55.155091286225414,
                                21.2411871182774
                            ],
                            [
                                55.02896516009929,
                                21.2411871182774
                            ]
                        ],
                        [
                            [
                                55.014747457874016,
                                21.430385787602322
                            ],
                            [
                                55.14087358400014,
                                21.430385787602322
                            ],
                            [
                                55.14087358400014,
                                21.3042596614762
                            ],
                            [
                                55.014747457874016,
                                21.3042596614762
                            ]
                        ],
                        [
                            [
                                55.000521180212885,
                                21.49345717667634
                            ],
                            [
                                55.12664730633901,
                                21.49345717667634
                            ],
                            [
                                55.12664730633901,
                                21.367331050550217
                            ],
                            [
                                55.000521180212885,
                                21.367331050550217
                            ]
                        ],
                        [
                            [
                                54.98628629334188,
                                21.55652740777653
                            ],
                            [
                                55.112412419468,
                                21.55652740777653
                            ],
                            [
                                55.112412419468,
                                21.430401281650408
                            ],
                            [
                                54.98628629334188,
                                21.430401281650408
                            ]
                        ],
                        [
                            [
                                54.97204276077139,
                                21.619596477049893
                            ],
                            [
                                55.09816888689751,
                                21.619596477049893
                            ],
                            [
                                55.09816888689751,
                                21.49347035092377
                            ],
                            [
                                54.97204276077139,
                                21.49347035092377
                            ]
                        ],
                        [
                            [
                                54.95779054853127,
                                21.682664380639434
                            ],
                            [
                                55.083916674657395,
                                21.682664380639434
                            ],
                            [
                                55.083916674657395,
                                21.55653825451331
                            ],
                            [
                                54.95779054853127,
                                21.55653825451331
                            ]
                        ],
                        [
                            [
                                54.94352961992001,
                                21.74573111468419
                            ],
                            [
                                55.06965574604613,
                                21.74573111468419
                            ],
                            [
                                55.06965574604613,
                                21.619604988558066
                            ],
                            [
                                54.94352961992001,
                                21.619604988558066
                            ]
                        ],
                        [
                            [
                                54.92925994076916,
                                21.80879667531914
                            ],
                            [
                                55.05538606689528,
                                21.80879667531914
                            ],
                            [
                                55.05538606689528,
                                21.682670549193016
                            ],
                            [
                                54.92925994076916,
                                21.682670549193016
                            ]
                        ],
                        [
                            [
                                54.91498147417463,
                                21.871861058675194
                            ],
                            [
                                55.041107600300755,
                                21.871861058675194
                            ],
                            [
                                55.041107600300755,
                                21.74573493254907
                            ],
                            [
                                54.91498147417463,
                                21.74573493254907
                            ]
                        ],
                        [
                            [
                                54.90069418576089,
                                21.934924260879182
                            ],
                            [
                                55.026820311887015,
                                21.934924260879182
                            ],
                            [
                                55.026820311887015,
                                21.808798134753058
                            ],
                            [
                                54.90069418576089,
                                21.808798134753058
                            ]
                        ],
                        [
                            [
                                54.886398038417525,
                                21.997986278053784
                            ],
                            [
                                55.01252416454365,
                                21.997986278053784
                            ],
                            [
                                55.01252416454365,
                                21.87186015192766
                            ],
                            [
                                54.886398038417525,
                                21.87186015192766
                            ]
                        ],
                        [
                            [
                                48.94221810301104,
                                12.504986770721203
                            ],
                            [
                                49.06834422913716,
                                12.504986770721203
                            ],
                            [
                                49.06834422913716,
                                12.378860644595076
                            ],
                            [
                                48.94221810301104,
                                12.378860644595076
                            ]
                        ],
                        [
                            [
                                48.928920424872466,
                                12.568070441588583
                            ],
                            [
                                49.05504655099859,
                                12.568070441588583
                            ],
                            [
                                49.05504655099859,
                                12.441944315462456
                            ],
                            [
                                48.928920424872466,
                                12.441944315462456
                            ]
                        ],
                        [
                            [
                                48.91561830449028,
                                12.631153672657463
                            ],
                            [
                                49.041744430616404,
                                12.631153672657463
                            ],
                            [
                                49.041744430616404,
                                12.505027546531336
                            ],
                            [
                                48.91561830449028,
                                12.505027546531336
                            ]
                        ],
                        [
                            [
                                48.90231171542142,
                                12.694236460337484
                            ],
                            [
                                49.02843784154754,
                                12.694236460337484
                            ],
                            [
                                49.02843784154754,
                                12.568110334211356
                            ],
                            [
                                48.90231171542142,
                                12.568110334211356
                            ]
                        ],
                        [
                            [
                                48.8890006338154,
                                12.757318801036694
                            ],
                            [
                                49.01512675994152,
                                12.757318801036694
                            ],
                            [
                                49.01512675994152,
                                12.631192674910567
                            ],
                            [
                                48.8890006338154,
                                12.631192674910567
                            ]
                        ],
                        [
                            [
                                48.875685033143995,
                                12.820400691161543
                            ],
                            [
                                49.00181115927012,
                                12.820400691161543
                            ],
                            [
                                49.00181115927012,
                                12.694274565035416
                            ],
                            [
                                48.875685033143995,
                                12.694274565035416
                            ]
                        ],
                        [
                            [
                                48.86236488945962,
                                12.883482127116894
                            ],
                            [
                                48.988491015585744,
                                12.883482127116894
                            ],
                            [
                                48.988491015585744,
                                12.757356000990766
                            ],
                            [
                                48.86236488945962,
                                12.757356000990766
                            ]
                        ],
                        [
                            [
                                48.84904017614153,
                                12.946563105306025
                            ],
                            [
                                48.97516630226765,
                                12.946563105306025
                            ],
                            [
                                48.97516630226765,
                                12.820436979179897
                            ],
                            [
                                48.84904017614153,
                                12.820436979179897
                            ]
                        ],
                        [
                            [
                                48.83571086915191,
                                13.009643622130554
                            ],
                            [
                                48.961836995278034,
                                13.009643622130554
                            ],
                            [
                                48.961836995278034,
                                12.883517496004426
                            ],
                            [
                                48.83571086915191,
                                12.883517496004426
                            ]
                        ],
                        [
                            [
                                48.822376941774486,
                                13.072723673990513
                            ],
                            [
                                48.94850306790061,
                                13.072723673990513
                            ],
                            [
                                48.94850306790061,
                                12.946597547864386
                            ],
                            [
                                48.822376941774486,
                                12.946597547864386
                            ]
                        ],
                        [
                            [
                                48.80903836987868,
                                13.135803257284264
                            ],
                            [
                                48.9351644960048,
                                13.135803257284264
                            ],
                            [
                                48.9351644960048,
                                13.009677131158137
                            ],
                            [
                                48.80903836987868,
                                13.009677131158137
                            ]
                        ],
                        [
                            [
                                48.79569512664984,
                                13.198882368408524
                            ],
                            [
                                48.92182125277596,
                                13.198882368408524
                            ],
                            [
                                48.92182125277596,
                                13.072756242282397
                            ],
                            [
                                48.79569512664984,
                                13.072756242282397
                            ]
                        ],
                        [
                            [
                                48.78234718786174,
                                13.261961003758353
                            ],
                            [
                                48.90847331398786,
                                13.261961003758353
                            ],
                            [
                                48.90847331398786,
                                13.135834877632226
                            ],
                            [
                                48.78234718786174,
                                13.135834877632226
                            ]
                        ],
                        [
                            [
                                48.7689945266067,
                                13.325039159727144
                            ],
                            [
                                48.89512065273282,
                                13.325039159727144
                            ],
                            [
                                48.89512065273282,
                                13.198913033601016
                            ],
                            [
                                48.7689945266067,
                                13.198913033601016
                            ]
                        ],
                        [
                            [
                                48.75563711856003,
                                13.388116832706574
                            ],
                            [
                                48.881763244686155,
                                13.388116832706574
                            ],
                            [
                                48.881763244686155,
                                13.261990706580447
                            ],
                            [
                                48.75563711856003,
                                13.261990706580447
                            ]
                        ],
                        [
                            [
                                48.74227493671664,
                                13.451194019086653
                            ],
                            [
                                48.868401062842764,
                                13.451194019086653
                            ],
                            [
                                48.868401062842764,
                                13.325067892960526
                            ],
                            [
                                48.74227493671664,
                                13.325067892960526
                            ]
                        ],
                        [
                            [
                                48.72890795665534,
                                13.514270715255673
                            ],
                            [
                                48.855034082781465,
                                13.514270715255673
                            ],
                            [
                                48.855034082781465,
                                13.388144589129546
                            ],
                            [
                                48.72890795665534,
                                13.388144589129546
                            ]
                        ],
                        [
                            [
                                48.715536151270825,
                                13.577346917600204
                            ],
                            [
                                48.84166227739695,
                                13.577346917600204
                            ],
                            [
                                48.84166227739695,
                                13.451220791474077
                            ],
                            [
                                48.715536151270825,
                                13.451220791474077
                            ]
                        ],
                        [
                            [
                                48.70215949604109,
                                13.640422622505064
                            ],
                            [
                                48.828285622167215,
                                13.640422622505064
                            ],
                            [
                                48.828285622167215,
                                13.514296496378936
                            ],
                            [
                                48.70215949604109,
                                13.514296496378936
                            ]
                        ],
                        [
                            [
                                48.6887779637592,
                                13.703497826353344
                            ],
                            [
                                48.814904089885324,
                                13.703497826353344
                            ],
                            [
                                48.814904089885324,
                                13.577371700227216
                            ],
                            [
                                48.6887779637592,
                                13.577371700227216
                            ]
                        ],
                        [
                            [
                                48.67539152980926,
                                13.766572525526383
                            ],
                            [
                                48.801517655935385,
                                13.766572525526383
                            ],
                            [
                                48.801517655935385,
                                13.640446399400256
                            ],
                            [
                                48.67539152980926,
                                13.640446399400256
                            ]
                        ],
                        [
                            [
                                48.66200016688331,
                                13.829646716403744
                            ],
                            [
                                48.788126293009434,
                                13.829646716403744
                            ],
                            [
                                48.788126293009434,
                                13.703520590277616
                            ],
                            [
                                48.66200016688331,
                                13.703520590277616
                            ]
                        ],
                        [
                            [
                                48.64860385025559,
                                13.892720395363193
                            ],
                            [
                                48.774729976381714,
                                13.892720395363193
                            ],
                            [
                                48.774729976381714,
                                13.766594269237066
                            ],
                            [
                                48.64860385025559,
                                13.766594269237066
                            ]
                        ],
                        [
                            [
                                48.63520255252072,
                                13.955793558780723
                            ],
                            [
                                48.76132867864684,
                                13.955793558780723
                            ],
                            [
                                48.76132867864684,
                                13.829667432654595
                            ],
                            [
                                48.63520255252072,
                                13.829667432654595
                            ]
                        ],
                        [
                            [
                                48.62179624819539,
                                14.018866203030504
                            ],
                            [
                                48.74792237432151,
                                14.018866203030504
                            ],
                            [
                                48.74792237432151,
                                13.892740076904376
                            ],
                            [
                                48.62179624819539,
                                13.892740076904376
                            ]
                        ],
                        [
                            [
                                48.608384911739456,
                                14.081938324484893
                            ],
                            [
                                48.73451103786558,
                                14.081938324484893
                            ],
                            [
                                48.73451103786558,
                                13.955812198358766
                            ],
                            [
                                48.608384911739456,
                                13.955812198358766
                            ]
                        ],
                        [
                            [
                                48.59496851559343,
                                14.145009919514424
                            ],
                            [
                                48.72109464171955,
                                14.145009919514424
                            ],
                            [
                                48.72109464171955,
                                14.018883793388296
                            ],
                            [
                                48.59496851559343,
                                14.018883793388296
                            ]
                        ],
                        [
                            [
                                48.581547034770296,
                                14.208080984487763
                            ],
                            [
                                48.70767316089642,
                                14.208080984487763
                            ],
                            [
                                48.70767316089642,
                                14.081954858361636
                            ],
                            [
                                48.581547034770296,
                                14.081954858361636
                            ]
                        ],
                        [
                            [
                                48.56812044160514,
                                14.271151515771734
                            ],
                            [
                                48.69424656773126,
                                14.271151515771734
                            ],
                            [
                                48.69424656773126,
                                14.145025389645607
                            ],
                            [
                                48.56812044160514,
                                14.145025389645607
                            ]
                        ],
                        [
                            [
                                48.5546887110033,
                                14.334221509731293
                            ],
                            [
                                48.680814837129425,
                                14.334221509731293
                            ],
                            [
                                48.680814837129425,
                                14.208095383605166
                            ],
                            [
                                48.5546887110033,
                                14.208095383605166
                            ]
                        ],
                        [
                            [
                                48.541251815196425,
                                14.397290962729503
                            ],
                            [
                                48.66737794132255,
                                14.397290962729503
                            ],
                            [
                                48.66737794132255,
                                14.271164836603376
                            ],
                            [
                                48.541251815196425,
                                14.271164836603376
                            ]
                        ],
                        [
                            [
                                48.52780972898087,
                                14.460359871127544
                            ],
                            [
                                48.653935855106994,
                                14.460359871127544
                            ],
                            [
                                48.653935855106994,
                                14.334233745001416
                            ],
                            [
                                48.52780972898087,
                                14.334233745001416
                            ]
                        ],
                        [
                            [
                                48.514362424482066,
                                14.523428231284655
                            ],
                            [
                                48.64048855060819,
                                14.523428231284655
                            ],
                            [
                                48.64048855060819,
                                14.397302105158527
                            ],
                            [
                                48.514362424482066,
                                14.397302105158527
                            ]
                        ],
                        [
                            [
                                48.50090987638456,
                                14.586496039558185
                            ],
                            [
                                48.627036002510685,
                                14.586496039558185
                            ],
                            [
                                48.627036002510685,
                                14.460369913432057
                            ],
                            [
                                48.50090987638456,
                                14.460369913432057
                            ]
                        ],
                        [
                            [
                                48.48745205670449,
                                14.649563292303524
                            ],
                            [
                                48.613578182830615,
                                14.649563292303524
                            ],
                            [
                                48.613578182830615,
                                14.523437166177397
                            ],
                            [
                                48.48745205670449,
                                14.523437166177397
                            ]
                        ],
                        [
                            [
                                48.473988940019815,
                                14.712629985874123
                            ],
                            [
                                48.60011506614594,
                                14.712629985874123
                            ],
                            [
                                48.60011506614594,
                                14.586503859747996
                            ],
                            [
                                48.473988940019815,
                                14.586503859747996
                            ]
                        ],
                        [
                            [
                                48.46052049823298,
                                14.775696116621454
                            ],
                            [
                                48.586646624359105,
                                14.775696116621454
                            ],
                            [
                                48.586646624359105,
                                14.649569990495326
                            ],
                            [
                                48.46052049823298,
                                14.649569990495326
                            ]
                        ],
                        [
                            [
                                48.44704670581401,
                                14.838761680895043
                            ],
                            [
                                48.573172831940134,
                                14.838761680895043
                            ],
                            [
                                48.573172831940134,
                                14.712635554768916
                            ],
                            [
                                48.44704670581401,
                                14.712635554768916
                            ]
                        ],
                        [
                            [
                                48.43356753455514,
                                14.901826675042374
                            ],
                            [
                                48.55969366068126,
                                14.901826675042374
                            ],
                            [
                                48.55969366068126,
                                14.775700548916246
                            ],
                            [
                                48.43356753455514,
                                14.775700548916246
                            ]
                        ],
                        [
                            [
                                48.42008295881239,
                                14.964891095408984
                            ],
                            [
                                48.54620908493851,
                                14.964891095408984
                            ],
                            [
                                48.54620908493851,
                                14.838764969282856
                            ],
                            [
                                48.42008295881239,
                                14.838764969282856
                            ]
                        ],
                        [
                            [
                                48.406592950255025,
                                15.027954938338343
                            ],
                            [
                                48.53271907638115,
                                15.027954938338343
                            ],
                            [
                                48.53271907638115,
                                14.901828812212216
                            ],
                            [
                                48.406592950255025,
                                14.901828812212216
                            ]
                        ],
                        [
                            [
                                48.39309748313187,
                                15.091018200171893
                            ],
                            [
                                48.51922360925799,
                                15.091018200171893
                            ],
                            [
                                48.51922360925799,
                                14.964892074045766
                            ],
                            [
                                48.39309748313187,
                                14.964892074045766
                            ]
                        ],
                        [
                            [
                                48.379596529007515,
                                15.154080877249054
                            ],
                            [
                                48.50572265513364,
                                15.154080877249054
                            ],
                            [
                                48.50572265513364,
                                15.027954751122927
                            ],
                            [
                                48.379596529007515,
                                15.027954751122927
                            ]
                        ],
                        [
                            [
                                48.3660900620076,
                                15.217142965907154
                            ],
                            [
                                48.492216188133725,
                                15.217142965907154
                            ],
                            [
                                48.492216188133725,
                                15.091016839781027
                            ],
                            [
                                48.3660900620076,
                                15.091016839781027
                            ]
                        ],
                        [
                            [
                                48.35257805357444,
                                15.280204462481473
                            ],
                            [
                                48.478704179700564,
                                15.280204462481473
                            ],
                            [
                                48.478704179700564,
                                15.154078336355346
                            ],
                            [
                                48.35257805357444,
                                15.154078336355346
                            ]
                        ],
                        [
                            [
                                48.339060477723685,
                                15.343265363305164
                            ],
                            [
                                48.46518660384981,
                                15.343265363305164
                            ],
                            [
                                48.46518660384981,
                                15.217139237179037
                            ],
                            [
                                48.339060477723685,
                                15.217139237179037
                            ]
                        ],
                        [
                            [
                                48.32553730578552,
                                15.406325664709314
                            ],
                            [
                                48.45166343191164,
                                15.406325664709314
                            ],
                            [
                                48.45166343191164,
                                15.280199538583187
                            ],
                            [
                                48.32553730578552,
                                15.280199538583187
                            ]
                        ],
                        [
                            [
                                48.31200851165128,
                                15.469385363022845
                            ],
                            [
                                48.4381346377774,
                                15.469385363022845
                            ],
                            [
                                48.4381346377774,
                                15.343259236896717
                            ],
                            [
                                48.31200851165128,
                                15.343259236896717
                            ]
                        ],
                        [
                            [
                                48.29847406653435,
                                15.532444454572584
                            ],
                            [
                                48.42460019266047,
                                15.532444454572584
                            ],
                            [
                                48.42460019266047,
                                15.406318328446456
                            ],
                            [
                                48.29847406653435,
                                15.406318328446456
                            ]
                        ],
                        [
                            [
                                48.28493394421014,
                                15.595502935683184
                            ],
                            [
                                48.41106007033626,
                                15.595502935683184
                            ],
                            [
                                48.41106007033626,
                                15.469376809557057
                            ],
                            [
                                48.28493394421014,
                                15.469376809557057
                            ]
                        ],
                        [
                            [
                                48.27138811576414,
                                15.658560802677124
                            ],
                            [
                                48.39751424189026,
                                15.658560802677124
                            ],
                            [
                                48.39751424189026,
                                15.532434676550997
                            ],
                            [
                                48.27138811576414,
                                15.532434676550997
                            ]
                        ],
                        [
                            [
                                48.25783655485453,
                                15.721618051874724
                            ],
                            [
                                48.38396268098065,
                                15.721618051874724
                            ],
                            [
                                48.38396268098065,
                                15.595491925748597
                            ],
                            [
                                48.25783655485453,
                                15.595491925748597
                            ]
                        ],
                        [
                            [
                                48.244279232453735,
                                15.784674679594083
                            ],
                            [
                                48.37040535857986,
                                15.784674679594083
                            ],
                            [
                                48.37040535857986,
                                15.658548553467956
                            ],
                            [
                                48.244279232453735,
                                15.658548553467956
                            ]
                        ],
                        [
                            [
                                48.230716122091586,
                                15.847730682151123
                            ],
                            [
                                48.35684224821771,
                                15.847730682151123
                            ],
                            [
                                48.35684224821771,
                                15.721604556024996
                            ],
                            [
                                48.230716122091586,
                                15.721604556024996
                            ]
                        ],
                        [
                            [
                                48.21714719461304,
                                15.910786055859504
                            ],
                            [
                                48.343273320739165,
                                15.910786055859504
                            ],
                            [
                                48.343273320739165,
                                15.784659929733376
                            ],
                            [
                                48.21714719461304,
                                15.784659929733376
                            ]
                        ],
                        [
                            [
                                48.20357242342952,
                                15.973840797030654
                            ],
                            [
                                48.329698549555644,
                                15.973840797030654
                            ],
                            [
                                48.329698549555644,
                                15.847714670904526
                            ],
                            [
                                48.20357242342952,
                                15.847714670904526
                            ]
                        ],
                        [
                            [
                                48.18999177926208,
                                16.036894901973753
                            ],
                            [
                                48.316117905388204,
                                16.036894901973753
                            ],
                            [
                                48.316117905388204,
                                15.910768775847627
                            ],
                            [
                                48.18999177926208,
                                15.910768775847627
                            ]
                        ],
                        [
                            [
                                48.176405235399166,
                                16.099948366995683
                            ],
                            [
                                48.30253136152529,
                                16.099948366995683
                            ],
                            [
                                48.30253136152529,
                                15.973822240869557
                            ],
                            [
                                48.176405235399166,
                                15.973822240869557
                            ]
                        ],
                        [
                            [
                                48.1628127624347,
                                16.16300118840105
                            ],
                            [
                                48.28893888856082,
                                16.16300118840105
                            ],
                            [
                                48.28893888856082,
                                16.036875062274927
                            ],
                            [
                                48.1628127624347,
                                16.036875062274927
                            ]
                        ],
                        [
                            [
                                48.149214333532726,
                                16.226053362492173
                            ],
                            [
                                48.27534045965885,
                                16.226053362492173
                            ],
                            [
                                48.27534045965885,
                                16.09992723636605
                            ],
                            [
                                48.149214333532726,
                                16.09992723636605
                            ]
                        ],
                        [
                            [
                                48.13560991915559,
                                16.289104885568992
                            ],
                            [
                                48.261736045281715,
                                16.289104885568992
                            ],
                            [
                                48.261736045281715,
                                16.16297875944287
                            ],
                            [
                                48.13560991915559,
                                16.16297875944287
                            ]
                        ],
                        [
                            [
                                48.121999492343065,
                                16.352155753929154
                            ],
                            [
                                48.24812561846919,
                                16.352155753929154
                            ],
                            [
                                48.24812561846919,
                                16.22602962780303
                            ],
                            [
                                48.121999492343065,
                                16.22602962780303
                            ]
                        ],
                        [
                            [
                                48.108383023432495,
                                16.41520596386795
                            ],
                            [
                                48.23450914955862,
                                16.41520596386795
                            ],
                            [
                                48.23450914955862,
                                16.289079837741827
                            ],
                            [
                                48.108383023432495,
                                16.289079837741827
                            ]
                        ],
                        [
                            [
                                48.094760485329836,
                                16.478255511678263
                            ],
                            [
                                48.22088661145596,
                                16.478255511678263
                            ],
                            [
                                48.22088661145596,
                                16.35212938555214
                            ],
                            [
                                48.094760485329836,
                                16.35212938555214
                            ]
                        ],
                        [
                            [
                                48.08113184824452,
                                16.541304393650623
                            ],
                            [
                                48.20725797437064,
                                16.541304393650623
                            ],
                            [
                                48.20725797437064,
                                16.4151782675245
                            ],
                            [
                                48.08113184824452,
                                16.4151782675245
                            ]
                        ],
                        [
                            [
                                48.067497084295745,
                                16.60435260607313
                            ],
                            [
                                48.19362321042187,
                                16.60435260607313
                            ],
                            [
                                48.19362321042187,
                                16.478226479947008
                            ],
                            [
                                48.067497084295745,
                                16.478226479947008
                            ]
                        ],
                        [
                            [
                                48.05385616553553,
                                16.667400145231472
                            ],
                            [
                                48.17998229166165,
                                16.667400145231472
                            ],
                            [
                                48.17998229166165,
                                16.541274019105348
                            ],
                            [
                                48.05385616553553,
                                16.541274019105348
                            ]
                        ],
                        [
                            [
                                48.04020906197276,
                                16.730447007408902
                            ],
                            [
                                48.166335188098884,
                                16.730447007408902
                            ],
                            [
                                48.166335188098884,
                                16.604320881282778
                            ],
                            [
                                48.04020906197276,
                                16.604320881282778
                            ]
                        ],
                        [
                            [
                                48.0265557461831,
                                16.793493188886192
                            ],
                            [
                                48.15268187230922,
                                16.793493188886192
                            ],
                            [
                                48.15268187230922,
                                16.667367062760068
                            ],
                            [
                                48.0265557461831,
                                16.667367062760068
                            ]
                        ],
                        [
                            [
                                48.012896188050256,
                                16.856538685941672
                            ],
                            [
                                48.13902231417638,
                                16.856538685941672
                            ],
                            [
                                48.13902231417638,
                                16.730412559815548
                            ],
                            [
                                48.012896188050256,
                                16.730412559815548
                            ]
                        ],
                        [
                            [
                                47.99923036001909,
                                16.919583494851143
                            ],
                            [
                                48.125356486145215,
                                16.919583494851143
                            ],
                            [
                                48.125356486145215,
                                16.79345736872502
                            ],
                            [
                                47.99923036001909,
                                16.79345736872502
                            ]
                        ],
                        [
                            [
                                47.985558231828776,
                                16.98262761188793
                            ],
                            [
                                48.1116843579549,
                                16.98262761188793
                            ],
                            [
                                48.1116843579549,
                                16.856501485761807
                            ],
                            [
                                47.985558231828776,
                                16.856501485761807
                            ]
                        ],
                        [
                            [
                                47.97187977578542,
                                17.04567103332281
                            ],
                            [
                                48.09800590191154,
                                17.04567103332281
                            ],
                            [
                                48.09800590191154,
                                16.919544907196688
                            ],
                            [
                                47.97187977578542,
                                16.919544907196688
                            ]
                        ],
                        [
                            [
                                47.95819496150323,
                                17.10871375542403
                            ],
                            [
                                48.084321087629355,
                                17.10871375542403
                            ],
                            [
                                48.084321087629355,
                                16.982587629297907
                            ],
                            [
                                47.95819496150323,
                                16.982587629297907
                            ]
                        ],
                        [
                            [
                                47.9445037611464,
                                17.171755774457253
                            ],
                            [
                                48.070629887272524,
                                17.171755774457253
                            ],
                            [
                                48.070629887272524,
                                17.04562964833113
                            ],
                            [
                                47.9445037611464,
                                17.04562964833113
                            ]
                        ],
                        [
                            [
                                47.930806144189795,
                                17.23479708668557
                            ],
                            [
                                48.05693227031592,
                                17.23479708668557
                            ],
                            [
                                48.05693227031592,
                                17.108670960559447
                            ],
                            [
                                47.930806144189795,
                                17.108670960559447
                            ]
                        ],
                        [
                            [
                                47.91710208266551,
                                17.29783768836949
                            ],
                            [
                                48.04322820879163,
                                17.29783768836949
                            ],
                            [
                                48.04322820879163,
                                17.171711562243367
                            ],
                            [
                                47.91710208266551,
                                17.171711562243367
                            ]
                        ],
                        [
                            [
                                47.90339154590255,
                                17.36087757576687
                            ],
                            [
                                48.029517672028675,
                                17.36087757576687
                            ],
                            [
                                48.029517672028675,
                                17.234751449640747
                            ],
                            [
                                47.90339154590255,
                                17.234751449640747
                            ]
                        ],
                        [
                            [
                                47.88967450578972,
                                17.42391674513297
                            ],
                            [
                                48.015800631915845,
                                17.42391674513297
                            ],
                            [
                                48.015800631915845,
                                17.297790619006847
                            ],
                            [
                                47.88967450578972,
                                17.297790619006847
                            ]
                        ],
                        [
                            [
                                47.875950931524905,
                                17.48695519272035
                            ],
                            [
                                48.00207705765103,
                                17.48695519272035
                            ],
                            [
                                48.00207705765103,
                                17.360829066594228
                            ],
                            [
                                47.875950931524905,
                                17.360829066594228
                            ]
                        ],
                        [
                            [
                                47.86222079484878,
                                17.549992914778944
                            ],
                            [
                                47.988346920974905,
                                17.549992914778944
                            ],
                            [
                                47.988346920974905,
                                17.42386678865282
                            ],
                            [
                                47.86222079484878,
                                17.42386678865282
                            ]
                        ],
                        [
                            [
                                47.84848406481847,
                                17.61302990755594
                            ],
                            [
                                47.974610190944595,
                                17.61302990755594
                            ],
                            [
                                47.974610190944595,
                                17.486903781429817
                            ],
                            [
                                47.84848406481847,
                                17.486903781429817
                            ]
                        ],
                        [
                            [
                                47.83474071303145,
                                17.676066167295893
                            ],
                            [
                                47.960866839157575,
                                17.676066167295893
                            ],
                            [
                                47.960866839157575,
                                17.54994004116977
                            ],
                            [
                                47.83474071303145,
                                17.54994004116977
                            ]
                        ],
                        [
                            [
                                47.82099070839763,
                                17.73910169024053
                            ],
                            [
                                47.947116834523754,
                                17.73910169024053
                            ],
                            [
                                47.947116834523754,
                                17.612975564114407
                            ],
                            [
                                47.82099070839763,
                                17.612975564114407
                            ]
                        ],
                        [
                            [
                                47.80723402237626,
                                17.802136472628913
                            ],
                            [
                                47.93336014850238,
                                17.802136472628913
                            ],
                            [
                                47.93336014850238,
                                17.67601034650279
                            ],
                            [
                                47.80723402237626,
                                17.67601034650279
                            ]
                        ],
                        [
                            [
                                42.751227194508985,
                                14.829723141466284
                            ],
                            [
                                42.87735332063511,
                                14.829723141466284
                            ],
                            [
                                42.87735332063511,
                                14.703597015340156
                            ],
                            [
                                42.751227194508985,
                                14.703597015340156
                            ]
                        ],
                        [
                            [
                                42.737522534700325,
                                14.766686571310023
                            ],
                            [
                                42.86364866082645,
                                14.766686571310023
                            ],
                            [
                                42.86364866082645,
                                14.640560445183896
                            ],
                            [
                                42.737522534700325,
                                14.640560445183896
                            ]
                        ],
                        [
                            [
                                42.723823350052385,
                                14.703649418984243
                            ],
                            [
                                42.84994947617851,
                                14.703649418984243
                            ],
                            [
                                42.84994947617851,
                                14.577523292858116
                            ],
                            [
                                42.723823350052385,
                                14.577523292858116
                            ]
                        ],
                        [
                            [
                                42.71012961440439,
                                14.640611688234564
                            ],
                            [
                                42.83625574053051,
                                14.640611688234564
                            ],
                            [
                                42.83625574053051,
                                14.514485562108437
                            ],
                            [
                                42.71012961440439,
                                14.514485562108437
                            ]
                        ],
                        [
                            [
                                42.69644129902092,
                                14.577573382804424
                            ],
                            [
                                42.82256742514704,
                                14.577573382804424
                            ],
                            [
                                42.82256742514704,
                                14.451447256678296
                            ],
                            [
                                42.69644129902092,
                                14.451447256678296
                            ]
                        ],
                        [
                            [
                                42.682758377866186,
                                14.514534506435114
                            ],
                            [
                                42.80888450399231,
                                14.514534506435114
                            ],
                            [
                                42.80888450399231,
                                14.388408380308986
                            ],
                            [
                                42.682758377866186,
                                14.388408380308986
                            ]
                        ],
                        [
                            [
                                42.66908082232422,
                                14.451495062865774
                            ],
                            [
                                42.79520694845034,
                                14.451495062865774
                            ],
                            [
                                42.79520694845034,
                                14.325368936739647
                            ],
                            [
                                42.66908082232422,
                                14.325368936739647
                            ]
                        ],
                        [
                            [
                                42.65540860646005,
                                14.388455055833434
                            ],
                            [
                                42.781534732586174,
                                14.388455055833434
                            ],
                            [
                                42.781534732586174,
                                14.262328929707307
                            ],
                            [
                                42.65540860646005,
                                14.262328929707307
                            ]
                        ],
                        [
                            [
                                42.64174170176772,
                                14.325414489073005
                            ],
                            [
                                42.767867827893845,
                                14.325414489073005
                            ],
                            [
                                42.767867827893845,
                                14.199288362946877
                            ],
                            [
                                42.64174170176772,
                                14.199288362946877
                            ]
                        ],
                        [
                            [
                                42.6280800824297,
                                14.262373366317293
                            ],
                            [
                                42.75420620855582,
                                14.262373366317293
                            ],
                            [
                                42.75420620855582,
                                14.136247240191166
                            ],
                            [
                                42.6280800824297,
                                14.136247240191166
                            ]
                        ],
                        [
                            [
                                42.614423720050276,
                                14.199331691297054
                            ],
                            [
                                42.7405498461764,
                                14.199331691297054
                            ],
                            [
                                42.7405498461764,
                                14.073205565170927
                            ],
                            [
                                42.614423720050276,
                                14.073205565170927
                            ]
                        ],
                        [
                            [
                                42.600772588919995,
                                14.136289467740944
                            ],
                            [
                                42.72689871504612,
                                14.136289467740944
                            ],
                            [
                                42.72689871504612,
                                14.010163341614817
                            ],
                            [
                                42.600772588919995,
                                14.010163341614817
                            ]
                        ],
                        [
                            [
                                42.58712666075214,
                                14.073246699375554
                            ],
                            [
                                42.713252786878265,
                                14.073246699375554
                            ],
                            [
                                42.713252786878265,
                                13.947120573249427
                            ],
                            [
                                42.58712666075214,
                                13.947120573249427
                            ]
                        ],
                        [
                            [
                                42.573485909947216,
                                14.010203389925485
                            ],
                            [
                                42.69961203607334,
                                14.010203389925485
                            ],
                            [
                                42.69961203607334,
                                13.884077263799357
                            ],
                            [
                                42.573485909947216,
                                13.884077263799357
                            ]
                        ],
                        [
                            [
                                42.55985030832298,
                                13.947159543113283
                            ],
                            [
                                42.6859764344491,
                                13.947159543113283
                            ],
                            [
                                42.6859764344491,
                                13.821033416987156
                            ],
                            [
                                42.55985030832298,
                                13.821033416987156
                            ]
                        ],
                        [
                            [
                                42.54621983038694,
                                13.884115162659464
                            ],
                            [
                                42.67234595651306,
                                13.884115162659464
                            ],
                            [
                                42.67234595651306,
                                13.757989036533337
                            ],
                            [
                                42.54621983038694,
                                13.757989036533337
                            ]
                        ],
                        [
                            [
                                42.53259444806662,
                                13.821070252282574
                            ],
                            [
                                42.65872057419274,
                                13.821070252282574
                            ],
                            [
                                42.65872057419274,
                                13.694944126156447
                            ],
                            [
                                42.53259444806662,
                                13.694944126156447
                            ]
                        ],
                        [
                            [
                                42.51897413597203,
                                13.758024815699144
                            ],
                            [
                                42.645100262098154,
                                13.758024815699144
                            ],
                            [
                                42.645100262098154,
                                13.631898689573017
                            ],
                            [
                                42.51897413597203,
                                13.631898689573017
                            ]
                        ],
                        [
                            [
                                42.505358866140725,
                                13.694978856623774
                            ],
                            [
                                42.63148499226685,
                                13.694978856623774
                            ],
                            [
                                42.63148499226685,
                                13.568852730497646
                            ],
                            [
                                42.505358866140725,
                                13.568852730497646
                            ]
                        ]
                    ],
                    remainingStrips: []
                },
                progress: 50,
                subStatus: "Partially Completed",
                schedule: {
                    satellites: [
                        {
                            id: "sat_001",
                            name: "Satellite Alpha",
                            orbitalParameters: {
                                altitude: 500,
                                inclination: 98.0
                            }
                        }
                    ],
                    orderAssignments: [
                        {
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_001",
                                name: "Satellite Alpha",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            strip: {
                                id: "strip_001",
                                featureId: "feat_001",
                                coordinates: [[
                                    [
                                        46.74192873718151,
                                        17.18109408243627
                                    ],
                                    [
                                        46.868054863307634,
                                        17.18109408243627
                                    ],
                                    [
                                        46.868054863307634,
                                        17.054967956310147
                                    ],
                                    [
                                        46.74192873718151,
                                        17.054967956310147
                                    ]
                                ],
                                [
                                    [
                                        46.72808934129506,
                                        17.11795749940532
                                    ],
                                    [
                                        46.854215467421184,
                                        17.11795749940532
                                    ],
                                    [
                                        46.854215467421184,
                                        16.991831373279197
                                    ],
                                    [
                                        46.72808934129506,
                                        16.991831373279197
                                    ]
                                ],
                                [
                                    [
                                        46.71425641402321,
                                        17.054820007672163
                                    ],
                                    [
                                        46.840382540149335,
                                        17.054820007672163
                                    ],
                                    [
                                        46.840382540149335,
                                        16.92869388154604
                                    ],
                                    [
                                        46.71425641402321,
                                        16.92869388154604
                                    ]
                                ],
                                [
                                    [
                                        46.70042992440969,
                                        16.991681610936133
                                    ],
                                    [
                                        46.826556050535814,
                                        16.991681610936133
                                    ],
                                    [
                                        46.826556050535814,
                                        16.86555548481001
                                    ],
                                    [
                                        46.70042992440969,
                                        16.86555548481001
                                    ]
                                ],
                                [
                                    [
                                        46.68660984418971,
                                        16.92854231289413
                                    ],
                                    [
                                        46.812735970315835,
                                        16.92854231289413
                                    ],
                                    [
                                        46.812735970315835,
                                        16.802416186768006
                                    ],
                                    [
                                        46.68660984418971,
                                        16.802416186768006
                                    ]
                                ],
                                [
                                    [
                                        46.672796142542595,
                                        16.865402117240674
                                    ],
                                    [
                                        46.79892226866872,
                                        16.865402117240674
                                    ],
                                    [
                                        46.79892226866872,
                                        16.73927599111455
                                    ],
                                    [
                                        46.672796142542595,
                                        16.73927599111455
                                    ]
                                ],
                                [
                                    [
                                        46.65898879134503,
                                        16.80226102766792
                                    ],
                                    [
                                        46.785114917471155,
                                        16.80226102766792
                                    ],
                                    [
                                        46.785114917471155,
                                        16.676134901541797
                                    ],
                                    [
                                        46.65898879134503,
                                        16.676134901541797
                                    ]
                                ],
                                [
                                    [
                                        46.645187759908886,
                                        16.739119047865703
                                    ],
                                    [
                                        46.77131388603501,
                                        16.739119047865703
                                    ],
                                    [
                                        46.77131388603501,
                                        16.61299292173958
                                    ],
                                    [
                                        46.645187759908886,
                                        16.61299292173958
                                    ]
                                ],
                                [
                                    [
                                        46.6313930202441,
                                        16.67597618152147
                                    ],
                                    [
                                        46.75751914637022,
                                        16.67597618152147
                                    ],
                                    [
                                        46.75751914637022,
                                        16.549850055395346
                                    ],
                                    [
                                        46.6313930202441,
                                        16.549850055395346
                                    ]
                                ],
                                [
                                    [
                                        46.617604541808205,
                                        16.61283243232042
                                    ],
                                    [
                                        46.74373066793433,
                                        16.61283243232042
                                    ],
                                    [
                                        46.74373066793433,
                                        16.486706306194296
                                    ],
                                    [
                                        46.617604541808205,
                                        16.486706306194296
                                    ]
                                ],
                                [
                                    [
                                        46.603822296739786,
                                        16.54968780394542
                                    ],
                                    [
                                        46.72994842286591,
                                        16.54968780394542
                                    ],
                                    [
                                        46.72994842286591,
                                        16.423561677819297
                                    ],
                                    [
                                        46.603822296739786,
                                        16.423561677819297
                                    ]
                                ],
                                [
                                    [
                                        46.59004625463229,
                                        16.486542300077073
                                    ],
                                    [
                                        46.716172380758415,
                                        16.486542300077073
                                    ],
                                    [
                                        46.716172380758415,
                                        16.36041617395095
                                    ],
                                    [
                                        46.59004625463229,
                                        16.36041617395095
                                    ]
                                ],
                                [
                                    [
                                        46.57627638775795,
                                        16.423395924393763
                                    ],
                                    [
                                        46.702402513884074,
                                        16.423395924393763
                                    ],
                                    [
                                        46.702402513884074,
                                        16.29726979826764
                                    ],
                                    [
                                        46.57627638775795,
                                        16.29726979826764
                                    ]
                                ],
                                [
                                    [
                                        46.56251266584146,
                                        16.36024868057155
                                    ],
                                    [
                                        46.688638791967584,
                                        16.36024868057155
                                    ],
                                    [
                                        46.688638791967584,
                                        16.234122554445428
                                    ],
                                    [
                                        46.56251266584146,
                                        16.234122554445428
                                    ]
                                ],
                                [
                                    [
                                        46.54875506129202,
                                        16.29710057228437
                                    ],
                                    [
                                        46.674881187418144,
                                        16.29710057228437
                                    ],
                                    [
                                        46.674881187418144,
                                        16.170974446158247
                                    ],
                                    [
                                        46.54875506129202,
                                        16.170974446158247
                                    ]
                                ],
                                [
                                    [
                                        46.53500354396087,
                                        16.23395160320389
                                    ],
                                    [
                                        46.66112967008699,
                                        16.23395160320389
                                    ],
                                    [
                                        46.66112967008699,
                                        16.107825477077768
                                    ],
                                    [
                                        46.53500354396087,
                                        16.107825477077768
                                    ]
                                ],
                                [
                                    [
                                        46.521258086387846,
                                        16.17080177699962
                                    ],
                                    [
                                        46.64738421251397,
                                        16.17080177699962
                                    ],
                                    [
                                        46.64738421251397,
                                        16.044675650873497
                                    ],
                                    [
                                        46.521258086387846,
                                        16.044675650873497
                                    ]
                                ],
                                [
                                    [
                                        46.507518658560656,
                                        16.107651097338913
                                    ],
                                    [
                                        46.63364478468678,
                                        16.107651097338913
                                    ],
                                    [
                                        46.63364478468678,
                                        15.981524971212787
                                    ],
                                    [
                                        46.507518658560656,
                                        15.981524971212787
                                    ]
                                ],
                                [
                                    [
                                        46.49378523314015,
                                        16.04449956788695
                                    ],
                                    [
                                        46.619911359266275,
                                        16.04449956788695
                                    ],
                                    [
                                        46.619911359266275,
                                        15.918373441760826
                                    ],
                                    [
                                        46.49378523314015,
                                        15.918373441760826
                                    ]
                                ],
                                [
                                    [
                                        46.48005778024246,
                                        15.981347192306783
                                    ],
                                    [
                                        46.606183906368585,
                                        15.981347192306783
                                    ],
                                    [
                                        46.606183906368585,
                                        15.855221066180656
                                    ],
                                    [
                                        46.48005778024246,
                                        15.855221066180656
                                    ]
                                ],
                                [
                                    [
                                        46.46633627266275,
                                        15.918193974259363
                                    ],
                                    [
                                        46.592462398788875,
                                        15.918193974259363
                                    ],
                                    [
                                        46.592462398788875,
                                        15.792067848133236
                                    ],
                                    [
                                        46.46633627266275,
                                        15.792067848133236
                                    ]
                                ],
                                [
                                    [
                                        46.452620680642525,
                                        15.855039917403523
                                    ],
                                    [
                                        46.57874680676865,
                                        15.855039917403523
                                    ],
                                    [
                                        46.57874680676865,
                                        15.728913791277396
                                    ],
                                    [
                                        46.452620680642525,
                                        15.728913791277396
                                    ]
                                ],
                                [
                                    [
                                        46.43891097710006,
                                        15.791885025396024
                                    ],
                                    [
                                        46.56503710322618,
                                        15.791885025396024
                                    ],
                                    [
                                        46.56503710322618,
                                        15.665758899269896
                                    ],
                                    [
                                        46.43891097710006,
                                        15.665758899269896
                                    ]
                                ],
                                [
                                    [
                                        46.425207132404005,
                                        15.728729301891564
                                    ],
                                    [
                                        46.55133325853013,
                                        15.728729301891564
                                    ],
                                    [
                                        46.55133325853013,
                                        15.602603175765436
                                    ],
                                    [
                                        46.425207132404005,
                                        15.602603175765436
                                    ]
                                ],
                                [
                                    [
                                        46.41150911959751,
                                        15.665572750542793
                                    ],
                                    [
                                        46.537635245723635,
                                        15.665572750542793
                                    ],
                                    [
                                        46.537635245723635,
                                        15.539446624416666
                                    ],
                                    [
                                        46.41150911959751,
                                        15.539446624416666
                                    ]
                                ],
                                [
                                    [
                                        46.397816909170245,
                                        15.602415375000323
                                    ],
                                    [
                                        46.52394303529637,
                                        15.602415375000323
                                    ],
                                    [
                                        46.52394303529637,
                                        15.476289248874195
                                    ],
                                    [
                                        46.397816909170245,
                                        15.476289248874195
                                    ]
                                ],
                                [
                                    [
                                        46.38413047429524,
                                        15.539257178912743
                                    ],
                                    [
                                        46.510256600421364,
                                        15.539257178912743
                                    ],
                                    [
                                        46.510256600421364,
                                        15.413131052786616
                                    ],
                                    [
                                        46.38413047429524,
                                        15.413131052786616
                                    ]
                                ],
                                [
                                    [
                                        46.37044978558335,
                                        15.476098165926674
                                    ],
                                    [
                                        46.49657591170947,
                                        15.476098165926674
                                    ],
                                    [
                                        46.49657591170947,
                                        15.349972039800546
                                    ],
                                    [
                                        46.37044978558335,
                                        15.349972039800546
                                    ]
                                ],
                                [
                                    [
                                        46.35677481632799,
                                        15.412938339686724
                                    ],
                                    [
                                        46.482900942454116,
                                        15.412938339686724
                                    ],
                                    [
                                        46.482900942454116,
                                        15.286812213560596
                                    ],
                                    [
                                        46.35677481632799,
                                        15.286812213560596
                                    ]
                                ],
                                [
                                    [
                                        46.343105537259795,
                                        15.349777703835533
                                    ],
                                    [
                                        46.46923166338592,
                                        15.349777703835533
                                    ],
                                    [
                                        46.46923166338592,
                                        15.223651577709406
                                    ],
                                    [
                                        46.343105537259795,
                                        15.223651577709406
                                    ]
                                ],
                                [
                                    [
                                        46.329441921136635,
                                        15.286616262013803
                                    ],
                                    [
                                        46.45556804726276,
                                        15.286616262013803
                                    ],
                                    [
                                        46.45556804726276,
                                        15.160490135887676
                                    ],
                                    [
                                        46.329441921136635,
                                        15.160490135887676
                                    ]
                                ],
                                [
                                    [
                                        46.31578394078108,
                                        15.223454017860314
                                    ],
                                    [
                                        46.441910066907205,
                                        15.223454017860314
                                    ],
                                    [
                                        46.441910066907205,
                                        15.097327891734187
                                    ],
                                    [
                                        46.31578394078108,
                                        15.097327891734187
                                    ]
                                ],
                                [
                                    [
                                        46.302131567099956,
                                        15.160290975011893
                                    ],
                                    [
                                        46.42825769322608,
                                        15.160290975011893
                                    ],
                                    [
                                        46.42825769322608,
                                        15.034164848885766
                                    ],
                                    [
                                        46.302131567099956,
                                        15.034164848885766
                                    ]
                                ],
                                [
                                    [
                                        46.288484773692325,
                                        15.097127137103485
                                    ],
                                    [
                                        46.41461089981845,
                                        15.097127137103485
                                    ],
                                    [
                                        46.41461089981845,
                                        14.971001010977357
                                    ],
                                    [
                                        46.288484773692325,
                                        14.971001010977357
                                    ]
                                ],
                                [
                                    [
                                        46.2748435315796,
                                        15.033962507768134
                                    ],
                                    [
                                        46.40096965770572,
                                        15.033962507768134
                                    ],
                                    [
                                        46.40096965770572,
                                        14.907836381642007
                                    ],
                                    [
                                        46.2748435315796,
                                        14.907836381642007
                                    ]
                                ],
                                [
                                    [
                                        46.26120781448144,
                                        14.970797090637024
                                    ],
                                    [
                                        46.38733394060756,
                                        14.970797090637024
                                    ],
                                    [
                                        46.38733394060756,
                                        14.844670964510897
                                    ],
                                    [
                                        46.26120781448144,
                                        14.844670964510897
                                    ]
                                ],
                                [
                                    [
                                        46.24757759353092,
                                        14.907630889339474
                                    ],
                                    [
                                        46.373703719657044,
                                        14.907630889339474
                                    ],
                                    [
                                        46.373703719657044,
                                        14.781504763213347
                                    ],
                                    [
                                        46.24757759353092,
                                        14.781504763213347
                                    ]
                                ],
                                [
                                    [
                                        46.233952842566836,
                                        14.844463907502954
                                    ],
                                    [
                                        46.36007896869296,
                                        14.844463907502954
                                    ],
                                    [
                                        46.36007896869296,
                                        14.718337781376826
                                    ],
                                    [
                                        46.233952842566836,
                                        14.718337781376826
                                    ]
                                ],
                                [
                                    [
                                        46.220333532839106,
                                        14.781296148753114
                                    ],
                                    [
                                        46.34645965896523,
                                        14.781296148753114
                                    ],
                                    [
                                        46.34645965896523,
                                        14.655170022626987
                                    ],
                                    [
                                        46.220333532839106,
                                        14.655170022626987
                                    ]
                                ],
                                [
                                    [
                                        46.2067196383027,
                                        14.718127616713794
                                    ],
                                    [
                                        46.332845764428825,
                                        14.718127616713794
                                    ],
                                    [
                                        46.332845764428825,
                                        14.592001490587666
                                    ],
                                    [
                                        46.2067196383027,
                                        14.592001490587666
                                    ]
                                ],
                                [
                                    [
                                        46.193111130316545,
                                        14.654958315007034
                                    ],
                                    [
                                        46.31923725644267,
                                        14.654958315007034
                                    ],
                                    [
                                        46.31923725644267,
                                        14.528832188880907
                                    ],
                                    [
                                        46.193111130316545,
                                        14.528832188880907
                                    ]
                                ],
                                [
                                    [
                                        46.17950798294394,
                                        14.591788247253074
                                    ],
                                    [
                                        46.30563410907006,
                                        14.591788247253074
                                    ],
                                    [
                                        46.30563410907006,
                                        14.465662121126947
                                    ],
                                    [
                                        46.17950798294394,
                                        14.465662121126947
                                    ]
                                ],
                                [
                                    [
                                        46.165910167667796,
                                        14.528617417070414
                                    ],
                                    [
                                        46.29203629379392,
                                        14.528617417070414
                                    ],
                                    [
                                        46.29203629379392,
                                        14.402491290944287
                                    ],
                                    [
                                        46.165910167667796,
                                        14.402491290944287
                                    ]
                                ],
                                [
                                    [
                                        46.152317658658376,
                                        14.465445828075774
                                    ],
                                    [
                                        46.2784437847845,
                                        14.465445828075774
                                    ],
                                    [
                                        46.2784437847845,
                                        14.339319701949647
                                    ],
                                    [
                                        46.152317658658376,
                                        14.339319701949647
                                    ]
                                ],
                                [
                                    [
                                        46.1387304275115,
                                        14.402273483884164
                                    ],
                                    [
                                        46.26485655363762,
                                        14.402273483884164
                                    ],
                                    [
                                        46.26485655363762,
                                        14.276147357758036
                                    ],
                                    [
                                        46.1387304275115,
                                        14.276147357758036
                                    ]
                                ],
                                [
                                    [
                                        46.12514844850474,
                                        14.339100388108834
                                    ],
                                    [
                                        46.25127457463086,
                                        14.339100388108834
                                    ],
                                    [
                                        46.25127457463086,
                                        14.212974261982707
                                    ],
                                    [
                                        46.12514844850474,
                                        14.212974261982707
                                    ]
                                ],
                                [
                                    [
                                        46.111571693343805,
                                        14.275926544361333
                                    ],
                                    [
                                        46.23769781946993,
                                        14.275926544361333
                                    ],
                                    [
                                        46.23769781946993,
                                        14.149800418235206
                                    ],
                                    [
                                        46.111571693343805,
                                        14.149800418235206
                                    ]
                                ],
                                [
                                    [
                                        46.0980001364189,
                                        14.212751956251534
                                    ],
                                    [
                                        46.22412626254502,
                                        14.212751956251534
                                    ],
                                    [
                                        46.22412626254502,
                                        14.086625830125406
                                    ],
                                    [
                                        46.0980001364189,
                                        14.086625830125406
                                    ]
                                ],
                                [
                                    [
                                        46.08443374954269,
                                        14.149576627387603
                                    ],
                                    [
                                        46.210559875668814,
                                        14.149576627387603
                                    ],
                                    [
                                        46.210559875668814,
                                        14.023450501261475
                                    ],
                                    [
                                        46.08443374954269,
                                        14.023450501261475
                                    ]
                                ],
                                [
                                    [
                                        46.07087250721336,
                                        14.086400561376054
                                    ],
                                    [
                                        46.19699863333948,
                                        14.086400561376054
                                    ],
                                    [
                                        46.19699863333948,
                                        13.960274435249927
                                    ],
                                    [
                                        46.07087250721336,
                                        13.960274435249927
                                    ]
                                ],
                                [
                                    [
                                        46.05731638135257,
                                        14.023223761821724
                                    ],
                                    [
                                        46.183442507478695,
                                        14.023223761821724
                                    ],
                                    [
                                        46.183442507478695,
                                        13.897097635695596
                                    ],
                                    [
                                        46.05731638135257,
                                        13.897097635695596
                                    ]
                                ],
                                [
                                    [
                                        46.043765346563546,
                                        13.960046232327823
                                    ],
                                    [
                                        46.16989147268967,
                                        13.960046232327823
                                    ],
                                    [
                                        46.16989147268967,
                                        13.833920106201695
                                    ],
                                    [
                                        46.043765346563546,
                                        13.833920106201695
                                    ]
                                ],
                                [
                                    [
                                        46.03021937486924,
                                        13.896867976495944
                                    ],
                                    [
                                        46.156345500995364,
                                        13.896867976495944
                                    ],
                                    [
                                        46.156345500995364,
                                        13.770741850369816
                                    ],
                                    [
                                        46.03021937486924,
                                        13.770741850369816
                                    ]
                                ],
                                [
                                    [
                                        46.01667844098636,
                                        13.833688997926053
                                    ],
                                    [
                                        46.142804567112485,
                                        13.833688997926053
                                    ],
                                    [
                                        46.142804567112485,
                                        13.707562871799926
                                    ],
                                    [
                                        46.01667844098636,
                                        13.707562871799926
                                    ]
                                ],
                                [
                                    [
                                        46.003142517039386,
                                        13.770509300216494
                                    ],
                                    [
                                        46.12926864316551,
                                        13.770509300216494
                                    ],
                                    [
                                        46.12926864316551,
                                        13.644383174090366
                                    ],
                                    [
                                        46.003142517039386,
                                        13.644383174090366
                                    ]
                                ],
                                [
                                    [
                                        45.98961157785095,
                                        13.707328886964055
                                    ],
                                    [
                                        46.115737703977075,
                                        13.707328886964055
                                    ],
                                    [
                                        46.115737703977075,
                                        13.581202760837927
                                    ],
                                    [
                                        45.98961157785095,
                                        13.581202760837927
                                    ]
                                ],
                                [
                                    [
                                        45.97608559564732,
                                        13.644147761763945
                                    ],
                                    [
                                        46.10221172177344,
                                        13.644147761763945
                                    ],
                                    [
                                        46.10221172177344,
                                        13.518021635637817
                                    ],
                                    [
                                        45.97608559564732,
                                        13.518021635637817
                                    ]
                                ],
                                [
                                    [
                                        45.96256454534923,
                                        13.580965928209803
                                    ],
                                    [
                                        46.088690671475355,
                                        13.580965928209803
                                    ],
                                    [
                                        46.088690671475355,
                                        13.454839802083676
                                    ],
                                    [
                                        45.96256454534923,
                                        13.454839802083676
                                    ]
                                ],
                                [
                                    [
                                        45.94904839929342,
                                        13.517783389893713
                                    ],
                                    [
                                        46.07517452541954,
                                        13.517783389893713
                                    ],
                                    [
                                        46.07517452541954,
                                        13.391657263767586
                                    ],
                                    [
                                        45.94904839929342,
                                        13.391657263767586
                                    ]
                                ],
                                [
                                    [
                                        45.935537132498936,
                                        13.454600150406243
                                    ],
                                    [
                                        46.06166325862506,
                                        13.454600150406243
                                    ],
                                    [
                                        46.06166325862506,
                                        13.328474024280116
                                    ],
                                    [
                                        45.935537132498936,
                                        13.328474024280116
                                    ]
                                ],
                                [
                                    [
                                        45.92203071740365,
                                        13.391416213336413
                                    ],
                                    [
                                        46.048156843529775,
                                        13.391416213336413
                                    ],
                                    [
                                        46.048156843529775,
                                        13.265290087210285
                                    ],
                                    [
                                        45.92203071740365,
                                        13.265290087210285
                                    ]
                                ],
                                [
                                    [
                                        45.908529129128645,
                                        13.328231582271764
                                    ],
                                    [
                                        46.03465525525477,
                                        13.328231582271764
                                    ],
                                    [
                                        46.03465525525477,
                                        13.202105456145636
                                    ],
                                    [
                                        45.908529129128645,
                                        13.202105456145636
                                    ]
                                ],
                                [
                                    [
                                        45.89503234021005,
                                        13.265046260798323
                                    ],
                                    [
                                        46.021158466336175,
                                        13.265046260798323
                                    ],
                                    [
                                        46.021158466336175,
                                        13.138920134672196
                                    ],
                                    [
                                        45.89503234021005,
                                        13.138920134672196
                                    ]
                                ],
                                [
                                    [
                                        45.88154032586486,
                                        13.201860252500623
                                    ],
                                    [
                                        46.007666451990985,
                                        13.201860252500623
                                    ],
                                    [
                                        46.007666451990985,
                                        13.075734126374496
                                    ],
                                    [
                                        45.88154032586486,
                                        13.075734126374496
                                    ]
                                ],
                                [
                                    [
                                        45.868053058732585,
                                        13.138673560961744
                                    ],
                                    [
                                        45.99417918485871,
                                        13.138673560961744
                                    ],
                                    [
                                        45.99417918485871,
                                        13.012547434835616
                                    ],
                                    [
                                        45.868053058732585,
                                        13.012547434835616
                                    ]
                                ],
                                [
                                    [
                                        45.85457051412678,
                                        13.075486189763284
                                    ],
                                    [
                                        45.9806966402529,
                                        13.075486189763284
                                    ],
                                    [
                                        45.9806966402529,
                                        12.949360063637156
                                    ],
                                    [
                                        45.85457051412678,
                                        12.949360063637156
                                    ]
                                ],
                                [
                                    [
                                        45.84109266478429,
                                        13.012298142485424
                                    ],
                                    [
                                        45.967218790910415,
                                        13.012298142485424
                                    ],
                                    [
                                        45.967218790910415,
                                        12.886172016359296
                                    ],
                                    [
                                        45.84109266478429,
                                        12.886172016359296
                                    ]
                                ],
                                [
                                    [
                                        45.827619486117,
                                        12.949109422706893
                                    ],
                                    [
                                        45.953745612243125,
                                        12.949109422706893
                                    ],
                                    [
                                        45.953745612243125,
                                        12.822983296580766
                                    ],
                                    [
                                        45.827619486117,
                                        12.822983296580766
                                    ]
                                ],
                                [
                                    [
                                        45.8141509509515,
                                        12.885920034004984
                                    ],
                                    [
                                        45.940277077077624,
                                        12.885920034004984
                                    ],
                                    [
                                        45.940277077077624,
                                        12.759793907878857
                                    ],
                                    [
                                        45.8141509509515,
                                        12.759793907878857
                                    ]
                                ],
                                [
                                    [
                                        45.800687034803346,
                                        12.822729979955614
                                    ],
                                    [
                                        45.92681316092947,
                                        12.822729979955614
                                    ],
                                    [
                                        45.92681316092947,
                                        12.696603853829487
                                    ],
                                    [
                                        45.800687034803346,
                                        12.696603853829487
                                    ]
                                ],
                                [
                                    [
                                        45.78722771059252,
                                        12.759539264133254
                                    ],
                                    [
                                        45.91335383671864,
                                        12.759539264133254
                                    ],
                                    [
                                        45.91335383671864,
                                        12.633413138007127
                                    ],
                                    [
                                        45.78722771059252,
                                        12.633413138007127
                                    ]
                                ],
                                [
                                    [
                                        45.773772953269486,
                                        12.696347890111044
                                    ],
                                    [
                                        45.89989907939561,
                                        12.696347890111044
                                    ],
                                    [
                                        45.89989907939561,
                                        12.570221763984916
                                    ],
                                    [
                                        45.773772953269486,
                                        12.570221763984916
                                    ]
                                ],
                                [
                                    [
                                        45.760322737816786,
                                        12.633155861460693
                                    ],
                                    [
                                        45.88644886394291,
                                        12.633155861460693
                                    ],
                                    [
                                        45.88644886394291,
                                        12.507029735334566
                                    ],
                                    [
                                        45.760322737816786,
                                        12.507029735334566
                                    ]
                                ],
                                [
                                    [
                                        45.74687703731089,
                                        12.569963181752593
                                    ],
                                    [
                                        45.87300316343701,
                                        12.569963181752593
                                    ],
                                    [
                                        45.87300316343701,
                                        12.443837055626465
                                    ],
                                    [
                                        45.74687703731089,
                                        12.443837055626465
                                    ]
                                ],
                                [
                                    [
                                        45.73343582748652,
                                        12.506769854555744
                                    ],
                                    [
                                        45.85956195361264,
                                        12.506769854555744
                                    ],
                                    [
                                        45.85956195361264,
                                        12.380643728429616
                                    ],
                                    [
                                        45.73343582748652,
                                        12.380643728429616
                                    ]
                                ],
                                [
                                    [
                                        45.719999081508945,
                                        12.443575883437834
                                    ],
                                    [
                                        45.84612520763507,
                                        12.443575883437834
                                    ],
                                    [
                                        45.84612520763507,
                                        12.317449757311707
                                    ],
                                    [
                                        45.719999081508945,
                                        12.317449757311707
                                    ]
                                ],
                                [
                                    [
                                        45.70656677520268,
                                        12.380381271965224
                                    ],
                                    [
                                        45.832692901328805,
                                        12.380381271965224
                                    ],
                                    [
                                        45.832692901328805,
                                        12.254255145839096
                                    ],
                                    [
                                        45.70656677520268,
                                        12.254255145839096
                                    ]
                                ],
                                [
                                    [
                                        45.69313888182549,
                                        12.317186023702924
                                    ],
                                    [
                                        45.819265007951614,
                                        12.317186023702924
                                    ],
                                    [
                                        45.819265007951614,
                                        12.191059897576796
                                    ],
                                    [
                                        45.69313888182549,
                                        12.191059897576796
                                    ]
                                ],
                                [
                                    [
                                        45.67971537729215,
                                        12.253990142214684
                                    ],
                                    [
                                        45.80584150341827,
                                        12.253990142214684
                                    ],
                                    [
                                        45.80584150341827,
                                        12.127864016088557
                                    ],
                                    [
                                        45.67971537729215,
                                        12.127864016088557
                                    ]
                                ],
                                [
                                    [
                                        45.66629623494856,
                                        12.190793631062935
                                    ],
                                    [
                                        45.792422361074685,
                                        12.190793631062935
                                    ],
                                    [
                                        45.792422361074685,
                                        12.064667504936807
                                    ],
                                    [
                                        45.66629623494856,
                                        12.064667504936807
                                    ]
                                ],
                                [
                                    [
                                        45.65288143080185,
                                        12.127596493808813
                                    ],
                                    [
                                        45.77900755692797,
                                        12.127596493808813
                                    ],
                                    [
                                        45.77900755692797,
                                        12.001470367682685
                                    ],
                                    [
                                        45.65288143080185,
                                        12.001470367682685
                                    ]
                                ],
                                [
                                    [
                                        45.639470938284795,
                                        12.064398734012194
                                    ],
                                    [
                                        45.76559706441092,
                                        12.064398734012194
                                    ],
                                    [
                                        45.76559706441092,
                                        11.938272607886066
                                    ],
                                    [
                                        45.639470938284795,
                                        11.938272607886066
                                    ]
                                ],
                                [
                                    [
                                        45.626064733494246,
                                        12.001200355231713
                                    ],
                                    [
                                        45.75219085962037,
                                        12.001200355231713
                                    ],
                                    [
                                        45.75219085962037,
                                        11.875074229105586
                                    ],
                                    [
                                        45.626064733494246,
                                        11.875074229105586
                                    ]
                                ],
                                [
                                    [
                                        45.612662789947045,
                                        11.938001361024723
                                    ],
                                    [
                                        45.73878891607317,
                                        11.938001361024723
                                    ],
                                    [
                                        45.73878891607317,
                                        11.811875234898595
                                    ],
                                    [
                                        45.612662789947045,
                                        11.811875234898595
                                    ]
                                ],
                                [
                                    [
                                        45.599265083826864,
                                        11.874801754947324
                                    ],
                                    [
                                        45.72539120995299,
                                        11.874801754947324
                                    ],
                                    [
                                        45.72539120995299,
                                        11.748675628821196
                                    ],
                                    [
                                        45.599265083826864,
                                        11.748675628821196
                                    ]
                                ],
                                [
                                    [
                                        45.58587158874019,
                                        11.811601540554435
                                    ],
                                    [
                                        45.711997714866314,
                                        11.811601540554435
                                    ],
                                    [
                                        45.711997714866314,
                                        11.685475414428307
                                    ],
                                    [
                                        45.58587158874019,
                                        11.685475414428307
                                    ]
                                ],
                                [
                                    [
                                        45.57248228095469,
                                        11.748400721399733
                                    ],
                                    [
                                        45.69860840708081,
                                        11.748400721399733
                                    ],
                                    [
                                        45.69860840708081,
                                        11.622274595273606
                                    ],
                                    [
                                        45.57248228095469,
                                        11.622274595273606
                                    ]
                                ],
                                [
                                    [
                                        45.55909713416198,
                                        11.685199301035674
                                    ],
                                    [
                                        45.685223260288105,
                                        11.685199301035674
                                    ],
                                    [
                                        45.685223260288105,
                                        11.559073174909546
                                    ],
                                    [
                                        45.55909713416198,
                                        11.559073174909546
                                    ]
                                ],
                                [
                                    [
                                        45.545716124719235,
                                        11.621997283013533
                                    ],
                                    [
                                        45.67184225084536,
                                        11.621997283013533
                                    ],
                                    [
                                        45.67184225084536,
                                        11.495871156887405
                                    ],
                                    [
                                        45.545716124719235,
                                        11.495871156887405
                                    ]
                                ],
                                [
                                    [
                                        45.53233922639395,
                                        11.558794670883394
                                    ],
                                    [
                                        45.658465352520075,
                                        11.558794670883394
                                    ],
                                    [
                                        45.658465352520075,
                                        11.432668544757266
                                    ],
                                    [
                                        45.53233922639395,
                                        11.432668544757266
                                    ]
                                ],
                                [
                                    [
                                        45.51896641562353,
                                        11.495591468194164
                                    ],
                                    [
                                        45.645092541749655,
                                        11.495591468194164
                                    ],
                                    [
                                        45.645092541749655,
                                        11.369465342068036
                                    ],
                                    [
                                        45.51896641562353,
                                        11.369465342068036
                                    ]
                                ],
                                [
                                    [
                                        45.505597666268045,
                                        11.432387678493583
                                    ],
                                    [
                                        45.63172379239417,
                                        11.432387678493583
                                    ],
                                    [
                                        45.63172379239417,
                                        11.306261552367456
                                    ],
                                    [
                                        45.505597666268045,
                                        11.306261552367456
                                    ]
                                ],
                                [
                                    [
                                        45.49223295484572,
                                        11.369183305328233
                                    ],
                                    [
                                        45.61835908097184,
                                        11.369183305328233
                                    ],
                                    [
                                        45.61835908097184,
                                        11.243057179202106
                                    ],
                                    [
                                        45.49223295484572,
                                        11.243057179202106
                                    ]
                                ],
                                [
                                    [
                                        45.47887225529845,
                                        11.305978352243553
                                    ],
                                    [
                                        45.60499838142457,
                                        11.305978352243553
                                    ],
                                    [
                                        45.60499838142457,
                                        11.179852226117426
                                    ],
                                    [
                                        45.47887225529845,
                                        11.179852226117426
                                    ]
                                ],
                                [
                                    [
                                        45.46551554422073,
                                        11.242772822783843
                                    ],
                                    [
                                        45.59164167034685,
                                        11.242772822783843
                                    ],
                                    [
                                        45.59164167034685,
                                        11.116646696657716
                                    ],
                                    [
                                        45.46551554422073,
                                        11.116646696657716
                                    ]
                                ],
                                [
                                    [
                                        45.45216279563851,
                                        11.179566720492284
                                    ],
                                    [
                                        45.57828892176463,
                                        11.179566720492284
                                    ],
                                    [
                                        45.57828892176463,
                                        11.053440594366156
                                    ],
                                    [
                                        45.45216279563851,
                                        11.053440594366156
                                    ]
                                ],
                                [
                                    [
                                        45.43881398622964,
                                        11.116360048910934
                                    ],
                                    [
                                        45.564940112355764,
                                        11.116360048910934
                                    ],
                                    [
                                        45.564940112355764,
                                        10.990233922784807
                                    ],
                                    [
                                        45.43881398622964,
                                        10.990233922784807
                                    ]
                                ],
                                [
                                    [
                                        45.425469090094765,
                                        11.053152811580723
                                    ],
                                    [
                                        45.55159521622089,
                                        11.053152811580723
                                    ],
                                    [
                                        45.55159521622089,
                                        10.927026685454596
                                    ],
                                    [
                                        45.425469090094765,
                                        10.927026685454596
                                    ]
                                ],
                                [
                                    [
                                        45.4121280839925,
                                        10.989945012041554
                                    ],
                                    [
                                        45.53825421011862,
                                        10.989945012041554
                                    ],
                                    [
                                        45.53825421011862,
                                        10.863818885915427
                                    ],
                                    [
                                        45.4121280839925,
                                        10.863818885915427
                                    ]
                                ],
                                [
                                    [
                                        45.398790942103595,
                                        10.926736653832164
                                    ],
                                    [
                                        45.52491706822972,
                                        10.926736653832164
                                    ],
                                    [
                                        45.52491706822972,
                                        10.800610527706036
                                    ],
                                    [
                                        45.398790942103595,
                                        10.800610527706036
                                    ]
                                ],
                                [
                                    [
                                        45.3854576412614,
                                        10.863527740490264
                                    ],
                                    [
                                        45.51158376738752,
                                        10.863527740490264
                                    ],
                                    [
                                        45.51158376738752,
                                        10.737401614364137
                                    ],
                                    [
                                        45.3854576412614,
                                        10.737401614364137
                                    ]
                                ],
                                [
                                    [
                                        45.37212815572412,
                                        10.800318275552454
                                    ],
                                    [
                                        45.498254281850244,
                                        10.800318275552454
                                    ],
                                    [
                                        45.498254281850244,
                                        10.674192149426327
                                    ],
                                    [
                                        45.37212815572412,
                                        10.674192149426327
                                    ]
                                ],
                                [
                                    [
                                        45.358802462410075,
                                        10.737108262554344
                                    ],
                                    [
                                        45.4849285885362,
                                        10.737108262554344
                                    ],
                                    [
                                        45.4849285885362,
                                        10.610982136428216
                                    ],
                                    [
                                        45.358802462410075,
                                        10.610982136428216
                                    ]
                                ],
                                [
                                    [
                                        45.34548053563931,
                                        10.673897705030404
                                    ],
                                    [
                                        45.471606661765435,
                                        10.673897705030404
                                    ],
                                    [
                                        45.471606661765435,
                                        10.547771578904277
                                    ],
                                    [
                                        45.34548053563931,
                                        10.547771578904277
                                    ]
                                ],
                                [
                                    [
                                        45.33216235241732,
                                        10.610686606514143
                                    ],
                                    [
                                        45.458288478543444,
                                        10.610686606514143
                                    ],
                                    [
                                        45.458288478543444,
                                        10.484560480388016
                                    ],
                                    [
                                        45.33216235241732,
                                        10.484560480388016
                                    ]
                                ],
                                [
                                    [
                                        45.31884788713778,
                                        10.547474970538024
                                    ],
                                    [
                                        45.4449740132639,
                                        10.547474970538024
                                    ],
                                    [
                                        45.4449740132639,
                                        10.421348844411897
                                    ],
                                    [
                                        45.31884788713778,
                                        10.421348844411897
                                    ]
                                ],
                                [
                                    [
                                        45.30553711687452,
                                        10.484262800633454
                                    ],
                                    [
                                        45.43166324300064,
                                        10.484262800633454
                                    ],
                                    [
                                        45.43166324300064,
                                        10.358136674507326
                                    ],
                                    [
                                        45.30553711687452,
                                        10.358136674507326
                                    ]
                                ],
                                [
                                    [
                                        45.29223001610356,
                                        10.421050100330854
                                    ],
                                    [
                                        45.41835614222968,
                                        10.421050100330854
                                    ],
                                    [
                                        45.41835614222968,
                                        10.294923974204726
                                    ],
                                    [
                                        45.29223001610356,
                                        10.294923974204726
                                    ]
                                ],
                                [
                                    [
                                        45.27892656196758,
                                        10.357836873159643
                                    ],
                                    [
                                        45.405052688093704,
                                        10.357836873159643
                                    ],
                                    [
                                        45.405052688093704,
                                        10.231710747033516
                                    ],
                                    [
                                        45.27892656196758,
                                        10.231710747033516
                                    ]
                                ],
                                [
                                    [
                                        45.26562672902063,
                                        10.294623122648243
                                    ],
                                    [
                                        45.391752855146756,
                                        10.294623122648243
                                    ],
                                    [
                                        45.391752855146756,
                                        10.168496996522116
                                    ],
                                    [
                                        45.26562672902063,
                                        10.168496996522116
                                    ]
                                ],
                                [
                                    [
                                        45.25233049381857,
                                        10.231408852324103
                                    ],
                                    [
                                        45.37845661994469,
                                        10.231408852324103
                                    ],
                                    [
                                        45.37845661994469,
                                        10.105282726197975
                                    ],
                                    [
                                        45.25233049381857,
                                        10.105282726197975
                                    ]
                                ],
                                [
                                    [
                                        45.23903783295475,
                                        10.168194065713674
                                    ],
                                    [
                                        45.365163959080874,
                                        10.168194065713674
                                    ],
                                    [
                                        45.365163959080874,
                                        10.042067939587547
                                    ],
                                    [
                                        45.23903783295475,
                                        10.042067939587547
                                    ]
                                ],
                                [
                                    [
                                        45.22574872108787,
                                        10.104978766342445
                                    ],
                                    [
                                        45.35187484721399,
                                        10.104978766342445
                                    ],
                                    [
                                        45.35187484721399,
                                        9.978852640216317
                                    ],
                                    [
                                        45.22574872108787,
                                        9.978852640216317
                                    ]
                                ],
                                [
                                    [
                                        45.212463135543516,
                                        10.041762957734965
                                    ],
                                    [
                                        45.33858926166964,
                                        10.041762957734965
                                    ],
                                    [
                                        45.33858926166964,
                                        9.915636831608838
                                    ],
                                    [
                                        45.212463135543516,
                                        9.915636831608838
                                    ]
                                ],
                                [
                                    [
                                        45.19918105104772,
                                        9.978546643414822
                                    ],
                                    [
                                        45.32530717717384,
                                        9.978546643414822
                                    ],
                                    [
                                        45.32530717717384,
                                        9.852420517288694
                                    ],
                                    [
                                        45.19918105104772,
                                        9.852420517288694
                                    ]
                                ],
                                [
                                    [
                                        55.81048934185217,
                                        22.53893370919226
                                    ],
                                    [
                                        55.93661546797829,
                                        22.53893370919226
                                    ],
                                    [
                                        55.93661546797829,
                                        22.412807583066137
                                    ],
                                    [
                                        55.81048934185217,
                                        22.412807583066137
                                    ]
                                ],
                                [
                                    [
                                        55.79601004192049,
                                        22.47597779380856
                                    ],
                                    [
                                        55.92213616804661,
                                        22.47597779380856
                                    ],
                                    [
                                        55.92213616804661,
                                        22.349851667682437
                                    ],
                                    [
                                        55.79601004192049,
                                        22.349851667682437
                                    ]
                                ],
                                [
                                    [
                                        55.781540018928226,
                                        22.413020828632224
                                    ],
                                    [
                                        55.90766614505435,
                                        22.413020828632224
                                    ],
                                    [
                                        55.90766614505435,
                                        22.2868947025061
                                    ],
                                    [
                                        55.781540018928226,
                                        22.2868947025061
                                    ]
                                ],
                                [
                                    [
                                        55.76707923716826,
                                        22.35006281776864
                                    ],
                                    [
                                        55.893205363294385,
                                        22.35006281776864
                                    ],
                                    [
                                        55.893205363294385,
                                        22.223936691642518
                                    ],
                                    [
                                        55.76707923716826,
                                        22.223936691642518
                                    ]
                                ],
                                [
                                    [
                                        55.75262765841132,
                                        22.287103765318363
                                    ],
                                    [
                                        55.878753784537444,
                                        22.287103765318363
                                    ],
                                    [
                                        55.878753784537444,
                                        22.16097763919224
                                    ],
                                    [
                                        55.75262765841132,
                                        22.16097763919224
                                    ]
                                ],
                                [
                                    [
                                        55.738185247168275,
                                        22.22414367537713
                                    ],
                                    [
                                        55.8643113732944,
                                        22.22414367537713
                                    ],
                                    [
                                        55.8643113732944,
                                        22.098017549251008
                                    ],
                                    [
                                        55.738185247168275,
                                        22.098017549251008
                                    ]
                                ],
                                [
                                    [
                                        55.7237519654284,
                                        22.161182552035953
                                    ],
                                    [
                                        55.84987809155452,
                                        22.161182552035953
                                    ],
                                    [
                                        55.84987809155452,
                                        22.03505642590983
                                    ],
                                    [
                                        55.7237519654284,
                                        22.03505642590983
                                    ]
                                ],
                                [
                                    [
                                        55.70932777791517,
                                        22.098220399381052
                                    ],
                                    [
                                        55.835453904041294,
                                        22.098220399381052
                                    ],
                                    [
                                        55.835453904041294,
                                        21.97209427325493
                                    ],
                                    [
                                        55.70932777791517,
                                        21.97209427325493
                                    ]
                                ],
                                [
                                    [
                                        55.69491264683242,
                                        22.035257221493982
                                    ],
                                    [
                                        55.821038772958545,
                                        22.035257221493982
                                    ],
                                    [
                                        55.821038772958545,
                                        21.909131095367858
                                    ],
                                    [
                                        55.69491264683242,
                                        21.909131095367858
                                    ]
                                ],
                                [
                                    [
                                        55.68050653712208,
                                        21.972293022451574
                                    ],
                                    [
                                        55.8066326632482,
                                        21.972293022451574
                                    ],
                                    [
                                        55.8066326632482,
                                        21.84616689632545
                                    ],
                                    [
                                        55.68050653712208,
                                        21.84616689632545
                                    ]
                                ],
                                [
                                    [
                                        55.66610941119558,
                                        21.90932780632605
                                    ],
                                    [
                                        55.7922355373217,
                                        21.90932780632605
                                    ],
                                    [
                                        55.7922355373217,
                                        21.783201680199927
                                    ],
                                    [
                                        55.66610941119558,
                                        21.783201680199927
                                    ]
                                ],
                                [
                                    [
                                        55.651721234207756,
                                        21.846361577185032
                                    ],
                                    [
                                        55.77784736033388,
                                        21.846361577185032
                                    ],
                                    [
                                        55.77784736033388,
                                        21.720235451058908
                                    ],
                                    [
                                        55.651721234207756,
                                        21.720235451058908
                                    ]
                                ],
                                [
                                    [
                                        55.63734196878029,
                                        21.783394339091533
                                    ],
                                    [
                                        55.763468094906415,
                                        21.783394339091533
                                    ],
                                    [
                                        55.763468094906415,
                                        21.65726821296541
                                    ],
                                    [
                                        55.63734196878029,
                                        21.65726821296541
                                    ]
                                ],
                                [
                                    [
                                        55.622971580277145,
                                        21.720426096104042
                                    ],
                                    [
                                        55.74909770640327,
                                        21.720426096104042
                                    ],
                                    [
                                        55.74909770640327,
                                        21.594299969977918
                                    ],
                                    [
                                        55.622971580277145,
                                        21.594299969977918
                                    ]
                                ],
                                [
                                    [
                                        55.60861003152803,
                                        21.657456852276532
                                    ],
                                    [
                                        55.734736157654154,
                                        21.657456852276532
                                    ],
                                    [
                                        55.734736157654154,
                                        21.531330726150408
                                    ],
                                    [
                                        55.60861003152803,
                                        21.531330726150408
                                    ]
                                ],
                                [
                                    [
                                        55.59425728810394,
                                        21.59448661165847
                                    ],
                                    [
                                        55.720383414230064,
                                        21.59448661165847
                                    ],
                                    [
                                        55.720383414230064,
                                        21.468360485532347
                                    ],
                                    [
                                        55.59425728810394,
                                        21.468360485532347
                                    ]
                                ],
                                [
                                    [
                                        55.57991331304041,
                                        21.531515378294873
                                    ],
                                    [
                                        55.70603943916653,
                                        21.531515378294873
                                    ],
                                    [
                                        55.70603943916653,
                                        21.40538925216875
                                    ],
                                    [
                                        55.57991331304041,
                                        21.40538925216875
                                    ]
                                ],
                                [
                                    [
                                        55.56557807211162,
                                        21.46854315622637
                                    ],
                                    [
                                        55.691704198237744,
                                        21.46854315622637
                                    ],
                                    [
                                        55.691704198237744,
                                        21.342417030100247
                                    ],
                                    [
                                        55.56557807211162,
                                        21.342417030100247
                                    ]
                                ],
                                [
                                    [
                                        55.55125152856173,
                                        21.405569949489163
                                    ],
                                    [
                                        55.67737765468785,
                                        21.405569949489163
                                    ],
                                    [
                                        55.67737765468785,
                                        21.27944382336304
                                    ],
                                    [
                                        55.55125152856173,
                                        21.27944382336304
                                    ]
                                ],
                                [
                                    [
                                        55.53693364770649,
                                        21.342595762115092
                                    ],
                                    [
                                        55.663059773832614,
                                        21.342595762115092
                                    ],
                                    [
                                        55.663059773832614,
                                        21.216469635988968
                                    ],
                                    [
                                        55.53693364770649,
                                        21.216469635988968
                                    ]
                                ],
                                [
                                    [
                                        55.522624394962136,
                                        21.27962059813167
                                    ],
                                    [
                                        55.64875052108826,
                                        21.27962059813167
                                    ],
                                    [
                                        55.64875052108826,
                                        21.153494472005548
                                    ],
                                    [
                                        55.522624394962136,
                                        21.153494472005548
                                    ]
                                ],
                                [
                                    [
                                        55.508323733876146,
                                        21.216644461562122
                                    ],
                                    [
                                        55.63444986000227,
                                        21.216644461562122
                                    ],
                                    [
                                        55.63444986000227,
                                        21.090518335436
                                    ],
                                    [
                                        55.508323733876146,
                                        21.090518335436
                                    ]
                                ],
                                [
                                    [
                                        55.49403163071889,
                                        21.153667356425373
                                    ],
                                    [
                                        55.62015775684501,
                                        21.153667356425373
                                    ],
                                    [
                                        55.62015775684501,
                                        21.02754123029925
                                    ],
                                    [
                                        55.49403163071889,
                                        21.02754123029925
                                    ]
                                ],
                                [
                                    [
                                        55.479748049239625,
                                        21.090689286736122
                                    ],
                                    [
                                        55.60587417536575,
                                        21.090689286736122
                                    ],
                                    [
                                        55.60587417536575,
                                        20.96456316061
                                    ],
                                    [
                                        55.479748049239625,
                                        20.96456316061
                                    ]
                                ],
                                [
                                    [
                                        55.46547295590461,
                                        21.02771025650486
                                    ],
                                    [
                                        55.59159908203073,
                                        21.02771025650486
                                    ],
                                    [
                                        55.59159908203073,
                                        20.901584130378737
                                    ],
                                    [
                                        55.46547295590461,
                                        20.901584130378737
                                    ]
                                ],
                                [
                                    [
                                        55.45120631466107,
                                        20.96473026973787
                                    ],
                                    [
                                        55.577332440787195,
                                        20.96473026973787
                                    ],
                                    [
                                        55.577332440787195,
                                        20.838604143611747
                                    ],
                                    [
                                        55.45120631466107,
                                        20.838604143611747
                                    ]
                                ],
                                [
                                    [
                                        55.43694809216577,
                                        20.9017493304373
                                    ],
                                    [
                                        55.563074218291895,
                                        20.9017493304373
                                    ],
                                    [
                                        55.563074218291895,
                                        20.775623204311177
                                    ],
                                    [
                                        55.43694809216577,
                                        20.775623204311177
                                    ]
                                ],
                                [
                                    [
                                        55.422698252563656,
                                        20.83876744260117
                                    ],
                                    [
                                        55.54882437868978,
                                        20.83876744260117
                                    ],
                                    [
                                        55.54882437868978,
                                        20.712641316475047
                                    ],
                                    [
                                        55.422698252563656,
                                        20.712641316475047
                                    ]
                                ],
                                [
                                    [
                                        55.408456762708006,
                                        20.775784610223383
                                    ],
                                    [
                                        55.53458288883413,
                                        20.775784610223383
                                    ],
                                    [
                                        55.53458288883413,
                                        20.64965848409726
                                    ],
                                    [
                                        55.408456762708006,
                                        20.64965848409726
                                    ]
                                ],
                                [
                                    [
                                        55.39422358693121,
                                        20.71280083729378
                                    ],
                                    [
                                        55.520349713057335,
                                        20.71280083729378
                                    ],
                                    [
                                        55.520349713057335,
                                        20.586674711167657
                                    ],
                                    [
                                        55.39422358693121,
                                        20.586674711167657
                                    ]
                                ],
                                [
                                    [
                                        55.37999869228123,
                                        20.64981612779815
                                    ],
                                    [
                                        55.50612481840735,
                                        20.64981612779815
                                    ],
                                    [
                                        55.50612481840735,
                                        20.523690001672026
                                    ],
                                    [
                                        55.37999869228123,
                                        20.523690001672026
                                    ]
                                ],
                                [
                                    [
                                        55.36578204327749,
                                        20.586830485718313
                                    ],
                                    [
                                        55.491908169403615,
                                        20.586830485718313
                                    ],
                                    [
                                        55.491908169403615,
                                        20.46070435959219
                                    ],
                                    [
                                        55.36578204327749,
                                        20.46070435959219
                                    ]
                                ],
                                [
                                    [
                                        55.351573607158976,
                                        20.52384391503201
                                    ],
                                    [
                                        55.4776997332851,
                                        20.52384391503201
                                    ],
                                    [
                                        55.4776997332851,
                                        20.397717788905886
                                    ],
                                    [
                                        55.351573607158976,
                                        20.397717788905886
                                    ]
                                ],
                                [
                                    [
                                        55.337373348633385,
                                        20.46085641971313
                                    ],
                                    [
                                        55.46349947475951,
                                        20.46085641971313
                                    ],
                                    [
                                        55.46349947475951,
                                        20.334730293587008
                                    ],
                                    [
                                        55.337373348633385,
                                        20.334730293587008
                                    ]
                                ],
                                [
                                    [
                                        55.323181235127066,
                                        20.397868003731542
                                    ],
                                    [
                                        55.44930736125319,
                                        20.397868003731542
                                    ],
                                    [
                                        55.44930736125319,
                                        20.271741877605418
                                    ],
                                    [
                                        55.323181235127066,
                                        20.271741877605418
                                    ]
                                ],
                                [
                                    [
                                        55.30899723153578,
                                        20.33487867105326
                                    ],
                                    [
                                        55.4351233576619,
                                        20.33487867105326
                                    ],
                                    [
                                        55.4351233576619,
                                        20.208752544927137
                                    ],
                                    [
                                        55.30899723153578,
                                        20.208752544927137
                                    ]
                                ],
                                [
                                    [
                                        55.294821305467956,
                                        20.27188842564039
                                    ],
                                    [
                                        55.42094743159408,
                                        20.27188842564039
                                    ],
                                    [
                                        55.42094743159408,
                                        20.145762299514267
                                    ],
                                    [
                                        55.294821305467956,
                                        20.145762299514267
                                    ]
                                ],
                                [
                                    [
                                        55.28065342200386,
                                        20.20889727145122
                                    ],
                                    [
                                        55.40677954812998,
                                        20.20889727145122
                                    ],
                                    [
                                        55.40677954812998,
                                        20.082771145325097
                                    ],
                                    [
                                        55.28065342200386,
                                        20.082771145325097
                                    ]
                                ],
                                [
                                    [
                                        55.26649354893692,
                                        20.1459052124402
                                    ],
                                    [
                                        55.392619675063045,
                                        20.1459052124402
                                    ],
                                    [
                                        55.392619675063045,
                                        20.019779086314077
                                    ],
                                    [
                                        55.26649354893692,
                                        20.019779086314077
                                    ]
                                ],
                                [
                                    [
                                        55.25234165153158,
                                        20.082912252557943
                                    ],
                                    [
                                        55.378467777657704,
                                        20.082912252557943
                                    ],
                                    [
                                        55.378467777657704,
                                        19.95678612643182
                                    ],
                                    [
                                        55.25234165153158,
                                        19.95678612643182
                                    ]
                                ],
                                [
                                    [
                                        55.238197697761166,
                                        20.01991839575135
                                    ],
                                    [
                                        55.36432382388729,
                                        20.01991839575135
                                    ],
                                    [
                                        55.36432382388729,
                                        19.893792269625227
                                    ],
                                    [
                                        55.238197697761166,
                                        19.893792269625227
                                    ]
                                ],
                                [
                                    [
                                        55.224061653072305,
                                        19.95692364596353
                                    ],
                                    [
                                        55.35018777919843,
                                        19.95692364596353
                                    ],
                                    [
                                        55.35018777919843,
                                        19.830797519837407
                                    ],
                                    [
                                        55.224061653072305,
                                        19.830797519837407
                                    ]
                                ],
                                [
                                    [
                                        55.209933485614755,
                                        19.893928007133933
                                    ],
                                    [
                                        55.33605961174088,
                                        19.893928007133933
                                    ],
                                    [
                                        55.33605961174088,
                                        19.76780188100781
                                    ],
                                    [
                                        55.209933485614755,
                                        19.76780188100781
                                    ]
                                ],
                                [
                                    [
                                        55.195813161013746,
                                        19.830931483198203
                                    ],
                                    [
                                        55.32193928713987,
                                        19.830931483198203
                                    ],
                                    [
                                        55.32193928713987,
                                        19.70480535707208
                                    ],
                                    [
                                        55.195813161013746,
                                        19.70480535707208
                                    ]
                                ],
                                [
                                    [
                                        55.18170064760013,
                                        19.767934078088413
                                    ],
                                    [
                                        55.307826773726255,
                                        19.767934078088413
                                    ],
                                    [
                                        55.307826773726255,
                                        19.64180795196229
                                    ],
                                    [
                                        55.18170064760013,
                                        19.64180795196229
                                    ]
                                ],
                                [
                                    [
                                        55.16759591117589,
                                        19.704935795732982
                                    ],
                                    [
                                        55.29372203730201,
                                        19.704935795732982
                                    ],
                                    [
                                        55.29372203730201,
                                        19.57880966960686
                                    ],
                                    [
                                        55.16759591117589,
                                        19.57880966960686
                                    ]
                                ],
                                [
                                    [
                                        55.15349892024443,
                                        19.641936640056674
                                    ],
                                    [
                                        55.279625046370555,
                                        19.641936640056674
                                    ],
                                    [
                                        55.279625046370555,
                                        19.51581051393055
                                    ],
                                    [
                                        55.15349892024443,
                                        19.51581051393055
                                    ]
                                ],
                                [
                                    [
                                        55.139409640784365,
                                        19.57893661498069
                                    ],
                                    [
                                        55.26553576691049,
                                        19.57893661498069
                                    ],
                                    [
                                        55.26553576691049,
                                        19.452810488854567
                                    ],
                                    [
                                        55.139409640784365,
                                        19.452810488854567
                                    ]
                                ],
                                [
                                    [
                                        55.12532804147155,
                                        19.51593572442261
                                    ],
                                    [
                                        55.25145416759767,
                                        19.51593572442261
                                    ],
                                    [
                                        55.25145416759767,
                                        19.389809598296488
                                    ],
                                    [
                                        55.12532804147155,
                                        19.389809598296488
                                    ]
                                ],
                                [
                                    [
                                        55.111254088459276,
                                        19.452933972296552
                                    ],
                                    [
                                        55.2373802145854,
                                        19.452933972296552
                                    ],
                                    [
                                        55.2373802145854,
                                        19.32680784617043
                                    ],
                                    [
                                        55.111254088459276,
                                        19.32680784617043
                                    ]
                                ],
                                [
                                    [
                                        55.09718775060044,
                                        19.389931362513053
                                    ],
                                    [
                                        55.223313876726564,
                                        19.389931362513053
                                    ],
                                    [
                                        55.223313876726564,
                                        19.26380523638693
                                    ],
                                    [
                                        55.09718775060044,
                                        19.26380523638693
                                    ]
                                ],
                                [
                                    [
                                        55.08312899420988,
                                        19.32692789897916
                                    ],
                                    [
                                        55.209255120336,
                                        19.32692789897916
                                    ],
                                    [
                                        55.209255120336,
                                        19.200801772853037
                                    ],
                                    [
                                        55.08312899420988,
                                        19.200801772853037
                                    ]
                                ],
                                [
                                    [
                                        55.06907778831898,
                                        19.26392358559849
                                    ],
                                    [
                                        55.1952039144451,
                                        19.26392358559849
                                    ],
                                    [
                                        55.1952039144451,
                                        19.137797459472367
                                    ],
                                    [
                                        55.06907778831898,
                                        19.137797459472367
                                    ]
                                ],
                                [
                                    [
                                        55.0550340994088,
                                        19.200918426271173
                                    ],
                                    [
                                        55.181160225534924,
                                        19.200918426271173
                                    ],
                                    [
                                        55.181160225534924,
                                        19.07479230014505
                                    ],
                                    [
                                        55.0550340994088,
                                        19.07479230014505
                                    ]
                                ],
                                [
                                    [
                                        55.04099789667439,
                                        19.137912424893923
                                    ],
                                    [
                                        55.167124022800515,
                                        19.137912424893923
                                    ],
                                    [
                                        55.167124022800515,
                                        19.0117862987678
                                    ],
                                    [
                                        55.04099789667439,
                                        19.0117862987678
                                    ]
                                ],
                                [
                                    [
                                        55.02696914677261,
                                        19.074905585360053
                                    ],
                                    [
                                        55.15309527289873,
                                        19.074905585360053
                                    ],
                                    [
                                        55.15309527289873,
                                        18.94877945923393
                                    ],
                                    [
                                        55.02696914677261,
                                        18.94877945923393
                                    ]
                                ],
                                [
                                    [
                                        55.01294781906207,
                                        19.011897911559533
                                    ],
                                    [
                                        55.139073945188194,
                                        19.011897911559533
                                    ],
                                    [
                                        55.139073945188194,
                                        18.88577178543341
                                    ],
                                    [
                                        55.01294781906207,
                                        18.88577178543341
                                    ]
                                ],
                                [
                                    [
                                        54.998933880365506,
                                        18.948889407378932
                                    ],
                                    [
                                        55.12506000649163,
                                        18.948889407378932
                                    ],
                                    [
                                        55.12506000649163,
                                        18.822763281252808
                                    ],
                                    [
                                        54.998933880365506,
                                        18.822763281252808
                                    ]
                                ],
                                [
                                    [
                                        54.98492729955193,
                                        18.885880076701532
                                    ],
                                    [
                                        55.11105342567805,
                                        18.885880076701532
                                    ],
                                    [
                                        55.11105342567805,
                                        18.75975395057541
                                    ],
                                    [
                                        54.98492729955193,
                                        18.75975395057541
                                    ]
                                ],
                                [
                                    [
                                        54.97092804557539,
                                        18.82286992340726
                                    ],
                                    [
                                        55.097054171701515,
                                        18.82286992340726
                                    ],
                                    [
                                        55.097054171701515,
                                        18.696743797281137
                                    ],
                                    [
                                        54.97092804557539,
                                        18.696743797281137
                                    ]
                                ],
                                [
                                    [
                                        54.95693608549767,
                                        18.75985895137283
                                    ],
                                    [
                                        55.08306221162379,
                                        18.75985895137283
                                    ],
                                    [
                                        55.08306221162379,
                                        18.633732825246707
                                    ],
                                    [
                                        54.95693608549767,
                                        18.633732825246707
                                    ]
                                ],
                                [
                                    [
                                        54.942951389091455,
                                        18.69684716447162
                                    ],
                                    [
                                        55.06907751521758,
                                        18.69684716447162
                                    ],
                                    [
                                        55.06907751521758,
                                        18.570721038345496
                                    ],
                                    [
                                        54.942951389091455,
                                        18.570721038345496
                                    ]
                                ],
                                [
                                    [
                                        54.92897392358175,
                                        18.63383456657382
                                    ],
                                    [
                                        55.055100049707875,
                                        18.63383456657382
                                    ],
                                    [
                                        55.055100049707875,
                                        18.507708440447697
                                    ],
                                    [
                                        54.92897392358175,
                                        18.507708440447697
                                    ]
                                ],
                                [
                                    [
                                        54.91500365890522,
                                        18.570821161546373
                                    ],
                                    [
                                        55.041129785031345,
                                        18.570821161546373
                                    ],
                                    [
                                        55.041129785031345,
                                        18.44469503542025
                                    ],
                                    [
                                        54.91500365890522,
                                        18.44469503542025
                                    ]
                                ],
                                [
                                    [
                                        54.90104056244168,
                                        18.507806953253073
                                    ],
                                    [
                                        55.0271666885678,
                                        18.507806953253073
                                    ],
                                    [
                                        55.0271666885678,
                                        18.38168082712695
                                    ],
                                    [
                                        54.90104056244168,
                                        18.38168082712695
                                    ]
                                ],
                                [
                                    [
                                        54.887084604286756,
                                        18.444791945554492
                                    ],
                                    [
                                        55.01321073041288,
                                        18.444791945554492
                                    ],
                                    [
                                        55.01321073041288,
                                        18.318665819428368
                                    ],
                                    [
                                        54.887084604286756,
                                        18.318665819428368
                                    ]
                                ],
                                [
                                    [
                                        54.873135751981636,
                                        18.38177614230809
                                    ],
                                    [
                                        54.99926187810776,
                                        18.38177614230809
                                    ],
                                    [
                                        54.99926187810776,
                                        18.255650016181967
                                    ],
                                    [
                                        54.873135751981636,
                                        18.255650016181967
                                    ]
                                ],
                                [
                                    [
                                        54.859193975779185,
                                        18.31875954736818
                                    ],
                                    [
                                        54.98532010190531,
                                        18.31875954736818
                                    ],
                                    [
                                        54.98532010190531,
                                        18.192633421242057
                                    ],
                                    [
                                        54.859193975779185,
                                        18.192633421242057
                                    ]
                                ],
                                [
                                    [
                                        54.84525924337219,
                                        18.25574216458596
                                    ],
                                    [
                                        54.971385369498314,
                                        18.25574216458596
                                    ],
                                    [
                                        54.971385369498314,
                                        18.129616038459837
                                    ],
                                    [
                                        54.84525924337219,
                                        18.129616038459837
                                    ]
                                ],
                                [
                                    [
                                        54.831331525175486,
                                        18.192723997809583
                                    ],
                                    [
                                        54.95745765130161,
                                        18.192723997809583
                                    ],
                                    [
                                        54.95745765130161,
                                        18.06659787168346
                                    ],
                                    [
                                        54.831331525175486,
                                        18.06659787168346
                                    ]
                                ],
                                [
                                    [
                                        54.81741078903342,
                                        18.12970505088409
                                    ],
                                    [
                                        54.94353691515954,
                                        18.12970505088409
                                    ],
                                    [
                                        54.94353691515954,
                                        18.003578924757967
                                    ],
                                    [
                                        54.81741078903342,
                                        18.003578924757967
                                    ]
                                ],
                                [
                                    [
                                        54.803497005508206,
                                        18.06668532765153
                                    ],
                                    [
                                        54.92962313163433,
                                        18.06668532765153
                                    ],
                                    [
                                        54.92962313163433,
                                        17.940559201525407
                                    ],
                                    [
                                        54.803497005508206,
                                        17.940559201525407
                                    ]
                                ],
                                [
                                    [
                                        54.7895901426102,
                                        18.00366483195087
                                    ],
                                    [
                                        54.915716268736325,
                                        18.00366483195087
                                    ],
                                    [
                                        54.915716268736325,
                                        17.877538705824747
                                    ],
                                    [
                                        54.7895901426102,
                                        17.877538705824747
                                    ]
                                ],],
                                swathWidth: 10.0,
                                length: 15.0,
                                areaCovered: 100.0,
                                captureTime: "2024-10-03T08:00:00Z",
                                cloudCoverage: 5.0,
                                resolution: 1.0
                            },
                            satellite_operations: [
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 1",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 2",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                }

                            ],
                        },
                        {
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_002",
                                name: "Satellite Alpha 2",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            strip: {
                                id: "strip_002",
                                featureId: "feat_002",
                                coordinates: [[
                                    [
                                        54.775690171047486,
                                        17.940643567618153
                                    ],
                                    [
                                        54.90181629717361,
                                        17.940643567618153
                                    ],
                                    [
                                        54.90181629717361,
                                        17.81451744149203
                                    ],
                                    [
                                        54.775690171047486,
                                        17.81451744149203
                                    ]
                                ],
                                [
                                    [
                                        54.76179705898017,
                                        17.87762153848639
                                    ],
                                    [
                                        54.887923185106295,
                                        17.87762153848639
                                    ],
                                    [
                                        54.887923185106295,
                                        17.751495412360267
                                    ],
                                    [
                                        54.76179705898017,
                                        17.751495412360267
                                    ]
                                ],
                                [
                                    [
                                        54.7479107772685,
                                        17.81459874838563
                                    ],
                                    [
                                        54.874036903394625,
                                        17.81459874838563
                                    ],
                                    [
                                        54.874036903394625,
                                        17.688472622259507
                                    ],
                                    [
                                        54.7479107772685,
                                        17.688472622259507
                                    ]
                                ],
                                [
                                    [
                                        54.73403129422085,
                                        17.751575201143023
                                    ],
                                    [
                                        54.86015742034697,
                                        17.751575201143023
                                    ],
                                    [
                                        54.86015742034697,
                                        17.6254490750169
                                    ],
                                    [
                                        54.73403129422085,
                                        17.6254490750169
                                    ]
                                ],
                                [
                                    [
                                        54.72015858084974,
                                        17.688550900582783
                                    ],
                                    [
                                        54.846284706975865,
                                        17.688550900582783
                                    ],
                                    [
                                        54.846284706975865,
                                        17.56242477445666
                                    ],
                                    [
                                        54.72015858084974,
                                        17.56242477445666
                                    ]
                                ],
                                [
                                    [
                                        54.70629260560999,
                                        17.62552585052619
                                    ],
                                    [
                                        54.83241873173611,
                                        17.62552585052619
                                    ],
                                    [
                                        54.83241873173611,
                                        17.499399724400067
                                    ],
                                    [
                                        54.70629260560999,
                                        17.499399724400067
                                    ]
                                ],
                                [
                                    [
                                        54.69243333966153,
                                        17.562500054791713
                                    ],
                                    [
                                        54.81855946578765,
                                        17.562500054791713
                                    ],
                                    [
                                        54.81855946578765,
                                        17.43637392866559
                                    ],
                                    [
                                        54.69243333966153,
                                        17.43637392866559
                                    ]
                                ],
                                [
                                    [
                                        54.6785807516074,
                                        17.49947351719491
                                    ],
                                    [
                                        54.80470687773352,
                                        17.49947351719491
                                    ],
                                    [
                                        54.80470687773352,
                                        17.373347391068787
                                    ],
                                    [
                                        54.6785807516074,
                                        17.373347391068787
                                    ]
                                ],
                                [
                                    [
                                        54.66473481275009,
                                        17.436446241548563
                                    ],
                                    [
                                        54.790860938876214,
                                        17.436446241548563
                                    ],
                                    [
                                        54.790860938876214,
                                        17.31032011542244
                                    ],
                                    [
                                        54.66473481275009,
                                        17.31032011542244
                                    ]
                                ],
                                [
                                    [
                                        54.65089549183763,
                                        17.373418231662562
                                    ],
                                    [
                                        54.777021617963754,
                                        17.373418231662562
                                    ],
                                    [
                                        54.777021617963754,
                                        17.247292105536438
                                    ],
                                    [
                                        54.65089549183763,
                                        17.247292105536438
                                    ]
                                ],
                                [
                                    [
                                        54.6370627603199,
                                        17.310389491344043
                                    ],
                                    [
                                        54.763188886446024,
                                        17.310389491344043
                                    ],
                                    [
                                        54.763188886446024,
                                        17.18426336521792
                                    ],
                                    [
                                        54.6370627603199,
                                        17.18426336521792
                                    ]
                                ],
                                [
                                    [
                                        54.62323658708679,
                                        17.247360024397352
                                    ],
                                    [
                                        54.74936271321291,
                                        17.247360024397352
                                    ],
                                    [
                                        54.74936271321291,
                                        17.121233898271228
                                    ],
                                    [
                                        54.62323658708679,
                                        17.121233898271228
                                    ]
                                ],
                                [
                                    [
                                        54.609416943735695,
                                        17.1843298346241
                                    ],
                                    [
                                        54.73554306986182,
                                        17.1843298346241
                                    ],
                                    [
                                        54.73554306986182,
                                        17.058203708497977
                                    ],
                                    [
                                        54.609416943735695,
                                        17.058203708497977
                                    ]
                                ],
                                [
                                    [
                                        54.59560379929018,
                                        17.1212989258231
                                    ],
                                    [
                                        54.721729925416305,
                                        17.1212989258231
                                    ],
                                    [
                                        54.721729925416305,
                                        16.995172799696977
                                    ],
                                    [
                                        54.59560379929018,
                                        16.995172799696977
                                    ]
                                ],
                                [
                                    [
                                        54.581797125485465,
                                        17.0582673017905
                                    ],
                                    [
                                        54.70792325161159,
                                        17.0582673017905
                                    ],
                                    [
                                        54.70792325161159,
                                        16.932141175664377
                                    ],
                                    [
                                        54.581797125485465,
                                        16.932141175664377
                                    ]
                                ],
                                [
                                    [
                                        54.5679968914952,
                                        16.99523496631972
                                    ],
                                    [
                                        54.69412301762132,
                                        16.99523496631972
                                    ],
                                    [
                                        54.69412301762132,
                                        16.869108840193597
                                    ],
                                    [
                                        54.5679968914952,
                                        16.869108840193597
                                    ]
                                ],
                                [
                                    [
                                        54.55420306919254,
                                        16.932201923201504
                                    ],
                                    [
                                        54.68032919531866,
                                        16.932201923201504
                                    ],
                                    [
                                        54.68032919531866,
                                        16.80607579707538
                                    ],
                                    [
                                        54.55420306919254,
                                        16.80607579707538
                                    ]
                                ],
                                [
                                    [
                                        54.54041562788991,
                                        16.869168176223923
                                    ],
                                    [
                                        54.66654175401603,
                                        16.869168176223923
                                    ],
                                    [
                                        54.66654175401603,
                                        16.7430420500978
                                    ],
                                    [
                                        54.54041562788991,
                                        16.7430420500978
                                    ]
                                ],
                                [
                                    [
                                        54.526634539595186,
                                        16.8061337291724
                                    ],
                                    [
                                        54.65276066572131,
                                        16.8061337291724
                                    ],
                                    [
                                        54.65276066572131,
                                        16.680007603046278
                                    ],
                                    [
                                        54.526634539595186,
                                        16.680007603046278
                                    ]
                                ],
                                [
                                    [
                                        54.512859773758095,
                                        16.743098585829742
                                    ],
                                    [
                                        54.63898589988422,
                                        16.743098585829742
                                    ],
                                    [
                                        54.63898589988422,
                                        16.616972459703618
                                    ],
                                    [
                                        54.512859773758095,
                                        16.616972459703618
                                    ]
                                ],
                                [
                                    [
                                        54.499091302526246,
                                        16.68006274997613
                                    ],
                                    [
                                        54.62521742865237,
                                        16.68006274997613
                                    ],
                                    [
                                        54.62521742865237,
                                        16.553936623850007
                                    ],
                                    [
                                        54.499091302526246,
                                        16.553936623850007
                                    ]
                                ],
                                [
                                    [
                                        54.48532909548343,
                                        16.617026225389182
                                    ],
                                    [
                                        54.61145522160955,
                                        16.617026225389182
                                    ],
                                    [
                                        54.61145522160955,
                                        16.490900099263058
                                    ],
                                    [
                                        54.48532909548343,
                                        16.490900099263058
                                    ]
                                ],
                                [
                                    [
                                        54.47157312491235,
                                        16.553989015843893
                                    ],
                                    [
                                        54.59769925103847,
                                        16.553989015843893
                                    ],
                                    [
                                        54.59769925103847,
                                        16.42786288971777
                                    ],
                                    [
                                        54.47157312491235,
                                        16.42786288971777
                                    ]
                                ],
                                [
                                    [
                                        54.45782336053096,
                                        16.490951125112762
                                    ],
                                    [
                                        54.58394948665708,
                                        16.490951125112762
                                    ],
                                    [
                                        54.58394948665708,
                                        16.364824998986638
                                    ],
                                    [
                                        54.45782336053096,
                                        16.364824998986638
                                    ]
                                ],
                                [
                                    [
                                        54.444079774099215,
                                        16.42791255696569
                                    ],
                                    [
                                        54.57020590022534,
                                        16.42791255696569
                                    ],
                                    [
                                        54.57020590022534,
                                        16.301786430839567
                                    ],
                                    [
                                        54.444079774099215,
                                        16.301786430839567
                                    ]
                                ],
                                [
                                    [
                                        54.43034233743669,
                                        16.3648733151701
                                    ],
                                    [
                                        54.556468463562815,
                                        16.3648733151701
                                    ],
                                    [
                                        54.556468463562815,
                                        16.238747189043977
                                    ],
                                    [
                                        54.43034233743669,
                                        16.238747189043977
                                    ]
                                ],
                                [
                                    [
                                        54.416611020468146,
                                        16.301833403490882
                                    ],
                                    [
                                        54.54273714659427,
                                        16.301833403490882
                                    ],
                                    [
                                        54.54273714659427,
                                        16.175707277364758
                                    ],
                                    [
                                        54.416611020468146,
                                        16.175707277364758
                                    ]
                                ],
                                [
                                    [
                                        54.40288579579748,
                                        16.238792825690453
                                    ],
                                    [
                                        54.529011921923605,
                                        16.238792825690453
                                    ],
                                    [
                                        54.529011921923605,
                                        16.11266669956433
                                    ],
                                    [
                                        54.40288579579748,
                                        16.11266669956433
                                    ]
                                ],
                                [
                                    [
                                        54.38916663348165,
                                        16.175751585528772
                                    ],
                                    [
                                        54.515292759607775,
                                        16.175751585528772
                                    ],
                                    [
                                        54.515292759607775,
                                        16.049625459402648
                                    ],
                                    [
                                        54.38916663348165,
                                        16.049625459402648
                                    ]
                                ],
                                [
                                    [
                                        54.37545350625437,
                                        16.112709686763342
                                    ],
                                    [
                                        54.50157963238049,
                                        16.112709686763342
                                    ],
                                    [
                                        54.50157963238049,
                                        15.986583560637216
                                    ],
                                    [
                                        54.37545350625437,
                                        15.986583560637216
                                    ]
                                ],
                                [
                                    [
                                        45.097700533651775,
                                        9.962910452213494
                                    ],
                                    [
                                        45.2238266597779,
                                        9.962910452213494
                                    ],
                                    [
                                        45.2238266597779,
                                        9.836784326087367
                                    ],
                                    [
                                        45.097700533651775,
                                        9.836784326087367
                                    ]
                                ],
                                [
                                    [
                                        45.08442522467638,
                                        10.02611619958894
                                    ],
                                    [
                                        45.210551350802504,
                                        10.02611619958894
                                    ],
                                    [
                                        45.210551350802504,
                                        9.899990073462812
                                    ],
                                    [
                                        45.08442522467638,
                                        9.899990073462812
                                    ]
                                ],
                                [
                                    [
                                        45.071146419692575,
                                        10.089321441789613
                                    ],
                                    [
                                        45.1972725458187,
                                        10.089321441789613
                                    ],
                                    [
                                        45.1972725458187,
                                        9.963195315663485
                                    ],
                                    [
                                        45.071146419692575,
                                        9.963195315663485
                                    ]
                                ],
                                [
                                    [
                                        45.05786409608394,
                                        10.152526175303864
                                    ],
                                    [
                                        45.18399022221006,
                                        10.152526175303864
                                    ],
                                    [
                                        45.18399022221006,
                                        10.026400049177736
                                    ],
                                    [
                                        45.05786409608394,
                                        10.026400049177736
                                    ]
                                ],
                                [
                                    [
                                        45.04457822857227,
                                        10.215730396619094
                                    ],
                                    [
                                        45.170704354698394,
                                        10.215730396619094
                                    ],
                                    [
                                        45.170704354698394,
                                        10.089604270492966
                                    ],
                                    [
                                        45.04457822857227,
                                        10.089604270492966
                                    ]
                                ],
                                [
                                    [
                                        45.03128879447393,
                                        10.278934102221744
                                    ],
                                    [
                                        45.157414920600054,
                                        10.278934102221744
                                    ],
                                    [
                                        45.157414920600054,
                                        10.152807976095616
                                    ],
                                    [
                                        45.03128879447393,
                                        10.152807976095616
                                    ]
                                ],
                                [
                                    [
                                        45.01799576843817,
                                        10.342137288597293
                                    ],
                                    [
                                        45.14412189456429,
                                        10.342137288597293
                                    ],
                                    [
                                        45.14412189456429,
                                        10.216011162471165
                                    ],
                                    [
                                        45.01799576843817,
                                        10.216011162471165
                                    ]
                                ],
                                [
                                    [
                                        45.004699127711525,
                                        10.405339952230264
                                    ],
                                    [
                                        45.13082525383765,
                                        10.405339952230264
                                    ],
                                    [
                                        45.13082525383765,
                                        10.279213826104137
                                    ],
                                    [
                                        45.004699127711525,
                                        10.279213826104137
                                    ]
                                ],
                                [
                                    [
                                        44.99139884686972,
                                        10.468542089604174
                                    ],
                                    [
                                        45.117524972995845,
                                        10.468542089604174
                                    ],
                                    [
                                        45.117524972995845,
                                        10.342415963478047
                                    ],
                                    [
                                        44.99139884686972,
                                        10.342415963478047
                                    ]
                                ],
                                [
                                    [
                                        44.978094903083516,
                                        10.531743697201565
                                    ],
                                    [
                                        45.10422102920964,
                                        10.531743697201565
                                    ],
                                    [
                                        45.10422102920964,
                                        10.405617571075437
                                    ],
                                    [
                                        44.978094903083516,
                                        10.405617571075437
                                    ]
                                ],
                                [
                                    [
                                        44.96478727085882,
                                        10.594944771503993
                                    ],
                                    [
                                        45.090913396984945,
                                        10.594944771503993
                                    ],
                                    [
                                        45.090913396984945,
                                        10.468818645377866
                                    ],
                                    [
                                        44.96478727085882,
                                        10.468818645377866
                                    ]
                                ],
                                [
                                    [
                                        44.95147592729296,
                                        10.658145308991994
                                    ],
                                    [
                                        45.077602053419085,
                                        10.658145308991994
                                    ],
                                    [
                                        45.077602053419085,
                                        10.532019182865866
                                    ],
                                    [
                                        44.95147592729296,
                                        10.532019182865866
                                    ]
                                ],
                                [
                                    [
                                        44.938160846819414,
                                        10.721345306145073
                                    ],
                                    [
                                        45.06428697294554,
                                        10.721345306145073
                                    ],
                                    [
                                        45.06428697294554,
                                        10.595219180018946
                                    ],
                                    [
                                        44.938160846819414,
                                        10.595219180018946
                                    ]
                                ],
                                [
                                    [
                                        44.9248420064641,
                                        10.784544759441724
                                    ],
                                    [
                                        45.05096813259022,
                                        10.784544759441724
                                    ],
                                    [
                                        45.05096813259022,
                                        10.658418633315597
                                    ],
                                    [
                                        44.9248420064641,
                                        10.658418633315597
                                    ]
                                ],
                                [
                                    [
                                        44.911519380574035,
                                        10.847743665359424
                                    ],
                                    [
                                        45.03764550670016,
                                        10.847743665359424
                                    ],
                                    [
                                        45.03764550670016,
                                        10.721617539233296
                                    ],
                                    [
                                        44.911519380574035,
                                        10.721617539233296
                                    ]
                                ],
                                [
                                    [
                                        44.898192946107635,
                                        10.910942020374574
                                    ],
                                    [
                                        45.02431907223376,
                                        10.910942020374574
                                    ],
                                    [
                                        45.02431907223376,
                                        10.784815894248446
                                    ],
                                    [
                                        44.898192946107635,
                                        10.784815894248446
                                    ]
                                ],
                                [
                                    [
                                        44.88486267733572,
                                        10.974139820962554
                                    ],
                                    [
                                        45.01098880346184,
                                        10.974139820962554
                                    ],
                                    [
                                        45.01098880346184,
                                        10.848013694836427
                                    ],
                                    [
                                        44.88486267733572,
                                        10.848013694836427
                                    ]
                                ],
                                [
                                    [
                                        44.87152855113351,
                                        11.037337063597654
                                    ],
                                    [
                                        44.99765467725963,
                                        11.037337063597654
                                    ],
                                    [
                                        44.99765467725963,
                                        10.911210937471527
                                    ],
                                    [
                                        44.87152855113351,
                                        10.911210937471527
                                    ]
                                ],
                                [
                                    [
                                        44.85819054170427,
                                        11.100533744753085
                                    ],
                                    [
                                        44.98431666783039,
                                        11.100533744753085
                                    ],
                                    [
                                        44.98431666783039,
                                        10.974407618626957
                                    ],
                                    [
                                        44.85819054170427,
                                        10.974407618626957
                                    ]
                                ],
                                [
                                    [
                                        44.84484862583897,
                                        11.163729860901004
                                    ],
                                    [
                                        44.970974751965095,
                                        11.163729860901004
                                    ],
                                    [
                                        44.970974751965095,
                                        11.037603734774876
                                    ],
                                    [
                                        44.84484862583897,
                                        11.037603734774876
                                    ]
                                ],
                                [
                                    [
                                        44.83150277766257,
                                        11.226925408512454
                                    ],
                                    [
                                        44.957628903788695,
                                        11.226925408512454
                                    ],
                                    [
                                        44.957628903788695,
                                        11.100799282386326
                                    ],
                                    [
                                        44.83150277766257,
                                        11.100799282386326
                                    ]
                                ],
                                [
                                    [
                                        44.818152973892005,
                                        11.290120384057383
                                    ],
                                    [
                                        44.94427910001813,
                                        11.290120384057383
                                    ],
                                    [
                                        44.94427910001813,
                                        11.163994257931256
                                    ],
                                    [
                                        44.818152973892005,
                                        11.163994257931256
                                    ]
                                ],
                                [
                                    [
                                        44.80479918856799,
                                        11.353314784004654
                                    ],
                                    [
                                        44.930925314694115,
                                        11.353314784004654
                                    ],
                                    [
                                        44.930925314694115,
                                        11.227188657878527
                                    ],
                                    [
                                        44.80479918856799,
                                        11.227188657878527
                                    ]
                                ],
                                [
                                    [
                                        44.79144139832747,
                                        11.416508604821964
                                    ],
                                    [
                                        44.917567524453595,
                                        11.416508604821964
                                    ],
                                    [
                                        44.917567524453595,
                                        11.290382478695836
                                    ],
                                    [
                                        44.79144139832747,
                                        11.290382478695836
                                    ]
                                ],
                                [
                                    [
                                        44.7780795771355,
                                        11.479701842975894
                                    ],
                                    [
                                        44.904205703261624,
                                        11.479701842975894
                                    ],
                                    [
                                        44.904205703261624,
                                        11.353575716849766
                                    ],
                                    [
                                        44.7780795771355,
                                        11.353575716849766
                                    ]
                                ],
                                [
                                    [
                                        44.76471370154315,
                                        11.542894494931884
                                    ],
                                    [
                                        44.890839827669275,
                                        11.542894494931884
                                    ],
                                    [
                                        44.890839827669275,
                                        11.416768368805757
                                    ],
                                    [
                                        44.76471370154315,
                                        11.416768368805757
                                    ]
                                ],
                                [
                                    [
                                        44.75134374543387,
                                        11.606086557154255
                                    ],
                                    [
                                        44.877469871559995,
                                        11.606086557154255
                                    ],
                                    [
                                        44.877469871559995,
                                        11.479960431028127
                                    ],
                                    [
                                        44.75134374543387,
                                        11.479960431028127
                                    ]
                                ],
                                [
                                    [
                                        44.737969685279815,
                                        11.669278026106104
                                    ],
                                    [
                                        44.86409581140594,
                                        11.669278026106104
                                    ],
                                    [
                                        44.86409581140594,
                                        11.543151899979977
                                    ],
                                    [
                                        44.737969685279815,
                                        11.543151899979977
                                    ]
                                ],
                                [
                                    [
                                        44.7245914948801,
                                        11.732468898249394
                                    ],
                                    [
                                        44.850717621006225,
                                        11.732468898249394
                                    ],
                                    [
                                        44.850717621006225,
                                        11.606342772123266
                                    ],
                                    [
                                        44.7245914948801,
                                        11.606342772123266
                                    ]
                                ],
                                [
                                    [
                                        44.711209150626885,
                                        11.795659170044924
                                    ],
                                    [
                                        44.83733527675301,
                                        11.795659170044924
                                    ],
                                    [
                                        44.83733527675301,
                                        11.669533043918797
                                    ],
                                    [
                                        44.711209150626885,
                                        11.669533043918797
                                    ]
                                ],
                                [
                                    [
                                        44.69782262622732,
                                        11.858848837952253
                                    ],
                                    [
                                        44.823948752353445,
                                        11.858848837952253
                                    ],
                                    [
                                        44.823948752353445,
                                        11.732722711826126
                                    ],
                                    [
                                        44.69782262622732,
                                        11.732722711826126
                                    ]
                                ],
                                [
                                    [
                                        44.684431897994045,
                                        11.922037898429753
                                    ],
                                    [
                                        44.81055802412017,
                                        11.922037898429753
                                    ],
                                    [
                                        44.81055802412017,
                                        11.795911772303626
                                    ],
                                    [
                                        44.684431897994045,
                                        11.795911772303626
                                    ]
                                ],
                                [
                                    [
                                        44.67103693955258,
                                        11.985226347934605
                                    ],
                                    [
                                        44.7971630656787,
                                        11.985226347934605
                                    ],
                                    [
                                        44.7971630656787,
                                        11.859100221808477
                                    ],
                                    [
                                        44.67103693955258,
                                        11.859100221808477
                                    ]
                                ],
                                [
                                    [
                                        44.65763772712351,
                                        12.048414182922754
                                    ],
                                    [
                                        44.78376385324963,
                                        12.048414182922754
                                    ],
                                    [
                                        44.78376385324963,
                                        11.922288056796626
                                    ],
                                    [
                                        44.65763772712351,
                                        11.922288056796626
                                    ]
                                ],
                                [
                                    [
                                        44.64423423425116,
                                        12.111601399848874
                                    ],
                                    [
                                        44.77036036037728,
                                        12.111601399848874
                                    ],
                                    [
                                        44.77036036037728,
                                        11.985475273722747
                                    ],
                                    [
                                        44.64423423425116,
                                        11.985475273722747
                                    ]
                                ],
                                [
                                    [
                                        44.63082643641329,
                                        12.174787995166454
                                    ],
                                    [
                                        44.75695256253941,
                                        12.174787995166454
                                    ],
                                    [
                                        44.75695256253941,
                                        12.048661869040327
                                    ],
                                    [
                                        44.63082643641329,
                                        12.048661869040327
                                    ]
                                ],
                                [
                                    [
                                        44.617414309032505,
                                        12.237973965327683
                                    ],
                                    [
                                        44.74354043515863,
                                        12.237973965327683
                                    ],
                                    [
                                        44.74354043515863,
                                        12.111847839201555
                                    ],
                                    [
                                        44.617414309032505,
                                        12.111847839201555
                                    ]
                                ],
                                [
                                    [
                                        44.60399782552999,
                                        12.301159306783493
                                    ],
                                    [
                                        44.73012395165611,
                                        12.301159306783493
                                    ],
                                    [
                                        44.73012395165611,
                                        12.175033180657366
                                    ],
                                    [
                                        44.60399782552999,
                                        12.175033180657366
                                    ]
                                ],
                                [
                                    [
                                        44.590576961899565,
                                        12.364344015983544
                                    ],
                                    [
                                        44.71670308802569,
                                        12.364344015983544
                                    ],
                                    [
                                        44.71670308802569,
                                        12.238217889857417
                                    ],
                                    [
                                        44.590576961899565,
                                        12.238217889857417
                                    ]
                                ],
                                [
                                    [
                                        44.57715169147187,
                                        12.427528089376194
                                    ],
                                    [
                                        44.70327781759799,
                                        12.427528089376194
                                    ],
                                    [
                                        44.70327781759799,
                                        12.301401963250067
                                    ],
                                    [
                                        44.57715169147187,
                                        12.301401963250067
                                    ]
                                ],
                                [
                                    [
                                        44.56372199015448,
                                        12.490711523408503
                                    ],
                                    [
                                        44.6898481162806,
                                        12.490711523408503
                                    ],
                                    [
                                        44.6898481162806,
                                        12.364585397282376
                                    ],
                                    [
                                        44.56372199015448,
                                        12.364585397282376
                                    ]
                                ],
                                [
                                    [
                                        44.55028783118145,
                                        12.553894314526223
                                    ],
                                    [
                                        44.676413957307574,
                                        12.553894314526223
                                    ],
                                    [
                                        44.676413957307574,
                                        12.427768188400096
                                    ],
                                    [
                                        44.55028783118145,
                                        12.427768188400096
                                    ]
                                ],
                                [
                                    [
                                        44.536849190369686,
                                        12.617076459173784
                                    ],
                                    [
                                        44.66297531649581,
                                        12.617076459173784
                                    ],
                                    [
                                        44.66297531649581,
                                        12.490950333047657
                                    ],
                                    [
                                        44.536849190369686,
                                        12.490950333047657
                                    ]
                                ],
                                [
                                    [
                                        44.523406040868565,
                                        12.680257953794273
                                    ],
                                    [
                                        44.64953216699469,
                                        12.680257953794273
                                    ],
                                    [
                                        44.64953216699469,
                                        12.554131827668146
                                    ],
                                    [
                                        44.523406040868565,
                                        12.554131827668146
                                    ]
                                ],
                                [
                                    [
                                        44.50995835839993,
                                        12.743438794829434
                                    ],
                                    [
                                        44.63608448452605,
                                        12.743438794829434
                                    ],
                                    [
                                        44.63608448452605,
                                        12.617312668703306
                                    ],
                                    [
                                        44.50995835839993,
                                        12.617312668703306
                                    ]
                                ],
                                [
                                    [
                                        44.496506116020726,
                                        12.806618978719653
                                    ],
                                    [
                                        44.62263224214685,
                                        12.806618978719653
                                    ],
                                    [
                                        44.62263224214685,
                                        12.680492852593526
                                    ],
                                    [
                                        44.496506116020726,
                                        12.680492852593526
                                    ]
                                ],
                                [
                                    [
                                        44.4830492893614,
                                        12.869798501903924
                                    ],
                                    [
                                        44.60917541548752,
                                        12.869798501903924
                                    ],
                                    [
                                        44.60917541548752,
                                        12.743672375777797
                                    ],
                                    [
                                        44.4830492893614,
                                        12.743672375777797
                                    ]
                                ],
                                [
                                    [
                                        44.46958785138215,
                                        12.932977360819914
                                    ],
                                    [
                                        44.595713977508275,
                                        12.932977360819914
                                    ],
                                    [
                                        44.595713977508275,
                                        12.806851234693786
                                    ],
                                    [
                                        44.46958785138215,
                                        12.806851234693786
                                    ]
                                ],
                                [
                                    [
                                        44.456121777622485,
                                        12.996155551903835
                                    ],
                                    [
                                        44.58224790374861,
                                        12.996155551903835
                                    ],
                                    [
                                        44.58224790374861,
                                        12.870029425777707
                                    ],
                                    [
                                        44.456121777622485,
                                        12.870029425777707
                                    ]
                                ],
                                [
                                    [
                                        44.442651040944526,
                                        13.059333071590514
                                    ],
                                    [
                                        44.56877716707065,
                                        13.059333071590514
                                    ],
                                    [
                                        44.56877716707065,
                                        12.933206945464386
                                    ],
                                    [
                                        44.442651040944526,
                                        12.933206945464386
                                    ]
                                ],
                                [
                                    [
                                        44.42917561679083,
                                        13.122509916313374
                                    ],
                                    [
                                        44.55530174291695,
                                        13.122509916313374
                                    ],
                                    [
                                        44.55530174291695,
                                        12.996383790187247
                                    ],
                                    [
                                        44.42917561679083,
                                        12.996383790187247
                                    ]
                                ],
                                [
                                    [
                                        44.415695477935735,
                                        13.185686082504414
                                    ],
                                    [
                                        44.54182160406186,
                                        13.185686082504414
                                    ],
                                    [
                                        44.54182160406186,
                                        13.059559956378287
                                    ],
                                    [
                                        44.415695477935735,
                                        13.059559956378287
                                    ]
                                ],
                                [
                                    [
                                        44.402210599718686,
                                        13.248861566594144
                                    ],
                                    [
                                        44.52833672584481,
                                        13.248861566594144
                                    ],
                                    [
                                        44.52833672584481,
                                        13.122735440468016
                                    ],
                                    [
                                        44.402210599718686,
                                        13.122735440468016
                                    ]
                                ],
                                [
                                    [
                                        44.388720954816876,
                                        13.312036365011673
                                    ],
                                    [
                                        44.514847080943,
                                        13.312036365011673
                                    ],
                                    [
                                        44.514847080943,
                                        13.185910238885546
                                    ],
                                    [
                                        44.388720954816876,
                                        13.185910238885546
                                    ]
                                ],
                                [
                                    [
                                        44.37522651847526,
                                        13.375210474184623
                                    ],
                                    [
                                        44.50135264460138,
                                        13.375210474184623
                                    ],
                                    [
                                        44.50135264460138,
                                        13.249084348058496
                                    ],
                                    [
                                        44.37522651847526,
                                        13.249084348058496
                                    ]
                                ],
                                [
                                    [
                                        44.36172726327097,
                                        13.438383890539123
                                    ],
                                    [
                                        44.487853389397095,
                                        13.438383890539123
                                    ],
                                    [
                                        44.487853389397095,
                                        13.312257764412996
                                    ],
                                    [
                                        44.36172726327097,
                                        13.312257764412996
                                    ]
                                ],
                                [
                                    [
                                        44.34822316435007,
                                        13.501556610499835
                                    ],
                                    [
                                        44.47434929047619,
                                        13.501556610499835
                                    ],
                                    [
                                        44.47434929047619,
                                        13.375430484373707
                                    ],
                                    [
                                        44.34822316435007,
                                        13.375430484373707
                                    ]
                                ],
                                [
                                    [
                                        44.33471419419167,
                                        13.564728630489874
                                    ],
                                    [
                                        44.46084032031779,
                                        13.564728630489874
                                    ],
                                    [
                                        44.46084032031779,
                                        13.438602504363747
                                    ],
                                    [
                                        44.33471419419167,
                                        13.438602504363747
                                    ]
                                ],
                                [
                                    [
                                        44.32120032783672,
                                        13.627899946930905
                                    ],
                                    [
                                        44.447326453962845,
                                        13.627899946930905
                                    ],
                                    [
                                        44.447326453962845,
                                        13.501773820804777
                                    ],
                                    [
                                        44.32120032783672,
                                        13.501773820804777
                                    ]
                                ],
                                [
                                    [
                                        44.30768153766683,
                                        13.691070556242984
                                    ],
                                    [
                                        44.433807663792955,
                                        13.691070556242984
                                    ],
                                    [
                                        44.433807663792955,
                                        13.564944430116856
                                    ],
                                    [
                                        44.30768153766683,
                                        13.564944430116856
                                    ]
                                ],
                                [
                                    [
                                        44.29415779862478,
                                        13.754240454844684
                                    ],
                                    [
                                        44.420283924750905,
                                        13.754240454844684
                                    ],
                                    [
                                        44.420283924750905,
                                        13.628114328718556
                                    ],
                                    [
                                        44.29415779862478,
                                        13.628114328718556
                                    ]
                                ],
                                [
                                    [
                                        44.28062908298682,
                                        13.817409639153004
                                    ],
                                    [
                                        44.406755209112944,
                                        13.817409639153004
                                    ],
                                    [
                                        44.406755209112944,
                                        13.691283513026876
                                    ],
                                    [
                                        44.28062908298682,
                                        13.691283513026876
                                    ]
                                ],
                                [
                                    [
                                        44.267095365593015,
                                        13.880578105583364
                                    ],
                                    [
                                        44.39322149171914,
                                        13.880578105583364
                                    ],
                                    [
                                        44.39322149171914,
                                        13.754451979457237
                                    ],
                                    [
                                        44.267095365593015,
                                        13.754451979457237
                                    ]
                                ],
                                [
                                    [
                                        44.25355661861316,
                                        13.943745850549604
                                    ],
                                    [
                                        44.379682744739284,
                                        13.943745850549604
                                    ],
                                    [
                                        44.379682744739284,
                                        13.817619724423476
                                    ],
                                    [
                                        44.25355661861316,
                                        13.817619724423476
                                    ]
                                ],
                                [
                                    [
                                        44.24001281678657,
                                        14.006912870463983
                                    ],
                                    [
                                        44.36613894291269,
                                        14.006912870463983
                                    ],
                                    [
                                        44.36613894291269,
                                        13.880786744337856
                                    ],
                                    [
                                        44.24001281678657,
                                        13.880786744337856
                                    ]
                                ],
                                [
                                    [
                                        44.22646393218338,
                                        14.070079161737134
                                    ],
                                    [
                                        44.3525900583095,
                                        14.070079161737134
                                    ],
                                    [
                                        44.3525900583095,
                                        13.943953035611006
                                    ],
                                    [
                                        44.22646393218338,
                                        13.943953035611006
                                    ]
                                ],
                                [
                                    [
                                        44.21290993942958,
                                        14.133244720778073
                                    ],
                                    [
                                        44.3390360655557,
                                        14.133244720778073
                                    ],
                                    [
                                        44.3390360655557,
                                        14.007118594651946
                                    ],
                                    [
                                        44.21290993942958,
                                        14.007118594651946
                                    ]
                                ],
                                [
                                    [
                                        44.19935081048945,
                                        14.196409543994173
                                    ],
                                    [
                                        44.32547693661557,
                                        14.196409543994173
                                    ],
                                    [
                                        44.32547693661557,
                                        14.070283417868046
                                    ],
                                    [
                                        44.19935081048945,
                                        14.070283417868046
                                    ]
                                ],
                                [
                                    [
                                        44.18578651989072,
                                        14.259573627791184
                                    ],
                                    [
                                        44.31191264601684,
                                        14.259573627791184
                                    ],
                                    [
                                        44.31191264601684,
                                        14.133447501665056
                                    ],
                                    [
                                        44.18578651989072,
                                        14.133447501665056
                                    ]
                                ],
                                [
                                    [
                                        44.17221703948402,
                                        14.322736968573153
                                    ],
                                    [
                                        44.29834316561014,
                                        14.322736968573153
                                    ],
                                    [
                                        44.29834316561014,
                                        14.196610842447026
                                    ],
                                    [
                                        44.17221703948402,
                                        14.196610842447026
                                    ]
                                ],
                                [
                                    [
                                        44.158642343689365,
                                        14.385899562742473
                                    ],
                                    [
                                        44.28476846981549,
                                        14.385899562742473
                                    ],
                                    [
                                        44.28476846981549,
                                        14.259773436616346
                                    ],
                                    [
                                        44.158642343689365,
                                        14.259773436616346
                                    ]
                                ],
                                [
                                    [
                                        44.1450624042522,
                                        14.449061406699844
                                    ],
                                    [
                                        44.271188530378325,
                                        14.449061406699844
                                    ],
                                    [
                                        44.271188530378325,
                                        14.322935280573716
                                    ],
                                    [
                                        44.1450624042522,
                                        14.322935280573716
                                    ]
                                ],
                                [
                                    [
                                        44.13147719548192,
                                        14.512222496844243
                                    ],
                                    [
                                        44.257603321608045,
                                        14.512222496844243
                                    ],
                                    [
                                        44.257603321608045,
                                        14.386096370718116
                                    ],
                                    [
                                        44.13147719548192,
                                        14.386096370718116
                                    ]
                                ],
                                [
                                    [
                                        44.11788668901596,
                                        14.575382829572954
                                    ],
                                    [
                                        44.244012815142085,
                                        14.575382829572954
                                    ],
                                    [
                                        44.244012815142085,
                                        14.449256703446826
                                    ],
                                    [
                                        44.11788668901596,
                                        14.449256703446826
                                    ]
                                ],
                                [
                                    [
                                        44.10429085839541,
                                        14.638542401281525
                                    ],
                                    [
                                        44.23041698452153,
                                        14.638542401281525
                                    ],
                                    [
                                        44.23041698452153,
                                        14.512416275155397
                                    ],
                                    [
                                        44.10429085839541,
                                        14.512416275155397
                                    ]
                                ],
                                [
                                    [
                                        44.090689677112316,
                                        14.701701208363714
                                    ],
                                    [
                                        44.21681580323844,
                                        14.701701208363714
                                    ],
                                    [
                                        44.21681580323844,
                                        14.575575082237586
                                    ],
                                    [
                                        44.090689677112316,
                                        14.575575082237586
                                    ]
                                ],
                                [
                                    [
                                        44.07708311663061,
                                        14.764859247211565
                                    ],
                                    [
                                        44.203209242756735,
                                        14.764859247211565
                                    ],
                                    [
                                        44.203209242756735,
                                        14.638733121085437
                                    ],
                                    [
                                        44.07708311663061,
                                        14.638733121085437
                                    ]
                                ],
                                [
                                    [
                                        44.06347115098786,
                                        14.828016514215314
                                    ],
                                    [
                                        44.18959727711398,
                                        14.828016514215314
                                    ],
                                    [
                                        44.18959727711398,
                                        14.701890388089186
                                    ],
                                    [
                                        44.06347115098786,
                                        14.701890388089186
                                    ]
                                ],
                                [
                                    [
                                        44.04985375153509,
                                        14.891173005763413
                                    ],
                                    [
                                        44.17597987766121,
                                        14.891173005763413
                                    ],
                                    [
                                        44.17597987766121,
                                        14.765046879637286
                                    ],
                                    [
                                        44.04985375153509,
                                        14.765046879637286
                                    ]
                                ],
                                [
                                    [
                                        44.036230892193025,
                                        14.954328718242524
                                    ],
                                    [
                                        44.16235701831915,
                                        14.954328718242524
                                    ],
                                    [
                                        44.16235701831915,
                                        14.828202592116396
                                    ],
                                    [
                                        44.036230892193025,
                                        14.828202592116396
                                    ]
                                ],
                                [
                                    [
                                        44.02260254419836,
                                        15.017483648037464
                                    ],
                                    [
                                        44.14872867032448,
                                        15.017483648037464
                                    ],
                                    [
                                        44.14872867032448,
                                        14.891357521911337
                                    ],
                                    [
                                        44.02260254419836,
                                        14.891357521911337
                                    ]
                                ],
                                [
                                    [
                                        44.008968681358475,
                                        15.080637791531204
                                    ],
                                    [
                                        44.1350948074846,
                                        15.080637791531204
                                    ],
                                    [
                                        44.1350948074846,
                                        14.954511665405077
                                    ],
                                    [
                                        44.008968681358475,
                                        14.954511665405077
                                    ]
                                ],
                                [
                                    [
                                        43.99532927479609,
                                        15.143791145104904
                                    ],
                                    [
                                        44.121455400922216,
                                        15.143791145104904
                                    ],
                                    [
                                        44.121455400922216,
                                        15.017665018978777
                                    ],
                                    [
                                        43.99532927479609,
                                        15.017665018978777
                                    ]
                                ],
                                [
                                    [
                                        43.98168429820231,
                                        15.206943705137814
                                    ],
                                    [
                                        44.107810424328434,
                                        15.206943705137814
                                    ],
                                    [
                                        44.107810424328434,
                                        15.080817579011686
                                    ],
                                    [
                                        43.98168429820231,
                                        15.080817579011686
                                    ]
                                ],
                                [
                                    [
                                        43.96803372258121,
                                        15.270095468007323
                                    ],
                                    [
                                        44.094159848707335,
                                        15.270095468007323
                                    ],
                                    [
                                        44.094159848707335,
                                        15.143969341881196
                                    ],
                                    [
                                        43.96803372258121,
                                        15.143969341881196
                                    ]
                                ],
                                [
                                    [
                                        43.95437752150626,
                                        15.333246430088924
                                    ],
                                    [
                                        44.08050364763238,
                                        15.333246430088924
                                    ],
                                    [
                                        44.08050364763238,
                                        15.207120303962796
                                    ],
                                    [
                                        43.95437752150626,
                                        15.207120303962796
                                    ]
                                ],
                                [
                                    [
                                        43.94071566585833,
                                        15.396396587756174
                                    ],
                                    [
                                        44.06684179198445,
                                        15.396396587756174
                                    ],
                                    [
                                        44.06684179198445,
                                        15.270270461630046
                                    ],
                                    [
                                        43.94071566585833,
                                        15.270270461630046
                                    ]
                                ],
                                [
                                    [
                                        43.92704812909998,
                                        15.459545937380724
                                    ],
                                    [
                                        44.053174255226104,
                                        15.459545937380724
                                    ],
                                    [
                                        44.053174255226104,
                                        15.333419811254597
                                    ],
                                    [
                                        43.92704812909998,
                                        15.333419811254597
                                    ]
                                ],
                                [
                                    [
                                        43.913374881992425,
                                        15.522694475332283
                                    ],
                                    [
                                        44.03950100811855,
                                        15.522694475332283
                                    ],
                                    [
                                        44.03950100811855,
                                        15.396568349206156
                                    ],
                                    [
                                        43.913374881992425,
                                        15.396568349206156
                                    ]
                                ],
                                [
                                    [
                                        52.00545716250035,
                                        14.503223344257425
                                    ],
                                    [
                                        52.13158328862647,
                                        14.503223344257425
                                    ],
                                    [
                                        52.13158328862647,
                                        14.377097218131297
                                    ],
                                    [
                                        52.00545716250035,
                                        14.377097218131297
                                    ]
                                ],
                                [
                                    [
                                        51.991856606422026,
                                        14.566458164286974
                                    ],
                                    [
                                        52.11798273254815,
                                        14.566458164286974
                                    ],
                                    [
                                        52.11798273254815,
                                        14.440332038160847
                                    ],
                                    [
                                        51.991856606422026,
                                        14.440332038160847
                                    ]
                                ],
                                [
                                    [
                                        51.978250719834556,
                                        14.629692203924744
                                    ],
                                    [
                                        52.10437684596068,
                                        14.629692203924744
                                    ],
                                    [
                                        52.10437684596068,
                                        14.503566077798617
                                    ],
                                    [
                                        51.978250719834556,
                                        14.503566077798617
                                    ]
                                ],
                                [
                                    [
                                        51.96463947681786,
                                        14.692925459467064
                                    ],
                                    [
                                        52.09076560294398,
                                        14.692925459467064
                                    ],
                                    [
                                        52.09076560294398,
                                        14.566799333340937
                                    ],
                                    [
                                        51.96463947681786,
                                        14.566799333340937
                                    ]
                                ],
                                [
                                    [
                                        51.951022848765646,
                                        14.756157927208573
                                    ],
                                    [
                                        52.07714897489177,
                                        14.756157927208573
                                    ],
                                    [
                                        52.07714897489177,
                                        14.630031801082445
                                    ],
                                    [
                                        51.951022848765646,
                                        14.630031801082445
                                    ]
                                ],
                                [
                                    [
                                        51.93740080964198,
                                        14.819389603442135
                                    ],
                                    [
                                        52.0635269357681,
                                        14.819389603442135
                                    ],
                                    [
                                        52.0635269357681,
                                        14.693263477316007
                                    ],
                                    [
                                        51.93740080964198,
                                        14.693263477316007
                                    ]
                                ],
                                [
                                    [
                                        51.92377333072745,
                                        14.882620484458844
                                    ],
                                    [
                                        52.049899456853574,
                                        14.882620484458844
                                    ],
                                    [
                                        52.049899456853574,
                                        14.756494358332716
                                    ],
                                    [
                                        51.92377333072745,
                                        14.756494358332716
                                    ]
                                ],
                                [
                                    [
                                        51.91014038587702,
                                        14.945850566548025
                                    ],
                                    [
                                        52.036266512003145,
                                        14.945850566548025
                                    ],
                                    [
                                        52.036266512003145,
                                        14.819724440421897
                                    ],
                                    [
                                        51.91014038587702,
                                        14.819724440421897
                                    ]
                                ],
                                [
                                    [
                                        51.89650194625177,
                                        15.009079845997233
                                    ],
                                    [
                                        52.02262807237789,
                                        15.009079845997233
                                    ],
                                    [
                                        52.02262807237789,
                                        14.882953719871105
                                    ],
                                    [
                                        51.89650194625177,
                                        14.882953719871105
                                    ]
                                ],
                                [
                                    [
                                        51.882857985591436,
                                        15.072308319092214
                                    ],
                                    [
                                        52.00898411171756,
                                        15.072308319092214
                                    ],
                                    [
                                        52.00898411171756,
                                        14.946182192966086
                                    ],
                                    [
                                        51.882857985591436,
                                        14.946182192966086
                                    ]
                                ],
                                [
                                    [
                                        51.869208474946085,
                                        15.135535982116854
                                    ],
                                    [
                                        51.99533460107221,
                                        15.135535982116854
                                    ],
                                    [
                                        51.99533460107221,
                                        15.009409855990727
                                    ],
                                    [
                                        51.869208474946085,
                                        15.009409855990727
                                    ]
                                ],
                                [
                                    [
                                        51.85555338793396,
                                        15.198762831353253
                                    ],
                                    [
                                        51.98167951406008,
                                        15.198762831353253
                                    ],
                                    [
                                        51.98167951406008,
                                        15.072636705227126
                                    ],
                                    [
                                        51.85555338793396,
                                        15.072636705227126
                                    ]
                                ],
                                [
                                    [
                                        51.841892695489435,
                                        15.261988863081633
                                    ],
                                    [
                                        51.96801882161556,
                                        15.261988863081633
                                    ],
                                    [
                                        51.96801882161556,
                                        15.135862736955506
                                    ],
                                    [
                                        51.841892695489435,
                                        15.135862736955506
                                    ]
                                ],
                                [
                                    [
                                        51.82822637111442,
                                        15.325214073580364
                                    ],
                                    [
                                        51.95435249724054,
                                        15.325214073580364
                                    ],
                                    [
                                        51.95435249724054,
                                        15.199087947454236
                                    ],
                                    [
                                        51.82822637111442,
                                        15.199087947454236
                                    ]
                                ],],
                                swathWidth: 10.0,
                                length: 15.0,
                                areaCovered: 100.0,
                                captureTime: "2024-10-03T08:00:00Z",
                                cloudCoverage: 5.0,
                                resolution: 1.0
                            },
                            satellite_operations: [
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 1",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 2",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                }
                            ],
                        },
                        {
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_003",
                                name: "Satellite Alpha 3",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            strip: {
                                id: "strip_003",
                                featureId: "feat_003",
                                coordinates: [
                                    [
                                        [
                                            51.81455438561818,
                                            15.388438459125924
                                        ],
                                        [
                                            51.940680511744304,
                                            15.388438459125924
                                        ],
                                        [
                                            51.940680511744304,
                                            15.262312332999796
                                        ],
                                        [
                                            51.81455438561818,
                                            15.262312332999796
                                        ]
                                    ],
                                    [
                                        [
                                            51.80087671239302,
                                            15.451662015992904
                                        ],
                                        [
                                            51.927002838519144,
                                            15.451662015992904
                                        ],
                                        [
                                            51.927002838519144,
                                            15.325535889866776
                                        ],
                                        [
                                            51.80087671239302,
                                            15.325535889866776
                                        ]
                                    ],
                                    [
                                        [
                                            51.787193322123265,
                                            15.514884740453963
                                        ],
                                        [
                                            51.91331944824939,
                                            15.514884740453963
                                        ],
                                        [
                                            51.91331944824939,
                                            15.388758614327836
                                        ],
                                        [
                                            51.787193322123265,
                                            15.388758614327836
                                        ]
                                    ],
                                    [
                                        [
                                            51.77350418807569,
                                            15.578106628779853
                                        ],
                                        [
                                            51.899630314201815,
                                            15.578106628779853
                                        ],
                                        [
                                            51.899630314201815,
                                            15.451980502653726
                                        ],
                                        [
                                            51.77350418807569,
                                            15.451980502653726
                                        ]
                                    ],
                                    [
                                        [
                                            51.75980928082128,
                                            15.641327677239383
                                        ],
                                        [
                                            51.885935406947404,
                                            15.641327677239383
                                        ],
                                        [
                                            51.885935406947404,
                                            15.515201551113256
                                        ],
                                        [
                                            51.75980928082128,
                                            15.515201551113256
                                        ]
                                    ],
                                    [
                                        [
                                            51.74610857350146,
                                            15.704547882099394
                                        ],
                                        [
                                            51.87223469962758,
                                            15.704547882099394
                                        ],
                                        [
                                            51.87223469962758,
                                            15.578421755973267
                                        ],
                                        [
                                            51.74610857350146,
                                            15.578421755973267
                                        ]
                                    ],
                                    [
                                        [
                                            51.73240203656444,
                                            15.767767239624733
                                        ],
                                        [
                                            51.85852816269056,
                                            15.767767239624733
                                        ],
                                        [
                                            51.85852816269056,
                                            15.641641113498606
                                        ],
                                        [
                                            51.73240203656444,
                                            15.641641113498606
                                        ]
                                    ],
                                    [
                                        [
                                            51.718689643029705,
                                            15.830985746078284
                                        ],
                                        [
                                            51.84481576915583,
                                            15.830985746078284
                                        ],
                                        [
                                            51.84481576915583,
                                            15.704859619952156
                                        ],
                                        [
                                            51.718689643029705,
                                            15.704859619952156
                                        ]
                                    ],
                                    [
                                        [
                                            51.704971363216345,
                                            15.894203397720904
                                        ],
                                        [
                                            51.83109748934247,
                                            15.894203397720904
                                        ],
                                        [
                                            51.83109748934247,
                                            15.768077271594777
                                        ],
                                        [
                                            51.704971363216345,
                                            15.768077271594777
                                        ]
                                    ],
                                    [
                                        [
                                            51.69124717002223,
                                            15.957420190811433
                                        ],
                                        [
                                            51.817373296148354,
                                            15.957420190811433
                                        ],
                                        [
                                            51.817373296148354,
                                            15.831294064685306
                                        ],
                                        [
                                            51.69124717002223,
                                            15.831294064685306
                                        ]
                                    ],
                                    [
                                        [
                                            51.677517033644065,
                                            16.020636121606664
                                        ],
                                        [
                                            51.80364315977019,
                                            16.020636121606664
                                        ],
                                        [
                                            51.80364315977019,
                                            15.894509995480536
                                        ],
                                        [
                                            51.677517033644065,
                                            15.894509995480536
                                        ]
                                    ],
                                    [
                                        [
                                            51.66378092685006,
                                            16.08385118636131
                                        ],
                                        [
                                            51.78990705297618,
                                            16.08385118636131
                                        ],
                                        [
                                            51.78990705297618,
                                            15.957725060235186
                                        ],
                                        [
                                            51.66378092685006,
                                            15.957725060235186
                                        ]
                                    ],
                                    [
                                        [
                                            51.650038819709835,
                                            16.147065381328062
                                        ],
                                        [
                                            51.77616494583596,
                                            16.147065381328062
                                        ],
                                        [
                                            51.77616494583596,
                                            16.020939255201938
                                        ],
                                        [
                                            51.650038819709835,
                                            16.020939255201938
                                        ]
                                    ],
                                    [
                                        [
                                            51.636290684204326,
                                            16.21027870275747
                                        ],
                                        [
                                            51.76241681033045,
                                            16.21027870275747
                                        ],
                                        [
                                            51.76241681033045,
                                            16.084152576631347
                                        ],
                                        [
                                            51.636290684204326,
                                            16.084152576631347
                                        ]
                                    ],
                                    [
                                        [
                                            51.62253649224664,
                                            16.273491146898003
                                        ],
                                        [
                                            51.74866261837276,
                                            16.273491146898003
                                        ],
                                        [
                                            51.74866261837276,
                                            16.14736502077188
                                        ],
                                        [
                                            51.62253649224664,
                                            16.14736502077188
                                        ]
                                    ],
                                    [
                                        [
                                            51.60877621372127,
                                            16.336702709995983
                                        ],
                                        [
                                            51.734902339847395,
                                            16.336702709995983
                                        ],
                                        [
                                            51.734902339847395,
                                            16.21057658386986
                                        ],
                                        [
                                            51.60877621372127,
                                            16.21057658386986
                                        ]
                                    ],
                                    [
                                        [
                                            51.59500982106603,
                                            16.39991338829559
                                        ],
                                        [
                                            51.72113594719215,
                                            16.39991338829559
                                        ],
                                        [
                                            51.72113594719215,
                                            16.273787262169467
                                        ],
                                        [
                                            51.59500982106603,
                                            16.273787262169467
                                        ]
                                    ],
                                    [
                                        [
                                            51.58123728403473,
                                            16.463123178038863
                                        ],
                                        [
                                            51.70736341016085,
                                            16.463123178038863
                                        ],
                                        [
                                            51.70736341016085,
                                            16.33699705191274
                                        ],
                                        [
                                            51.58123728403473,
                                            16.33699705191274
                                        ]
                                    ],
                                    [
                                        [
                                            51.56745857493857,
                                            16.52633207546562
                                        ],
                                        [
                                            51.693584701064694,
                                            16.52633207546562
                                        ],
                                        [
                                            51.693584701064694,
                                            16.400205949339497
                                        ],
                                        [
                                            51.56745857493857,
                                            16.400205949339497
                                        ]
                                    ],
                                    [
                                        [
                                            51.55367366338939,
                                            16.589540076813552
                                        ],
                                        [
                                            51.67979978951551,
                                            16.589540076813552
                                        ],
                                        [
                                            51.67979978951551,
                                            16.46341395068743
                                        ],
                                        [
                                            51.55367366338939,
                                            16.46341395068743
                                        ]
                                    ],
                                    [
                                        [
                                            51.539882521565495,
                                            16.65274717831804
                                        ],
                                        [
                                            51.66600864769162,
                                            16.65274717831804
                                        ],
                                        [
                                            51.66600864769162,
                                            16.526621052191917
                                        ],
                                        [
                                            51.539882521565495,
                                            16.526621052191917
                                        ]
                                    ],
                                    [
                                        [
                                            51.526085118954775,
                                            16.715953376212333
                                        ],
                                        [
                                            51.6522112450809,
                                            16.715953376212333
                                        ],
                                        [
                                            51.6522112450809,
                                            16.58982725008621
                                        ],
                                        [
                                            51.526085118954775,
                                            16.58982725008621
                                        ]
                                    ],
                                    [
                                        [
                                            51.5122814275963,
                                            16.77915866672734
                                        ],
                                        [
                                            51.63840755372242,
                                            16.77915866672734
                                        ],
                                        [
                                            51.63840755372242,
                                            16.653032540601217
                                        ],
                                        [
                                            51.5122814275963,
                                            16.653032540601217
                                        ]
                                    ],
                                    [
                                        [
                                            51.498471416844346,
                                            16.842363046091762
                                        ],
                                        [
                                            51.62459754297047,
                                            16.842363046091762
                                        ],
                                        [
                                            51.62459754297047,
                                            16.716236919965638
                                        ],
                                        [
                                            51.498471416844346,
                                            16.716236919965638
                                        ]
                                    ],
                                    [
                                        [
                                            51.484655058598825,
                                            16.905566510531983
                                        ],
                                        [
                                            51.61078118472495,
                                            16.905566510531983
                                        ],
                                        [
                                            51.61078118472495,
                                            16.77944038440586
                                        ],
                                        [
                                            51.484655058598825,
                                            16.77944038440586
                                        ]
                                    ],
                                    [
                                        [
                                            51.470832322078955,
                                            16.9687690562721
                                        ],
                                        [
                                            51.59695844820508,
                                            16.9687690562721
                                        ],
                                        [
                                            51.59695844820508,
                                            16.842642930145978
                                        ],
                                        [
                                            51.470832322078955,
                                            16.842642930145978
                                        ]
                                    ],
                                    [
                                        [
                                            51.45700317904717,
                                            17.03197067953387
                                        ],
                                        [
                                            51.583129305173294,
                                            17.03197067953387
                                        ],
                                        [
                                            51.583129305173294,
                                            16.905844553407746
                                        ],
                                        [
                                            51.45700317904717,
                                            16.905844553407746
                                        ]
                                    ],
                                    [
                                        [
                                            51.44316759858286,
                                            17.09517137653671
                                        ],
                                        [
                                            51.569293724708984,
                                            17.09517137653671
                                        ],
                                        [
                                            51.569293724708984,
                                            16.969045250410588
                                        ],
                                        [
                                            51.44316759858286,
                                            16.969045250410588
                                        ]
                                    ],
                                    [
                                        [
                                            51.42932555231274,
                                            17.158371143497693
                                        ],
                                        [
                                            51.555451678438864,
                                            17.158371143497693
                                        ],
                                        [
                                            51.555451678438864,
                                            17.03224501737157
                                        ],
                                        [
                                            51.42932555231274,
                                            17.03224501737157
                                        ]
                                    ],
                                    [
                                        [
                                            51.415477009176435,
                                            17.22156997663147
                                        ],
                                        [
                                            51.54160313530256,
                                            17.22156997663147
                                        ],
                                        [
                                            51.54160313530256,
                                            17.095443850505347
                                        ],
                                        [
                                            51.415477009176435,
                                            17.095443850505347
                                        ]
                                    ],
                                    [
                                        [
                                            51.401621940656916,
                                            17.28476787215035
                                        ],
                                        [
                                            51.52774806678304,
                                            17.28476787215035
                                        ],
                                        [
                                            51.52774806678304,
                                            17.158641746024227
                                        ],
                                        [
                                            51.401621940656916,
                                            17.158641746024227
                                        ]
                                    ],
                                    [
                                        [
                                            51.38776031555088,
                                            17.347964826264153
                                        ],
                                        [
                                            51.513886441677,
                                            17.347964826264153
                                        ],
                                        [
                                            51.513886441677,
                                            17.22183870013803
                                        ],
                                        [
                                            51.38776031555088,
                                            17.22183870013803
                                        ]
                                    ],
                                    [
                                        [
                                            51.373892105205606,
                                            17.411160835180333
                                        ],
                                        [
                                            51.50001823133173,
                                            17.411160835180333
                                        ],
                                        [
                                            51.50001823133173,
                                            17.28503470905421
                                        ],
                                        [
                                            51.373892105205606,
                                            17.28503470905421
                                        ]
                                    ],
                                    [
                                        [
                                            51.36001727827342,
                                            17.47435589510383
                                        ],
                                        [
                                            51.48614340439954,
                                            17.47435589510383
                                        ],
                                        [
                                            51.48614340439954,
                                            17.348229768977706
                                        ],
                                        [
                                            51.36001727827342,
                                            17.348229768977706
                                        ]
                                    ],
                                    [
                                        [
                                            51.34613580595631,
                                            17.537550002237133
                                        ],
                                        [
                                            51.47226193208243,
                                            17.537550002237133
                                        ],
                                        [
                                            51.47226193208243,
                                            17.41142387611101
                                        ],
                                        [
                                            51.34613580595631,
                                            17.41142387611101
                                        ]
                                    ],
                                    [
                                        [
                                            51.332247656760465,
                                            17.600743152780222
                                        ],
                                        [
                                            51.45837378288659,
                                            17.600743152780222
                                        ],
                                        [
                                            51.45837378288659,
                                            17.474617026654098
                                        ],
                                        [
                                            51.332247656760465,
                                            17.474617026654098
                                        ]
                                    ],
                                    [
                                        [
                                            51.31835280174265,
                                            17.66393534293057
                                        ],
                                        [
                                            51.44447892786877,
                                            17.66393534293057
                                        ],
                                        [
                                            51.44447892786877,
                                            17.537809216804447
                                        ],
                                        [
                                            51.31835280174265,
                                            17.537809216804447
                                        ]
                                    ],
                                    [
                                        [
                                            51.30445120926953,
                                            17.7271265688831
                                        ],
                                        [
                                            51.430577335395654,
                                            17.7271265688831
                                        ],
                                        [
                                            51.430577335395654,
                                            17.601000442756977
                                        ],
                                        [
                                            51.30445120926953,
                                            17.601000442756977
                                        ]
                                    ],
                                    [
                                        [
                                            51.290542850242915,
                                            17.790316826830193
                                        ],
                                        [
                                            51.41666897636904,
                                            17.790316826830193
                                        ],
                                        [
                                            51.41666897636904,
                                            17.66419070070407
                                        ],
                                        [
                                            51.290542850242915,
                                            17.66419070070407
                                        ]
                                    ],
                                    [
                                        [
                                            51.27662769288181,
                                            17.853506112961643
                                        ],
                                        [
                                            51.402753819007934,
                                            17.853506112961643
                                        ],
                                        [
                                            51.402753819007934,
                                            17.72737998683552
                                        ],
                                        [
                                            51.27662769288181,
                                            17.72737998683552
                                        ]
                                    ],
                                    [
                                        [
                                            51.262705707947696,
                                            17.91669442346465
                                        ],
                                        [
                                            51.38883183407382,
                                            17.91669442346465
                                        ],
                                        [
                                            51.38883183407382,
                                            17.790568297338528
                                        ],
                                        [
                                            51.262705707947696,
                                            17.790568297338528
                                        ]
                                    ],
                                    [
                                        [
                                            51.2487768635038,
                                            17.97988175452378
                                        ],
                                        [
                                            51.37490298962992,
                                            17.97988175452378
                                        ],
                                        [
                                            51.37490298962992,
                                            17.853755628397657
                                        ],
                                        [
                                            51.2487768635038,
                                            17.853755628397657
                                        ]
                                    ],
                                    [
                                        [
                                            51.23484113016315,
                                            18.043068102320973
                                        ],
                                        [
                                            51.36096725628927,
                                            18.043068102320973
                                        ],
                                        [
                                            51.36096725628927,
                                            17.91694197619485
                                        ],
                                        [
                                            51.23484113016315,
                                            17.91694197619485
                                        ]
                                    ],
                                    [
                                        [
                                            51.22089847583809,
                                            18.106253463035493
                                        ],
                                        [
                                            51.347024601964215,
                                            18.106253463035493
                                        ],
                                        [
                                            51.347024601964215,
                                            17.98012733690937
                                        ],
                                        [
                                            51.22089847583809,
                                            17.98012733690937
                                        ]
                                    ],
                                    [
                                        [
                                            51.20694887098827,
                                            18.16943783284395
                                        ],
                                        [
                                            51.333074997114394,
                                            18.16943783284395
                                        ],
                                        [
                                            51.333074997114394,
                                            18.043311706717827
                                        ],
                                        [
                                            51.20694887098827,
                                            18.043311706717827
                                        ]
                                    ],
                                    [
                                        [
                                            51.19299228337839,
                                            18.23262120792022
                                        ],
                                        [
                                            51.31911840950451,
                                            18.23262120792022
                                        ],
                                        [
                                            51.31911840950451,
                                            18.106495081794098
                                        ],
                                        [
                                            51.19299228337839,
                                            18.106495081794098
                                        ]
                                    ],
                                    [
                                        [
                                            51.179028683309845,
                                            18.295803584435482
                                        ],
                                        [
                                            51.30515480943597,
                                            18.295803584435482
                                        ],
                                        [
                                            51.30515480943597,
                                            18.16967745830936
                                        ],
                                        [
                                            51.179028683309845,
                                            18.16967745830936
                                        ]
                                    ],
                                    [
                                        [
                                            51.16505803839308,
                                            18.358984958558153
                                        ],
                                        [
                                            51.2911841645192,
                                            18.358984958558153
                                        ],
                                        [
                                            51.2911841645192,
                                            18.23285883243203
                                        ],
                                        [
                                            51.16505803839308,
                                            18.23285883243203
                                        ]
                                    ],
                                    [
                                        [
                                            51.15108031877771,
                                            18.42216532645387
                                        ],
                                        [
                                            51.27720644490383,
                                            18.42216532645387
                                        ],
                                        [
                                            51.27720644490383,
                                            18.296039200327748
                                        ],
                                        [
                                            51.15108031877771,
                                            18.296039200327748
                                        ]
                                    ],
                                    [
                                        [
                                            51.13709549191666,
                                            18.48534468428551
                                        ],
                                        [
                                            51.263221618042785,
                                            18.48534468428551
                                        ],
                                        [
                                            51.263221618042785,
                                            18.359218558159387
                                        ],
                                        [
                                            51.13709549191666,
                                            18.359218558159387
                                        ]
                                    ],
                                    [
                                        [
                                            51.12310352780448,
                                            18.548523028213133
                                        ],
                                        [
                                            51.249229653930605,
                                            18.548523028213133
                                        ],
                                        [
                                            51.249229653930605,
                                            18.42239690208701
                                        ],
                                        [
                                            51.12310352780448,
                                            18.42239690208701
                                        ]
                                    ],
                                    [
                                        [
                                            51.109104393733205,
                                            18.611700354393903
                                        ],
                                        [
                                            51.23523051985933,
                                            18.611700354393903
                                        ],
                                        [
                                            51.23523051985933,
                                            18.48557422826778
                                        ],
                                        [
                                            51.109104393733205,
                                            18.48557422826778
                                        ]
                                    ],
                                    [
                                        [
                                            51.09509805954219,
                                            18.674876658982242
                                        ],
                                        [
                                            51.22122418566831,
                                            18.674876658982242
                                        ],
                                        [
                                            51.22122418566831,
                                            18.54875053285612
                                        ],
                                        [
                                            51.09509805954219,
                                            18.54875053285612
                                        ]
                                    ],
                                    [
                                        [
                                            51.08108449235771,
                                            18.7380519381296
                                        ],
                                        [
                                            51.207210618483835,
                                            18.7380519381296
                                        ],
                                        [
                                            51.207210618483835,
                                            18.611925812003477
                                        ],
                                        [
                                            51.08108449235771,
                                            18.611925812003477
                                        ]
                                    ],
                                    [
                                        [
                                            51.067063661207555,
                                            18.80122618798456
                                        ],
                                        [
                                            51.19318978733368,
                                            18.80122618798456
                                        ],
                                        [
                                            51.19318978733368,
                                            18.675100061858437
                                        ],
                                        [
                                            51.067063661207555,
                                            18.675100061858437
                                        ]
                                    ],
                                    [
                                        [
                                            51.05303553503392,
                                            18.864399404692783
                                        ],
                                        [
                                            51.179161661160045,
                                            18.864399404692783
                                        ],
                                        [
                                            51.179161661160045,
                                            18.73827327856666
                                        ],
                                        [
                                            51.05303553503392,
                                            18.73827327856666
                                        ]
                                    ],
                                    [
                                        [
                                            51.03900008072089,
                                            18.927571584396983
                                        ],
                                        [
                                            51.16512620684701,
                                            18.927571584396983
                                        ],
                                        [
                                            51.16512620684701,
                                            18.80144545827086
                                        ],
                                        [
                                            51.03900008072089,
                                            18.80144545827086
                                        ]
                                    ],
                                    [
                                        [
                                            51.02495726770904,
                                            18.990742723236902
                                        ],
                                        [
                                            51.151083393835165,
                                            18.990742723236902
                                        ],
                                        [
                                            51.151083393835165,
                                            18.86461659711078
                                        ],
                                        [
                                            51.02495726770904,
                                            18.86461659711078
                                        ]
                                    ],
                                    [
                                        [
                                            51.010907062718815,
                                            19.053912817349282
                                        ],
                                        [
                                            51.13703318884494,
                                            19.053912817349282
                                        ],
                                        [
                                            51.13703318884494,
                                            18.927786691223158
                                        ],
                                        [
                                            51.010907062718815,
                                            18.927786691223158
                                        ]
                                    ],
                                    [
                                        [
                                            50.99684943502471,
                                            19.117081862867852
                                        ],
                                        [
                                            51.12297556115083,
                                            19.117081862867852
                                        ],
                                        [
                                            51.12297556115083,
                                            18.99095573674173
                                        ],
                                        [
                                            50.99684943502471,
                                            18.99095573674173
                                        ]
                                    ],
                                    [
                                        [
                                            50.98278435118494,
                                            19.180249855923332
                                        ],
                                        [
                                            51.10891047731106,
                                            19.180249855923332
                                        ],
                                        [
                                            51.10891047731106,
                                            19.054123729797208
                                        ],
                                        [
                                            50.98278435118494,
                                            19.054123729797208
                                        ]
                                    ],
                                    [
                                        [
                                            50.968711780307736,
                                            19.243416792643313
                                        ],
                                        [
                                            51.09483790643386,
                                            19.243416792643313
                                        ],
                                        [
                                            51.09483790643386,
                                            19.11729066651719
                                        ],
                                        [
                                            50.968711780307736,
                                            19.11729066651719
                                        ]
                                    ],
                                    [
                                        [
                                            50.954631688780935,
                                            19.306582669152352
                                        ],
                                        [
                                            51.08075781490706,
                                            19.306582669152352
                                        ],
                                        [
                                            51.08075781490706,
                                            19.180456543026228
                                        ],
                                        [
                                            50.954631688780935,
                                            19.180456543026228
                                        ]
                                    ],
                                    [
                                        [
                                            50.94054404554462,
                                            19.36974748157188
                                        ],
                                        [
                                            51.066670171670744,
                                            19.36974748157188
                                        ],
                                        [
                                            51.066670171670744,
                                            19.243621355445757
                                        ],
                                        [
                                            50.94054404554462,
                                            19.243621355445757
                                        ]
                                    ],
                                    [
                                        [
                                            50.926448816822585,
                                            19.43291122602019
                                        ],
                                        [
                                            51.05257494294871,
                                            19.43291122602019
                                        ],
                                        [
                                            51.05257494294871,
                                            19.306785099894068
                                        ],
                                        [
                                            50.926448816822585,
                                            19.306785099894068
                                        ]
                                    ],
                                    [
                                        [
                                            50.91234597138013,
                                            19.496073898612433
                                        ],
                                        [
                                            51.03847209750625,
                                            19.496073898612433
                                        ],
                                        [
                                            51.03847209750625,
                                            19.36994777248631
                                        ],
                                        [
                                            50.91234597138013,
                                            19.36994777248631
                                        ]
                                    ],
                                    [
                                        [
                                            50.89823547527024,
                                            19.55923549546054
                                        ],
                                        [
                                            51.024361601396365,
                                            19.55923549546054
                                        ],
                                        [
                                            51.024361601396365,
                                            19.433109369334417
                                        ],
                                        [
                                            50.89823547527024,
                                            19.433109369334417
                                        ]
                                    ],
                                    [
                                        [
                                            50.88411729709305,
                                            19.622396012673242
                                        ],
                                        [
                                            51.01024342321917,
                                            19.622396012673242
                                        ],
                                        [
                                            51.01024342321917,
                                            19.49626988654712
                                        ],
                                        [
                                            50.88411729709305,
                                            19.49626988654712
                                        ]
                                    ],
                                    [
                                        [
                                            51.9894195265005,
                                            20.295491321736662
                                        ],
                                        [
                                            52.11554565262662,
                                            20.295491321736662
                                        ],
                                        [
                                            52.11554565262662,
                                            20.16936519561054
                                        ],
                                        [
                                            51.9894195265005,
                                            20.16936519561054
                                        ]
                                    ],
                                    [
                                        [
                                            51.975091973173676,
                                            20.232361597365433
                                        ],
                                        [
                                            52.1012180992998,
                                            20.232361597365433
                                        ],
                                        [
                                            52.1012180992998,
                                            20.10623547123931
                                        ],
                                        [
                                            51.975091973173676,
                                            20.10623547123931
                                        ]
                                    ],
                                    [
                                        [
                                            51.96077255121066,
                                            20.169230735460612
                                        ],
                                        [
                                            52.086898677336784,
                                            20.169230735460612
                                        ],
                                        [
                                            52.086898677336784,
                                            20.04310460933449
                                        ],
                                        [
                                            51.96077255121066,
                                            20.04310460933449
                                        ]
                                    ],
                                    [
                                        [
                                            51.946461227862706,
                                            20.106098740011703
                                        ],
                                        [
                                            52.07258735398883,
                                            20.106098740011703
                                        ],
                                        [
                                            52.07258735398883,
                                            19.97997261388558
                                        ],
                                        [
                                            51.946461227862706,
                                            19.97997261388558
                                        ]
                                    ],
                                    [
                                        [
                                            51.93215796785786,
                                            20.042965615004622
                                        ],
                                        [
                                            52.058284093983985,
                                            20.042965615004622
                                        ],
                                        [
                                            52.058284093983985,
                                            19.916839488878498
                                        ],
                                        [
                                            51.93215796785786,
                                            19.916839488878498
                                        ]
                                    ],
                                    [
                                        [
                                            51.91786273862462,
                                            19.979831364421603
                                        ],
                                        [
                                            52.043988864750744,
                                            19.979831364421603
                                        ],
                                        [
                                            52.043988864750744,
                                            19.85370523829548
                                        ],
                                        [
                                            51.91786273862462,
                                            19.85370523829548
                                        ]
                                    ],
                                    [
                                        [
                                            51.903575505073775,
                                            19.91669599224135
                                        ],
                                        [
                                            52.0297016311999,
                                            19.91669599224135
                                        ],
                                        [
                                            52.0297016311999,
                                            19.790569866115227
                                        ],
                                        [
                                            51.903575505073775,
                                            19.790569866115227
                                        ]
                                    ],
                                    [
                                        [
                                            51.889296234822005,
                                            19.853559502439023
                                        ],
                                        [
                                            52.01542236094813,
                                            19.853559502439023
                                        ],
                                        [
                                            52.01542236094813,
                                            19.7274333763129
                                        ],
                                        [
                                            51.889296234822005,
                                            19.7274333763129
                                        ]
                                    ],
                                    [
                                        [
                                            51.875024892957676,
                                            19.79042189898622
                                        ],
                                        [
                                            52.0011510190838,
                                            19.79042189898622
                                        ],
                                        [
                                            52.0011510190838,
                                            19.664295772860097
                                        ],
                                        [
                                            51.875024892957676,
                                            19.664295772860097
                                        ]
                                    ],
                                    [
                                        [
                                            51.86076144727573,
                                            19.72728318585106
                                        ],
                                        [
                                            51.98688757340185,
                                            19.72728318585106
                                        ],
                                        [
                                            51.98688757340185,
                                            19.601157059724937
                                        ],
                                        [
                                            51.86076144727573,
                                            19.601157059724937
                                        ]
                                    ],
                                    [
                                        [
                                            51.84650586304821,
                                            19.66414336699817
                                        ],
                                        [
                                            51.97263198917433,
                                            19.66414336699817
                                        ],
                                        [
                                            51.97263198917433,
                                            19.538017240872048
                                        ],
                                        [
                                            51.84650586304821,
                                            19.538017240872048
                                        ]
                                    ],
                                    [
                                        [
                                            51.83225810824479,
                                            19.601002446388772
                                        ],
                                        [
                                            51.95838423437091,
                                            19.601002446388772
                                        ],
                                        [
                                            51.95838423437091,
                                            19.47487632026265
                                        ],
                                        [
                                            51.83225810824479,
                                            19.47487632026265
                                        ]
                                    ],
                                    [
                                        [
                                            51.81801814831126,
                                            19.537860427980593
                                        ],
                                        [
                                            51.944144274437384,
                                            19.537860427980593
                                        ],
                                        [
                                            51.944144274437384,
                                            19.41173430185447
                                        ],
                                        [
                                            51.81801814831126,
                                            19.41173430185447
                                        ]
                                    ],
                                    [
                                        [
                                            51.80378595139986,
                                            19.474717315728043
                                        ],
                                        [
                                            51.929912077525984,
                                            19.474717315728043
                                        ],
                                        [
                                            51.929912077525984,
                                            19.34859118960192
                                        ],
                                        [
                                            51.80378595139986,
                                            19.34859118960192
                                        ]
                                    ],
                                    [
                                        [
                                            51.789561483128224,
                                            19.411573113582122
                                        ],
                                        [
                                            51.91568760925435,
                                            19.411573113582122
                                        ],
                                        [
                                            51.91568760925435,
                                            19.285446987455998
                                        ],
                                        [
                                            51.789561483128224,
                                            19.285446987455998
                                        ]
                                    ],
                                    [
                                        [
                                            51.77534471182119,
                                            19.348427825490504
                                        ],
                                        [
                                            51.901470837947315,
                                            19.348427825490504
                                        ],
                                        [
                                            51.901470837947315,
                                            19.22230169936438
                                        ],
                                        [
                                            51.77534471182119,
                                            19.22230169936438
                                        ]
                                    ],
                                    [
                                        [
                                            51.761135603269665,
                                            19.285281455397502
                                        ],
                                        [
                                            51.88726172939579,
                                            19.285281455397502
                                        ],
                                        [
                                            51.88726172939579,
                                            19.15915532927138
                                        ],
                                        [
                                            51.761135603269665,
                                            19.15915532927138
                                        ]
                                    ],
                                    [
                                        [
                                            51.74693412597241,
                                            19.222134007244183
                                        ],
                                        [
                                            51.87306025209853,
                                            19.222134007244183
                                        ],
                                        [
                                            51.87306025209853,
                                            19.09600788111806
                                        ],
                                        [
                                            51.74693412597241,
                                            19.09600788111806
                                        ]
                                    ],
                                    [
                                        [
                                            51.73274024589344,
                                            19.15898548496834
                                        ],
                                        [
                                            51.85886637201956,
                                            19.15898548496834
                                        ],
                                        [
                                            51.85886637201956,
                                            19.032859358842217
                                        ],
                                        [
                                            51.73274024589344,
                                            19.032859358842217
                                        ]
                                    ],
                                    [
                                        [
                                            51.718553931700406,
                                            19.095835892504493
                                        ],
                                        [
                                            51.84468005782653,
                                            19.095835892504493
                                        ],
                                        [
                                            51.84468005782653,
                                            18.96970976637837
                                        ],
                                        [
                                            51.718553931700406,
                                            18.96970976637837
                                        ]
                                    ],
                                    [
                                        [
                                            51.704375149526925,
                                            19.032685233783983
                                        ],
                                        [
                                            51.83050127565305,
                                            19.032685233783983
                                        ],
                                        [
                                            51.83050127565305,
                                            18.90655910765786
                                        ],
                                        [
                                            51.704375149526925,
                                            18.90655910765786
                                        ]
                                    ],
                                    [
                                        [
                                            51.6902038682126,
                                            18.969533512734913
                                        ],
                                        [
                                            51.81632999433872,
                                            18.969533512734913
                                        ],
                                        [
                                            51.81632999433872,
                                            18.84340738660879
                                        ],
                                        [
                                            51.6902038682126,
                                            18.84340738660879
                                        ]
                                    ],
                                    [
                                        [
                                            51.676040054052365,
                                            18.906380733282262
                                        ],
                                        [
                                            51.80216618017849,
                                            18.906380733282262
                                        ],
                                        [
                                            51.80216618017849,
                                            18.780254607156138
                                        ],
                                        [
                                            51.676040054052365,
                                            18.780254607156138
                                        ]
                                    ],
                                    [
                                        [
                                            51.661883675394805,
                                            18.843226899347812
                                        ],
                                        [
                                            51.78800980152093,
                                            18.843226899347812
                                        ],
                                        [
                                            51.78800980152093,
                                            18.717100773221688
                                        ],
                                        [
                                            51.661883675394805,
                                            18.717100773221688
                                        ]
                                    ],
                                    [
                                        [
                                            51.6477347006811,
                                            18.780072014850262
                                        ],
                                        [
                                            51.77386082680722,
                                            18.780072014850262
                                        ],
                                        [
                                            51.77386082680722,
                                            18.653945888724138
                                        ],
                                        [
                                            51.6477347006811,
                                            18.653945888724138
                                        ]
                                    ],
                                    [
                                        [
                                            51.633593096447996,
                                            18.71691608370522
                                        ],
                                        [
                                            51.75971922257412,
                                            18.71691608370522
                                        ],
                                        [
                                            51.75971922257412,
                                            18.590789957579098
                                        ],
                                        [
                                            51.633593096447996,
                                            18.590789957579098
                                        ]
                                    ],
                                    [
                                        [
                                            51.619458831960465,
                                            18.65375910982518
                                        ],
                                        [
                                            51.74558495808659,
                                            18.65375910982518
                                        ],
                                        [
                                            51.74558495808659,
                                            18.527632983699057
                                        ],
                                        [
                                            51.619458831960465,
                                            18.527632983699057
                                        ]
                                    ],
                                    [
                                        [
                                            51.60533187391865,
                                            18.59060109711962
                                        ],
                                        [
                                            51.731458000044775,
                                            18.59060109711962
                                        ],
                                        [
                                            51.731458000044775,
                                            18.464474970993496
                                        ],
                                        [
                                            51.60533187391865,
                                            18.464474970993496
                                        ]
                                    ],
                                    [
                                        [
                                            51.59121219174181,
                                            18.527442049494983
                                        ],
                                        [
                                            51.717338317867934,
                                            18.527442049494983
                                        ],
                                        [
                                            51.717338317867934,
                                            18.40131592336886
                                        ],
                                        [
                                            51.59121219174181,
                                            18.40131592336886
                                        ]
                                    ],
                                    [
                                        [
                                            51.57709975229988,
                                            18.464281970854703
                                        ],
                                        [
                                            51.703225878426004,
                                            18.464281970854703
                                        ],
                                        [
                                            51.703225878426004,
                                            18.33815584472858
                                        ],
                                        [
                                            51.57709975229988,
                                            18.33815584472858
                                        ]
                                    ],
                                    [
                                        [
                                            51.56299452517286,
                                            18.401120865099223
                                        ],
                                        [
                                            51.689120651298985,
                                            18.401120865099223
                                        ],
                                        [
                                            51.689120651298985,
                                            18.2749947389731
                                        ],
                                        [
                                            51.56299452517286,
                                            18.2749947389731
                                        ]
                                    ],
                                    [
                                        [
                                            51.54889647738895,
                                            18.33795873612604
                                        ],
                                        [
                                            51.675022603515075,
                                            18.33795873612604
                                        ],
                                        [
                                            51.675022603515075,
                                            18.211832609999917
                                        ],
                                        [
                                            51.54889647738895,
                                            18.211832609999917
                                        ]
                                    ],
                                    [
                                        [
                                            51.53480557868884,
                                            18.274795587829722
                                        ],
                                        [
                                            51.66093170481496,
                                            18.274795587829722
                                        ],
                                        [
                                            51.66093170481496,
                                            18.148669461703598
                                        ],
                                        [
                                            51.53480557868884,
                                            18.148669461703598
                                        ]
                                    ],
                                    [
                                        [
                                            51.520721796253966,
                                            18.21163142410192
                                        ],
                                        [
                                            51.64684792238009,
                                            18.21163142410192
                                        ],
                                        [
                                            51.64684792238009,
                                            18.085505297975796
                                        ],
                                        [
                                            51.520721796253966,
                                            18.085505297975796
                                        ]
                                    ],
                                    [
                                        [
                                            51.50664509998559,
                                            18.148466248831394
                                        ],
                                        [
                                            51.632771226111714,
                                            18.148466248831394
                                        ],
                                        [
                                            51.632771226111714,
                                            18.02234012270527
                                        ],
                                        [
                                            51.50664509998559,
                                            18.02234012270527
                                        ]
                                    ],
                                    [
                                        [
                                            51.492575457224895,
                                            18.08530006590401
                                        ],
                                        [
                                            51.61870158335102,
                                            18.08530006590401
                                        ],
                                        [
                                            51.61870158335102,
                                            17.959173939777887
                                        ],
                                        [
                                            51.492575457224895,
                                            17.959173939777887
                                        ]
                                    ],
                                    [
                                        [
                                            51.478512838023896,
                                            18.022132879202854
                                        ],
                                        [
                                            51.60463896415002,
                                            18.022132879202854
                                        ],
                                        [
                                            51.60463896415002,
                                            17.89600675307673
                                        ],
                                        [
                                            51.478512838023896,
                                            17.89600675307673
                                        ]
                                    ],
                                    [
                                        [
                                            51.464457209881814,
                                            17.958964692608113
                                        ],
                                        [
                                            51.59058333600794,
                                            17.958964692608113
                                        ],
                                        [
                                            51.59058333600794,
                                            17.83283856648199
                                        ],
                                        [
                                            51.464457209881814,
                                            17.83283856648199
                                        ]
                                    ],
                                    [
                                        [
                                            51.450408543004585,
                                            17.89579550999722
                                        ],
                                        [
                                            51.57653466913071,
                                            17.89579550999722
                                        ],
                                        [
                                            51.57653466913071,
                                            17.769669383871097
                                        ],
                                        [
                                            51.450408543004585,
                                            17.769669383871097
                                        ]
                                    ],
                                    [
                                        [
                                            51.436366805036386,
                                            17.832625335244813
                                        ],
                                        [
                                            51.56249293116251,
                                            17.832625335244813
                                        ],
                                        [
                                            51.56249293116251,
                                            17.70649920911869
                                        ],
                                        [
                                            51.436366805036386,
                                            17.70649920911869
                                        ]
                                    ],
                                    [
                                        [
                                            51.42233196634363,
                                            17.7694541722228
                                        ],
                                        [
                                            51.54845809246975,
                                            17.7694541722228
                                        ],
                                        [
                                            51.54845809246975,
                                            17.643328046096677
                                        ],
                                        [
                                            51.42233196634363,
                                            17.643328046096677
                                        ]
                                    ],
                                    [
                                        [
                                            51.40830399472518,
                                            17.70628202480028
                                        ],
                                        [
                                            51.534430120851304,
                                            17.70628202480028
                                        ],
                                        [
                                            51.534430120851304,
                                            17.580155898674157
                                        ],
                                        [
                                            51.40830399472518,
                                            17.580155898674157
                                        ]
                                    ],
                                    [
                                        [
                                            51.394282860688286,
                                            17.643108896843714
                                        ],
                                        [
                                            51.52040898681441,
                                            17.643108896843714
                                        ],
                                        [
                                            51.52040898681441,
                                            17.51698277071759
                                        ],
                                        [
                                            51.394282860688286,
                                            17.51698277071759
                                        ]
                                    ],
                                    [
                                        [
                                            51.38026853218983,
                                            17.57993479221682
                                        ],
                                        [
                                            51.506394658315955,
                                            17.57993479221682
                                        ],
                                        [
                                            51.506394658315955,
                                            17.453808666090698
                                        ],
                                        [
                                            51.38026853218983,
                                            17.453808666090698
                                        ]
                                    ],
                                    [
                                        [
                                            51.366260979879435,
                                            17.51675971478066
                                        ],
                                        [
                                            51.49238710600556,
                                            17.51675971478066
                                        ],
                                        [
                                            51.49238710600556,
                                            17.390633588654538
                                        ],
                                        [
                                            51.366260979879435,
                                            17.390633588654538
                                        ]
                                    ],
                                    [
                                        [
                                            51.35226017186387,
                                            17.453583668393662
                                        ],
                                        [
                                            51.47838629798999,
                                            17.453583668393662
                                        ],
                                        [
                                            51.47838629798999,
                                            17.32745754226754
                                        ],
                                        [
                                            51.35226017186387,
                                            17.32745754226754
                                        ]
                                    ],
                                    [
                                        [
                                            51.33826607894669,
                                            17.390406656911573
                                        ],
                                        [
                                            51.46439220507281,
                                            17.390406656911573
                                        ],
                                        [
                                            51.46439220507281,
                                            17.26428053078545
                                        ],
                                        [
                                            51.33826607894669,
                                            17.26428053078545
                                        ]
                                    ],
                                    [
                                        [
                                            51.32427866937463,
                                            17.32722868418758
                                        ],
                                        [
                                            51.45040479550075,
                                            17.32722868418758
                                        ],
                                        [
                                            51.45040479550075,
                                            17.201102558061457
                                        ],
                                        [
                                            51.32427866937463,
                                            17.201102558061457
                                        ]
                                    ],
                                    [
                                        [
                                            51.310297914100346,
                                            17.264049754072232
                                        ],
                                        [
                                            51.43642404022647,
                                            17.264049754072232
                                        ],
                                        [
                                            51.43642404022647,
                                            17.13792362794611
                                        ],
                                        [
                                            51.310297914100346,
                                            17.13792362794611
                                        ]
                                    ],
                                    [
                                        [
                                            51.29632378151227,
                                            17.20086987041354
                                        ],
                                        [
                                            51.422449907638395,
                                            17.20086987041354
                                        ],
                                        [
                                            51.422449907638395,
                                            17.074743744287417
                                        ],
                                        [
                                            51.29632378151227,
                                            17.074743744287417
                                        ]
                                    ],
                                    [
                                        [
                                            51.28235624270721,
                                            17.137689037056983
                                        ],
                                        [
                                            51.40848236883333,
                                            17.137689037056983
                                        ],
                                        [
                                            51.40848236883333,
                                            17.01156291093086
                                        ],
                                        [
                                            51.28235624270721,
                                            17.01156291093086
                                        ]
                                    ],
                                    [
                                        [
                                            51.26839526622022,
                                            17.074507257845422
                                        ],
                                        [
                                            51.39452139234634,
                                            17.074507257845422
                                        ],
                                        [
                                            51.39452139234634,
                                            16.948381131719298
                                        ],
                                        [
                                            51.26839526622022,
                                            16.948381131719298
                                        ]
                                    ],
                                    [
                                        [
                                            51.25444082328908,
                                            17.011324536619323
                                        ],
                                        [
                                            51.380566949415204,
                                            17.011324536619323
                                        ],
                                        [
                                            51.380566949415204,
                                            16.8851984104932
                                        ],
                                        [
                                            51.25444082328908,
                                            16.8851984104932
                                        ]
                                    ],
                                    [
                                        [
                                            51.24049288259399,
                                            16.94814087721658
                                        ],
                                        [
                                            51.366619008720114,
                                            16.94814087721658
                                        ],
                                        [
                                            51.366619008720114,
                                            16.822014751090457
                                        ],
                                        [
                                            51.24049288259399,
                                            16.822014751090457
                                        ]
                                    ],
                                    [
                                        [
                                            51.226551415505575,
                                            16.884956283472633
                                        ],
                                        [
                                            51.3526775416317,
                                            16.884956283472633
                                        ],
                                        [
                                            51.3526775416317,
                                            16.75883015734651
                                        ],
                                        [
                                            51.226551415505575,
                                            16.75883015734651
                                        ]
                                    ],
                                    [
                                        [
                                            51.212616390849135,
                                            16.82177075922048
                                        ],
                                        [
                                            51.33874251697526,
                                            16.82177075922048
                                        ],
                                        [
                                            51.33874251697526,
                                            16.695644633094357
                                        ],
                                        [
                                            51.212616390849135,
                                            16.695644633094357
                                        ]
                                    ],
                                    [
                                        [
                                            51.19868778013804,
                                            16.758584308290672
                                        ],
                                        [
                                            51.32481390626416,
                                            16.758584308290672
                                        ],
                                        [
                                            51.32481390626416,
                                            16.632458182164548
                                        ],
                                        [
                                            51.19868778013804,
                                            16.632458182164548
                                        ]
                                    ],
                                    [
                                        [
                                            51.184765552331456,
                                            16.695396934511393
                                        ],
                                        [
                                            51.31089167845758,
                                            16.695396934511393
                                        ],
                                        [
                                            51.31089167845758,
                                            16.56927080838527
                                        ],
                                        [
                                            51.184765552331456,
                                            16.56927080838527
                                        ]
                                    ],
                                    [
                                        [
                                            51.170849679080675,
                                            16.632208641708353
                                        ],
                                        [
                                            51.2969758052068,
                                            16.632208641708353
                                        ],
                                        [
                                            51.2969758052068,
                                            16.50608251558223
                                        ],
                                        [
                                            51.170849679080675,
                                            16.50608251558223
                                        ]
                                    ],
                                    [
                                        [
                                            51.15694012948693,
                                            16.56901943370494
                                        ],
                                        [
                                            51.28306625561305,
                                            16.56901943370494
                                        ],
                                        [
                                            51.28306625561305,
                                            16.442893307578817
                                        ],
                                        [
                                            51.15694012948693,
                                            16.442893307578817
                                        ]
                                    ],
                                    [
                                        [
                                            51.14303687533302,
                                            16.50582931432218
                                        ],
                                        [
                                            51.26916300145914,
                                            16.50582931432218
                                        ],
                                        [
                                            51.26916300145914,
                                            16.379703188196057
                                        ],
                                        [
                                            51.14303687533302,
                                            16.379703188196057
                                        ]
                                    ],
                                    [
                                        [
                                            51.12913988585578,
                                            16.442638287378742
                                        ],
                                        [
                                            51.2552660119819,
                                            16.442638287378742
                                        ],
                                        [
                                            51.2552660119819,
                                            16.316512161252618
                                        ],
                                        [
                                            51.12913988585578,
                                            16.316512161252618
                                        ]
                                    ],
                                    [
                                        [
                                            51.115249132319995,
                                            16.37944635669099
                                        ],
                                        [
                                            51.24137525844612,
                                            16.37944635669099
                                        ],
                                        [
                                            51.24137525844612,
                                            16.253320230564867
                                        ],
                                        [
                                            51.115249132319995,
                                            16.253320230564867
                                        ]
                                    ],
                                    [
                                        [
                                            51.10136458606543,
                                            16.316253526073
                                        ],
                                        [
                                            51.22749071219155,
                                            16.316253526073
                                        ],
                                        [
                                            51.22749071219155,
                                            16.190127399946878
                                        ],
                                        [
                                            51.10136458606543,
                                            16.190127399946878
                                        ]
                                    ],
                                    [
                                        [
                                            51.08748621651977,
                                            16.253059799336533
                                        ],
                                        [
                                            51.213612342645895,
                                            16.253059799336533
                                        ],
                                        [
                                            51.213612342645895,
                                            16.12693367321041
                                        ],
                                        [
                                            51.08748621651977,
                                            16.12693367321041
                                        ]
                                    ],
                                    [
                                        [
                                            51.073613995808465,
                                            16.189865180291083
                                        ],
                                        [
                                            51.19974012193459,
                                            16.189865180291083
                                        ],
                                        [
                                            51.19974012193459,
                                            16.06373905416496
                                        ],
                                        [
                                            51.073613995808465,
                                            16.06373905416496
                                        ]
                                    ],
                                    [
                                        [
                                            51.05974789349444,
                                            16.12666967274395
                                        ],
                                        [
                                            51.18587401962056,
                                            16.12666967274395
                                        ],
                                        [
                                            51.18587401962056,
                                            16.000543546617827
                                        ],
                                        [
                                            51.05974789349444,
                                            16.000543546617827
                                        ]
                                    ],
                                    [
                                        [
                                            51.04588788183751,
                                            16.06347328050014
                                        ],
                                        [
                                            51.17201400796363,
                                            16.06347328050014
                                        ],
                                        [
                                            51.17201400796363,
                                            15.937347154374015
                                        ],
                                        [
                                            51.04588788183751,
                                            15.937347154374015
                                        ]
                                    ],
                                    [
                                        [
                                            51.03203393052611,
                                            16.000276007362494
                                        ],
                                        [
                                            51.158160056652235,
                                            16.000276007362494
                                        ],
                                        [
                                            51.158160056652235,
                                            15.874149881236367
                                        ],
                                        [
                                            51.03203393052611,
                                            15.874149881236367
                                        ]
                                    ],
                                    [
                                        [
                                            51.01818601194979,
                                            15.937077857131634
                                        ],
                                        [
                                            51.14431213807591,
                                            15.937077857131634
                                        ],
                                        [
                                            51.14431213807591,
                                            15.810951731005506
                                        ],
                                        [
                                            51.01818601194979,
                                            15.810951731005506
                                        ]
                                    ],
                                    [
                                        [
                                            51.00434409593086,
                                            15.873878833606003
                                        ],
                                        [
                                            51.130470222056985,
                                            15.873878833606003
                                        ],
                                        [
                                            51.130470222056985,
                                            15.747752707479876
                                        ],
                                        [
                                            51.00434409593086,
                                            15.747752707479876
                                        ]
                                    ],
                                    [
                                        [
                                            50.99050815498382,
                                            15.810678940581903
                                        ],
                                        [
                                            51.116634281109945,
                                            15.810678940581903
                                        ],
                                        [
                                            51.116634281109945,
                                            15.684552814455776
                                        ],
                                        [
                                            50.99050815498382,
                                            15.684552814455776
                                        ]
                                    ],
                                    [
                                        [
                                            50.97667815905363,
                                            15.747478181853493
                                        ],
                                        [
                                            51.10280428517975,
                                            15.747478181853493
                                        ],
                                        [
                                            51.10280428517975,
                                            15.621352055727366
                                        ],
                                        [
                                            50.97667815905363,
                                            15.621352055727366
                                        ]
                                    ],
                                    [
                                        [
                                            50.962854080789725,
                                            15.684276561212783
                                        ],
                                        [
                                            51.08898020691585,
                                            15.684276561212783
                                        ],
                                        [
                                            51.08898020691585,
                                            15.558150435086656
                                        ],
                                        [
                                            50.962854080789725,
                                            15.558150435086656
                                        ]
                                    ],
                                    [
                                        [
                                            50.949035890259815,
                                            15.621074082449713
                                        ],
                                        [
                                            51.07516201638594,
                                            15.621074082449713
                                        ],
                                        [
                                            51.07516201638594,
                                            15.494947956323585
                                        ],
                                        [
                                            50.949035890259815,
                                            15.494947956323585
                                        ]
                                    ],
                                    [
                                        [
                                            50.935223560240445,
                                            15.557870749352103
                                        ],
                                        [
                                            51.06134968636657,
                                            15.557870749352103
                                        ],
                                        [
                                            51.06134968636657,
                                            15.431744623225976
                                        ],
                                        [
                                            50.935223560240445,
                                            15.431744623225976
                                        ]
                                    ],
                                    [
                                        [
                                            50.92141706092228,
                                            15.494666565705703
                                        ],
                                        [
                                            51.047543187048404,
                                            15.494666565705703
                                        ],
                                        [
                                            51.047543187048404,
                                            15.368540439579576
                                        ],
                                        [
                                            50.92141706092228,
                                            15.368540439579576
                                        ]
                                    ],
                                    [
                                        [
                                            50.90761636520086,
                                            15.431461535294234
                                        ],
                                        [
                                            51.03374249132698,
                                            15.431461535294234
                                        ],
                                        [
                                            51.03374249132698,
                                            15.305335409168107
                                        ],
                                        [
                                            50.90761636520086,
                                            15.305335409168107
                                        ]
                                    ],
                                    [
                                        [
                                            50.89382144339828,
                                            15.368255661899324
                                        ],
                                        [
                                            51.019947569524405,
                                            15.368255661899324
                                        ],
                                        [
                                            51.019947569524405,
                                            15.242129535773197
                                        ],
                                        [
                                            50.89382144339828,
                                            15.242129535773197
                                        ]
                                    ],
                                    [
                                        [
                                            50.8800322685277,
                                            15.305048949300623
                                        ],
                                        [
                                            51.00615839465382,
                                            15.305048949300623
                                        ],
                                        [
                                            51.00615839465382,
                                            15.178922823174496
                                        ],
                                        [
                                            50.8800322685277,
                                            15.178922823174496
                                        ]
                                    ],
                                    [
                                        [
                                            50.866248811037906,
                                            15.241841401275764
                                        ],
                                        [
                                            50.99237493716403,
                                            15.241841401275764
                                        ],
                                        [
                                            50.99237493716403,
                                            15.115715275149636
                                        ],
                                        [
                                            50.866248811037906,
                                            15.115715275149636
                                        ]
                                    ],
                                    [
                                        [
                                            50.85247104406158,
                                            15.178633021600364
                                        ],
                                        [
                                            50.978597170187705,
                                            15.178633021600364
                                        ],
                                        [
                                            50.978597170187705,
                                            15.052506895474236
                                        ],
                                        [
                                            50.85247104406158,
                                            15.052506895474236
                                        ]
                                    ],
                                    [
                                        [
                                            50.838698938161436,
                                            15.115423814048114
                                        ],
                                        [
                                            50.96482506428756,
                                            15.115423814048114
                                        ],
                                        [
                                            50.96482506428756,
                                            14.989297687921987
                                        ],
                                        [
                                            50.838698938161436,
                                            14.989297687921987
                                        ]
                                    ],
                                    [
                                        [
                                            50.82493246659473,
                                            15.052213782390695
                                        ],
                                        [
                                            50.951058592720855,
                                            15.052213782390695
                                        ],
                                        [
                                            50.951058592720855,
                                            14.926087656264567
                                        ],
                                        [
                                            50.82493246659473,
                                            14.926087656264567
                                        ]
                                    ],
                                    [
                                        [
                                            50.811171600044865,
                                            14.989002930397843
                                        ],
                                        [
                                            50.93729772617099,
                                            14.989002930397843
                                        ],
                                        [
                                            50.93729772617099,
                                            14.862876804271716
                                        ],
                                        [
                                            50.811171600044865,
                                            14.862876804271716
                                        ]
                                    ],
                                    [
                                        [
                                            50.7974163118858,
                                            14.925791261837423
                                        ],
                                        [
                                            50.92354243801192,
                                            14.925791261837423
                                        ],
                                        [
                                            50.92354243801192,
                                            14.799665135711296
                                        ],
                                        [
                                            50.7974163118858,
                                            14.799665135711296
                                        ]
                                    ],
                                    [
                                        [
                                            50.78366657292024,
                                            14.862578780475344
                                        ],
                                        [
                                            50.90979269904636,
                                            14.862578780475344
                                        ],
                                        [
                                            50.90979269904636,
                                            14.736452654349216
                                        ],
                                        [
                                            50.78366657292024,
                                            14.736452654349216
                                        ]
                                    ],
                                    [
                                        [
                                            50.7699223566375,
                                            14.799365490075614
                                        ],
                                        [
                                            50.896048482763625,
                                            14.799365490075614
                                        ],
                                        [
                                            50.896048482763625,
                                            14.673239363949486
                                        ],
                                        [
                                            50.7699223566375,
                                            14.673239363949486
                                        ]
                                    ],
                                    [
                                        [
                                            50.75618363395335,
                                            14.736151394400373
                                        ],
                                        [
                                            50.88230976007947,
                                            14.736151394400373
                                        ],
                                        [
                                            50.88230976007947,
                                            14.610025268274246
                                        ],
                                        [
                                            50.75618363395335,
                                            14.610025268274246
                                        ]
                                    ],
                                    [
                                        [
                                            50.74245037847762,
                                            14.672936497209914
                                        ],
                                        [
                                            50.868576504603745,
                                            14.672936497209914
                                        ],
                                        [
                                            50.868576504603745,
                                            14.546810371083787
                                        ],
                                        [
                                            50.74245037847762,
                                            14.546810371083787
                                        ]
                                    ],
                                    [
                                        [
                                            50.72872256123779,
                                            14.609720802262634
                                        ],
                                        [
                                            50.85484868736391,
                                            14.609720802262634
                                        ],
                                        [
                                            50.85484868736391,
                                            14.483594676136507
                                        ],
                                        [
                                            50.72872256123779,
                                            14.483594676136507
                                        ]
                                    ],
                                    [
                                        [
                                            50.71500015596116,
                                            14.546504313315134
                                        ],
                                        [
                                            50.84112628208728,
                                            14.546504313315134
                                        ],
                                        [
                                            50.84112628208728,
                                            14.420378187189007
                                        ],
                                        [
                                            50.71500015596116,
                                            14.420378187189007
                                        ]
                                    ],
                                    [
                                        [
                                            50.70128313379046,
                                            14.483287034122183
                                        ],
                                        [
                                            50.827409259916585,
                                            14.483287034122183
                                        ],
                                        [
                                            50.827409259916585,
                                            14.357160907996056
                                        ],
                                        [
                                            50.70128313379046,
                                            14.357160907996056
                                        ]
                                    ],
                                    [
                                        [
                                            50.6875714685579,
                                            14.420068968436743
                                        ],
                                        [
                                            50.81369759468402,
                                            14.420068968436743
                                        ],
                                        [
                                            50.81369759468402,
                                            14.293942842310615
                                        ],
                                        [
                                            50.6875714685579,
                                            14.293942842310615
                                        ]
                                    ],
                                    [
                                        [
                                            50.673865131524884,
                                            14.356850120009994
                                        ],
                                        [
                                            50.79999125765101,
                                            14.356850120009994
                                        ],
                                        [
                                            50.79999125765101,
                                            14.230723993883867
                                        ],
                                        [
                                            50.673865131524884,
                                            14.230723993883867
                                        ]
                                    ],
                                    [
                                        [
                                            50.660164096633615,
                                            14.293630492591303
                                        ],
                                        [
                                            50.78629022275974,
                                            14.293630492591303
                                        ],
                                        [
                                            50.78629022275974,
                                            14.167504366465176
                                        ],
                                        [
                                            50.660164096633615,
                                            14.167504366465176
                                        ]
                                    ],
                                    [
                                        [
                                            50.646468335254916,
                                            14.230410089928343
                                        ],
                                        [
                                            50.77259446138104,
                                            14.230410089928343
                                        ],
                                        [
                                            50.77259446138104,
                                            14.104283963802215
                                        ],
                                        [
                                            50.646468335254916,
                                            14.104283963802215
                                        ]
                                    ],
                                    [
                                        [
                                            50.63277782144614,
                                            14.167188915766964
                                        ],
                                        [
                                            50.758903947572264,
                                            14.167188915766964
                                        ],
                                        [
                                            50.758903947572264,
                                            14.041062789640836
                                        ],
                                        [
                                            50.63277782144614,
                                            14.041062789640836
                                        ]
                                    ],
                                    [
                                        [
                                            50.619092526682785,
                                            14.103966973851344
                                        ],
                                        [
                                            50.74521865280891,
                                            14.103966973851344
                                        ],
                                        [
                                            50.74521865280891,
                                            13.977840847725217
                                        ],
                                        [
                                            50.619092526682785,
                                            13.977840847725217
                                        ]
                                    ],
                                    [
                                        [
                                            50.60541242513128,
                                            14.040744267923914
                                        ],
                                        [
                                            50.7315385512574,
                                            14.040744267923914
                                        ],
                                        [
                                            50.7315385512574,
                                            13.914618141797787
                                        ],
                                        [
                                            50.60541242513128,
                                            13.914618141797787
                                        ]
                                    ],
                                    [
                                        [
                                            50.59173748837874,
                                            13.977520801725404
                                        ],
                                        [
                                            50.717863614504864,
                                            13.977520801725404
                                        ],
                                        [
                                            50.717863614504864,
                                            13.851394675599277
                                        ],
                                        [
                                            50.59173748837874,
                                            13.851394675599277
                                        ]
                                    ],
                                    [
                                        [
                                            50.5780676906976,
                                            13.914296578994835
                                        ],
                                        [
                                            50.704193816823725,
                                            13.914296578994835
                                        ],
                                        [
                                            50.704193816823725,
                                            13.788170452868707
                                        ],
                                        [
                                            50.5780676906976,
                                            13.788170452868707
                                        ]
                                    ],
                                ],
                                swathWidth: 10.0,
                                length: 15.0,
                                areaCovered: 100.0,
                                captureTime: "2024-10-03T08:00:00Z",
                                cloudCoverage: 5.0,
                                resolution: 1.0
                            },
                            satellite_operations: [
                                {
                                    type: "ADCS - Target Track 3",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track 3",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track 3",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                }

                            ],
                        },
                        {
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_004",
                                name: "Satellite Alpha 4",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            strip: {
                                id: "strip_004",
                                featureId: "feat_004",
                                coordinates: [
                                    [
                                        [
                                            50.56440300378049,
                                            13.851071603469613
                                        ],
                                        [
                                            50.690529129906615,
                                            13.851071603469613
                                        ],
                                        [
                                            50.690529129906615,
                                            13.724945477343486
                                        ],
                                        [
                                            50.56440300378049,
                                            13.724945477343486
                                        ]
                                    ],
                                    [
                                        [
                                            50.55074340135478,
                                            13.787845878885413
                                        ],
                                        [
                                            50.6768695274809,
                                            13.787845878885413
                                        ],
                                        [
                                            50.6768695274809,
                                            13.661719752759286
                                        ],
                                        [
                                            50.55074340135478,
                                            13.661719752759286
                                        ]
                                    ],
                                    [
                                        [
                                            50.53708885718445,
                                            13.724619408976313
                                        ],
                                        [
                                            50.663214983310574,
                                            13.724619408976313
                                        ],
                                        [
                                            50.663214983310574,
                                            13.598493282850185
                                        ],
                                        [
                                            50.53708885718445,
                                            13.598493282850185
                                        ]
                                    ],
                                    [
                                        [
                                            50.523439343129965,
                                            13.661392197474713
                                        ],
                                        [
                                            50.64956546925609,
                                            13.661392197474713
                                        ],
                                        [
                                            50.64956546925609,
                                            13.535266071348586
                                        ],
                                        [
                                            50.523439343129965,
                                            13.535266071348586
                                        ]
                                    ],
                                    [
                                        [
                                            50.50979483372091,
                                            13.598164248111434
                                        ],
                                        [
                                            50.635920959847034,
                                            13.598164248111434
                                        ],
                                        [
                                            50.635920959847034,
                                            13.472038121985307
                                        ],
                                        [
                                            50.50979483372091,
                                            13.472038121985307
                                        ]
                                    ],
                                    [
                                        [
                                            50.496155300921686,
                                            13.534935564615644
                                        ],
                                        [
                                            50.62228142704781,
                                            13.534935564615644
                                        ],
                                        [
                                            50.62228142704781,
                                            13.408809438489516
                                        ],
                                        [
                                            50.496155300921686,
                                            13.408809438489516
                                        ]
                                    ],
                                    [
                                        [
                                            60.39013241280216,
                                            22.59447728821307
                                        ],
                                        [
                                            60.516258538928284,
                                            22.59447728821307
                                        ],
                                        [
                                            60.516258538928284,
                                            22.468351162086947
                                        ],
                                        [
                                            60.39013241280216,
                                            22.468351162086947
                                        ]
                                    ],
                                    [
                                        [
                                            60.37549498180649,
                                            22.531450083998223
                                        ],
                                        [
                                            60.501621107932614,
                                            22.531450083998223
                                        ],
                                        [
                                            60.501621107932614,
                                            22.4053239578721
                                        ],
                                        [
                                            60.37549498180649,
                                            22.4053239578721
                                        ]
                                    ],
                                    [
                                        [
                                            60.36086697776114,
                                            22.468421628985833
                                        ],
                                        [
                                            60.486993103887265,
                                            22.468421628985833
                                        ],
                                        [
                                            60.486993103887265,
                                            22.34229550285971
                                        ],
                                        [
                                            60.36086697776114,
                                            22.34229550285971
                                        ]
                                    ],
                                    [
                                        [
                                            60.346248364295775,
                                            22.40539192723078
                                        ],
                                        [
                                            60.4723744904219,
                                            22.40539192723078
                                        ],
                                        [
                                            60.4723744904219,
                                            22.279265801104657
                                        ],
                                        [
                                            60.346248364295775,
                                            22.279265801104657
                                        ]
                                    ],
                                    [
                                        [
                                            60.33163910252464,
                                            22.342360982783163
                                        ],
                                        [
                                            60.457765228650764,
                                            22.342360982783163
                                        ],
                                        [
                                            60.457765228650764,
                                            22.21623485665704
                                        ],
                                        [
                                            60.33163910252464,
                                            22.21623485665704
                                        ]
                                    ],
                                    [
                                        [
                                            60.317039156292715,
                                            22.279328799688322
                                        ],
                                        [
                                            60.44316528241884,
                                            22.279328799688322
                                        ],
                                        [
                                            60.44316528241884,
                                            22.1532026735622
                                        ],
                                        [
                                            60.317039156292715,
                                            22.1532026735622
                                        ]
                                    ],
                                    [
                                        [
                                            60.3024484869382,
                                            22.216295381986843
                                        ],
                                        [
                                            60.42857461306432,
                                            22.216295381986843
                                        ],
                                        [
                                            60.42857461306432,
                                            22.09016925586072
                                        ],
                                        [
                                            60.3024484869382,
                                            22.09016925586072
                                        ]
                                    ],
                                    [
                                        [
                                            60.28786705786782,
                                            22.153260733714582
                                        ],
                                        [
                                            60.413993183993945,
                                            22.153260733714582
                                        ],
                                        [
                                            60.413993183993945,
                                            22.027134607588458
                                        ],
                                        [
                                            60.28786705786782,
                                            22.027134607588458
                                        ]
                                    ],
                                    [
                                        [
                                            60.27329483260378,
                                            22.09022485890275
                                        ],
                                        [
                                            60.399420958729905,
                                            22.09022485890275
                                        ],
                                        [
                                            60.399420958729905,
                                            21.964098732776627
                                        ],
                                        [
                                            60.27329483260378,
                                            21.964098732776627
                                        ]
                                    ],
                                    [
                                        [
                                            60.25873177280448,
                                            22.02718776157793
                                        ],
                                        [
                                            60.384857898930605,
                                            22.02718776157793
                                        ],
                                        [
                                            60.384857898930605,
                                            21.901061635451807
                                        ],
                                        [
                                            60.25873177280448,
                                            21.901061635451807
                                        ]
                                    ],
                                    [
                                        [
                                            60.244177842867806,
                                            21.96414944576206
                                        ],
                                        [
                                            60.37030396899393,
                                            21.96414944576206
                                        ],
                                        [
                                            60.37030396899393,
                                            21.838023319635937
                                        ],
                                        [
                                            60.244177842867806,
                                            21.838023319635937
                                        ]
                                    ],
                                    [
                                        [
                                            60.229633004660705,
                                            21.90110991547253
                                        ],
                                        [
                                            60.35575913078683,
                                            21.90110991547253
                                        ],
                                        [
                                            60.35575913078683,
                                            21.774983789346408
                                        ],
                                        [
                                            60.229633004660705,
                                            21.774983789346408
                                        ]
                                    ],
                                    [
                                        [
                                            60.215097222799905,
                                            21.838069174722182
                                        ],
                                        [
                                            60.34122334892603,
                                            21.838069174722182
                                        ],
                                        [
                                            60.34122334892603,
                                            21.71194304859606
                                        ],
                                        [
                                            60.215097222799905,
                                            21.71194304859606
                                        ]
                                    ],
                                    [
                                        [
                                            60.20057045936349,
                                            21.775027227519402
                                        ],
                                        [
                                            60.326696585489614,
                                            21.775027227519402
                                        ],
                                        [
                                            60.326696585489614,
                                            21.648901101393278
                                        ],
                                        [
                                            60.20057045936349,
                                            21.648901101393278
                                        ]
                                    ],
                                    [
                                        [
                                            60.18605267917983,
                                            21.711984077868003
                                        ],
                                        [
                                            60.312178805305955,
                                            21.711984077868003
                                        ],
                                        [
                                            60.312178805305955,
                                            21.58585795174188
                                        ],
                                        [
                                            60.18605267917983,
                                            21.58585795174188
                                        ]
                                    ],
                                    [
                                        [
                                            60.17154384454092,
                                            21.64893972976744
                                        ],
                                        [
                                            60.297669970667044,
                                            21.64893972976744
                                        ],
                                        [
                                            60.297669970667044,
                                            21.522813603641318
                                        ],
                                        [
                                            60.17154384454092,
                                            21.522813603641318
                                        ]
                                    ],
                                    [
                                        [
                                            60.15704392047646,
                                            21.58589418721274
                                        ],
                                        [
                                            60.283170046602585,
                                            21.58589418721274
                                        ],
                                        [
                                            60.283170046602585,
                                            21.459768061086617
                                        ],
                                        [
                                            60.15704392047646,
                                            21.459768061086617
                                        ]
                                    ],
                                    [
                                        [
                                            60.142552869491745,
                                            21.52284745419456
                                        ],
                                        [
                                            60.26867899561787,
                                            21.52284745419456
                                        ],
                                        [
                                            60.26867899561787,
                                            21.396721328068438
                                        ],
                                        [
                                            60.142552869491745,
                                            21.396721328068438
                                        ]
                                    ],
                                    [
                                        [
                                            60.128070656827006,
                                            21.459799534699194
                                        ],
                                        [
                                            60.25419678295313,
                                            21.459799534699194
                                        ],
                                        [
                                            60.25419678295313,
                                            21.33367340857307
                                        ],
                                        [
                                            60.128070656827006,
                                            21.33367340857307
                                        ]
                                    ],
                                    [
                                        [
                                            60.11359724518888,
                                            21.396750432708632
                                        ],
                                        [
                                            60.239723371315,
                                            21.396750432708632
                                        ],
                                        [
                                            60.239723371315,
                                            21.27062430658251
                                        ],
                                        [
                                            60.11359724518888,
                                            21.27062430658251
                                        ]
                                    ],
                                    [
                                        [
                                            60.09913260002439,
                                            21.333700152200592
                                        ],
                                        [
                                            60.225258726150514,
                                            21.333700152200592
                                        ],
                                        [
                                            60.225258726150514,
                                            21.207574026074468
                                        ],
                                        [
                                            60.09913260002439,
                                            21.207574026074468
                                        ]
                                    ],
                                    [
                                        [
                                            60.08467668424425,
                                            21.27064869714855
                                        ],
                                        [
                                            60.210802810370375,
                                            21.27064869714855
                                        ],
                                        [
                                            60.210802810370375,
                                            21.144522571022428
                                        ],
                                        [
                                            60.08467668424425,
                                            21.144522571022428
                                        ]
                                    ],
                                    [
                                        [
                                            60.070229463496815,
                                            21.20759607152173
                                        ],
                                        [
                                            60.19635558962294,
                                            21.20759607152173
                                        ],
                                        [
                                            60.19635558962294,
                                            21.081469945395607
                                        ],
                                        [
                                            60.070229463496815,
                                            21.081469945395607
                                        ]
                                    ],
                                    [
                                        [
                                            60.05579090090133,
                                            21.144542279285222
                                        ],
                                        [
                                            60.18191702702745,
                                            21.144542279285222
                                        ],
                                        [
                                            60.18191702702745,
                                            21.018416153159098
                                        ],
                                        [
                                            60.05579090090133,
                                            21.018416153159098
                                        ]
                                    ],
                                    [
                                        [
                                            60.04136096229726,
                                            21.081487324399912
                                        ],
                                        [
                                            60.167487088423385,
                                            21.081487324399912
                                        ],
                                        [
                                            60.167487088423385,
                                            20.95536119827379
                                        ],
                                        [
                                            60.04136096229726,
                                            20.95536119827379
                                        ]
                                    ],
                                    [
                                        [
                                            60.02693961100853,
                                            21.0184312108226
                                        ],
                                        [
                                            60.15306573713465,
                                            21.0184312108226
                                        ],
                                        [
                                            60.15306573713465,
                                            20.892305084696478
                                        ],
                                        [
                                            60.02693961100853,
                                            20.892305084696478
                                        ]
                                    ],
                                    [
                                        [
                                            60.012526813075056,
                                            20.95537394250596
                                        ],
                                        [
                                            60.13865293920118,
                                            20.95537394250596
                                        ],
                                        [
                                            60.13865293920118,
                                            20.829247816379837
                                        ],
                                        [
                                            60.012526813075056,
                                            20.829247816379837
                                        ]
                                    ],
                                    [
                                        [
                                            59.998122532015245,
                                            20.89231552339861
                                        ],
                                        [
                                            60.12424865814137,
                                            20.89231552339861
                                        ],
                                        [
                                            60.12424865814137,
                                            20.766189397272488
                                        ],
                                        [
                                            59.998122532015245,
                                            20.766189397272488
                                        ]
                                    ],
                                    [
                                        [
                                            59.98372673407395,
                                            20.82925595744514
                                        ],
                                        [
                                            60.10985286020007,
                                            20.82925595744514
                                        ],
                                        [
                                            60.10985286020007,
                                            20.703129831319018
                                        ],
                                        [
                                            59.98372673407395,
                                            20.703129831319018
                                        ]
                                    ],
                                    [
                                        [
                                            59.96933938295542,
                                            20.766195248586143
                                        ],
                                        [
                                            60.09546550908154,
                                            20.766195248586143
                                        ],
                                        [
                                            60.09546550908154,
                                            20.64006912246002
                                        ],
                                        [
                                            59.96933938295542,
                                            20.64006912246002
                                        ]
                                    ],
                                    [
                                        [
                                            59.954960445095885,
                                            20.703133400758222
                                        ],
                                        [
                                            60.08108657122201,
                                            20.703133400758222
                                        ],
                                        [
                                            60.08108657122201,
                                            20.5770072746321
                                        ],
                                        [
                                            59.954960445095885,
                                            20.5770072746321
                                        ]
                                    ],
                                    [
                                        [
                                            59.94058988440308,
                                            20.640070417894023
                                        ],
                                        [
                                            60.066716010529206,
                                            20.640070417894023
                                        ],
                                        [
                                            60.066716010529206,
                                            20.5139442917679
                                        ],
                                        [
                                            59.94058988440308,
                                            20.5139442917679
                                        ]
                                    ],
                                    [
                                        [
                                            59.92622766750582,
                                            20.577006303922303
                                        ],
                                        [
                                            60.052353793631944,
                                            20.577006303922303
                                        ],
                                        [
                                            60.052353793631944,
                                            20.45088017779618
                                        ],
                                        [
                                            59.92622766750582,
                                            20.45088017779618
                                        ]
                                    ],
                                    [
                                        [
                                            59.911873758501855,
                                            20.513941062767913
                                        ],
                                        [
                                            60.03799988462798,
                                            20.513941062767913
                                        ],
                                        [
                                            60.03799988462798,
                                            20.38781493664179
                                        ],
                                        [
                                            59.911873758501855,
                                            20.38781493664179
                                        ]
                                    ],
                                    [
                                        [
                                            59.897528124204065,
                                            20.45087469835186
                                        ],
                                        [
                                            60.02365425033019,
                                            20.45087469835186
                                        ],
                                        [
                                            60.02365425033019,
                                            20.324748572225737
                                        ],
                                        [
                                            59.897528124204065,
                                            20.324748572225737
                                        ]
                                    ],
                                    [
                                        [
                                            59.88319072890465,
                                            20.387807214591312
                                        ],
                                        [
                                            60.009316855030775,
                                            20.387807214591312
                                        ],
                                        [
                                            60.009316855030775,
                                            20.26168108846519
                                        ],
                                        [
                                            59.88319072890465,
                                            20.26168108846519
                                        ]
                                    ],
                                    [
                                        [
                                            59.86886153960686,
                                            20.32473861539961
                                        ],
                                        [
                                            59.994987665732985,
                                            20.32473861539961
                                        ],
                                        [
                                            59.994987665732985,
                                            20.198612489273486
                                        ],
                                        [
                                            59.86886153960686,
                                            20.198612489273486
                                        ]
                                    ],
                                    [
                                        [
                                            59.854540520788746,
                                            20.26166890468637
                                        ],
                                        [
                                            59.98066664691487,
                                            20.26166890468637
                                        ],
                                        [
                                            59.98066664691487,
                                            20.135542778560247
                                        ],
                                        [
                                            59.854540520788746,
                                            20.135542778560247
                                        ]
                                    ],
                                    [
                                        [
                                            59.84022763964019,
                                            20.19859808635742
                                        ],
                                        [
                                            59.966353765766314,
                                            20.19859808635742
                                        ],
                                        [
                                            59.966353765766314,
                                            20.072471960231297
                                        ],
                                        [
                                            59.84022763964019,
                                            20.072471960231297
                                        ]
                                    ],
                                    [
                                        [
                                            59.82592286082666,
                                            20.135526164314893
                                        ],
                                        [
                                            59.95204898695278,
                                            20.135526164314893
                                        ],
                                        [
                                            59.95204898695278,
                                            20.00940003818877
                                        ],
                                        [
                                            59.82592286082666,
                                            20.00940003818877
                                        ]
                                    ],
                                    [
                                        [
                                            59.81162615172095,
                                            20.07245314245722
                                        ],
                                        [
                                            59.937752277847075,
                                            20.07245314245722
                                        ],
                                        [
                                            59.937752277847075,
                                            19.946327016331097
                                        ],
                                        [
                                            59.81162615172095,
                                            19.946327016331097
                                        ]
                                    ],
                                    [
                                        [
                                            59.79733747716901,
                                            20.00937902467918
                                        ],
                                        [
                                            59.92346360329513,
                                            20.00937902467918
                                        ],
                                        [
                                            59.92346360329513,
                                            19.883252898553057
                                        ],
                                        [
                                            59.79733747716901,
                                            19.883252898553057
                                        ]
                                    ],
                                    [
                                        [
                                            59.7830568047296,
                                            19.94630381487191
                                        ],
                                        [
                                            59.90918293085572,
                                            19.94630381487191
                                        ],
                                        [
                                            59.90918293085572,
                                            19.820177688745787
                                        ],
                                        [
                                            59.7830568047296,
                                            19.820177688745787
                                        ]
                                    ],
                                    [
                                        [
                                            59.76878409943207,
                                            19.883227516922933
                                        ],
                                        [
                                            59.89491022555819,
                                            19.883227516922933
                                        ],
                                        [
                                            59.89491022555819,
                                            19.75710139079681
                                        ],
                                        [
                                            59.76878409943207,
                                            19.75710139079681
                                        ]
                                    ],
                                    [
                                        [
                                            59.7545193290144,
                                            19.8201501347162
                                        ],
                                        [
                                            59.880645455140524,
                                            19.8201501347162
                                        ],
                                        [
                                            59.880645455140524,
                                            19.694024008590077
                                        ],
                                        [
                                            59.7545193290144,
                                            19.694024008590077
                                        ]
                                    ],
                                    [
                                        [
                                            59.74026245868896,
                                            19.757071672132103
                                        ],
                                        [
                                            59.866388584815084,
                                            19.757071672132103
                                        ],
                                        [
                                            59.866388584815084,
                                            19.63094554600598
                                        ],
                                        [
                                            59.74026245868896,
                                            19.63094554600598
                                        ]
                                    ],
                                    [
                                        [
                                            59.72601345571152,
                                            19.6939921330475
                                        ],
                                        [
                                            59.85213958183764,
                                            19.6939921330475
                                        ],
                                        [
                                            59.85213958183764,
                                            19.567866006921378
                                        ],
                                        [
                                            59.72601345571152,
                                            19.567866006921378
                                        ]
                                    ],
                                    [
                                        [
                                            59.71177228743796,
                                            19.630911521335744
                                        ],
                                        [
                                            59.83789841356408,
                                            19.630911521335744
                                        ],
                                        [
                                            59.83789841356408,
                                            19.50478539520962
                                        ],
                                        [
                                            59.71177228743796,
                                            19.50478539520962
                                        ]
                                    ],
                                    [
                                        [
                                            55.963770488241096,
                                            17.139022163179863
                                        ],
                                        [
                                            56.08989661436722,
                                            17.139022163179863
                                        ],
                                        [
                                            56.08989661436722,
                                            17.01289603705374
                                        ],
                                        [
                                            55.963770488241096,
                                            17.01289603705374
                                        ]
                                    ],
                                    [
                                        [
                                            55.95005218167705,
                                            17.202163446347832
                                        ],
                                        [
                                            56.076178307803175,
                                            17.202163446347832
                                        ],
                                        [
                                            56.076178307803175,
                                            17.076037320221708
                                        ],
                                        [
                                            55.95005218167705,
                                            17.076037320221708
                                        ]
                                    ],
                                    [
                                        [
                                            55.936327448463125,
                                            17.26530382568632
                                        ],
                                        [
                                            56.06245357458925,
                                            17.26530382568632
                                        ],
                                        [
                                            56.06245357458925,
                                            17.139177699560197
                                        ],
                                        [
                                            55.936327448463125,
                                            17.139177699560197
                                        ]
                                    ],
                                    [
                                        [
                                            55.92259625784828,
                                            17.328443297552013
                                        ],
                                        [
                                            56.0487223839744,
                                            17.328443297552013
                                        ],
                                        [
                                            56.0487223839744,
                                            17.20231717142589
                                        ],
                                        [
                                            55.92259625784828,
                                            17.20231717142589
                                        ]
                                    ],
                                    [
                                        [
                                            55.90885858163157,
                                            17.391581858299283
                                        ],
                                        [
                                            56.034984707757694,
                                            17.391581858299283
                                        ],
                                        [
                                            56.034984707757694,
                                            17.26545573217316
                                        ],
                                        [
                                            55.90885858163157,
                                            17.26545573217316
                                        ]
                                    ],
                                    [
                                        [
                                            55.89511438891818,
                                            17.45471950428012
                                        ],
                                        [
                                            56.021240515044305,
                                            17.45471950428012
                                        ],
                                        [
                                            56.021240515044305,
                                            17.328593378153997
                                        ],
                                        [
                                            55.89511438891818,
                                            17.328593378153997
                                        ]
                                    ],
                                    [
                                        [
                                            55.88136365136577,
                                            17.517856231844164
                                        ],
                                        [
                                            56.00748977749189,
                                            17.517856231844164
                                        ],
                                        [
                                            56.00748977749189,
                                            17.39173010571804
                                        ],
                                        [
                                            55.88136365136577,
                                            17.39173010571804
                                        ]
                                    ],
                                    [
                                        [
                                            55.86760633794063,
                                            17.58099203733862
                                        ],
                                        [
                                            55.99373246406675,
                                            17.58099203733862
                                        ],
                                        [
                                            55.99373246406675,
                                            17.454865911212497
                                        ],
                                        [
                                            55.86760633794063,
                                            17.454865911212497
                                        ]
                                    ],
                                    [
                                        [
                                            55.85384241949972,
                                            17.64412691710828
                                        ],
                                        [
                                            55.979968545625844,
                                            17.64412691710828
                                        ],
                                        [
                                            55.979968545625844,
                                            17.518000790982157
                                        ],
                                        [
                                            55.85384241949972,
                                            17.518000790982157
                                        ]
                                    ],
                                    [
                                        [
                                            55.84007186683598,
                                            17.70726086749552
                                        ],
                                        [
                                            55.9661979929621,
                                            17.70726086749552
                                        ],
                                        [
                                            55.9661979929621,
                                            17.581134741369397
                                        ],
                                        [
                                            55.84007186683598,
                                            17.581134741369397
                                        ]
                                    ],
                                    [
                                        [
                                            55.82629464868989,
                                            17.77039388484023
                                        ],
                                        [
                                            55.95242077481601,
                                            17.77039388484023
                                        ],
                                        [
                                            55.95242077481601,
                                            17.644267758714108
                                        ],
                                        [
                                            55.82629464868989,
                                            17.644267758714108
                                        ]
                                    ],
                                    [
                                        [
                                            55.81251073636544,
                                            17.83352596547986
                                        ],
                                        [
                                            55.93863686249156,
                                            17.83352596547986
                                        ],
                                        [
                                            55.93863686249156,
                                            17.707399839353737
                                        ],
                                        [
                                            55.81251073636544,
                                            17.707399839353737
                                        ]
                                    ],
                                    [
                                        [
                                            55.79872009846025,
                                            17.896657105749313
                                        ],
                                        [
                                            55.924846224586375,
                                            17.896657105749313
                                        ],
                                        [
                                            55.924846224586375,
                                            17.77053097962319
                                        ],
                                        [
                                            55.79872009846025,
                                            17.77053097962319
                                        ]
                                    ],
                                    [
                                        [
                                            55.78492270613144,
                                            17.959787301981024
                                        ],
                                        [
                                            55.91104883225756,
                                            17.959787301981024
                                        ],
                                        [
                                            55.91104883225756,
                                            17.8336611758549
                                        ],
                                        [
                                            55.78492270613144,
                                            17.8336611758549
                                        ]
                                    ],
                                    [
                                        [
                                            55.77111852782577,
                                            18.022916550504853
                                        ],
                                        [
                                            55.897244653951894,
                                            18.022916550504853
                                        ],
                                        [
                                            55.897244653951894,
                                            17.89679042437873
                                        ],
                                        [
                                            55.77111852782577,
                                            17.89679042437873
                                        ]
                                    ],
                                    [
                                        [
                                            55.75730753455511,
                                            18.08604484764811
                                        ],
                                        [
                                            55.883433660681234,
                                            18.08604484764811
                                        ],
                                        [
                                            55.883433660681234,
                                            17.959918721521987
                                        ],
                                        [
                                            55.75730753455511,
                                            17.959918721521987
                                        ]
                                    ],
                                    [
                                        [
                                            55.74348969461209,
                                            18.149172189735562
                                        ],
                                        [
                                            55.86961582073821,
                                            18.149172189735562
                                        ],
                                        [
                                            55.86961582073821,
                                            18.02304606360944
                                        ],
                                        [
                                            55.74348969461209,
                                            18.02304606360944
                                        ]
                                    ],
                                    [
                                        [
                                            55.72966497886175,
                                            18.21229857308932
                                        ],
                                        [
                                            55.85579110498787,
                                            18.21229857308932
                                        ],
                                        [
                                            55.85579110498787,
                                            18.086172446963197
                                        ],
                                        [
                                            55.72966497886175,
                                            18.086172446963197
                                        ]
                                    ],
                                    [
                                        [
                                            55.71583335545063,
                                            18.275423994028912
                                        ],
                                        [
                                            55.84195948157675,
                                            18.275423994028912
                                        ],
                                        [
                                            55.84195948157675,
                                            18.14929786790279
                                        ],
                                        [
                                            55.71583335545063,
                                            18.14929786790279
                                        ]
                                    ],
                                    [
                                        [
                                            55.701994795082385,
                                            18.338548448871233
                                        ],
                                        [
                                            55.82812092120851,
                                            18.338548448871233
                                        ],
                                        [
                                            55.82812092120851,
                                            18.21242232274511
                                        ],
                                        [
                                            55.701994795082385,
                                            18.21242232274511
                                        ]
                                    ],
                                    [
                                        [
                                            55.68814926575748,
                                            18.40167193393049
                                        ],
                                        [
                                            55.814275391883605,
                                            18.40167193393049
                                        ],
                                        [
                                            55.814275391883605,
                                            18.275545807804367
                                        ],
                                        [
                                            55.68814926575748,
                                            18.275545807804367
                                        ]
                                    ],
                                    [
                                        [
                                            55.674296738024516,
                                            18.464794445518223
                                        ],
                                        [
                                            55.80042286415064,
                                            18.464794445518223
                                        ],
                                        [
                                            55.80042286415064,
                                            18.3386683193921
                                        ],
                                        [
                                            55.674296738024516,
                                            18.3386683193921
                                        ]
                                    ],
                                    [
                                        [
                                            55.66043717972818,
                                            18.527915979943252
                                        ],
                                        [
                                            55.7865633058543,
                                            18.527915979943252
                                        ],
                                        [
                                            55.7865633058543,
                                            18.401789853817128
                                        ],
                                        [
                                            55.66043717972818,
                                            18.401789853817128
                                        ]
                                    ],
                                    [
                                        [
                                            55.64657056126528,
                                            18.591036533511723
                                        ],
                                        [
                                            55.7726966873914,
                                            18.591036533511723
                                        ],
                                        [
                                            55.7726966873914,
                                            18.4649104073856
                                        ],
                                        [
                                            55.64657056126528,
                                            18.4649104073856
                                        ]
                                    ],
                                    [
                                        [
                                            55.63269685032295,
                                            18.65415610252696
                                        ],
                                        [
                                            55.758822976449075,
                                            18.65415610252696
                                        ],
                                        [
                                            55.758822976449075,
                                            18.528029976400838
                                        ],
                                        [
                                            55.63269685032295,
                                            18.528029976400838
                                        ]
                                    ],
                                    [
                                        [
                                            55.618816017142976,
                                            18.71727468328958
                                        ],
                                        [
                                            55.7449421432691,
                                            18.71727468328958
                                        ],
                                        [
                                            55.7449421432691,
                                            18.591148557163457
                                        ],
                                        [
                                            55.618816017142976,
                                            18.591148557163457
                                        ]
                                    ],
                                    [
                                        [
                                            55.60492802925166,
                                            18.78039227209739
                                        ],
                                        [
                                            55.731054155377784,
                                            18.78039227209739
                                        ],
                                        [
                                            55.731054155377784,
                                            18.654266145971267
                                        ],
                                        [
                                            55.60492802925166,
                                            18.654266145971267
                                        ]
                                    ],
                                    [
                                        [
                                            55.59103285673234,
                                            18.84350886524535
                                        ],
                                        [
                                            55.717158982858464,
                                            18.84350886524535
                                        ],
                                        [
                                            55.717158982858464,
                                            18.717382739119227
                                        ],
                                        [
                                            55.59103285673234,
                                            18.717382739119227
                                        ]
                                    ],
                                    [
                                        [
                                            55.57713046695531,
                                            18.90662445902567
                                        ],
                                        [
                                            55.703256593081434,
                                            18.90662445902567
                                        ],
                                        [
                                            55.703256593081434,
                                            18.780498332899548
                                        ],
                                        [
                                            55.57713046695531,
                                            18.780498332899548
                                        ]
                                    ],
                                    [
                                        [
                                            55.563220829840475,
                                            18.96973904972761
                                        ],
                                        [
                                            55.6893469559666,
                                            18.96973904972761
                                        ],
                                        [
                                            55.6893469559666,
                                            18.843612923601487
                                        ],
                                        [
                                            55.563220829840475,
                                            18.843612923601487
                                        ]
                                    ],
                                    [
                                        [
                                            55.54930391260046,
                                            19.032852633637614
                                        ],
                                        [
                                            55.67543003872658,
                                            19.032852633637614
                                        ],
                                        [
                                            55.67543003872658,
                                            18.90672650751149
                                        ],
                                        [
                                            55.54930391260046,
                                            18.90672650751149
                                        ]
                                    ],
                                    [
                                        [
                                            55.535379684994915,
                                            19.095965207039182
                                        ],
                                        [
                                            55.66150581112104,
                                            19.095965207039182
                                        ],
                                        [
                                            55.66150581112104,
                                            18.969839080913058
                                        ],
                                        [
                                            55.535379684994915,
                                            18.969839080913058
                                        ]
                                    ],
                                    [
                                        [
                                            55.52144811406886,
                                            19.15907676621295
                                        ],
                                        [
                                            55.647574240194984,
                                            19.15907676621295
                                        ],
                                        [
                                            55.647574240194984,
                                            19.032950640086828
                                        ],
                                        [
                                            55.52144811406886,
                                            19.032950640086828
                                        ]
                                    ],
                                    [
                                        [
                                            55.50750916941834,
                                            19.22218730743652
                                        ],
                                        [
                                            55.633635295544465,
                                            19.22218730743652
                                        ],
                                        [
                                            55.633635295544465,
                                            19.096061181310397
                                        ],
                                        [
                                            55.50750916941834,
                                            19.096061181310397
                                        ]
                                    ],
                                    [
                                        [
                                            55.493562817922275,
                                            19.285296826984602
                                        ],
                                        [
                                            55.6196889440484,
                                            19.285296826984602
                                        ],
                                        [
                                            55.6196889440484,
                                            19.15917070085848
                                        ],
                                        [
                                            55.493562817922275,
                                            19.15917070085848
                                        ]
                                    ],
                                    [
                                        [
                                            55.479609029017865,
                                            19.348405321128862
                                        ],
                                        [
                                            55.60573515514399,
                                            19.348405321128862
                                        ],
                                        [
                                            55.60573515514399,
                                            19.222279195002738
                                        ],
                                        [
                                            55.479609029017865,
                                            19.222279195002738
                                        ]
                                    ],
                                    [
                                        [
                                            55.46564776941775,
                                            19.411512786137983
                                        ],
                                        [
                                            55.591773895543874,
                                            19.411512786137983
                                        ],
                                        [
                                            55.591773895543874,
                                            19.28538666001186
                                        ],
                                        [
                                            55.46564776941775,
                                            19.28538666001186
                                        ]
                                    ],
                                    [
                                        [
                                            55.45167900838393,
                                            19.474619218277592
                                        ],
                                        [
                                            55.57780513451005,
                                            19.474619218277592
                                        ],
                                        [
                                            55.57780513451005,
                                            19.348493092151468
                                        ],
                                        [
                                            55.45167900838393,
                                            19.348493092151468
                                        ]
                                    ],
                                    [
                                        [
                                            55.437702712467456,
                                            19.537724613810262
                                        ],
                                        [
                                            55.56382883859358,
                                            19.537724613810262
                                        ],
                                        [
                                            55.56382883859358,
                                            19.411598487684138
                                        ],
                                        [
                                            55.437702712467456,
                                            19.411598487684138
                                        ]
                                    ],
                                    [
                                        [
                                            55.4237188507598,
                                            19.600828968995422
                                        ],
                                        [
                                            55.54984497688592,
                                            19.600828968995422
                                        ],
                                        [
                                            55.54984497688592,
                                            19.4747028428693
                                        ],
                                        [
                                            55.4237188507598,
                                            19.4747028428693
                                        ]
                                    ],
                                    [
                                        [
                                            55.40972738964055,
                                            19.66393228008949
                                        ],
                                        [
                                            55.535853515766675,
                                            19.66393228008949
                                        ],
                                        [
                                            55.535853515766675,
                                            19.537806153963366
                                        ],
                                        [
                                            55.40972738964055,
                                            19.537806153963366
                                        ]
                                    ],
                                    [
                                        [
                                            55.39572829803371,
                                            19.72703454334568
                                        ],
                                        [
                                            55.521854424159834,
                                            19.72703454334568
                                        ],
                                        [
                                            55.521854424159834,
                                            19.600908417219557
                                        ],
                                        [
                                            55.39572829803371,
                                            19.600908417219557
                                        ]
                                    ],
                                    [
                                        [
                                            55.38172154214721,
                                            19.79013575501404
                                        ],
                                        [
                                            55.507847668273335,
                                            19.79013575501404
                                        ],
                                        [
                                            55.507847668273335,
                                            19.664009628887918
                                        ],
                                        [
                                            55.38172154214721,
                                            19.664009628887918
                                        ]
                                    ],
                                    [
                                        [
                                            55.36770709072924,
                                            19.85323591134147
                                        ],
                                        [
                                            55.493833216855364,
                                            19.85323591134147
                                        ],
                                        [
                                            55.493833216855364,
                                            19.727109785215347
                                        ],
                                        [
                                            55.36770709072924,
                                            19.727109785215347
                                        ]
                                    ],
                                    [
                                        [
                                            55.35368490981427,
                                            19.91633500857161
                                        ],
                                        [
                                            55.47981103594039,
                                            19.91633500857161
                                        ],
                                        [
                                            55.47981103594039,
                                            19.790208882445487
                                        ],
                                        [
                                            55.35368490981427,
                                            19.790208882445487
                                        ]
                                    ],
                                    [
                                        [
                                            55.3396549679793,
                                            19.979433042944923
                                        ],
                                        [
                                            55.465781094105424,
                                            19.979433042944923
                                        ],
                                        [
                                            55.465781094105424,
                                            19.8533069168188
                                        ],
                                        [
                                            55.3396549679793,
                                            19.8533069168188
                                        ]
                                    ],
                                    [
                                        [
                                            55.325617231083505,
                                            20.04253001069858
                                        ],
                                        [
                                            55.45174335720963,
                                            20.04253001069858
                                        ],
                                        [
                                            55.45174335720963,
                                            19.916403884572457
                                        ],
                                        [
                                            55.325617231083505,
                                            19.916403884572457
                                        ]
                                    ],
                                    [
                                        [
                                            55.31157166686972,
                                            20.105625908066443
                                        ],
                                        [
                                            55.43769779299584,
                                            20.105625908066443
                                        ],
                                        [
                                            55.43769779299584,
                                            19.97949978194032
                                        ],
                                        [
                                            55.31157166686972,
                                            19.97949978194032
                                        ]
                                    ],
                                    [
                                        [
                                            55.29751824298546,
                                            20.168720731279123
                                        ],
                                        [
                                            55.423644369111585,
                                            20.168720731279123
                                        ],
                                        [
                                            55.423644369111585,
                                            20.042594605153
                                        ],
                                        [
                                            55.29751824298546,
                                            20.042594605153
                                        ]
                                    ],
                                    [
                                        [
                                            55.283456925029896,
                                            20.23181447656385
                                        ],
                                        [
                                            55.40958305115602,
                                            20.23181447656385
                                        ],
                                        [
                                            55.40958305115602,
                                            20.105688350437728
                                        ],
                                        [
                                            55.283456925029896,
                                            20.105688350437728
                                        ]
                                    ],
                                    [
                                        [
                                            55.26938768113095,
                                            20.294907140144513
                                        ],
                                        [
                                            55.395513807257075,
                                            20.294907140144513
                                        ],
                                        [
                                            55.395513807257075,
                                            20.16878101401839
                                        ],
                                        [
                                            55.26938768113095,
                                            20.16878101401839
                                        ]
                                    ],
                                    [
                                        [
                                            55.25531047669963,
                                            20.357998718241582
                                        ],
                                        [
                                            55.38143660282575,
                                            20.357998718241582
                                        ],
                                        [
                                            55.38143660282575,
                                            20.231872592115458
                                        ],
                                        [
                                            55.25531047669963,
                                            20.231872592115458
                                        ]
                                    ],
                                    [
                                        [
                                            55.24122527968933,
                                            20.421089207072132
                                        ],
                                        [
                                            55.367351405815455,
                                            20.421089207072132
                                        ],
                                        [
                                            55.367351405815455,
                                            20.294963080946008
                                        ],
                                        [
                                            55.24122527968933,
                                            20.294963080946008
                                        ]
                                    ],
                                    [
                                        [
                                            55.22713205533568,
                                            20.484178602849823
                                        ],
                                        [
                                            55.353258181461804,
                                            20.484178602849823
                                        ],
                                        [
                                            55.353258181461804,
                                            20.3580524767237
                                        ],
                                        [
                                            55.22713205533568,
                                            20.3580524767237
                                        ]
                                    ],
                                    [
                                        [
                                            55.21303077139455,
                                            20.547266901784813
                                        ],
                                        [
                                            55.339156897520674,
                                            20.547266901784813
                                        ],
                                        [
                                            55.339156897520674,
                                            20.42114077565869
                                        ],
                                        [
                                            55.21303077139455,
                                            20.42114077565869
                                        ]
                                    ],
                                    [
                                        [
                                            55.19892139292086,
                                            20.61035410008377
                                        ],
                                        [
                                            55.32504751904698,
                                            20.61035410008377
                                        ],
                                        [
                                            55.32504751904698,
                                            20.484227973957648
                                        ],
                                        [
                                            55.19892139292086,
                                            20.484227973957648
                                        ]
                                    ],
                                    [
                                        [
                                            55.18480388749218,
                                            20.67344019394986
                                        ],
                                        [
                                            55.310930013618304,
                                            20.67344019394986
                                        ],
                                        [
                                            55.310930013618304,
                                            20.547314067823738
                                        ],
                                        [
                                            55.18480388749218,
                                            20.547314067823738
                                        ]
                                    ],
                                    [
                                        [
                                            55.170678219974285,
                                            20.736525179582703
                                        ],
                                        [
                                            55.29680434610041,
                                            20.736525179582703
                                        ],
                                        [
                                            55.29680434610041,
                                            20.61039905345658
                                        ],
                                        [
                                            55.170678219974285,
                                            20.61039905345658
                                        ]
                                    ],
                                    [
                                        [
                                            55.15654435775967,
                                            20.79960905317829
                                        ],
                                        [
                                            55.282670483885795,
                                            20.79960905317829
                                        ],
                                        [
                                            55.282670483885795,
                                            20.673482927052167
                                        ],
                                        [
                                            55.15654435775967,
                                            20.673482927052167
                                        ]
                                    ],
                                    [
                                        [
                                            55.14240226552461,
                                            20.862691810929082
                                        ],
                                        [
                                            55.26852839165073,
                                            20.862691810929082
                                        ],
                                        [
                                            55.26852839165073,
                                            20.736565684802958
                                        ],
                                        [
                                            55.14240226552461,
                                            20.736565684802958
                                        ]
                                    ],
                                    [
                                        [
                                            55.12825191046949,
                                            20.925773449023882
                                        ],
                                        [
                                            55.25437803659561,
                                            20.925773449023882
                                        ],
                                        [
                                            55.25437803659561,
                                            20.79964732289776
                                        ],
                                        [
                                            55.12825191046949,
                                            20.79964732289776
                                        ]
                                    ],
                                    [
                                        [
                                            55.11409325708418,
                                            20.988853963647813
                                        ],
                                        [
                                            55.2402193832103,
                                            20.988853963647813
                                        ],
                                        [
                                            55.2402193832103,
                                            20.86272783752169
                                        ],
                                        [
                                            55.11409325708418,
                                            20.86272783752169
                                        ]
                                    ],
                                    [
                                        [
                                            55.09992627238146,
                                            21.051933350982363
                                        ],
                                        [
                                            55.22605239850758,
                                            21.051933350982363
                                        ],
                                        [
                                            55.22605239850758,
                                            20.92580722485624
                                        ],
                                        [
                                            55.09992627238146,
                                            20.92580722485624
                                        ]
                                    ],
                                    [
                                        [
                                            55.08575092065455,
                                            21.115011607205243
                                        ],
                                        [
                                            55.21187704678067,
                                            21.115011607205243
                                        ],
                                        [
                                            55.21187704678067,
                                            20.98888548107912
                                        ],
                                        [
                                            55.08575092065455,
                                            20.98888548107912
                                        ]
                                    ],
                                    [
                                        [
                                            55.071567168728265,
                                            21.178088728490472
                                        ],
                                        [
                                            55.19769329485439,
                                            21.178088728490472
                                        ],
                                        [
                                            55.19769329485439,
                                            21.05196260236435
                                        ],
                                        [
                                            55.071567168728265,
                                            21.05196260236435
                                        ]
                                    ],
                                    [
                                        [
                                            55.057374980692266,
                                            21.24116471100827
                                        ],
                                        [
                                            55.18350110681839,
                                            21.24116471100827
                                        ],
                                        [
                                            55.18350110681839,
                                            21.115038584882146
                                        ],
                                        [
                                            55.057374980692266,
                                            21.115038584882146
                                        ]
                                    ],
                                    [
                                        [
                                            55.04317432318146,
                                            21.304239550925093
                                        ],
                                        [
                                            55.169300449307585,
                                            21.304239550925093
                                        ],
                                        [
                                            55.169300449307585,
                                            21.17811342479897
                                        ],
                                        [
                                            55.04317432318146,
                                            21.17811342479897
                                        ]
                                    ],
                                    [
                                        [
                                            55.02896516009929,
                                            21.367313244403523
                                        ],
                                        [
                                            55.155091286225414,
                                            21.367313244403523
                                        ],
                                        [
                                            55.155091286225414,
                                            21.2411871182774
                                        ],
                                        [
                                            55.02896516009929,
                                            21.2411871182774
                                        ]
                                    ],
                                    [
                                        [
                                            55.014747457874016,
                                            21.430385787602322
                                        ],
                                        [
                                            55.14087358400014,
                                            21.430385787602322
                                        ],
                                        [
                                            55.14087358400014,
                                            21.3042596614762
                                        ],
                                        [
                                            55.014747457874016,
                                            21.3042596614762
                                        ]
                                    ],
                                    [
                                        [
                                            55.000521180212885,
                                            21.49345717667634
                                        ],
                                        [
                                            55.12664730633901,
                                            21.49345717667634
                                        ],
                                        [
                                            55.12664730633901,
                                            21.367331050550217
                                        ],
                                        [
                                            55.000521180212885,
                                            21.367331050550217
                                        ]
                                    ],
                                    [
                                        [
                                            54.98628629334188,
                                            21.55652740777653
                                        ],
                                        [
                                            55.112412419468,
                                            21.55652740777653
                                        ],
                                        [
                                            55.112412419468,
                                            21.430401281650408
                                        ],
                                        [
                                            54.98628629334188,
                                            21.430401281650408
                                        ]
                                    ],
                                    [
                                        [
                                            54.97204276077139,
                                            21.619596477049893
                                        ],
                                        [
                                            55.09816888689751,
                                            21.619596477049893
                                        ],
                                        [
                                            55.09816888689751,
                                            21.49347035092377
                                        ],
                                        [
                                            54.97204276077139,
                                            21.49347035092377
                                        ]
                                    ],
                                    [
                                        [
                                            54.95779054853127,
                                            21.682664380639434
                                        ],
                                        [
                                            55.083916674657395,
                                            21.682664380639434
                                        ],
                                        [
                                            55.083916674657395,
                                            21.55653825451331
                                        ],
                                        [
                                            54.95779054853127,
                                            21.55653825451331
                                        ]
                                    ],
                                    [
                                        [
                                            54.94352961992001,
                                            21.74573111468419
                                        ],
                                        [
                                            55.06965574604613,
                                            21.74573111468419
                                        ],
                                        [
                                            55.06965574604613,
                                            21.619604988558066
                                        ],
                                        [
                                            54.94352961992001,
                                            21.619604988558066
                                        ]
                                    ],
                                    [
                                        [
                                            54.92925994076916,
                                            21.80879667531914
                                        ],
                                        [
                                            55.05538606689528,
                                            21.80879667531914
                                        ],
                                        [
                                            55.05538606689528,
                                            21.682670549193016
                                        ],
                                        [
                                            54.92925994076916,
                                            21.682670549193016
                                        ]
                                    ],
                                    [
                                        [
                                            54.91498147417463,
                                            21.871861058675194
                                        ],
                                        [
                                            55.041107600300755,
                                            21.871861058675194
                                        ],
                                        [
                                            55.041107600300755,
                                            21.74573493254907
                                        ],
                                        [
                                            54.91498147417463,
                                            21.74573493254907
                                        ]
                                    ],
                                    [
                                        [
                                            54.90069418576089,
                                            21.934924260879182
                                        ],
                                        [
                                            55.026820311887015,
                                            21.934924260879182
                                        ],
                                        [
                                            55.026820311887015,
                                            21.808798134753058
                                        ],
                                        [
                                            54.90069418576089,
                                            21.808798134753058
                                        ]
                                    ],
                                    [
                                        [
                                            54.886398038417525,
                                            21.997986278053784
                                        ],
                                        [
                                            55.01252416454365,
                                            21.997986278053784
                                        ],
                                        [
                                            55.01252416454365,
                                            21.87186015192766
                                        ],
                                        [
                                            54.886398038417525,
                                            21.87186015192766
                                        ]
                                    ],
                                    [
                                        [
                                            48.94221810301104,
                                            12.504986770721203
                                        ],
                                        [
                                            49.06834422913716,
                                            12.504986770721203
                                        ],
                                        [
                                            49.06834422913716,
                                            12.378860644595076
                                        ],
                                        [
                                            48.94221810301104,
                                            12.378860644595076
                                        ]
                                    ],
                                    [
                                        [
                                            48.928920424872466,
                                            12.568070441588583
                                        ],
                                        [
                                            49.05504655099859,
                                            12.568070441588583
                                        ],
                                        [
                                            49.05504655099859,
                                            12.441944315462456
                                        ],
                                        [
                                            48.928920424872466,
                                            12.441944315462456
                                        ]
                                    ],
                                    [
                                        [
                                            48.91561830449028,
                                            12.631153672657463
                                        ],
                                        [
                                            49.041744430616404,
                                            12.631153672657463
                                        ],
                                        [
                                            49.041744430616404,
                                            12.505027546531336
                                        ],
                                        [
                                            48.91561830449028,
                                            12.505027546531336
                                        ]
                                    ],
                                    [
                                        [
                                            48.90231171542142,
                                            12.694236460337484
                                        ],
                                        [
                                            49.02843784154754,
                                            12.694236460337484
                                        ],
                                        [
                                            49.02843784154754,
                                            12.568110334211356
                                        ],
                                        [
                                            48.90231171542142,
                                            12.568110334211356
                                        ]
                                    ],
                                    [
                                        [
                                            48.8890006338154,
                                            12.757318801036694
                                        ],
                                        [
                                            49.01512675994152,
                                            12.757318801036694
                                        ],
                                        [
                                            49.01512675994152,
                                            12.631192674910567
                                        ],
                                        [
                                            48.8890006338154,
                                            12.631192674910567
                                        ]
                                    ],
                                    [
                                        [
                                            48.875685033143995,
                                            12.820400691161543
                                        ],
                                        [
                                            49.00181115927012,
                                            12.820400691161543
                                        ],
                                        [
                                            49.00181115927012,
                                            12.694274565035416
                                        ],
                                        [
                                            48.875685033143995,
                                            12.694274565035416
                                        ]
                                    ],
                                    [
                                        [
                                            48.86236488945962,
                                            12.883482127116894
                                        ],
                                        [
                                            48.988491015585744,
                                            12.883482127116894
                                        ],
                                        [
                                            48.988491015585744,
                                            12.757356000990766
                                        ],
                                        [
                                            48.86236488945962,
                                            12.757356000990766
                                        ]
                                    ],
                                    [
                                        [
                                            48.84904017614153,
                                            12.946563105306025
                                        ],
                                        [
                                            48.97516630226765,
                                            12.946563105306025
                                        ],
                                        [
                                            48.97516630226765,
                                            12.820436979179897
                                        ],
                                        [
                                            48.84904017614153,
                                            12.820436979179897
                                        ]
                                    ],
                                    [
                                        [
                                            48.83571086915191,
                                            13.009643622130554
                                        ],
                                        [
                                            48.961836995278034,
                                            13.009643622130554
                                        ],
                                        [
                                            48.961836995278034,
                                            12.883517496004426
                                        ],
                                        [
                                            48.83571086915191,
                                            12.883517496004426
                                        ]
                                    ],
                                    [
                                        [
                                            48.822376941774486,
                                            13.072723673990513
                                        ],
                                        [
                                            48.94850306790061,
                                            13.072723673990513
                                        ],
                                        [
                                            48.94850306790061,
                                            12.946597547864386
                                        ],
                                        [
                                            48.822376941774486,
                                            12.946597547864386
                                        ]
                                    ],
                                    [
                                        [
                                            48.80903836987868,
                                            13.135803257284264
                                        ],
                                        [
                                            48.9351644960048,
                                            13.135803257284264
                                        ],
                                        [
                                            48.9351644960048,
                                            13.009677131158137
                                        ],
                                        [
                                            48.80903836987868,
                                            13.009677131158137
                                        ]
                                    ],
                                    [
                                        [
                                            48.79569512664984,
                                            13.198882368408524
                                        ],
                                        [
                                            48.92182125277596,
                                            13.198882368408524
                                        ],
                                        [
                                            48.92182125277596,
                                            13.072756242282397
                                        ],
                                        [
                                            48.79569512664984,
                                            13.072756242282397
                                        ]
                                    ],
                                    [
                                        [
                                            48.78234718786174,
                                            13.261961003758353
                                        ],
                                        [
                                            48.90847331398786,
                                            13.261961003758353
                                        ],
                                        [
                                            48.90847331398786,
                                            13.135834877632226
                                        ],
                                        [
                                            48.78234718786174,
                                            13.135834877632226
                                        ]
                                    ],
                                    [
                                        [
                                            48.7689945266067,
                                            13.325039159727144
                                        ],
                                        [
                                            48.89512065273282,
                                            13.325039159727144
                                        ],
                                        [
                                            48.89512065273282,
                                            13.198913033601016
                                        ],
                                        [
                                            48.7689945266067,
                                            13.198913033601016
                                        ]
                                    ],
                                    [
                                        [
                                            48.75563711856003,
                                            13.388116832706574
                                        ],
                                        [
                                            48.881763244686155,
                                            13.388116832706574
                                        ],
                                        [
                                            48.881763244686155,
                                            13.261990706580447
                                        ],
                                        [
                                            48.75563711856003,
                                            13.261990706580447
                                        ]
                                    ],
                                    [
                                        [
                                            48.74227493671664,
                                            13.451194019086653
                                        ],
                                        [
                                            48.868401062842764,
                                            13.451194019086653
                                        ],
                                        [
                                            48.868401062842764,
                                            13.325067892960526
                                        ],
                                        [
                                            48.74227493671664,
                                            13.325067892960526
                                        ]
                                    ],
                                    [
                                        [
                                            48.72890795665534,
                                            13.514270715255673
                                        ],
                                        [
                                            48.855034082781465,
                                            13.514270715255673
                                        ],
                                        [
                                            48.855034082781465,
                                            13.388144589129546
                                        ],
                                        [
                                            48.72890795665534,
                                            13.388144589129546
                                        ]
                                    ],
                                    [
                                        [
                                            48.715536151270825,
                                            13.577346917600204
                                        ],
                                        [
                                            48.84166227739695,
                                            13.577346917600204
                                        ],
                                        [
                                            48.84166227739695,
                                            13.451220791474077
                                        ],
                                        [
                                            48.715536151270825,
                                            13.451220791474077
                                        ]
                                    ],
                                    [
                                        [
                                            48.70215949604109,
                                            13.640422622505064
                                        ],
                                        [
                                            48.828285622167215,
                                            13.640422622505064
                                        ],
                                        [
                                            48.828285622167215,
                                            13.514296496378936
                                        ],
                                        [
                                            48.70215949604109,
                                            13.514296496378936
                                        ]
                                    ],
                                    [
                                        [
                                            48.6887779637592,
                                            13.703497826353344
                                        ],
                                        [
                                            48.814904089885324,
                                            13.703497826353344
                                        ],
                                        [
                                            48.814904089885324,
                                            13.577371700227216
                                        ],
                                        [
                                            48.6887779637592,
                                            13.577371700227216
                                        ]
                                    ],
                                    [
                                        [
                                            48.67539152980926,
                                            13.766572525526383
                                        ],
                                        [
                                            48.801517655935385,
                                            13.766572525526383
                                        ],
                                        [
                                            48.801517655935385,
                                            13.640446399400256
                                        ],
                                        [
                                            48.67539152980926,
                                            13.640446399400256
                                        ]
                                    ],
                                    [
                                        [
                                            48.66200016688331,
                                            13.829646716403744
                                        ],
                                        [
                                            48.788126293009434,
                                            13.829646716403744
                                        ],
                                        [
                                            48.788126293009434,
                                            13.703520590277616
                                        ],
                                        [
                                            48.66200016688331,
                                            13.703520590277616
                                        ]
                                    ],
                                    [
                                        [
                                            48.64860385025559,
                                            13.892720395363193
                                        ],
                                        [
                                            48.774729976381714,
                                            13.892720395363193
                                        ],
                                        [
                                            48.774729976381714,
                                            13.766594269237066
                                        ],
                                        [
                                            48.64860385025559,
                                            13.766594269237066
                                        ]
                                    ],
                                    [
                                        [
                                            48.63520255252072,
                                            13.955793558780723
                                        ],
                                        [
                                            48.76132867864684,
                                            13.955793558780723
                                        ],
                                        [
                                            48.76132867864684,
                                            13.829667432654595
                                        ],
                                        [
                                            48.63520255252072,
                                            13.829667432654595
                                        ]
                                    ],
                                    [
                                        [
                                            48.62179624819539,
                                            14.018866203030504
                                        ],
                                        [
                                            48.74792237432151,
                                            14.018866203030504
                                        ],
                                        [
                                            48.74792237432151,
                                            13.892740076904376
                                        ],
                                        [
                                            48.62179624819539,
                                            13.892740076904376
                                        ]
                                    ],
                                    [
                                        [
                                            48.608384911739456,
                                            14.081938324484893
                                        ],
                                        [
                                            48.73451103786558,
                                            14.081938324484893
                                        ],
                                        [
                                            48.73451103786558,
                                            13.955812198358766
                                        ],
                                        [
                                            48.608384911739456,
                                            13.955812198358766
                                        ]
                                    ],
                                    [
                                        [
                                            48.59496851559343,
                                            14.145009919514424
                                        ],
                                        [
                                            48.72109464171955,
                                            14.145009919514424
                                        ],
                                        [
                                            48.72109464171955,
                                            14.018883793388296
                                        ],
                                        [
                                            48.59496851559343,
                                            14.018883793388296
                                        ]
                                    ],
                                    [
                                        [
                                            48.581547034770296,
                                            14.208080984487763
                                        ],
                                        [
                                            48.70767316089642,
                                            14.208080984487763
                                        ],
                                        [
                                            48.70767316089642,
                                            14.081954858361636
                                        ],
                                        [
                                            48.581547034770296,
                                            14.081954858361636
                                        ]
                                    ],
                                    [
                                        [
                                            48.56812044160514,
                                            14.271151515771734
                                        ],
                                        [
                                            48.69424656773126,
                                            14.271151515771734
                                        ],
                                        [
                                            48.69424656773126,
                                            14.145025389645607
                                        ],
                                        [
                                            48.56812044160514,
                                            14.145025389645607
                                        ]
                                    ],
                                    [
                                        [
                                            48.5546887110033,
                                            14.334221509731293
                                        ],
                                        [
                                            48.680814837129425,
                                            14.334221509731293
                                        ],
                                        [
                                            48.680814837129425,
                                            14.208095383605166
                                        ],
                                        [
                                            48.5546887110033,
                                            14.208095383605166
                                        ]
                                    ],
                                    [
                                        [
                                            48.541251815196425,
                                            14.397290962729503
                                        ],
                                        [
                                            48.66737794132255,
                                            14.397290962729503
                                        ],
                                        [
                                            48.66737794132255,
                                            14.271164836603376
                                        ],
                                        [
                                            48.541251815196425,
                                            14.271164836603376
                                        ]
                                    ],
                                    [
                                        [
                                            48.52780972898087,
                                            14.460359871127544
                                        ],
                                        [
                                            48.653935855106994,
                                            14.460359871127544
                                        ],
                                        [
                                            48.653935855106994,
                                            14.334233745001416
                                        ],
                                        [
                                            48.52780972898087,
                                            14.334233745001416
                                        ]
                                    ],
                                    [
                                        [
                                            48.514362424482066,
                                            14.523428231284655
                                        ],
                                        [
                                            48.64048855060819,
                                            14.523428231284655
                                        ],
                                        [
                                            48.64048855060819,
                                            14.397302105158527
                                        ],
                                        [
                                            48.514362424482066,
                                            14.397302105158527
                                        ]
                                    ],
                                    [
                                        [
                                            48.50090987638456,
                                            14.586496039558185
                                        ],
                                        [
                                            48.627036002510685,
                                            14.586496039558185
                                        ],
                                        [
                                            48.627036002510685,
                                            14.460369913432057
                                        ],
                                        [
                                            48.50090987638456,
                                            14.460369913432057
                                        ]
                                    ],
                                    [
                                        [
                                            48.48745205670449,
                                            14.649563292303524
                                        ],
                                        [
                                            48.613578182830615,
                                            14.649563292303524
                                        ],
                                        [
                                            48.613578182830615,
                                            14.523437166177397
                                        ],
                                        [
                                            48.48745205670449,
                                            14.523437166177397
                                        ]
                                    ],
                                    [
                                        [
                                            48.473988940019815,
                                            14.712629985874123
                                        ],
                                        [
                                            48.60011506614594,
                                            14.712629985874123
                                        ],
                                        [
                                            48.60011506614594,
                                            14.586503859747996
                                        ],
                                        [
                                            48.473988940019815,
                                            14.586503859747996
                                        ]
                                    ],
                                    [
                                        [
                                            48.46052049823298,
                                            14.775696116621454
                                        ],
                                        [
                                            48.586646624359105,
                                            14.775696116621454
                                        ],
                                        [
                                            48.586646624359105,
                                            14.649569990495326
                                        ],
                                        [
                                            48.46052049823298,
                                            14.649569990495326
                                        ]
                                    ],
                                    [
                                        [
                                            48.44704670581401,
                                            14.838761680895043
                                        ],
                                        [
                                            48.573172831940134,
                                            14.838761680895043
                                        ],
                                        [
                                            48.573172831940134,
                                            14.712635554768916
                                        ],
                                        [
                                            48.44704670581401,
                                            14.712635554768916
                                        ]
                                    ],
                                    [
                                        [
                                            48.43356753455514,
                                            14.901826675042374
                                        ],
                                        [
                                            48.55969366068126,
                                            14.901826675042374
                                        ],
                                        [
                                            48.55969366068126,
                                            14.775700548916246
                                        ],
                                        [
                                            48.43356753455514,
                                            14.775700548916246
                                        ]
                                    ],
                                    [
                                        [
                                            48.42008295881239,
                                            14.964891095408984
                                        ],
                                        [
                                            48.54620908493851,
                                            14.964891095408984
                                        ],
                                        [
                                            48.54620908493851,
                                            14.838764969282856
                                        ],
                                        [
                                            48.42008295881239,
                                            14.838764969282856
                                        ]
                                    ],
                                    [
                                        [
                                            48.406592950255025,
                                            15.027954938338343
                                        ],
                                        [
                                            48.53271907638115,
                                            15.027954938338343
                                        ],
                                        [
                                            48.53271907638115,
                                            14.901828812212216
                                        ],
                                        [
                                            48.406592950255025,
                                            14.901828812212216
                                        ]
                                    ],
                                    [
                                        [
                                            48.39309748313187,
                                            15.091018200171893
                                        ],
                                        [
                                            48.51922360925799,
                                            15.091018200171893
                                        ],
                                        [
                                            48.51922360925799,
                                            14.964892074045766
                                        ],
                                        [
                                            48.39309748313187,
                                            14.964892074045766
                                        ]
                                    ],
                                    [
                                        [
                                            48.379596529007515,
                                            15.154080877249054
                                        ],
                                        [
                                            48.50572265513364,
                                            15.154080877249054
                                        ],
                                        [
                                            48.50572265513364,
                                            15.027954751122927
                                        ],
                                        [
                                            48.379596529007515,
                                            15.027954751122927
                                        ]
                                    ],
                                    [
                                        [
                                            48.3660900620076,
                                            15.217142965907154
                                        ],
                                        [
                                            48.492216188133725,
                                            15.217142965907154
                                        ],
                                        [
                                            48.492216188133725,
                                            15.091016839781027
                                        ],
                                        [
                                            48.3660900620076,
                                            15.091016839781027
                                        ]
                                    ],
                                    [
                                        [
                                            48.35257805357444,
                                            15.280204462481473
                                        ],
                                        [
                                            48.478704179700564,
                                            15.280204462481473
                                        ],
                                        [
                                            48.478704179700564,
                                            15.154078336355346
                                        ],
                                        [
                                            48.35257805357444,
                                            15.154078336355346
                                        ]
                                    ],
                                    [
                                        [
                                            48.339060477723685,
                                            15.343265363305164
                                        ],
                                        [
                                            48.46518660384981,
                                            15.343265363305164
                                        ],
                                        [
                                            48.46518660384981,
                                            15.217139237179037
                                        ],
                                        [
                                            48.339060477723685,
                                            15.217139237179037
                                        ]
                                    ],
                                    [
                                        [
                                            48.32553730578552,
                                            15.406325664709314
                                        ],
                                        [
                                            48.45166343191164,
                                            15.406325664709314
                                        ],
                                        [
                                            48.45166343191164,
                                            15.280199538583187
                                        ],
                                        [
                                            48.32553730578552,
                                            15.280199538583187
                                        ]
                                    ],
                                    [
                                        [
                                            48.31200851165128,
                                            15.469385363022845
                                        ],
                                        [
                                            48.4381346377774,
                                            15.469385363022845
                                        ],
                                        [
                                            48.4381346377774,
                                            15.343259236896717
                                        ],
                                        [
                                            48.31200851165128,
                                            15.343259236896717
                                        ]
                                    ],
                                    [
                                        [
                                            48.29847406653435,
                                            15.532444454572584
                                        ],
                                        [
                                            48.42460019266047,
                                            15.532444454572584
                                        ],
                                        [
                                            48.42460019266047,
                                            15.406318328446456
                                        ],
                                        [
                                            48.29847406653435,
                                            15.406318328446456
                                        ]
                                    ],
                                    [
                                        [
                                            48.28493394421014,
                                            15.595502935683184
                                        ],
                                        [
                                            48.41106007033626,
                                            15.595502935683184
                                        ],
                                        [
                                            48.41106007033626,
                                            15.469376809557057
                                        ],
                                        [
                                            48.28493394421014,
                                            15.469376809557057
                                        ]
                                    ],
                                    [
                                        [
                                            48.27138811576414,
                                            15.658560802677124
                                        ],
                                        [
                                            48.39751424189026,
                                            15.658560802677124
                                        ],
                                        [
                                            48.39751424189026,
                                            15.532434676550997
                                        ],
                                        [
                                            48.27138811576414,
                                            15.532434676550997
                                        ]
                                    ],
                                    [
                                        [
                                            48.25783655485453,
                                            15.721618051874724
                                        ],
                                        [
                                            48.38396268098065,
                                            15.721618051874724
                                        ],
                                        [
                                            48.38396268098065,
                                            15.595491925748597
                                        ],
                                        [
                                            48.25783655485453,
                                            15.595491925748597
                                        ]
                                    ],
                                    [
                                        [
                                            48.244279232453735,
                                            15.784674679594083
                                        ],
                                        [
                                            48.37040535857986,
                                            15.784674679594083
                                        ],
                                        [
                                            48.37040535857986,
                                            15.658548553467956
                                        ],
                                        [
                                            48.244279232453735,
                                            15.658548553467956
                                        ]
                                    ],
                                    [
                                        [
                                            48.230716122091586,
                                            15.847730682151123
                                        ],
                                        [
                                            48.35684224821771,
                                            15.847730682151123
                                        ],
                                        [
                                            48.35684224821771,
                                            15.721604556024996
                                        ],
                                        [
                                            48.230716122091586,
                                            15.721604556024996
                                        ]
                                    ],
                                    [
                                        [
                                            48.21714719461304,
                                            15.910786055859504
                                        ],
                                        [
                                            48.343273320739165,
                                            15.910786055859504
                                        ],
                                        [
                                            48.343273320739165,
                                            15.784659929733376
                                        ],
                                        [
                                            48.21714719461304,
                                            15.784659929733376
                                        ]
                                    ],
                                    [
                                        [
                                            48.20357242342952,
                                            15.973840797030654
                                        ],
                                        [
                                            48.329698549555644,
                                            15.973840797030654
                                        ],
                                        [
                                            48.329698549555644,
                                            15.847714670904526
                                        ],
                                        [
                                            48.20357242342952,
                                            15.847714670904526
                                        ]
                                    ],
                                    [
                                        [
                                            48.18999177926208,
                                            16.036894901973753
                                        ],
                                        [
                                            48.316117905388204,
                                            16.036894901973753
                                        ],
                                        [
                                            48.316117905388204,
                                            15.910768775847627
                                        ],
                                        [
                                            48.18999177926208,
                                            15.910768775847627
                                        ]
                                    ],
                                    [
                                        [
                                            48.176405235399166,
                                            16.099948366995683
                                        ],
                                        [
                                            48.30253136152529,
                                            16.099948366995683
                                        ],
                                        [
                                            48.30253136152529,
                                            15.973822240869557
                                        ],
                                        [
                                            48.176405235399166,
                                            15.973822240869557
                                        ]
                                    ],
                                    [
                                        [
                                            48.1628127624347,
                                            16.16300118840105
                                        ],
                                        [
                                            48.28893888856082,
                                            16.16300118840105
                                        ],
                                        [
                                            48.28893888856082,
                                            16.036875062274927
                                        ],
                                        [
                                            48.1628127624347,
                                            16.036875062274927
                                        ]
                                    ],
                                    [
                                        [
                                            48.149214333532726,
                                            16.226053362492173
                                        ],
                                        [
                                            48.27534045965885,
                                            16.226053362492173
                                        ],
                                        [
                                            48.27534045965885,
                                            16.09992723636605
                                        ],
                                        [
                                            48.149214333532726,
                                            16.09992723636605
                                        ]
                                    ],
                                    [
                                        [
                                            48.13560991915559,
                                            16.289104885568992
                                        ],
                                        [
                                            48.261736045281715,
                                            16.289104885568992
                                        ],
                                        [
                                            48.261736045281715,
                                            16.16297875944287
                                        ],
                                        [
                                            48.13560991915559,
                                            16.16297875944287
                                        ]
                                    ],
                                    [
                                        [
                                            48.121999492343065,
                                            16.352155753929154
                                        ],
                                        [
                                            48.24812561846919,
                                            16.352155753929154
                                        ],
                                        [
                                            48.24812561846919,
                                            16.22602962780303
                                        ],
                                        [
                                            48.121999492343065,
                                            16.22602962780303
                                        ]
                                    ],
                                    [
                                        [
                                            48.108383023432495,
                                            16.41520596386795
                                        ],
                                        [
                                            48.23450914955862,
                                            16.41520596386795
                                        ],
                                        [
                                            48.23450914955862,
                                            16.289079837741827
                                        ],
                                        [
                                            48.108383023432495,
                                            16.289079837741827
                                        ]
                                    ],
                                    [
                                        [
                                            48.094760485329836,
                                            16.478255511678263
                                        ],
                                        [
                                            48.22088661145596,
                                            16.478255511678263
                                        ],
                                        [
                                            48.22088661145596,
                                            16.35212938555214
                                        ],
                                        [
                                            48.094760485329836,
                                            16.35212938555214
                                        ]
                                    ],
                                    [
                                        [
                                            48.08113184824452,
                                            16.541304393650623
                                        ],
                                        [
                                            48.20725797437064,
                                            16.541304393650623
                                        ],
                                        [
                                            48.20725797437064,
                                            16.4151782675245
                                        ],
                                        [
                                            48.08113184824452,
                                            16.4151782675245
                                        ]
                                    ],
                                    [
                                        [
                                            48.067497084295745,
                                            16.60435260607313
                                        ],
                                        [
                                            48.19362321042187,
                                            16.60435260607313
                                        ],
                                        [
                                            48.19362321042187,
                                            16.478226479947008
                                        ],
                                        [
                                            48.067497084295745,
                                            16.478226479947008
                                        ]
                                    ],
                                    [
                                        [
                                            48.05385616553553,
                                            16.667400145231472
                                        ],
                                        [
                                            48.17998229166165,
                                            16.667400145231472
                                        ],
                                        [
                                            48.17998229166165,
                                            16.541274019105348
                                        ],
                                        [
                                            48.05385616553553,
                                            16.541274019105348
                                        ]
                                    ],
                                    [
                                        [
                                            48.04020906197276,
                                            16.730447007408902
                                        ],
                                        [
                                            48.166335188098884,
                                            16.730447007408902
                                        ],
                                        [
                                            48.166335188098884,
                                            16.604320881282778
                                        ],
                                        [
                                            48.04020906197276,
                                            16.604320881282778
                                        ]
                                    ],
                                    [
                                        [
                                            48.0265557461831,
                                            16.793493188886192
                                        ],
                                        [
                                            48.15268187230922,
                                            16.793493188886192
                                        ],
                                        [
                                            48.15268187230922,
                                            16.667367062760068
                                        ],
                                        [
                                            48.0265557461831,
                                            16.667367062760068
                                        ]
                                    ],
                                    [
                                        [
                                            48.012896188050256,
                                            16.856538685941672
                                        ],
                                        [
                                            48.13902231417638,
                                            16.856538685941672
                                        ],
                                        [
                                            48.13902231417638,
                                            16.730412559815548
                                        ],
                                        [
                                            48.012896188050256,
                                            16.730412559815548
                                        ]
                                    ],
                                    [
                                        [
                                            47.99923036001909,
                                            16.919583494851143
                                        ],
                                        [
                                            48.125356486145215,
                                            16.919583494851143
                                        ],
                                        [
                                            48.125356486145215,
                                            16.79345736872502
                                        ],
                                        [
                                            47.99923036001909,
                                            16.79345736872502
                                        ]
                                    ],
                                    [
                                        [
                                            47.985558231828776,
                                            16.98262761188793
                                        ],
                                        [
                                            48.1116843579549,
                                            16.98262761188793
                                        ],
                                        [
                                            48.1116843579549,
                                            16.856501485761807
                                        ],
                                        [
                                            47.985558231828776,
                                            16.856501485761807
                                        ]
                                    ],
                                    [
                                        [
                                            47.97187977578542,
                                            17.04567103332281
                                        ],
                                        [
                                            48.09800590191154,
                                            17.04567103332281
                                        ],
                                        [
                                            48.09800590191154,
                                            16.919544907196688
                                        ],
                                        [
                                            47.97187977578542,
                                            16.919544907196688
                                        ]
                                    ],
                                    [
                                        [
                                            47.95819496150323,
                                            17.10871375542403
                                        ],
                                        [
                                            48.084321087629355,
                                            17.10871375542403
                                        ],
                                        [
                                            48.084321087629355,
                                            16.982587629297907
                                        ],
                                        [
                                            47.95819496150323,
                                            16.982587629297907
                                        ]
                                    ],
                                    [
                                        [
                                            47.9445037611464,
                                            17.171755774457253
                                        ],
                                        [
                                            48.070629887272524,
                                            17.171755774457253
                                        ],
                                        [
                                            48.070629887272524,
                                            17.04562964833113
                                        ],
                                        [
                                            47.9445037611464,
                                            17.04562964833113
                                        ]
                                    ],
                                    [
                                        [
                                            47.930806144189795,
                                            17.23479708668557
                                        ],
                                        [
                                            48.05693227031592,
                                            17.23479708668557
                                        ],
                                        [
                                            48.05693227031592,
                                            17.108670960559447
                                        ],
                                        [
                                            47.930806144189795,
                                            17.108670960559447
                                        ]
                                    ],
                                    [
                                        [
                                            47.91710208266551,
                                            17.29783768836949
                                        ],
                                        [
                                            48.04322820879163,
                                            17.29783768836949
                                        ],
                                        [
                                            48.04322820879163,
                                            17.171711562243367
                                        ],
                                        [
                                            47.91710208266551,
                                            17.171711562243367
                                        ]
                                    ],
                                    [
                                        [
                                            47.90339154590255,
                                            17.36087757576687
                                        ],
                                        [
                                            48.029517672028675,
                                            17.36087757576687
                                        ],
                                        [
                                            48.029517672028675,
                                            17.234751449640747
                                        ],
                                        [
                                            47.90339154590255,
                                            17.234751449640747
                                        ]
                                    ],
                                    [
                                        [
                                            47.88967450578972,
                                            17.42391674513297
                                        ],
                                        [
                                            48.015800631915845,
                                            17.42391674513297
                                        ],
                                        [
                                            48.015800631915845,
                                            17.297790619006847
                                        ],
                                        [
                                            47.88967450578972,
                                            17.297790619006847
                                        ]
                                    ],
                                    [
                                        [
                                            47.875950931524905,
                                            17.48695519272035
                                        ],
                                        [
                                            48.00207705765103,
                                            17.48695519272035
                                        ],
                                        [
                                            48.00207705765103,
                                            17.360829066594228
                                        ],
                                        [
                                            47.875950931524905,
                                            17.360829066594228
                                        ]
                                    ],
                                    [
                                        [
                                            47.86222079484878,
                                            17.549992914778944
                                        ],
                                        [
                                            47.988346920974905,
                                            17.549992914778944
                                        ],
                                        [
                                            47.988346920974905,
                                            17.42386678865282
                                        ],
                                        [
                                            47.86222079484878,
                                            17.42386678865282
                                        ]
                                    ],
                                    [
                                        [
                                            47.84848406481847,
                                            17.61302990755594
                                        ],
                                        [
                                            47.974610190944595,
                                            17.61302990755594
                                        ],
                                        [
                                            47.974610190944595,
                                            17.486903781429817
                                        ],
                                        [
                                            47.84848406481847,
                                            17.486903781429817
                                        ]
                                    ],
                                    [
                                        [
                                            47.83474071303145,
                                            17.676066167295893
                                        ],
                                        [
                                            47.960866839157575,
                                            17.676066167295893
                                        ],
                                        [
                                            47.960866839157575,
                                            17.54994004116977
                                        ],
                                        [
                                            47.83474071303145,
                                            17.54994004116977
                                        ]
                                    ],
                                    [
                                        [
                                            47.82099070839763,
                                            17.73910169024053
                                        ],
                                        [
                                            47.947116834523754,
                                            17.73910169024053
                                        ],
                                        [
                                            47.947116834523754,
                                            17.612975564114407
                                        ],
                                        [
                                            47.82099070839763,
                                            17.612975564114407
                                        ]
                                    ],
                                    [
                                        [
                                            47.80723402237626,
                                            17.802136472628913
                                        ],
                                        [
                                            47.93336014850238,
                                            17.802136472628913
                                        ],
                                        [
                                            47.93336014850238,
                                            17.67601034650279
                                        ],
                                        [
                                            47.80723402237626,
                                            17.67601034650279
                                        ]
                                    ],
                                    [
                                        [
                                            42.751227194508985,
                                            14.829723141466284
                                        ],
                                        [
                                            42.87735332063511,
                                            14.829723141466284
                                        ],
                                        [
                                            42.87735332063511,
                                            14.703597015340156
                                        ],
                                        [
                                            42.751227194508985,
                                            14.703597015340156
                                        ]
                                    ],
                                    [
                                        [
                                            42.737522534700325,
                                            14.766686571310023
                                        ],
                                        [
                                            42.86364866082645,
                                            14.766686571310023
                                        ],
                                        [
                                            42.86364866082645,
                                            14.640560445183896
                                        ],
                                        [
                                            42.737522534700325,
                                            14.640560445183896
                                        ]
                                    ],
                                    [
                                        [
                                            42.723823350052385,
                                            14.703649418984243
                                        ],
                                        [
                                            42.84994947617851,
                                            14.703649418984243
                                        ],
                                        [
                                            42.84994947617851,
                                            14.577523292858116
                                        ],
                                        [
                                            42.723823350052385,
                                            14.577523292858116
                                        ]
                                    ],
                                    [
                                        [
                                            42.71012961440439,
                                            14.640611688234564
                                        ],
                                        [
                                            42.83625574053051,
                                            14.640611688234564
                                        ],
                                        [
                                            42.83625574053051,
                                            14.514485562108437
                                        ],
                                        [
                                            42.71012961440439,
                                            14.514485562108437
                                        ]
                                    ],
                                    [
                                        [
                                            42.69644129902092,
                                            14.577573382804424
                                        ],
                                        [
                                            42.82256742514704,
                                            14.577573382804424
                                        ],
                                        [
                                            42.82256742514704,
                                            14.451447256678296
                                        ],
                                        [
                                            42.69644129902092,
                                            14.451447256678296
                                        ]
                                    ],
                                    [
                                        [
                                            42.682758377866186,
                                            14.514534506435114
                                        ],
                                        [
                                            42.80888450399231,
                                            14.514534506435114
                                        ],
                                        [
                                            42.80888450399231,
                                            14.388408380308986
                                        ],
                                        [
                                            42.682758377866186,
                                            14.388408380308986
                                        ]
                                    ],
                                    [
                                        [
                                            42.66908082232422,
                                            14.451495062865774
                                        ],
                                        [
                                            42.79520694845034,
                                            14.451495062865774
                                        ],
                                        [
                                            42.79520694845034,
                                            14.325368936739647
                                        ],
                                        [
                                            42.66908082232422,
                                            14.325368936739647
                                        ]
                                    ],
                                    [
                                        [
                                            42.65540860646005,
                                            14.388455055833434
                                        ],
                                        [
                                            42.781534732586174,
                                            14.388455055833434
                                        ],
                                        [
                                            42.781534732586174,
                                            14.262328929707307
                                        ],
                                        [
                                            42.65540860646005,
                                            14.262328929707307
                                        ]
                                    ],
                                    [
                                        [
                                            42.64174170176772,
                                            14.325414489073005
                                        ],
                                        [
                                            42.767867827893845,
                                            14.325414489073005
                                        ],
                                        [
                                            42.767867827893845,
                                            14.199288362946877
                                        ],
                                        [
                                            42.64174170176772,
                                            14.199288362946877
                                        ]
                                    ],
                                    [
                                        [
                                            42.6280800824297,
                                            14.262373366317293
                                        ],
                                        [
                                            42.75420620855582,
                                            14.262373366317293
                                        ],
                                        [
                                            42.75420620855582,
                                            14.136247240191166
                                        ],
                                        [
                                            42.6280800824297,
                                            14.136247240191166
                                        ]
                                    ],
                                    [
                                        [
                                            42.614423720050276,
                                            14.199331691297054
                                        ],
                                        [
                                            42.7405498461764,
                                            14.199331691297054
                                        ],
                                        [
                                            42.7405498461764,
                                            14.073205565170927
                                        ],
                                        [
                                            42.614423720050276,
                                            14.073205565170927
                                        ]
                                    ],
                                    [
                                        [
                                            42.600772588919995,
                                            14.136289467740944
                                        ],
                                        [
                                            42.72689871504612,
                                            14.136289467740944
                                        ],
                                        [
                                            42.72689871504612,
                                            14.010163341614817
                                        ],
                                        [
                                            42.600772588919995,
                                            14.010163341614817
                                        ]
                                    ],
                                    [
                                        [
                                            42.58712666075214,
                                            14.073246699375554
                                        ],
                                        [
                                            42.713252786878265,
                                            14.073246699375554
                                        ],
                                        [
                                            42.713252786878265,
                                            13.947120573249427
                                        ],
                                        [
                                            42.58712666075214,
                                            13.947120573249427
                                        ]
                                    ],
                                    [
                                        [
                                            42.573485909947216,
                                            14.010203389925485
                                        ],
                                        [
                                            42.69961203607334,
                                            14.010203389925485
                                        ],
                                        [
                                            42.69961203607334,
                                            13.884077263799357
                                        ],
                                        [
                                            42.573485909947216,
                                            13.884077263799357
                                        ]
                                    ],
                                    [
                                        [
                                            42.55985030832298,
                                            13.947159543113283
                                        ],
                                        [
                                            42.6859764344491,
                                            13.947159543113283
                                        ],
                                        [
                                            42.6859764344491,
                                            13.821033416987156
                                        ],
                                        [
                                            42.55985030832298,
                                            13.821033416987156
                                        ]
                                    ],
                                    [
                                        [
                                            42.54621983038694,
                                            13.884115162659464
                                        ],
                                        [
                                            42.67234595651306,
                                            13.884115162659464
                                        ],
                                        [
                                            42.67234595651306,
                                            13.757989036533337
                                        ],
                                        [
                                            42.54621983038694,
                                            13.757989036533337
                                        ]
                                    ],
                                    [
                                        [
                                            42.53259444806662,
                                            13.821070252282574
                                        ],
                                        [
                                            42.65872057419274,
                                            13.821070252282574
                                        ],
                                        [
                                            42.65872057419274,
                                            13.694944126156447
                                        ],
                                        [
                                            42.53259444806662,
                                            13.694944126156447
                                        ]
                                    ],
                                    [
                                        [
                                            42.51897413597203,
                                            13.758024815699144
                                        ],
                                        [
                                            42.645100262098154,
                                            13.758024815699144
                                        ],
                                        [
                                            42.645100262098154,
                                            13.631898689573017
                                        ],
                                        [
                                            42.51897413597203,
                                            13.631898689573017
                                        ]
                                    ],
                                    [
                                        [
                                            42.505358866140725,
                                            13.694978856623774
                                        ],
                                        [
                                            42.63148499226685,
                                            13.694978856623774
                                        ],
                                        [
                                            42.63148499226685,
                                            13.568852730497646
                                        ],
                                        [
                                            42.505358866140725,
                                            13.568852730497646
                                        ]
                                    ]
                                ],
                                swathWidth: 10.0,
                                length: 15.0,
                                areaCovered: 100.0,
                                captureTime: "2024-10-03T08:00:00Z",
                                cloudCoverage: 5.0,
                                resolution: 1.0
                            },
                            satellite_operations: [
                                {
                                    type: "ADCS - Target Track 4",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 4",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track 4",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 4",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track 4",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 4",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                }
                            ],
                        },
                    ],
                    groundAssignments: [
                        {
                            ground_station: {
                                id: "gs_002",
                                name: "Ground Station Alpha2"
                            },
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_002",
                                name: "Satellite Alpha 2",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            satellite_operations: [
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 1",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                },
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 2",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                },
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                }
                            ]
                        },
                        {
                            ground_station: {
                                id: "gs_001",
                                name: "Ground Station Alpha"
                            },
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_001",
                                name: "Satellite Alpha",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            satellite_operations: [
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 1",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                },
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 2",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                },
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                }
                            ]
                        }
                    ]
                }
            }
        },
        {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa623",
            createdAt: "2024-04-01T08:30:00Z",
            updatedAt: "2024-04-01T08:30:00Z",
            spec: {
                name: "Order 2",
                geofeature: {
                    features: [
                        {
                            id: "927a31e7-5aa7-41b8-b9ca-f05798cdfa3d",
                            geometry: {
                                type: "Polygon",
                                coordinates: [
                                    [
                                        86.31422686170515,
                                        22.311781207028204
                                    ],
                                    [
                                        91.23610186170447,
                                        22.87978613345605
                                    ],
                                    [
                                        91.23610186170447,
                                        19.85178863847854
                                    ],
                                    [
                                        86.05055498670396,
                                        18.523697399964988
                                    ],
                                    [
                                        86.31422686170515,
                                        22.311781207028204
                                    ]
                                ],
                            },
                            properties: {}
                        }
                    ]
                },
                attributes: {
                    priorityLane: "Normal",
                    imagingSpectrum: "Hyper-S",
                    imagerADCSDetails: {
                        gsd: { min: 0, max: 1 },
                        nadirAngle: { min: 0, max: 30 },
                        sunAngle: { min: 0, max: 45 }
                    },
                    cloudCoverage: 50,
                    resolution: "0.1m - 1m (Very high)",
                    recurring: true
                },
                tipncue: {}
            },
            status: {
                state: "Current",
                coverage: {
                    coveredStrips: [
                        [
                            [
                                87.07806093760635,
                                18.89219318868886
                            ],
                            [
                                87.20418706373249,
                                18.89219318868886
                            ],
                            [
                                87.20418706373249,
                                18.766067062562737
                            ],
                            [
                                87.07806093760635,
                                18.766067062562737
                            ]
                        ],
                        [
                            [
                                87.06414293483576,
                                18.955381588322993
                            ],
                            [
                                87.1902690609619,
                                18.955381588322993
                            ],
                            [
                                87.1902690609619,
                                18.82925546219687
                            ],
                            [
                                87.06414293483576,
                                18.82925546219687
                            ]
                        ],
                        [
                            [
                                87.05021764930916,
                                19.018568957505792
                            ],
                            [
                                87.1763437754353,
                                19.018568957505792
                            ],
                            [
                                87.1763437754353,
                                18.892442831379668
                            ],
                            [
                                87.05021764930916,
                                18.892442831379668
                            ]
                        ],
                        [
                            [
                                87.03628505006021,
                                19.081755292425083
                            ],
                            [
                                87.16241117618635,
                                19.081755292425083
                            ],
                            [
                                87.16241117618635,
                                18.95562916629896
                            ],
                            [
                                87.03628505006021,
                                18.95562916629896
                            ]
                        ],
                        [
                            [
                                87.02234510407386,
                                19.144940589265822
                            ],
                            [
                                87.1484712302,
                                19.144940589265822
                            ],
                            [
                                87.1484712302,
                                19.018814463139698
                            ],
                            [
                                87.02234510407386,
                                19.018814463139698
                            ]
                        ],
                        [
                            [
                                87.0083977808761,
                                19.208124844210094
                            ],
                            [
                                87.13452390700223,
                                19.208124844210094
                            ],
                            [
                                87.13452390700223,
                                19.08199871808397
                            ],
                            [
                                87.0083977808761,
                                19.08199871808397
                            ]
                        ],
                        [
                            [
                                86.99444304729192,
                                19.271308053437004
                            ],
                            [
                                87.12056917341806,
                                19.271308053437004
                            ],
                            [
                                87.12056917341806,
                                19.14518192731088
                            ],
                            [
                                86.99444304729192,
                                19.14518192731088
                            ]
                        ],
                        [
                            [
                                86.98048087267682,
                                19.33449021312273
                            ],
                            [
                                87.10660699880296,
                                19.33449021312273
                            ],
                            [
                                87.10660699880296,
                                19.208364086996607
                            ],
                            [
                                86.98048087267682,
                                19.208364086996607
                            ]
                        ],
                        [
                            [
                                86.96651122368912,
                                19.3976713194405
                            ],
                            [
                                87.09263734981526,
                                19.3976713194405
                            ],
                            [
                                87.09263734981526,
                                19.271545193314378
                            ],
                            [
                                86.96651122368912,
                                19.271545193314378
                            ]
                        ],
                        [
                            [
                                86.95253406952182,
                                19.4608513685605
                            ],
                            [
                                87.07866019564796,
                                19.4608513685605
                            ],
                            [
                                87.07866019564796,
                                19.334725242434377
                            ],
                            [
                                86.95253406952182,
                                19.334725242434377
                            ]
                        ],
                        [
                            [
                                86.93854937665996,
                                19.524030356649973
                            ],
                            [
                                87.0646755027861,
                                19.524030356649973
                            ],
                            [
                                87.0646755027861,
                                19.39790423052385
                            ],
                            [
                                86.93854937665996,
                                19.39790423052385
                            ]
                        ],
                        [
                            [
                                86.92455711412724,
                                19.587208279873032
                            ],
                            [
                                87.05068324025338,
                                19.587208279873032
                            ],
                            [
                                87.05068324025338,
                                19.461082153746908
                            ],
                            [
                                86.92455711412724,
                                19.461082153746908
                            ]
                        ],
                        [
                            [
                                86.9105572482451,
                                19.65038513439078
                            ],
                            [
                                87.03668337437124,
                                19.65038513439078
                            ],
                            [
                                87.03668337437124,
                                19.524259008264657
                            ],
                            [
                                86.9105572482451,
                                19.524259008264657
                            ]
                        ],
                        [
                            [
                                86.89654974785799,
                                19.71356091636122
                            ],
                            [
                                87.02267587398413,
                                19.71356091636122
                            ],
                            [
                                87.02267587398413,
                                19.587434790235097
                            ],
                            [
                                86.89654974785799,
                                19.587434790235097
                            ]
                        ],
                        [
                            [
                                86.88253457911536,
                                19.77673562193923
                            ],
                            [
                                87.0086607052415,
                                19.77673562193923
                            ],
                            [
                                87.0086607052415,
                                19.650609495813107
                            ],
                            [
                                86.88253457911536,
                                19.650609495813107
                            ]
                        ],
                        [
                            [
                                86.86851171069527,
                                19.83990924727653
                            ],
                            [
                                86.99463783682141,
                                19.83990924727653
                            ],
                            [
                                86.99463783682141,
                                19.713783121150406
                            ],
                            [
                                86.86851171069527,
                                19.713783121150406
                            ]
                        ],
                        [
                            [
                                86.85448110857008,
                                19.903081788521742
                            ],
                            [
                                86.98060723469622,
                                19.903081788521742
                            ],
                            [
                                86.98060723469622,
                                19.77695566239562
                            ],
                            [
                                86.85448110857008,
                                19.77695566239562
                            ]
                        ],
                        [
                            [
                                86.84044274124639,
                                19.966253241820223
                            ],
                            [
                                86.96656886737253,
                                19.966253241820223
                            ],
                            [
                                86.96656886737253,
                                19.8401271156941
                            ],
                            [
                                86.84044274124639,
                                19.8401271156941
                            ]
                        ],
                        [
                            [
                                86.82639657451433,
                                20.02942360331412
                            ],
                            [
                                86.95252270064047,
                                20.02942360331412
                            ],
                            [
                                86.95252270064047,
                                19.903297477187998
                            ],
                            [
                                86.82639657451433,
                                19.903297477187998
                            ]
                        ],
                        [
                            [
                                86.81234257670386,
                                20.092592869142422
                            ],
                            [
                                86.93846870283,
                                20.092592869142422
                            ],
                            [
                                86.93846870283,
                                19.966466743016298
                            ],
                            [
                                86.81234257670386,
                                19.966466743016298
                            ]
                        ],
                        [
                            [
                                86.79828071343404,
                                20.155761035440783
                            ],
                            [
                                86.92440683956018,
                                20.155761035440783
                            ],
                            [
                                86.92440683956018,
                                20.02963490931466
                            ],
                            [
                                86.79828071343404,
                                20.02963490931466
                            ]
                        ],
                        [
                            [
                                86.78421095285307,
                                20.218928098341593
                            ],
                            [
                                86.91033707897921,
                                20.218928098341593
                            ],
                            [
                                86.91033707897921,
                                20.09280197221547
                            ],
                            [
                                86.78421095285307,
                                20.09280197221547
                            ]
                        ],
                        [
                            [
                                86.77013326040063,
                                20.282094053973903
                            ],
                            [
                                86.89625938652676,
                                20.282094053973903
                            ],
                            [
                                86.89625938652676,
                                20.15596792784778
                            ],
                            [
                                86.77013326040063,
                                20.15596792784778
                            ]
                        ],
                        [
                            [
                                86.75604760404772,
                                20.34525889846344
                            ],
                            [
                                86.88217373017386,
                                20.34525889846344
                            ],
                            [
                                86.88217373017386,
                                20.219132772337318
                            ],
                            [
                                86.75604760404772,
                                20.219132772337318
                            ]
                        ],
                        [
                            [
                                86.74195394904456,
                                20.408422627932563
                            ],
                            [
                                86.8680800751707,
                                20.408422627932563
                            ],
                            [
                                86.8680800751707,
                                20.28229650180644
                            ],
                            [
                                86.74195394904456,
                                20.28229650180644
                            ]
                        ],
                        [
                            [
                                86.72785226318304,
                                20.471585238500243
                            ],
                            [
                                86.85397838930918,
                                20.471585238500243
                            ],
                            [
                                86.85397838930918,
                                20.34545911237412
                            ],
                            [
                                86.72785226318304,
                                20.34545911237412
                            ]
                        ],
                        [
                            [
                                86.71374251153655,
                                20.534746726282002
                            ],
                            [
                                86.83986863766269,
                                20.534746726282002
                            ],
                            [
                                86.83986863766269,
                                20.408620600155878
                            ],
                            [
                                86.71374251153655,
                                20.408620600155878
                            ]
                        ],
                        [
                            [
                                86.69962466170627,
                                20.597907087389974
                            ],
                            [
                                86.8257507878324,
                                20.597907087389974
                            ],
                            [
                                86.8257507878324,
                                20.47178096126385
                            ],
                            [
                                86.69962466170627,
                                20.47178096126385
                            ]
                        ],
                        [
                            [
                                86.6854986785836,
                                20.661066317932743
                            ],
                            [
                                86.81162480470974,
                                20.661066317932743
                            ],
                            [
                                86.81162480470974,
                                20.53494019180662
                            ],
                            [
                                86.6854986785836,
                                20.53494019180662
                            ]
                        ],
                        [
                            [
                                86.67136452958023,
                                20.724224414015453
                            ],
                            [
                                86.79749065570637,
                                20.724224414015453
                            ],
                            [
                                86.79749065570637,
                                20.59809828788933
                            ],
                            [
                                86.67136452958023,
                                20.59809828788933
                            ]
                        ],
                        [
                            [
                                86.65722217940038,
                                20.78738137173969
                            ],
                            [
                                86.78334830552652,
                                20.78738137173969
                            ],
                            [
                                86.78334830552652,
                                20.661255245613567
                            ],
                            [
                                86.65722217940038,
                                20.661255245613567
                            ]
                        ],
                        [
                            [
                                86.64307159527075,
                                20.85053718720351
                            ],
                            [
                                86.76919772139689,
                                20.85053718720351
                            ],
                            [
                                86.76919772139689,
                                20.724411061077387
                            ],
                            [
                                86.64307159527075,
                                20.724411061077387
                            ]
                        ],
                        [
                            [
                                86.62891274170477,
                                20.91369185650135
                            ],
                            [
                                86.7550388678309,
                                20.91369185650135
                            ],
                            [
                                86.7550388678309,
                                20.787565730375228
                            ],
                            [
                                86.62891274170477,
                                20.787565730375228
                            ]
                        ],
                        [
                            [
                                86.61474558573897,
                                20.97684537572406
                            ],
                            [
                                86.74087171186511,
                                20.97684537572406
                            ],
                            [
                                86.74087171186511,
                                20.850719249597937
                            ],
                            [
                                86.61474558573897,
                                20.850719249597937
                            ]
                        ],
                        [
                            [
                                86.60057009169716,
                                21.039997740958853
                            ],
                            [
                                86.7266962178233,
                                21.039997740958853
                            ],
                            [
                                86.7266962178233,
                                20.91387161483273
                            ],
                            [
                                86.60057009169716,
                                20.91387161483273
                            ]
                        ],
                        [
                            [
                                86.58638622642049,
                                21.10314894828927
                            ],
                            [
                                86.71251235254663,
                                21.10314894828927
                            ],
                            [
                                86.71251235254663,
                                20.977022822163146
                            ],
                            [
                                86.58638622642049,
                                20.977022822163146
                            ]
                        ],
                        [
                            [
                                86.57219395404282,
                                21.166298993795202
                            ],
                            [
                                86.69832008016895,
                                21.166298993795202
                            ],
                            [
                                86.69832008016895,
                                21.040172867669078
                            ],
                            [
                                86.57219395404282,
                                21.040172867669078
                            ]
                        ],
                        [
                            [
                                86.55799324121097,
                                21.22944787355271
                            ],
                            [
                                86.6841193673371,
                                21.22944787355271
                            ],
                            [
                                86.6841193673371,
                                21.103321747426588
                            ],
                            [
                                86.55799324121097,
                                21.103321747426588
                            ]
                        ],
                        [
                            [
                                86.54378405185373,
                                21.292595583634213
                            ],
                            [
                                86.66991017797987,
                                21.292595583634213
                            ],
                            [
                                86.66991017797987,
                                21.16646945750809
                            ],
                            [
                                86.54378405185373,
                                21.16646945750809
                            ]
                        ],
                        [
                            [
                                86.52956635243483,
                                21.355742120108292
                            ],
                            [
                                86.65569247856097,
                                21.355742120108292
                            ],
                            [
                                86.65569247856097,
                                21.22961599398217
                            ],
                            [
                                86.52956635243483,
                                21.22961599398217
                            ]
                        ],
                        [
                            [
                                86.51534010668081,
                                21.41888747903977
                            ],
                            [
                                86.64146623280695,
                                21.41888747903977
                            ],
                            [
                                86.64146623280695,
                                21.292761352913647
                            ],
                            [
                                86.51534010668081,
                                21.292761352913647
                            ]
                        ],
                        [
                            [
                                86.50110528019738,
                                21.482031656489532
                            ],
                            [
                                86.62723140632352,
                                21.482031656489532
                            ],
                            [
                                86.62723140632352,
                                21.355905530363408
                            ],
                            [
                                86.50110528019738,
                                21.355905530363408
                            ]
                        ],
                        [
                            [
                                86.48686183848872,
                                21.54517464851467
                            ],
                            [
                                86.61298796461486,
                                21.54517464851467
                            ],
                            [
                                86.61298796461486,
                                21.419048522388547
                            ],
                            [
                                86.48686183848872,
                                21.419048522388547
                            ]
                        ],
                        [
                            [
                                86.47260974498474,
                                21.608316451168413
                            ],
                            [
                                86.59873587111088,
                                21.608316451168413
                            ],
                            [
                                86.59873587111088,
                                21.48219032504229
                            ],
                            [
                                86.47260974498474,
                                21.48219032504229
                            ]
                        ],
                        [
                            [
                                86.45834896564442,
                                21.67145706049997
                            ],
                            [
                                86.58447509177056,
                                21.67145706049997
                            ],
                            [
                                86.58447509177056,
                                21.545330934373847
                            ],
                            [
                                86.45834896564442,
                                21.545330934373847
                            ]
                        ],
                        [
                            [
                                86.4440794637,
                                21.73459647255467
                            ],
                            [
                                86.57020558982614,
                                21.73459647255467
                            ],
                            [
                                86.57020558982614,
                                21.608470346428547
                            ],
                            [
                                86.4440794637,
                                21.608470346428547
                            ]
                        ],
                        [
                            [
                                86.42980120490348,
                                21.7977346833738
                            ],
                            [
                                86.55592733102962,
                                21.7977346833738
                            ],
                            [
                                86.55592733102962,
                                21.671608557247676
                            ],
                            [
                                86.42980120490348,
                                21.671608557247676
                            ]
                        ],
                        [
                            [
                                86.4155141522792,
                                21.860871688994642
                            ],
                            [
                                86.54164027840534,
                                21.860871688994642
                            ],
                            [
                                86.54164027840534,
                                21.734745562868518
                            ],
                            [
                                86.4155141522792,
                                21.734745562868518
                            ]
                        ],
                        [
                            [
                                86.40121827137824,
                                21.92400748545047
                            ],
                            [
                                86.52734439750438,
                                21.92400748545047
                            ],
                            [
                                86.52734439750438,
                                21.797881359324347
                            ],
                            [
                                86.40121827137824,
                                21.797881359324347
                            ]
                        ],
                        [
                            [
                                86.38691352501807,
                                21.987142068770442
                            ],
                            [
                                86.51303965114421,
                                21.987142068770442
                            ],
                            [
                                86.51303965114421,
                                21.86101594264432
                            ],
                            [
                                86.38691352501807,
                                21.86101594264432
                            ]
                        ],
                        [
                            [
                                86.37259987854023,
                                22.050275434979593
                            ],
                            [
                                86.49872600466637,
                                22.050275434979593
                            ],
                            [
                                86.49872600466637,
                                21.92414930885347
                            ],
                            [
                                86.37259987854023,
                                21.92414930885347
                            ]
                        ],
                        [
                            [
                                86.35827729455154,
                                22.11340758009885
                            ],
                            [
                                86.48440342067768,
                                22.11340758009885
                            ],
                            [
                                86.48440342067768,
                                21.987281453972727
                            ],
                            [
                                86.35827729455154,
                                21.987281453972727
                            ]
                        ],
                        [
                            [
                                86.3439457381817,
                                22.176538500144932
                            ],
                            [
                                86.47007186430784,
                                22.176538500144932
                            ],
                            [
                                86.47007186430784,
                                22.05041237401881
                            ],
                            [
                                86.3439457381817,
                                22.05041237401881
                            ]
                        ],
                        [
                            [
                                86.3296051718312,
                                22.239668191130402
                            ],
                            [
                                86.45573129795734,
                                22.239668191130402
                            ],
                            [
                                86.45573129795734,
                                22.113542065004278
                            ],
                            [
                                86.3296051718312,
                                22.113542065004278
                            ]
                        ],
                        [
                            [
                                86.31525556042074,
                                22.302796649063524
                            ],
                            [
                                86.44138168654688,
                                22.302796649063524
                            ],
                            [
                                86.44138168654688,
                                22.1766705229374
                            ],
                            [
                                86.31525556042074,
                                22.1766705229374
                            ]
                        ],
                        [
                            [
                                86.30089686612745,
                                22.365923869948322
                            ],
                            [
                                86.42702299225358,
                                22.365923869948322
                            ],
                            [
                                86.42702299225358,
                                22.239797743822198
                            ],
                            [
                                86.30089686612745,
                                22.239797743822198
                            ]
                        ],
                        [
                            [
                                88.56970598071742,
                                22.62237520164433
                            ],
                            [
                                88.69583210684355,
                                22.62237520164433
                            ],
                            [
                                88.69583210684355,
                                22.496249075518207
                            ],
                            [
                                88.56970598071742,
                                22.496249075518207
                            ]
                        ],
                        [
                            [
                                88.55498099831382,
                                22.559300011886933
                            ],
                            [
                                88.68110712443996,
                                22.559300011886933
                            ],
                            [
                                88.68110712443996,
                                22.43317388576081
                            ],
                            [
                                88.55498099831382,
                                22.43317388576081
                            ]
                        ],
                        [
                            [
                                88.54026553981925,
                                22.496223523534102
                            ],
                            [
                                88.66639166594538,
                                22.496223523534102
                            ],
                            [
                                88.66639166594538,
                                22.370097397407978
                            ],
                            [
                                88.54026553981925,
                                22.370097397407978
                            ]
                        ],
                        [
                            [
                                88.52555956585745,
                                22.43314574077076
                            ],
                            [
                                88.65168569198359,
                                22.43314574077076
                            ],
                            [
                                88.65168569198359,
                                22.307019614644638
                            ],
                            [
                                88.52555956585745,
                                22.307019614644638
                            ]
                        ],
                        [
                            [
                                88.5108630397801,
                                22.370066667776932
                            ],
                            [
                                88.63698916590624,
                                22.370066667776932
                            ],
                            [
                                88.63698916590624,
                                22.243940541650808
                            ],
                            [
                                88.5108630397801,
                                22.243940541650808
                            ]
                        ],
                        [
                            [
                                88.49617592243857,
                                22.30698630872779
                            ],
                            [
                                88.6223020485647,
                                22.30698630872779
                            ],
                            [
                                88.6223020485647,
                                22.180860182601666
                            ],
                            [
                                88.49617592243857,
                                22.180860182601666
                            ]
                        ],
                        [
                            [
                                88.48149817740759,
                                22.243904667793693
                            ],
                            [
                                88.60762430353373,
                                22.243904667793693
                            ],
                            [
                                88.60762430353373,
                                22.11777854166757
                            ],
                            [
                                88.48149817740759,
                                22.11777854166757
                            ]
                        ],
                        [
                            [
                                88.46682976575893,
                                22.18082174914022
                            ],
                            [
                                88.59295589188507,
                                22.18082174914022
                            ],
                            [
                                88.59295589188507,
                                22.054695623014098
                            ],
                            [
                                88.46682976575893,
                                22.054695623014098
                            ]
                        ],
                        [
                            [
                                88.45217065129142,
                                22.117737556928212
                            ],
                            [
                                88.57829677741756,
                                22.117737556928212
                            ],
                            [
                                88.57829677741756,
                                21.991611430802088
                            ],
                            [
                                88.45217065129142,
                                21.991611430802088
                            ]
                        ],
                        [
                            [
                                88.4375207952949,
                                22.054652095313763
                            ],
                            [
                                88.56364692142104,
                                22.054652095313763
                            ],
                            [
                                88.56364692142104,
                                21.92852596918764
                            ],
                            [
                                88.4375207952949,
                                21.92852596918764
                            ]
                        ],
                        [
                            [
                                88.42288016178676,
                                21.99156536844834
                            ],
                            [
                                88.5490062879129,
                                21.99156536844834
                            ],
                            [
                                88.5490062879129,
                                21.865439242322218
                            ],
                            [
                                88.42288016178676,
                                21.865439242322218
                            ]
                        ],
                        [
                            [
                                88.40824871227753,
                                21.928477380478732
                            ],
                            [
                                88.53437483840366,
                                21.928477380478732
                            ],
                            [
                                88.53437483840366,
                                21.802351254352608
                            ],
                            [
                                88.40824871227753,
                                21.802351254352608
                            ]
                        ],
                        [
                            [
                                88.39362641099426,
                                21.865388135547093
                            ],
                            [
                                88.5197525371204,
                                21.865388135547093
                            ],
                            [
                                88.5197525371204,
                                21.73926200942097
                            ],
                            [
                                88.39362641099426,
                                21.73926200942097
                            ]
                        ],
                        [
                            [
                                88.37901321966427,
                                21.802297637791103
                            ],
                            [
                                88.50513934579041,
                                21.802297637791103
                            ],
                            [
                                88.50513934579041,
                                21.67617151166498
                            ],
                            [
                                88.37901321966427,
                                21.67617151166498
                            ]
                        ],
                        [
                            [
                                88.36440910273198,
                                21.739205891343783
                            ],
                            [
                                88.49053522885812,
                                21.739205891343783
                            ],
                            [
                                88.49053522885812,
                                21.61307976521766
                            ],
                            [
                                88.36440910273198,
                                21.61307976521766
                            ]
                        ],
                        [
                            [
                                88.34981402213434,
                                21.676112900333713
                            ],
                            [
                                88.47594014826048,
                                21.676112900333713
                            ],
                            [
                                88.47594014826048,
                                21.54998677420759
                            ],
                            [
                                88.34981402213434,
                                21.54998677420759
                            ]
                        ],
                        [
                            [
                                88.33522794253236,
                                21.613018668884962
                            ],
                            [
                                88.4613540686585,
                                21.613018668884962
                            ],
                            [
                                88.4613540686585,
                                21.486892542758838
                            ],
                            [
                                88.33522794253236,
                                21.486892542758838
                            ]
                        ],
                        [
                            [
                                88.32065082606883,
                                21.54992320111718
                            ],
                            [
                                88.44677695219497,
                                21.54992320111718
                            ],
                            [
                                88.44677695219497,
                                21.423797074991057
                            ],
                            [
                                88.32065082606883,
                                21.423797074991057
                            ]
                        ],
                        [
                            [
                                88.30608263761445,
                                21.48682650114562
                            ],
                            [
                                88.43220876374059,
                                21.48682650114562
                            ],
                            [
                                88.43220876374059,
                                21.360700375019498
                            ],
                            [
                                88.30608263761445,
                                21.360700375019498
                            ]
                        ],
                        [
                            [
                                88.29152333952366,
                                21.423728573081103
                            ],
                            [
                                88.4176494656498,
                                21.423728573081103
                            ],
                            [
                                88.4176494656498,
                                21.29760244695498
                            ],
                            [
                                88.29152333952366,
                                21.29760244695498
                            ]
                        ],
                        [
                            [
                                88.27697289687295,
                                21.36062942103016
                            ],
                            [
                                88.40309902299909,
                                21.36062942103016
                            ],
                            [
                                88.40309902299909,
                                21.234503294904037
                            ],
                            [
                                88.27697289687295,
                                21.234503294904037
                            ]
                        ],
                        [
                            [
                                88.26243127222311,
                                21.297529049094972
                            ],
                            [
                                88.38855739834925,
                                21.297529049094972
                            ],
                            [
                                88.38855739834925,
                                21.17140292296885
                            ],
                            [
                                88.26243127222311,
                                21.17140292296885
                            ]
                        ],
                        [
                            [
                                88.2478984308575,
                                21.23442746137346
                            ],
                            [
                                88.37402455698364,
                                21.23442746137346
                            ],
                            [
                                88.37402455698364,
                                21.108301335247337
                            ],
                            [
                                88.2478984308575,
                                21.108301335247337
                            ]
                        ],
                        [
                            [
                                88.2333743355378,
                                21.17132466195927
                            ],
                            [
                                88.35950046166394,
                                21.17132466195927
                            ],
                            [
                                88.35950046166394,
                                21.045198535833148
                            ],
                            [
                                88.2333743355378,
                                21.045198535833148
                            ]
                        ],
                        [
                            [
                                88.21885895174889,
                                21.108220654941864
                            ],
                            [
                                88.34498507787502,
                                21.108220654941864
                            ],
                            [
                                88.34498507787502,
                                20.98209452881574
                            ],
                            [
                                88.21885895174889,
                                20.98209452881574
                            ]
                        ],
                        [
                            [
                                88.20435224246259,
                                21.045115444406463
                            ],
                            [
                                88.33047836858873,
                                21.045115444406463
                            ],
                            [
                                88.33047836858873,
                                20.91898931828034
                            ],
                            [
                                88.20435224246259,
                                20.91898931828034
                            ]
                        ],
                        [
                            [
                                88.18985417270362,
                                20.982009034434203
                            ],
                            [
                                88.31598029882976,
                                20.982009034434203
                            ],
                            [
                                88.31598029882976,
                                20.85588290830808
                            ],
                            [
                                88.18985417270362,
                                20.85588290830808
                            ]
                        ],
                        [
                            [
                                88.1753647076142,
                                20.91890142910202
                            ],
                            [
                                88.30149083374033,
                                20.91890142910202
                            ],
                            [
                                88.30149083374033,
                                20.792775302975897
                            ],
                            [
                                88.1753647076142,
                                20.792775302975897
                            ]
                        ],
                        [
                            [
                                88.16088381044254,
                                20.855792632482792
                            ],
                            [
                                88.28700993656868,
                                20.855792632482792
                            ],
                            [
                                88.28700993656868,
                                20.72966650635667
                            ],
                            [
                                88.16088381044254,
                                20.72966650635667
                            ]
                        ],
                        [
                            [
                                88.14641144718826,
                                20.792682648645343
                            ],
                            [
                                88.2725375733144,
                                20.792682648645343
                            ],
                            [
                                88.2725375733144,
                                20.66655652251922
                            ],
                            [
                                88.14641144718826,
                                20.66655652251922
                            ]
                        ],
                        [
                            [
                                88.13194758130292,
                                20.72957148165442
                            ],
                            [
                                88.25807370742906,
                                20.72957148165442
                            ],
                            [
                                88.25807370742906,
                                20.603445355528297
                            ],
                            [
                                88.13194758130292,
                                20.603445355528297
                            ]
                        ],
                        [
                            [
                                88.11749217897376,
                                20.66645913557081
                            ],
                            [
                                88.2436183050999,
                                20.66645913557081
                            ],
                            [
                                88.2436183050999,
                                20.540333009444687
                            ],
                            [
                                88.11749217897376,
                                20.540333009444687
                            ]
                        ],
                        [
                            [
                                88.10304520385357,
                                20.60334561445126
                            ],
                            [
                                88.2291713299797,
                                20.60334561445126
                            ],
                            [
                                88.2291713299797,
                                20.477219488325137
                            ],
                            [
                                88.10304520385357,
                                20.477219488325137
                            ]
                        ],
                        [
                            [
                                88.08860662231845,
                                20.540230922348663
                            ],
                            [
                                88.21473274844459,
                                20.540230922348663
                            ],
                            [
                                88.21473274844459,
                                20.41410479622254
                            ],
                            [
                                88.08860662231845,
                                20.41410479622254
                            ]
                        ],
                        [
                            [
                                88.07417639821387,
                                20.477115063311913
                            ],
                            [
                                88.20030252434,
                                20.477115063311913
                            ],
                            [
                                88.20030252434,
                                20.35098893718579
                            ],
                            [
                                88.07417639821387,
                                20.35098893718579
                            ]
                        ],
                        [
                            [
                                88.05975449811088,
                                20.41399804138604
                            ],
                            [
                                88.18588062423702,
                                20.41399804138604
                            ],
                            [
                                88.18588062423702,
                                20.287871915259917
                            ],
                            [
                                88.05975449811088,
                                20.287871915259917
                            ]
                        ],
                        [
                            [
                                88.04534088604403,
                                20.35087986061223
                            ],
                            [
                                88.17146701217017,
                                20.35087986061223
                            ],
                            [
                                88.17146701217017,
                                20.224753734486107
                            ],
                            [
                                88.04534088604403,
                                20.224753734486107
                            ]
                        ],
                        [
                            [
                                88.03093552877401,
                                20.28776052502782
                            ],
                            [
                                88.15706165490015,
                                20.28776052502782
                            ],
                            [
                                88.15706165490015,
                                20.161634398901697
                            ],
                            [
                                88.03093552877401,
                                20.161634398901697
                            ]
                        ],
                        [
                            [
                                88.01653839052572,
                                20.22464003866635
                            ],
                            [
                                88.14266451665186,
                                20.22464003866635
                            ],
                            [
                                88.14266451665186,
                                20.098513912540227
                            ],
                            [
                                88.01653839052572,
                                20.098513912540227
                            ]
                        ],
                        [
                            [
                                88.0021494382459,
                                20.16151840555761
                            ],
                            [
                                88.12827556437203,
                                20.16151840555761
                            ],
                            [
                                88.12827556437203,
                                20.035392279431488
                            ],
                            [
                                88.0021494382459,
                                20.035392279431488
                            ]
                        ],
                        [
                            [
                                87.98776863634288,
                                20.098395629727612
                            ],
                            [
                                88.11389476246902,
                                20.098395629727612
                            ],
                            [
                                88.11389476246902,
                                19.97226950360149
                            ],
                            [
                                87.98776863634288,
                                19.97226950360149
                            ]
                        ],
                        [
                            [
                                87.97339595195238,
                                20.035271715198633
                            ],
                            [
                                88.09952207807852,
                                20.035271715198633
                            ],
                            [
                                88.09952207807852,
                                19.90914558907251
                            ],
                            [
                                87.97339595195238,
                                19.90914558907251
                            ]
                        ],
                        [
                            [
                                87.95903134966912,
                                19.97214666598932
                            ],
                            [
                                88.08515747579526,
                                19.97214666598932
                            ],
                            [
                                88.08515747579526,
                                19.846020539863197
                            ],
                            [
                                87.95903134966912,
                                19.846020539863197
                            ]
                        ],
                        [
                            [
                                87.94467479681416,
                                19.90902048611462
                            ],
                            [
                                88.0708009229403,
                                19.90902048611462
                            ],
                            [
                                88.0708009229403,
                                19.782894359988497
                            ],
                            [
                                87.94467479681416,
                                19.782894359988497
                            ]
                        ],
                        [
                            [
                                87.93032625815687,
                                19.845893179585843
                            ],
                            [
                                88.056452384283,
                                19.845893179585843
                            ],
                            [
                                88.056452384283,
                                19.71976705345972
                            ],
                            [
                                87.93032625815687,
                                19.71976705345972
                            ]
                        ],
                        [
                            [
                                87.91598570120507,
                                19.782764750410692
                            ],
                            [
                                88.04211182733121,
                                19.782764750410692
                            ],
                            [
                                88.04211182733121,
                                19.656638624284568
                            ],
                            [
                                87.91598570120507,
                                19.656638624284568
                            ]
                        ],
                        [
                            [
                                87.90165309091057,
                                19.719635202593302
                            ],
                            [
                                88.02777921703671,
                                19.719635202593302
                            ],
                            [
                                88.02777921703671,
                                19.59350907646718
                            ],
                            [
                                87.90165309091057,
                                19.59350907646718
                            ]
                        ],
                        [
                            [
                                87.8873283949595,
                                19.65650454013426
                            ],
                            [
                                88.01345452108563,
                                19.65650454013426
                            ],
                            [
                                88.01345452108563,
                                19.530378414008137
                            ],
                            [
                                87.8873283949595,
                                19.530378414008137
                            ]
                        ],
                        [
                            [
                                87.873011578481,
                                19.59337276703059
                            ],
                            [
                                87.99913770460714,
                                19.59337276703059
                            ],
                            [
                                87.99913770460714,
                                19.467246640904467
                            ],
                            [
                                87.873011578481,
                                19.467246640904467
                            ]
                        ],
                        [
                            [
                                87.85870260934408,
                                19.53023988727585
                            ],
                            [
                                87.98482873547022,
                                19.53023988727585
                            ],
                            [
                                87.98482873547022,
                                19.404113761149727
                            ],
                            [
                                87.85870260934408,
                                19.404113761149727
                            ]
                        ],
                        [
                            [
                                87.8444014528533,
                                19.467105904860123
                            ],
                            [
                                87.97052757897944,
                                19.467105904860123
                            ],
                            [
                                87.97052757897944,
                                19.340979778734
                            ],
                            [
                                87.8444014528533,
                                19.340979778734
                            ]
                        ],
                        [
                            [
                                87.83010807704567,
                                19.403970823769992
                            ],
                            [
                                87.9562342031718,
                                19.403970823769992
                            ],
                            [
                                87.9562342031718,
                                19.277844697643868
                            ],
                            [
                                87.83010807704567,
                                19.277844697643868
                            ]
                        ],
                        [
                            [
                                87.81582244741398,
                                19.340834647988704
                            ],
                            [
                                87.94194857354012,
                                19.340834647988704
                            ],
                            [
                                87.94194857354012,
                                19.21470852186258
                            ],
                            [
                                87.81582244741398,
                                19.21470852186258
                            ]
                        ],
                        [
                            [
                                87.80154453215971,
                                19.277697381496033
                            ],
                            [
                                87.92767065828585,
                                19.277697381496033
                            ],
                            [
                                87.92767065828585,
                                19.15157125536991
                            ],
                            [
                                87.80154453215971,
                                19.15157125536991
                            ]
                        ],
                        [
                            [
                                87.7872742969506,
                                19.214559028268432
                            ],
                            [
                                87.91340042307674,
                                19.214559028268432
                            ],
                            [
                                87.91340042307674,
                                19.08843290214231
                            ],
                            [
                                87.7872742969506,
                                19.08843290214231
                            ]
                        ],
                        [
                            [
                                87.77301171016383,
                                19.15141959227898
                            ],
                            [
                                87.89913783628997,
                                19.15141959227898
                            ],
                            [
                                87.89913783628997,
                                19.025293466152856
                            ],
                            [
                                87.77301171016383,
                                19.025293466152856
                            ]
                        ],
                        [
                            [
                                87.75875673763535,
                                19.088279077497443
                            ],
                            [
                                87.88488286376149,
                                19.088279077497443
                            ],
                            [
                                87.88488286376149,
                                18.96215295137132
                            ],
                            [
                                87.75875673763535,
                                18.96215295137132
                            ]
                        ],
                        [
                            [
                                91.15945488661477,
                                20.83245566307395
                            ],
                            [
                                91.2855810127409,
                                20.83245566307395
                            ],
                            [
                                91.2855810127409,
                                20.706329536947827
                            ],
                            [
                                91.15945488661477,
                                20.706329536947827
                            ]
                        ],
                        [
                            [
                                91.14538765423369,
                                20.895546947498882
                            ],
                            [
                                91.27151378035983,
                                20.895546947498882
                            ],
                            [
                                91.27151378035983,
                                20.76942082137276
                            ],
                            [
                                91.14538765423369,
                                20.76942082137276
                            ]
                        ],
                        [
                            [
                                91.13131220801478,
                                20.95863712020731
                            ],
                            [
                                91.25743833414091,
                                20.95863712020731
                            ],
                            [
                                91.25743833414091,
                                20.832510994081186
                            ],
                            [
                                91.13131220801478,
                                20.832510994081186
                            ]
                        ],
                        [
                            [
                                91.1172285126546,
                                21.021726177419353
                            ],
                            [
                                91.24335463878074,
                                21.021726177419353
                            ],
                            [
                                91.24335463878074,
                                20.89560005129323
                            ],
                            [
                                91.1172285126546,
                                20.89560005129323
                            ]
                        ],
                        [
                            [
                                91.10313653538154,
                                21.08481411535147
                            ],
                            [
                                91.22926266150768,
                                21.08481411535147
                            ],
                            [
                                91.22926266150768,
                                20.958687989225346
                            ],
                            [
                                91.10313653538154,
                                20.958687989225346
                            ]
                        ],
                        [
                            [
                                91.0890362407029,
                                21.14790093021656
                            ],
                            [
                                91.21516236682903,
                                21.14790093021656
                            ],
                            [
                                91.21516236682903,
                                21.021774804090438
                            ],
                            [
                                91.0890362407029,
                                21.021774804090438
                            ]
                        ],
                        [
                            [
                                91.07492759565505,
                                21.21098661822382
                            ],
                            [
                                91.2010537217812,
                                21.21098661822382
                            ],
                            [
                                91.2010537217812,
                                21.084860492097697
                            ],
                            [
                                91.07492759565505,
                                21.084860492097697
                            ]
                        ],
                        [
                            [
                                91.06081056455089,
                                21.274071175578843
                            ],
                            [
                                91.18693669067703,
                                21.274071175578843
                            ],
                            [
                                91.18693669067703,
                                21.14794504945272
                            ],
                            [
                                91.06081056455089,
                                21.14794504945272
                            ]
                        ],
                        [
                            [
                                91.04668511423597,
                                21.337154598483483
                            ],
                            [
                                91.17281124036211,
                                21.337154598483483
                            ],
                            [
                                91.17281124036211,
                                21.21102847235736
                            ],
                            [
                                91.04668511423597,
                                21.21102847235736
                            ]
                        ],
                        [
                            [
                                91.03255120882667,
                                21.40023688313587
                            ],
                            [
                                91.15867733495281,
                                21.40023688313587
                            ],
                            [
                                91.15867733495281,
                                21.274110757009748
                            ],
                            [
                                91.03255120882667,
                                21.274110757009748
                            ]
                        ],
                        [
                            [
                                91.01840881497591,
                                21.463318025730363
                            ],
                            [
                                91.14453494110205,
                                21.463318025730363
                            ],
                            [
                                91.14453494110205,
                                21.33719189960424
                            ],
                            [
                                91.01840881497591,
                                21.33719189960424
                            ]
                        ],
                        [
                            [
                                91.00425789659477,
                                21.52639802245752
                            ],
                            [
                                91.1303840227209,
                                21.52639802245752
                            ],
                            [
                                91.1303840227209,
                                21.400271896331397
                            ],
                            [
                                91.00425789659477,
                                21.400271896331397
                            ]
                        ],
                        [
                            [
                                90.99009842014307,
                                21.58947686950412
                            ],
                            [
                                91.11622454626921,
                                21.58947686950412
                            ],
                            [
                                91.11622454626921,
                                21.463350743377998
                            ],
                            [
                                90.99009842014307,
                                21.463350743377998
                            ]
                        ],
                        [
                            [
                                90.97593034933773,
                                21.652554563053073
                            ],
                            [
                                91.10205647546387,
                                21.652554563053073
                            ],
                            [
                                91.10205647546387,
                                21.52642843692695
                            ],
                            [
                                90.97593034933773,
                                21.52642843692695
                            ]
                        ],
                        [
                            [
                                90.96175365042849,
                                21.715631099283453
                            ],
                            [
                                91.08787977655463,
                                21.715631099283453
                            ],
                            [
                                91.08787977655463,
                                21.58950497315733
                            ],
                            [
                                90.96175365042849,
                                21.58950497315733
                            ]
                        ],
                        [
                            [
                                90.94756828693613,
                                21.778706474370352
                            ],
                            [
                                91.07369441306227,
                                21.778706474370352
                            ],
                            [
                                91.07369441306227,
                                21.65258034824423
                            ],
                            [
                                90.94756828693613,
                                21.65258034824423
                            ]
                        ],
                        [
                            [
                                90.93337422490983,
                                21.84178068448498
                            ],
                            [
                                91.05950035103596,
                                21.84178068448498
                            ],
                            [
                                91.05950035103596,
                                21.715654558358857
                            ],
                            [
                                90.93337422490983,
                                21.715654558358857
                            ]
                        ],
                        [
                            [
                                90.91917142766523,
                                21.90485372579458
                            ],
                            [
                                91.04529755379137,
                                21.90485372579458
                            ],
                            [
                                91.04529755379137,
                                21.778727599668457
                            ],
                            [
                                90.91917142766523,
                                21.778727599668457
                            ]
                        ],
                        [
                            [
                                90.90495986104723,
                                21.967925594462393
                            ],
                            [
                                91.03108598717337,
                                21.967925594462393
                            ],
                            [
                                91.03108598717337,
                                21.84179946833627
                            ],
                            [
                                90.90495986104723,
                                21.84179946833627
                            ]
                        ],
                        [
                            [
                                90.89073948816609,
                                22.030996286647632
                            ],
                            [
                                91.01686561429223,
                                22.030996286647632
                            ],
                            [
                                91.01686561429223,
                                21.904870160521508
                            ],
                            [
                                90.89073948816609,
                                21.904870160521508
                            ]
                        ],
                        [
                            [
                                90.87651027466009,
                                22.094065798505444
                            ],
                            [
                                91.00263640078623,
                                22.094065798505444
                            ],
                            [
                                91.00263640078623,
                                21.96793967237932
                            ],
                            [
                                90.87651027466009,
                                21.96793967237932
                            ]
                        ],
                        [
                            [
                                90.86227218343353,
                                22.157134126186904
                            ],
                            [
                                90.98839830955967,
                                22.157134126186904
                            ],
                            [
                                90.98839830955967,
                                22.03100800006078
                            ],
                            [
                                90.86227218343353,
                                22.03100800006078
                            ]
                        ],
                        [
                            [
                                90.84802517991272,
                                22.220201265838952
                            ],
                            [
                                90.97415130603886,
                                22.220201265838952
                            ],
                            [
                                90.97415130603886,
                                22.094075139712828
                            ],
                            [
                                90.84802517991272,
                                22.094075139712828
                            ]
                        ],
                        [
                            [
                                90.83376922679223,
                                22.2832672136044
                            ],
                            [
                                90.95989535291837,
                                22.2832672136044
                            ],
                            [
                                90.95989535291837,
                                22.157141087478276
                            ],
                            [
                                90.83376922679223,
                                22.157141087478276
                            ]
                        ],
                        [
                            [
                                90.81950428929073,
                                22.346331965621882
                            ],
                            [
                                90.94563041541687,
                                22.346331965621882
                            ],
                            [
                                90.94563041541687,
                                22.22020583949576
                            ],
                            [
                                90.81950428929073,
                                22.22020583949576
                            ]
                        ],
                        [
                            [
                                90.80523032989232,
                                22.409395518025782
                            ],
                            [
                                90.93135645601846,
                                22.409395518025782
                            ],
                            [
                                90.93135645601846,
                                22.28326939189966
                            ],
                            [
                                90.80523032989232,
                                22.28326939189966
                            ]
                        ],
                        [
                            [
                                90.79094731359453,
                                22.47245786694627
                            ],
                            [
                                90.91707343972067,
                                22.47245786694627
                            ],
                            [
                                90.91707343972067,
                                22.346331740820148
                            ],
                            [
                                90.79094731359453,
                                22.346331740820148
                            ]
                        ],
                        [
                            [
                                90.77665520267537,
                                22.53551900850925
                            ],
                            [
                                90.90278132880151,
                                22.53551900850925
                            ],
                            [
                                90.90278132880151,
                                22.409392882383127
                            ],
                            [
                                90.77665520267537,
                                22.409392882383127
                            ]
                        ],
                        [
                            [
                                90.76235396191367,
                                22.598578938836273
                            ],
                            [
                                90.88848008803981,
                                22.598578938836273
                            ],
                            [
                                90.88848008803981,
                                22.47245281271015
                            ],
                            [
                                90.76235396191367,
                                22.47245281271015
                            ]
                        ],
                        [
                            [
                                90.74804355336613,
                                22.6616376540446
                            ],
                            [
                                90.87416967949227,
                                22.6616376540446
                            ],
                            [
                                90.87416967949227,
                                22.535511527918477
                            ],
                            [
                                90.74804355336613,
                                22.535511527918477
                            ]
                        ],
                        [
                            [
                                90.73372394094422,
                                22.724695150247072
                            ],
                            [
                                90.85985006707035,
                                22.724695150247072
                            ],
                            [
                                90.85985006707035,
                                22.59856902412095
                            ],
                            [
                                90.73372394094422,
                                22.59856902412095
                            ]
                        ],
                        [
                            [
                                90.71939508845307,
                                22.78775142355213
                            ],
                            [
                                90.84552121457921,
                                22.78775142355213
                            ],
                            [
                                90.84552121457921,
                                22.661625297426006
                            ],
                            [
                                90.71939508845307,
                                22.661625297426006
                            ]
                        ],
                        [
                            [
                                90.70505695762037,
                                22.850806470063834
                            ],
                            [
                                90.83118308374651,
                                22.850806470063834
                            ],
                            [
                                90.83118308374651,
                                22.72468034393771
                            ],
                            [
                                90.70505695762037,
                                22.72468034393771
                            ]
                        ]
                    ],
                    remainingStrips: []
                },
                progress: 50,
                subStatus: "Partially Completed",
                schedule: {
                    satellites: [
                        {
                            id: "sat_001",
                            name: "Satellite Alpha",
                            orbitalParameters: {
                                altitude: 500,
                                inclination: 98.0
                            }
                        }
                    ],
                    orderAssignments: [
                        {
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_001",
                                name: "Satellite Alpha",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            strip: {
                                id: "strip_001",
                                featureId: "feat_001",
                                coordinates: [
                                    [
                                        [87.07806093760635, 18.89219318868886],
                                        [87.20418706373249, 18.89219318868886],
                                        [87.20418706373249, 18.766067062562737],
                                        [87.07806093760635, 18.766067062562737]
                                    ],
                                    [
                                        [87.06414293483576, 18.955381588322993],
                                        [87.1902690609619, 18.955381588322993],
                                        [87.1902690609619, 18.82925546219687],
                                        [87.06414293483576, 18.82925546219687]
                                    ],
                                    [
                                        [87.05021764930916, 19.018568957505792],
                                        [87.1763437754353, 19.018568957505792],
                                        [87.1763437754353, 18.892442831379668],
                                        [87.05021764930916, 18.892442831379668]
                                    ],
                                    [
                                        [87.03628505006021, 19.081755292425083],
                                        [87.16241117618635, 19.081755292425083],
                                        [87.16241117618635, 18.95562916629896],
                                        [87.03628505006021, 18.95562916629896]
                                    ],
                                    [
                                        [87.02234510407386, 19.144940589265822],
                                        [87.1484712302, 19.144940589265822],
                                        [87.1484712302, 19.018814463139698],
                                        [87.02234510407386, 19.018814463139698]
                                    ],
                                    [
                                        [87.0083977808761, 19.208124844210094],
                                        [87.13452390700223, 19.208124844210094],
                                        [87.13452390700223, 19.08199871808397],
                                        [87.0083977808761, 19.08199871808397]
                                    ],
                                    [
                                        [86.99444304729192, 19.271308053437004],
                                        [87.12056917341806, 19.271308053437004],
                                        [87.12056917341806, 19.14518192731088],
                                        [86.99444304729192, 19.14518192731088]
                                    ],
                                    [
                                        [86.98048087267682, 19.33449021312273],
                                        [87.10660699880296, 19.33449021312273],
                                        [87.10660699880296, 19.208364086996607],
                                        [86.98048087267682, 19.208364086996607]
                                    ],
                                    [
                                        [86.96651122368912, 19.3976713194405],
                                        [87.09263734981526, 19.3976713194405],
                                        [87.09263734981526, 19.271545193314378],
                                        [86.96651122368912, 19.271545193314378]
                                    ],
                                    [
                                        [86.95253406952182, 19.4608513685605],
                                        [87.07866019564796, 19.4608513685605],
                                        [87.07866019564796, 19.334725242434377],
                                        [86.95253406952182, 19.334725242434377]
                                    ],
                                    [
                                        [86.93854937665996, 19.524030356649973],
                                        [87.0646755027861, 19.524030356649973],
                                        [87.0646755027861, 19.39790423052385],
                                        [86.93854937665996, 19.39790423052385]
                                    ],
                                    [
                                        [86.92455711412724, 19.587208279873032],
                                        [87.05068324025338, 19.587208279873032],
                                        [87.05068324025338, 19.461082153746908],
                                        [86.92455711412724, 19.461082153746908]
                                    ],
                                    [
                                        [86.9105572482451, 19.65038513439078],
                                        [87.03668337437124, 19.65038513439078],
                                        [87.03668337437124, 19.524259008264657],
                                        [86.9105572482451, 19.524259008264657]
                                    ],
                                    [
                                        [86.89654974785799, 19.71356091636122],
                                        [87.02267587398413, 19.71356091636122],
                                        [87.02267587398413, 19.587434790235097],
                                        [86.89654974785799, 19.587434790235097]
                                    ],
                                    [
                                        [86.88253457911536, 19.77673562193923],
                                        [87.0086607052415, 19.77673562193923],
                                        [87.0086607052415, 19.650609495813107],
                                        [86.88253457911536, 19.650609495813107]
                                    ],
                                    [
                                        [86.86851171069527, 19.83990924727653],
                                        [86.99463783682141, 19.83990924727653],
                                        [86.99463783682141, 19.713783121150406],
                                        [86.86851171069527, 19.713783121150406]
                                    ],
                                    [
                                        [86.85448110857008, 19.903081788521742],
                                        [86.98060723469622, 19.903081788521742],
                                        [86.98060723469622, 19.77695566239562],
                                        [86.85448110857008, 19.77695566239562]
                                    ],
                                    [
                                        [86.84044274124639, 19.966253241820223],
                                        [86.96656886737253, 19.966253241820223],
                                        [86.96656886737253, 19.8401271156941],
                                        [86.84044274124639, 19.8401271156941]
                                    ],
                                    [
                                        [86.82639657451433, 20.02942360331412],
                                        [86.95252270064047, 20.02942360331412],
                                        [86.95252270064047, 19.903297477187998],
                                        [86.82639657451433, 19.903297477187998]
                                    ],
                                    [
                                        [86.81234257670386, 20.092592869142422],
                                        [86.93846870283, 20.092592869142422],
                                        [86.93846870283, 19.966466743016298],
                                        [86.81234257670386, 19.966466743016298]
                                    ],
                                    [
                                        [86.79828071343404, 20.155761035440783],
                                        [86.92440683956018, 20.155761035440783],
                                        [86.92440683956018, 20.02963490931466],
                                        [86.79828071343404, 20.02963490931466]
                                    ],
                                    [
                                        [86.78421095285307, 20.218928098341593],
                                        [86.91033707897921, 20.218928098341593],
                                        [86.91033707897921, 20.09280197221547],
                                        [86.78421095285307, 20.09280197221547]
                                    ],
                                    [
                                        [86.77013326040063, 20.282094053973903],
                                        [86.89625938652676, 20.282094053973903],
                                        [86.89625938652676, 20.15596792784778],
                                        [86.77013326040063, 20.15596792784778]
                                    ],
                                    [
                                        [86.75604760404772, 20.34525889846344],
                                        [86.88217373017386, 20.34525889846344],
                                        [86.88217373017386, 20.219132772337318],
                                        [86.75604760404772, 20.219132772337318]
                                    ],
                                    [
                                        [86.74195394904456, 20.408422627932563],
                                        [86.8680800751707, 20.408422627932563],
                                        [86.8680800751707, 20.28229650180644],
                                        [86.74195394904456, 20.28229650180644]
                                    ],
                                    [
                                        [86.72785226318304, 20.471585238500243],
                                        [86.85397838930918, 20.471585238500243],
                                        [86.85397838930918, 20.34545911237412],
                                        [86.72785226318304, 20.34545911237412]
                                    ],
                                    [
                                        [86.71374251153655, 20.534746726282002],
                                        [86.83986863766269, 20.534746726282002],
                                        [86.83986863766269, 20.408620600155878],
                                        [86.71374251153655, 20.408620600155878]
                                    ],
                                    [
                                        [86.69962466170627, 20.597907087389974],
                                        [86.8257507878324, 20.597907087389974],
                                        [86.8257507878324, 20.47178096126385],
                                        [86.69962466170627, 20.47178096126385]
                                    ],
                                    [
                                        [86.6854986785836, 20.661066317932743],
                                        [86.81162480470974, 20.661066317932743],
                                        [86.81162480470974, 20.53494019180662],
                                        [86.6854986785836, 20.53494019180662]
                                    ],
                                    [
                                        [86.67136452958023, 20.724224414015453],
                                        [86.79749065570637, 20.724224414015453],
                                        [86.79749065570637, 20.59809828788933],
                                        [86.67136452958023, 20.59809828788933]
                                    ],
                                    [
                                        [86.65722217940038, 20.78738137173969],
                                        [86.78334830552652, 20.78738137173969],
                                        [86.78334830552652, 20.661255245613567],
                                        [86.65722217940038, 20.661255245613567]
                                    ],
                                    [
                                        [86.64307159527075, 20.85053718720351],
                                        [86.76919772139689, 20.85053718720351],
                                        [86.76919772139689, 20.724411061077387],
                                        [86.64307159527075, 20.724411061077387]
                                    ],
                                    [
                                        [86.62891274170477, 20.91369185650135],
                                        [86.7550388678309, 20.91369185650135],
                                        [86.7550388678309, 20.787565730375228],
                                        [86.62891274170477, 20.787565730375228]
                                    ],
                                    [
                                        [86.61474558573897, 20.97684537572406],
                                        [86.74087171186511, 20.97684537572406],
                                        [86.74087171186511, 20.850719249597937],
                                        [86.61474558573897, 20.850719249597937]
                                    ],
                                    [
                                        [86.60057009169716, 21.039997740958853],
                                        [86.7266962178233, 21.039997740958853],
                                        [86.7266962178233, 20.91387161483273],
                                        [86.60057009169716, 20.91387161483273]
                                    ],
                                ],
                                swathWidth: 10.0,
                                length: 15.0,
                                areaCovered: 100.0,
                                captureTime: "2024-10-03T08:00:00Z",
                                cloudCoverage: 5.0,
                                resolution: 1.0
                            },
                            satellite_operations: [
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 1",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 2",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                }

                            ],
                        },
                        {
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_002",
                                name: "Satellite Alpha 2",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            strip: {
                                id: "strip_002",
                                featureId: "feat_002",
                                coordinates: [
                                    [
                                        [86.58638622642049, 21.10314894828927],
                                        [86.71251235254663, 21.10314894828927],
                                        [86.71251235254663, 20.977022822163146],
                                        [86.58638622642049, 20.977022822163146]
                                    ],
                                    [
                                        [86.57219395404282, 21.166298993795202],
                                        [86.69832008016895, 21.166298993795202],
                                        [86.69832008016895, 21.040172867669078],
                                        [86.57219395404282, 21.040172867669078]
                                    ],
                                    [
                                        [86.55799324121097, 21.22944787355271],
                                        [86.6841193673371, 21.22944787355271],
                                        [86.6841193673371, 21.103321747426588],
                                        [86.55799324121097, 21.103321747426588]
                                    ],
                                    [
                                        [86.54378405185373, 21.292595583634213],
                                        [86.66991017797987, 21.292595583634213],
                                        [86.66991017797987, 21.16646945750809],
                                        [86.54378405185373, 21.16646945750809]
                                    ],
                                    [
                                        [86.52956635243483, 21.355742120108292],
                                        [86.65569247856097, 21.355742120108292],
                                        [86.65569247856097, 21.22961599398217],
                                        [86.52956635243483, 21.22961599398217]
                                    ],
                                    [
                                        [86.51534010668081, 21.41888747903977],
                                        [86.64146623280695, 21.41888747903977],
                                        [86.64146623280695, 21.292761352913647],
                                        [86.51534010668081, 21.292761352913647]
                                    ],
                                    [
                                        [86.50110528019738, 21.482031656489532],
                                        [86.62723140632352, 21.482031656489532],
                                        [86.62723140632352, 21.355905530363408],
                                        [86.50110528019738, 21.355905530363408]
                                    ],
                                    [
                                        [86.48686183848872, 21.54517464851467],
                                        [86.61298796461486, 21.54517464851467],
                                        [86.61298796461486, 21.419048522388547],
                                        [86.48686183848872, 21.419048522388547]
                                    ],
                                    [
                                        [86.47260974498474, 21.608316451168413],
                                        [86.59873587111088, 21.608316451168413],
                                        [86.59873587111088, 21.48219032504229],
                                        [86.47260974498474, 21.48219032504229]
                                    ],
                                    [
                                        [86.45834896564442, 21.67145706049997],
                                        [86.58447509177056, 21.67145706049997],
                                        [86.58447509177056, 21.545330934373847],
                                        [86.45834896564442, 21.545330934373847]
                                    ],
                                    [
                                        [86.4440794637, 21.73459647255467],
                                        [86.57020558982614, 21.73459647255467],
                                        [86.57020558982614, 21.608470346428547],
                                        [86.4440794637, 21.608470346428547]
                                    ],
                                    [
                                        [86.42980120490348, 21.7977346833738],
                                        [86.55592733102962, 21.7977346833738],
                                        [86.55592733102962, 21.671608557247676],
                                        [86.42980120490348, 21.671608557247676]
                                    ],
                                    [
                                        [86.4155141522792, 21.860871688994642],
                                        [86.54164027840534, 21.860871688994642],
                                        [86.54164027840534, 21.734745562868518],
                                        [86.4155141522792, 21.734745562868518]
                                    ],
                                    [
                                        [86.40121827137824, 21.92400748545047],
                                        [86.52734439750438, 21.92400748545047],
                                        [86.52734439750438, 21.797881359324347],
                                        [86.40121827137824, 21.797881359324347]
                                    ],
                                    [
                                        [86.38691352501807, 21.987142068770442],
                                        [86.51303965114421, 21.987142068770442],
                                        [86.51303965114421, 21.86101594264432],
                                        [86.38691352501807, 21.86101594264432]
                                    ],
                                    [
                                        [86.37259987854023, 22.050275434979593],
                                        [86.49872600466637, 22.050275434979593],
                                        [86.49872600466637, 21.92414930885347],
                                        [86.37259987854023, 21.92414930885347]
                                    ],
                                    [
                                        [86.35827729455154, 22.11340758009885],
                                        [86.48440342067768, 22.11340758009885],
                                        [86.48440342067768, 21.987281453972727],
                                        [86.35827729455154, 21.987281453972727]
                                    ],
                                    [
                                        [86.3439457381817, 22.176538500144932],
                                        [86.47007186430784, 22.176538500144932],
                                        [86.47007186430784, 22.05041237401881],
                                        [86.3439457381817, 22.05041237401881]
                                    ],
                                    [
                                        [86.3296051718312, 22.239668191130402],
                                        [86.45573129795734, 22.239668191130402],
                                        [86.45573129795734, 22.113542065004278],
                                        [86.3296051718312, 22.113542065004278]
                                    ],
                                    [
                                        [86.31525556042074, 22.302796649063524],
                                        [86.44138168654688, 22.302796649063524],
                                        [86.44138168654688, 22.1766705229374],
                                        [86.31525556042074, 22.1766705229374]
                                    ],
                                    [
                                        [86.30089686612745, 22.365923869948322],
                                        [86.42702299225358, 22.365923869948322],
                                        [86.42702299225358, 22.239797743822198],
                                        [86.30089686612745, 22.239797743822198]
                                    ],
                                    [
                                        [88.56970598071742, 22.62237520164433],
                                        [88.69583210684355, 22.62237520164433],
                                        [88.69583210684355, 22.496249075518207],
                                        [88.56970598071742, 22.496249075518207]
                                    ],
                                    [
                                        [88.55498099831382, 22.559300011886933],
                                        [88.68110712443996, 22.559300011886933],
                                        [88.68110712443996, 22.43317388576081],
                                        [88.55498099831382, 22.43317388576081]
                                    ],
                                    [
                                        [88.54026553981925, 22.496223523534102],
                                        [88.66639166594538, 22.496223523534102],
                                        [88.66639166594538, 22.370097397407978],
                                        [88.54026553981925, 22.370097397407978]
                                    ],
                                    [
                                        [88.52555956585745, 22.43314574077076],
                                        [88.65168569198359, 22.43314574077076],
                                        [88.65168569198359, 22.307019614644638],
                                        [88.52555956585745, 22.307019614644638]
                                    ],
                                    [
                                        [88.5108630397801, 22.370066667776932],
                                        [88.63698916590624, 22.370066667776932],
                                        [88.63698916590624, 22.243940541650808],
                                        [88.5108630397801, 22.243940541650808]
                                    ],
                                    [
                                        [88.49617592243857, 22.30698630872779],
                                        [88.6223020485647, 22.30698630872779],
                                        [88.6223020485647, 22.180860182601666],
                                        [88.49617592243857, 22.180860182601666]
                                    ],
                                    [
                                        [88.48149817740759, 22.243904667793693],
                                        [88.60762430353373, 22.243904667793693],
                                        [88.60762430353373, 22.11777854166757],
                                        [88.48149817740759, 22.11777854166757]
                                    ],
                                    [
                                        [88.46682976575893, 22.18082174914022],
                                        [88.59295589188507, 22.18082174914022],
                                        [88.59295589188507, 22.054695623014098],
                                        [88.46682976575893, 22.054695623014098]
                                    ],
                                    [
                                        [88.45217065129142, 22.117737556928212],
                                        [88.57829677741756, 22.117737556928212],
                                        [88.57829677741756, 21.991611430802088],
                                        [88.45217065129142, 21.991611430802088]
                                    ],
                                    [
                                        [88.4375207952949, 22.054652095313763],
                                        [88.56364692142104, 22.054652095313763],
                                        [88.56364692142104, 21.92852596918764],
                                        [88.4375207952949, 21.92852596918764]
                                    ],
                                    [
                                        [88.42288016178676, 21.99156536844834],
                                        [88.5490062879129, 21.99156536844834],
                                        [88.5490062879129, 21.865439242322218],
                                        [88.42288016178676, 21.865439242322218]
                                    ],
                                    [
                                        [88.40824871227753, 21.928477380478732],
                                        [88.53437483840366, 21.928477380478732],
                                        [88.53437483840366, 21.802351254352608],
                                        [88.40824871227753, 21.802351254352608]
                                    ],
                                    [
                                        [88.39362641099426, 21.865388135547093],
                                        [88.5197525371204, 21.865388135547093],
                                        [88.5197525371204, 21.73926200942097],
                                        [88.39362641099426, 21.73926200942097]
                                    ],
                                ],
                                swathWidth: 10.0,
                                length: 15.0,
                                areaCovered: 100.0,
                                captureTime: "2024-10-03T08:00:00Z",
                                cloudCoverage: 5.0,
                                resolution: 1.0
                            },
                            satellite_operations: [
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 1",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 2",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                }
                            ],
                        },
                        {
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_003",
                                name: "Satellite Alpha 3",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            strip: {
                                id: "strip_003",
                                featureId: "feat_003",
                                coordinates: [
                                    [
                                        [88.37901321966427, 21.802297637791103],
                                        [88.50513934579041, 21.802297637791103],
                                        [88.50513934579041, 21.67617151166498],
                                        [88.37901321966427, 21.67617151166498]
                                    ],
                                    [
                                        [88.36440910273198, 21.739205891343783],
                                        [88.49053522885812, 21.739205891343783],
                                        [88.49053522885812, 21.61307976521766],
                                        [88.36440910273198, 21.61307976521766]
                                    ],
                                    [
                                        [88.34981402213434, 21.676112900333713],
                                        [88.47594014826048, 21.676112900333713],
                                        [88.47594014826048, 21.54998677420759],
                                        [88.34981402213434, 21.54998677420759]
                                    ],
                                    [
                                        [88.33522794253236, 21.613018668884962],
                                        [88.4613540686585, 21.613018668884962],
                                        [88.4613540686585, 21.486892542758838],
                                        [88.33522794253236, 21.486892542758838]
                                    ],
                                    [
                                        [88.32065082606883, 21.54992320111718],
                                        [88.44677695219497, 21.54992320111718],
                                        [88.44677695219497, 21.423797074991057],
                                        [88.32065082606883, 21.423797074991057]
                                    ],
                                    [
                                        [88.30608263761445, 21.48682650114562],
                                        [88.43220876374059, 21.48682650114562],
                                        [88.43220876374059, 21.360700375019498],
                                        [88.30608263761445, 21.360700375019498]
                                    ],
                                    [
                                        [88.29152333952366, 21.423728573081103],
                                        [88.4176494656498, 21.423728573081103],
                                        [88.4176494656498, 21.29760244695498],
                                        [88.29152333952366, 21.29760244695498]
                                    ],
                                    [
                                        [88.27697289687295, 21.36062942103016],
                                        [88.40309902299909, 21.36062942103016],
                                        [88.40309902299909, 21.234503294904037],
                                        [88.27697289687295, 21.234503294904037]
                                    ],
                                    [
                                        [88.26243127222311, 21.297529049094972],
                                        [88.38855739834925, 21.297529049094972],
                                        [88.38855739834925, 21.17140292296885],
                                        [88.26243127222311, 21.17140292296885]
                                    ],
                                    [
                                        [88.2478984308575, 21.23442746137346],
                                        [88.37402455698364, 21.23442746137346],
                                        [88.37402455698364, 21.108301335247337],
                                        [88.2478984308575, 21.108301335247337]
                                    ],
                                    [
                                        [88.2333743355378, 21.17132466195927],
                                        [88.35950046166394, 21.17132466195927],
                                        [88.35950046166394, 21.045198535833148],
                                        [88.2333743355378, 21.045198535833148]
                                    ],
                                    [
                                        [88.21885895174889, 21.108220654941864],
                                        [88.34498507787502, 21.108220654941864],
                                        [88.34498507787502, 20.98209452881574],
                                        [88.21885895174889, 20.98209452881574]
                                    ],
                                    [
                                        [88.20435224246259, 21.045115444406463],
                                        [88.33047836858873, 21.045115444406463],
                                        [88.33047836858873, 20.91898931828034],
                                        [88.20435224246259, 20.91898931828034]
                                    ],
                                    [
                                        [88.18985417270362, 20.982009034434203],
                                        [88.31598029882976, 20.982009034434203],
                                        [88.31598029882976, 20.85588290830808],
                                        [88.18985417270362, 20.85588290830808]
                                    ],
                                    [
                                        [88.1753647076142, 20.91890142910202],
                                        [88.30149083374033, 20.91890142910202],
                                        [88.30149083374033, 20.792775302975897],
                                        [88.1753647076142, 20.792775302975897]
                                    ],
                                    [
                                        [88.16088381044254, 20.855792632482792],
                                        [88.28700993656868, 20.855792632482792],
                                        [88.28700993656868, 20.72966650635667],
                                        [88.16088381044254, 20.72966650635667]
                                    ],
                                    [
                                        [88.14641144718826, 20.792682648645343],
                                        [88.2725375733144, 20.792682648645343],
                                        [88.2725375733144, 20.66655652251922],
                                        [88.14641144718826, 20.66655652251922]
                                    ],
                                    [
                                        [88.13194758130292, 20.72957148165442],
                                        [88.25807370742906, 20.72957148165442],
                                        [88.25807370742906, 20.603445355528297],
                                        [88.13194758130292, 20.603445355528297]
                                    ],
                                    [
                                        [88.11749217897376, 20.66645913557081],
                                        [88.2436183050999, 20.66645913557081],
                                        [88.2436183050999, 20.540333009444687],
                                        [88.11749217897376, 20.540333009444687]
                                    ],
                                    [
                                        [88.10304520385357, 20.60334561445126],
                                        [88.2291713299797, 20.60334561445126],
                                        [88.2291713299797, 20.477219488325137],
                                        [88.10304520385357, 20.477219488325137]
                                    ],
                                    [
                                        [88.08860662231845, 20.540230922348663],
                                        [88.21473274844459, 20.540230922348663],
                                        [88.21473274844459, 20.41410479622254],
                                        [88.08860662231845, 20.41410479622254]
                                    ],
                                    [
                                        [88.07417639821387, 20.477115063311913],
                                        [88.20030252434, 20.477115063311913],
                                        [88.20030252434, 20.35098893718579],
                                        [88.07417639821387, 20.35098893718579]
                                    ],
                                    [
                                        [88.05975449811088, 20.41399804138604],
                                        [88.18588062423702, 20.41399804138604],
                                        [88.18588062423702, 20.287871915259917],
                                        [88.05975449811088, 20.287871915259917]
                                    ],
                                    [
                                        [88.04534088604403, 20.35087986061223],
                                        [88.17146701217017, 20.35087986061223],
                                        [88.17146701217017, 20.224753734486107],
                                        [88.04534088604403, 20.224753734486107]
                                    ],
                                    [
                                        [88.03093552877401, 20.28776052502782],
                                        [88.15706165490015, 20.28776052502782],
                                        [88.15706165490015, 20.161634398901697],
                                        [88.03093552877401, 20.161634398901697]
                                    ],
                                    [
                                        [88.01653839052572, 20.22464003866635],
                                        [88.14266451665186, 20.22464003866635],
                                        [88.14266451665186, 20.098513912540227],
                                        [88.01653839052572, 20.098513912540227]
                                    ],
                                    [
                                        [88.0021494382459, 20.16151840555761],
                                        [88.12827556437203, 20.16151840555761],
                                        [88.12827556437203, 20.035392279431488],
                                        [88.0021494382459, 20.035392279431488]
                                    ],
                                    [
                                        [87.98776863634288, 20.098395629727612],
                                        [88.11389476246902, 20.098395629727612],
                                        [88.11389476246902, 19.97226950360149],
                                        [87.98776863634288, 19.97226950360149]
                                    ],
                                    [
                                        [87.97339595195238, 20.035271715198633],
                                        [88.09952207807852, 20.035271715198633],
                                        [88.09952207807852, 19.90914558907251],
                                        [87.97339595195238, 19.90914558907251]
                                    ],
                                    [
                                        [87.95903134966912, 19.97214666598932],
                                        [88.08515747579526, 19.97214666598932],
                                        [88.08515747579526, 19.846020539863197],
                                        [87.95903134966912, 19.846020539863197]
                                    ],
                                    [
                                        [87.94467479681416, 19.90902048611462],
                                        [88.0708009229403, 19.90902048611462],
                                        [88.0708009229403, 19.782894359988497],
                                        [87.94467479681416, 19.782894359988497]
                                    ],
                                    [
                                        [87.93032625815687, 19.845893179585843],
                                        [88.056452384283, 19.845893179585843],
                                        [88.056452384283, 19.71976705345972],
                                        [87.93032625815687, 19.71976705345972]
                                    ],
                                ],
                                swathWidth: 10.0,
                                length: 15.0,
                                areaCovered: 100.0,
                                captureTime: "2024-10-03T08:00:00Z",
                                cloudCoverage: 5.0,
                                resolution: 1.0
                            },
                            satellite_operations: [
                                {
                                    type: "ADCS - Target Track 3",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track 3",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track 3",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                }

                            ],
                        },
                        {
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_004",
                                name: "Satellite Alpha 4",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            strip: {
                                id: "strip_004",
                                featureId: "feat_004",
                                coordinates: [
                                    [
                                        [87.91598570120507, 19.782764750410692],
                                        [88.04211182733121, 19.782764750410692],
                                        [88.04211182733121, 19.656638624284568],
                                        [87.91598570120507, 19.656638624284568]
                                    ],
                                    [
                                        [87.90165309091057, 19.719635202593302],
                                        [88.02777921703671, 19.719635202593302],
                                        [88.02777921703671, 19.59350907646718],
                                        [87.90165309091057, 19.59350907646718]
                                    ],
                                    [
                                        [87.8873283949595, 19.65650454013426],
                                        [88.01345452108563, 19.65650454013426],
                                        [88.01345452108563, 19.530378414008137],
                                        [87.8873283949595, 19.530378414008137]
                                    ],
                                    [
                                        [87.873011578481, 19.59337276703059],
                                        [87.99913770460714, 19.59337276703059],
                                        [87.99913770460714, 19.467246640904467],
                                        [87.873011578481, 19.467246640904467]
                                    ],
                                    [
                                        [87.85870260934408, 19.53023988727585],
                                        [87.98482873547022, 19.53023988727585],
                                        [87.98482873547022, 19.404113761149727],
                                        [87.85870260934408, 19.404113761149727]
                                    ],
                                    [
                                        [87.8444014528533, 19.467105904860123],
                                        [87.97052757897944, 19.467105904860123],
                                        [87.97052757897944, 19.340979778734],
                                        [87.8444014528533, 19.340979778734]
                                    ],
                                    [
                                        [87.83010807704567, 19.403970823769992],
                                        [87.9562342031718, 19.403970823769992],
                                        [87.9562342031718, 19.277844697643868],
                                        [87.83010807704567, 19.277844697643868]
                                    ],
                                    [
                                        [87.81582244741398, 19.340834647988704],
                                        [87.94194857354012, 19.340834647988704],
                                        [87.94194857354012, 19.21470852186258],
                                        [87.81582244741398, 19.21470852186258]
                                    ],
                                    [
                                        [87.80154453215971, 19.277697381496033],
                                        [87.92767065828585, 19.277697381496033],
                                        [87.92767065828585, 19.15157125536991],
                                        [87.80154453215971, 19.15157125536991]
                                    ],
                                    [
                                        [87.7872742969506, 19.214559028268432],
                                        [87.91340042307674, 19.214559028268432],
                                        [87.91340042307674, 19.08843290214231],
                                        [87.7872742969506, 19.08843290214231]
                                    ],
                                    [
                                        [87.77301171016383, 19.15141959227898],
                                        [87.89913783628997, 19.15141959227898],
                                        [87.89913783628997, 19.025293466152856],
                                        [87.77301171016383, 19.025293466152856]
                                    ],
                                    [
                                        [87.75875673763535, 19.088279077497443],
                                        [87.88488286376149, 19.088279077497443],
                                        [87.88488286376149, 18.96215295137132],
                                        [87.75875673763535, 18.96215295137132]
                                    ],
                                    [
                                        [91.15945488661477, 20.83245566307395],
                                        [91.2855810127409, 20.83245566307395],
                                        [91.2855810127409, 20.706329536947827],
                                        [91.15945488661477, 20.706329536947827]
                                    ],
                                    [
                                        [91.14538765423369, 20.895546947498882],
                                        [91.27151378035983, 20.895546947498882],
                                        [91.27151378035983, 20.76942082137276],
                                        [91.14538765423369, 20.76942082137276]
                                    ],
                                    [
                                        [91.13131220801478, 20.95863712020731],
                                        [91.25743833414091, 20.95863712020731],
                                        [91.25743833414091, 20.832510994081186],
                                        [91.13131220801478, 20.832510994081186]
                                    ],
                                    [
                                        [91.1172285126546, 21.021726177419353],
                                        [91.24335463878074, 21.021726177419353],
                                        [91.24335463878074, 20.89560005129323],
                                        [91.1172285126546, 20.89560005129323]
                                    ],
                                    [
                                        [91.10313653538154, 21.08481411535147],
                                        [91.22926266150768, 21.08481411535147],
                                        [91.22926266150768, 20.958687989225346],
                                        [91.10313653538154, 20.958687989225346]
                                    ],
                                    [
                                        [91.0890362407029, 21.14790093021656],
                                        [91.21516236682903, 21.14790093021656],
                                        [91.21516236682903, 21.021774804090438],
                                        [91.0890362407029, 21.021774804090438]
                                    ],
                                    [
                                        [91.07492759565505, 21.21098661822382],
                                        [91.2010537217812, 21.21098661822382],
                                        [91.2010537217812, 21.084860492097697],
                                        [91.07492759565505, 21.084860492097697]
                                    ],
                                    [
                                        [91.06081056455089, 21.274071175578843],
                                        [91.18693669067703, 21.274071175578843],
                                        [91.18693669067703, 21.14794504945272],
                                        [91.06081056455089, 21.14794504945272]
                                    ],
                                    [
                                        [91.04668511423597, 21.337154598483483],
                                        [91.17281124036211, 21.337154598483483],
                                        [91.17281124036211, 21.21102847235736],
                                        [91.04668511423597, 21.21102847235736]
                                    ],
                                    [
                                        [91.03255120882667, 21.40023688313587],
                                        [91.15867733495281, 21.40023688313587],
                                        [91.15867733495281, 21.274110757009748],
                                        [91.03255120882667, 21.274110757009748]
                                    ],
                                    [
                                        [91.01840881497591, 21.463318025730363],
                                        [91.14453494110205, 21.463318025730363],
                                        [91.14453494110205, 21.33719189960424],
                                        [91.01840881497591, 21.33719189960424]
                                    ],
                                    [
                                        [91.00425789659477, 21.52639802245752],
                                        [91.1303840227209, 21.52639802245752],
                                        [91.1303840227209, 21.400271896331397],
                                        [91.00425789659477, 21.400271896331397]
                                    ],
                                    [
                                        [90.99009842014307, 21.58947686950412],
                                        [91.11622454626921, 21.58947686950412],
                                        [91.11622454626921, 21.463350743377998],
                                        [90.99009842014307, 21.463350743377998]
                                    ],
                                    [
                                        [90.97593034933773, 21.652554563053073],
                                        [91.10205647546387, 21.652554563053073],
                                        [91.10205647546387, 21.52642843692695],
                                        [90.97593034933773, 21.52642843692695]
                                    ],
                                    [
                                        [90.96175365042849, 21.715631099283453],
                                        [91.08787977655463, 21.715631099283453],
                                        [91.08787977655463, 21.58950497315733],
                                        [90.96175365042849, 21.58950497315733]
                                    ],
                                    [
                                        [90.94756828693613, 21.778706474370352],
                                        [91.07369441306227, 21.778706474370352],
                                        [91.07369441306227, 21.65258034824423],
                                        [90.94756828693613, 21.65258034824423]
                                    ],
                                    [
                                        [90.93337422490983, 21.84178068448498],
                                        [91.05950035103596, 21.84178068448498],
                                        [91.05950035103596, 21.715654558358857],
                                        [90.93337422490983, 21.715654558358857]
                                    ],
                                    [
                                        [90.91917142766523, 21.90485372579458],
                                        [91.04529755379137, 21.90485372579458],
                                        [91.04529755379137, 21.778727599668457],
                                        [90.91917142766523, 21.778727599668457]
                                    ],
                                    [
                                        [90.90495986104723, 21.967925594462393],
                                        [91.03108598717337, 21.967925594462393],
                                        [91.03108598717337, 21.84179946833627],
                                        [90.90495986104723, 21.84179946833627]
                                    ],
                                    [
                                        [90.89073948816609, 22.030996286647632],
                                        [91.01686561429223, 22.030996286647632],
                                        [91.01686561429223, 21.904870160521508],
                                        [90.89073948816609, 21.904870160521508]
                                    ],
                                    [
                                        [90.87651027466009, 22.094065798505444],
                                        [91.00263640078623, 22.094065798505444],
                                        [91.00263640078623, 21.96793967237932],
                                        [90.87651027466009, 21.96793967237932]
                                    ],
                                    [
                                        [90.86227218343353, 22.157134126186904],
                                        [90.98839830955967, 22.157134126186904],
                                        [90.98839830955967, 22.03100800006078],
                                        [90.86227218343353, 22.03100800006078]
                                    ],
                                    [
                                        [90.84802517991272, 22.220201265838952],
                                        [90.97415130603886, 22.220201265838952],
                                        [90.97415130603886, 22.094075139712828],
                                        [90.84802517991272, 22.094075139712828]
                                    ],
                                    [
                                        [90.83376922679223, 22.2832672136044],
                                        [90.95989535291837, 22.2832672136044],
                                        [90.95989535291837, 22.157141087478276],
                                        [90.83376922679223, 22.157141087478276]
                                    ],
                                    [
                                        [90.81950428929073, 22.346331965621882],
                                        [90.94563041541687, 22.346331965621882],
                                        [90.94563041541687, 22.22020583949576],
                                        [90.81950428929073, 22.22020583949576]
                                    ],
                                    [
                                        [90.80523032989232, 22.409395518025782],
                                        [90.93135645601846, 22.409395518025782],
                                        [90.93135645601846, 22.28326939189966],
                                        [90.80523032989232, 22.28326939189966]
                                    ],
                                    [
                                        [90.79094731359453, 22.47245786694627],
                                        [90.91707343972067, 22.47245786694627],
                                        [90.91707343972067, 22.346331740820148],
                                        [90.79094731359453, 22.346331740820148]
                                    ],
                                    [
                                        [90.77665520267537, 22.53551900850925],
                                        [90.90278132880151, 22.53551900850925],
                                        [90.90278132880151, 22.409392882383127],
                                        [90.77665520267537, 22.409392882383127]
                                    ],
                                    [
                                        [90.76235396191367, 22.598578938836273],
                                        [90.88848008803981, 22.598578938836273],
                                        [90.88848008803981, 22.47245281271015],
                                        [90.76235396191367, 22.47245281271015]
                                    ],
                                    [
                                        [90.74804355336613, 22.6616376540446],
                                        [90.87416967949227, 22.6616376540446],
                                        [90.87416967949227, 22.535511527918477],
                                        [90.74804355336613, 22.535511527918477]
                                    ],
                                    [
                                        [90.73372394094422, 22.724695150247072],
                                        [90.85985006707035, 22.724695150247072],
                                        [90.85985006707035, 22.59856902412095],
                                        [90.73372394094422, 22.59856902412095]
                                    ],
                                    [
                                        [90.71939508845307, 22.78775142355213],
                                        [90.84552121457921, 22.78775142355213],
                                        [90.84552121457921, 22.661625297426006],
                                        [90.71939508845307, 22.661625297426006]
                                    ],
                                    [
                                        [90.70505695762037, 22.850806470063834],
                                        [90.83118308374651, 22.850806470063834],
                                        [90.83118308374651, 22.72468034393771],
                                        [90.70505695762037, 22.72468034393771]
                                    ]
                                ],
                                swathWidth: 10.0,
                                length: 15.0,
                                areaCovered: 100.0,
                                captureTime: "2024-10-03T08:00:00Z",
                                cloudCoverage: 5.0,
                                resolution: 1.0
                            },
                            satellite_operations: [
                                {
                                    type: "ADCS - Target Track 4",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 4",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track 4",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 4",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                },
                                {
                                    type: "ADCS - Target Track 4",
                                    subsystem: "ADCS",
                                    name: "Target Tracking 4",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "target_123",
                                    src_id: "src_001",
                                    id: "op_001",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 1,
                                    start_epoch_sec: 1700000100,
                                    end_epoch_sec: 1700000200,
                                    command_id: "cmd_001",
                                    command_type: "type_1",
                                    task_id: "task_001",
                                    control_mode: "auto",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 0.0,
                                    pitch_deg: 0.0,
                                    yaw_deg: 0.0
                                }
                            ],
                        },
                    ],
                    groundAssignments: [
                        {
                            ground_station: {
                                id: "gs_002",
                                name: "Ground Station Alpha2"
                            },
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_002",
                                name: "Satellite Alpha 2",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            satellite_operations: [
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 1",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                },
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 2",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                },
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                }
                            ]
                        },
                        {
                            ground_station: {
                                id: "gs_001",
                                name: "Ground Station Alpha"
                            },
                            start_epoch_sec: 1700000000,
                            end_epoch_sec: 1700003600,
                            satellite: {
                                id: "sat_001",
                                name: "Satellite Alpha",
                                orbitalParameters: {
                                    altitude: 500,
                                    inclination: 98.0
                                }
                            },
                            satellite_operations: [
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 1",
                                    status: 'Pending',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                },
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 2",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                },
                                {
                                    type: "Payload - Power On",
                                    subsystem: "Payload",
                                    name: "Power On Payload 3",
                                    status: 'Complete',
                                    params: "{}",
                                    dest_id: "dest_001",
                                    src_id: "src_001",
                                    id: "op_002",
                                    arg1: "arg_val_1",
                                    arg2: "arg_val_2",
                                    priority: 2,
                                    start_epoch_sec: 1700000150,
                                    end_epoch_sec: 1700000250,
                                    command_id: "cmd_002",
                                    command_type: "type_2",
                                    task_id: "task_002",
                                    control_mode: "manual",
                                    latitude: -5.23,
                                    longitude: 34.75,
                                    altitude_km: 500.0,
                                    roll_deg: 1.0,
                                    pitch_deg: 1.0,
                                    yaw_deg: 1.0
                                }
                            ]
                        }
                    ]
                }
            }
        },
    ]

export function useOrdersVariables() {
    const [Orders, setorders] = useState([
        {
            status: "Complete",
            name: "Delhi",
            subName: "North_Command | Recurring",
            progress: 100,
            subStatus: "Executed",
            idNum: 0,
            priority: 2,
            isRecurring: true,
            recurringCount: 12,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Complete",
            name: "Western Coast",
            subName: "South_Command | One-time",
            progress: 75,
            subStatus: "Partially Completed",
            idNum: 1,
            priority: 1,
            isRecurring: false,
            recurringCount: 14,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Complete",
            name: "Chennai",
            subName: "South_Command | One-time",
            progress: 30,
            subStatus: "Cancelled",
            idNum: 1,
            priority: 0,
            isRecurring: true,
            recurringCount: 16,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Complete",
            name: "Bangalore",
            subName: "South_Command | One-time",
            progress: 90,
            subStatus: "Failed",
            idNum: 1,
            priority: 1,
            isRecurring: true,
            recurringCount: 14,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Current",
            name: "Eastern Borders",
            subName: "South_Command | Recurring",
            progress: 26,
            subStatus: "Ongoing",
            idNum: 2,
            priority: 2,
            isRecurring: false,
            recurringCount: 18,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Current",
            name: "Northern Borders",
            subName: "North_Command | One-time",
            progress: 55,
            subStatus: "Ongoing",
            idNum: 3,
            priority: 1,
            isRecurring: true,
            recurringCount: 20,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Current",
            name: "Southern Borders",
            subName: "North_Command | One-time",
            progress: 55,
            subStatus: "Ongoing",
            idNum: 3,
            priority: 0,
            isRecurring: false,
            recurringCount: 22,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Current",
            name: "Western Borders",
            subName: "Western_Command | One-time",
            progress: 25,
            subStatus: "Delay",
            idNum: 3,
            priority: 0,
            isRecurring: false,
            recurringCount: 22,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Pending",
            name: "Mumbai",
            subName: "South_Command | One-time",
            progress: 15,
            subStatus: "Ongoing",
            idNum: 4,
            priority: 2,
            isRecurring: true,
            recurringCount: 24,
            recurringLimit: 24,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Pending",
            name: "Dubai",
            subName: "South_Command | One-time",
            progress: 15,
            subStatus: "Ongoing",
            idNum: 4,
            priority: 1,
            isRecurring: false,
            recurringCount: 26,
            recurringLimit: 34,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
        {
            status: "Pending",
            name: "UAE",
            subName: "South_Command | One-time",
            progress: 15,
            subStatus: "Ongoing",
            idNum: 4,
            priority: 0,
            isRecurring: true,
            recurringCount: 28,
            recurringLimit: 34,
            startDate: "31 July, 2024 14:20 UTC",
            endDate: "35 August, 2024 7:35 UTC",
        },
    ]);
    return [Orders, setorders];
}

export function useSpikeVariables() {
    const [OrdersSpike, setOrdersSpike] = useState([
        {
            name: "SPIKE01",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Complete",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE01",
            usage: "TeleData Downlink",
            component: "X-Band",
            componentImg: require("../ImgAssets/XBandSmallIcon.svg").default,
            type: "Data_Img_type",
            status: "Complete",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE02",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Waiting",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE02",
            usage: "TeleData Downlink",
            component: "X-Band",
            componentImg: require("../ImgAssets/XBandSmallIcon.svg").default,
            type: "Data_Img_type",
            status: "Warning",
            // status: "Pending",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
            errorHeader: "ADCS malfunctioned in SPIKE 02",
            errorItems: [
                { header: "SPIKE 02 Strip Mapping", message: "Failed to Capture_Strip", time: "02:07 UTC", status: "Warning" },
                { header: "SPIKE 02 connecting to SPIKE 04", message: "Informing about Task Failure", time: "02:30 UTC", status: "Warning" },
                { header: "SPIKE 04 connecting to SPIKE 09", message: "Assigning Task Strip_Mapping", time: "02:30 UTC", status: "OK" },
            ],
        },
        {
            name: "SPIKE03",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Pending",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
    ]);
}

export const OrdersSpikeMock =
    [
        {
            name: "SPIKE01",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Complete",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE01",
            usage: "TeleData Downlink",
            component: "X-Band",
            componentImg: require("../ImgAssets/XBandSmallIcon.svg").default,
            type: "Data_Img_type",
            status: "Complete",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE02",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Waiting",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
        {
            name: "SPIKE02",
            usage: "TeleData Downlink",
            component: "X-Band",
            componentImg: require("../ImgAssets/XBandSmallIcon.svg").default,
            type: "Data_Img_type",
            status: "Warning",
            // status: "Pending",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
            errorHeader: "ADCS malfunctioned in SPIKE 02",
            errorItems: [
                { header: "SPIKE 02 Strip Mapping", message: "Failed to Capture_Strip", time: "02:07 UTC", status: "Warning" },
                { header: "SPIKE 02 connecting to SPIKE 04", message: "Informing about Task Failure", time: "02:30 UTC", status: "Warning" },
                { header: "SPIKE 04 connecting to SPIKE 09", message: "Assigning Task Strip_Mapping", time: "02:30 UTC", status: "OK" },
            ],
        },
        {
            name: "SPIKE03",
            usage: "Strip_mapping",
            component: "Imager",
            componentImg: require("../ImgAssets/ImagerSmallIcon.svg").default,
            type: "Capture_Strip",
            status: "Pending",
            triggerStatus: "02:00 UTC",
            geoTriggerLocation: "RKB | 05:00 UTC",
        },
    ];

