import { useState } from "react";
import { ORDER_STATUS_SERVER } from "../UnifiedControlPlane.constants";

const ExpandedSubOrderTable = ({displayAssingments}) => {
  return (
    <table className="expandedSubOrderTable">
      <tr className="expandedSubOrderTableHeaderRow">
        <th style={{textAlign: "left", paddingLeft: "50px"}}>TASK</th>
        <th>TRIGGER STATUS</th>
        <th>GEO-TRIGGER</th>
        <th>ACTION</th>
      </tr>
      {displayAssingments.map((item) => (
        <tr className="expandedSubOrderTableSpikeItemRow">
          <td>
            <div style={{ display: 'flex'}}>
              <button className="orderProgressDivPendingBtn" style={{ float: "left", padding: "0px" }}>
              {item.satellite_operation.status === ORDER_STATUS_SERVER.COMPLETE && <img className="pauseIcon noTop" src={require("../ImgAssets/GreenTickSmallIcon.svg").default} />}
            {item.satellite_operation.status === ORDER_STATUS_SERVER.WAITING && <img className="pauseIcon noTop rotationIcon" src={require("../ImgAssets/PendingIcon.svg").default}  />}
            {item.satellite_operation.status === ORDER_STATUS_SERVER.PENDING && <img className="pauseIcon noTop" src={require("../ImgAssets/WaitingTimeIcon.svg").default} />}
            {item.satellite_operation.status === ORDER_STATUS_SERVER.WARNING && <img className="pauseIcon noTop" src={require("../ImgAssets/OrangeWarningIcon.svg").default} />}
              </button>
              <div className="orderTitleMainDiv" style={{ alignContent: 'center' }}>
                <div className="orderTitleDiv">
                  <div className="subOrderTitleName white">{item.satellite.name}&ensp;|&ensp;</div>
                  <div className="subOrderTitleName grey"> {item.satellite_operation.name}</div>
                </div>
                {/* <div className="orderTitleDiv">
                  {item.componentImg != "" && <img className="subOrderImg" src={item.componentImg} />}
                  <div className="subOrderTitleName grey">{item.component}&ensp;|&ensp;</div>
                  <div className="subOrderTitleName yellow">{item.satellite_operation.type}</div>
                </div> */}
              </div>
            </div>
          </td>
          <td>
            <div className="subOrderTitleName white">{item.satellite_operation.status}</div>
          </td>
          <td>
            <div className="subOrderTitleName yellow">{item.satellite_operation.type}</div>
          </td>
          <td>
            <button className="spikeItemActionBtn">
              <img className="pauseIcon" src={require("../ImgAssets/OpenNewWindowIcon.svg").default} />
            </button>
          </td>
        </tr>
      ))}
    </table>
  );
};

export default ExpandedSubOrderTable;
