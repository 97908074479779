import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import RadialSeparators from "./RadialSeparators";

const CircularProgressBarComponent = ({ item }) => {

  const colourChooser = (item) => {
    if (item.status.subStatus === "Partially Completed") return "#F2A93B";
    else if (item.status.subStatus === "Cancelled") return "#F0F0F0";
    else if (item.status.subStatus === "Failed") return "#FF3333";
    else if (item.status.subStatus === "Delay") return "#A0BFFF";
    else return "#F0F0F0";
  };

  const fontChooser = (item) => {
    if (!["Cancelled", "Failed", "Delay"].includes(item.status.subStatus)) return "25px";
    else return "0px";
  };

  return (
    <>
      <CircularProgressbarWithChildren
        value={item.status.progress}
        text={`${item.status.progress}%`}
        strokeWidth={6}
        styles={buildStyles({
          pathColor: colourChooser(item),
          textColor: colourChooser(item),
          trailColor: "rgb(240,240,240,0.1)",
          backgroundColor: "transparent",
          textSize: fontChooser(item),
        })}
      >
        <RadialSeparators
          count={20}
          style={{
            background: "#000",
            width: "1.5px",
            height: `${10}%`,
          }}
        />
        {item.status.subStatus === 'Cancelled' && <img src={require("../ImgAssets/CancelledOrderIcon.svg").default} />}
        {item.status.subStatus === 'Failed' && <img src={require("../ImgAssets/FailedOrderIcon.svg").default} />}
        {item.status.subStatus === 'Delay' && <img src={require("../ImgAssets/BluePauseIcon.svg").default} />}
      </CircularProgressbarWithChildren>
    </>
  );
};

export default CircularProgressBarComponent;
