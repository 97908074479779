import { createSlice } from '@reduxjs/toolkit';
import { ORDER_STATUS, ORDER_STATUS_SERVER } from '../components/UnifiedControlPlane/UnifiedControlPlane.constants';
import { ordersMockData } from '../components/UnifiedControlPlane/UCPComponents/StateVariablesDefinitions';

// once filtered we need to align 
const filterOrdersByStatus = (ordersData) => {

    const allOrders = [...ordersMockData];


    const updatedAllOrders = allOrders.map(order => {
        const displayAssignments = [

            ...order.status.schedule.orderAssignments.flatMap(orderAssignment =>
                orderAssignment.satellite_operations.map(operation => ({
                    satellite: orderAssignment.satellite,
                    satellite_operation: operation
                }))
            ),

            ...order.status.schedule.groundAssignments.flatMap(groundAssignment =>
                groundAssignment.satellite_operations.map(operation => ({
                    satellite: groundAssignment.satellite,
                    satellite_operation: operation
                }))
            )
        ];

        return {
            ...order,
            status: {
                ...order.status,
                schedule: {
                    ...order.status.schedule,
                    displayAssignments: displayAssignments
                }
            }
        };
    });

    const filteredOrders = {
        executed: [],
        current: [],
        pending: [],
    };
    updatedAllOrders.forEach(order => {
        switch (order.status.state) {
            case ORDER_STATUS_SERVER.EXECUTED:
                filteredOrders.executed.push(order);
                break;
            case ORDER_STATUS_SERVER.CURRENT:
                filteredOrders.current.push(order);
                break;
            case ORDER_STATUS_SERVER.PENDING:
                filteredOrders.pending.push(order);
                break;
        }
    });
    return filteredOrders;
};

export const unifiedControlPlaneSlice = createSlice({
    name: 'ucpSlice',
    initialState: {
        scenarioId: '',
        ordersLoading: false,
        orders: {
            executed: [],
            current: [],
            pending: [],
        },
        creatingOrder: false,
        activeOrdersSection: ORDER_STATUS.CURRENT,
        showMapBoxMap: false,
        // aois: [],
        aoiCollection: {},
        uploadedCollection: {},

        allConstellation: [],
        allGroundStations: []
    },
    reducers: {
        getScenarioIdRequest: (state) => {
        },
        getScenarioIdSuccess: (state, action) => {
            state.scenarioId = action.payload
        },
        getScenarioIdFailure: (state) => {
        },
        getOrdersRequest: (state) => {
            state.ordersLoading = true;
        },
        getOrdersSuccess: (state, action) => {
            state.orders = filterOrdersByStatus(action.payload);
            state.ordersLoading = false;
        },
        getOrdersFailure: (state) => {
            state.ordersLoading = false;
        },
        createOrderRequest: (state) => {
            state.creatingOrder = true;
        },
        createOrderSuccess: (state) => {
            state.creatingOrder = false;
        },
        createOrderFailure: (state) => {
            state.creatingOrder = false;
        },
        setShowMapBoxMap: (state, action) => {
            state.showMapBoxMap = action.payload;
        },
        setUploadedFeatures: (state, action) => {
            state.uploadedCollection = action.payload;
        },
        resetUploadedFeatures: (state) => {
            state.uploadedCollection = {};
        },
        setOrdersSection: (state, action) => {
            state.activeOrdersSection = action.payload;
        },
        getConstellationRequest: (state) => { },
        getConstellationSuccess: (state, action) => {
            state.allConstellation = action.payload
        },
        getConstellationFailure: (state) => { },

        getGroundStationRequest: (state) => { },
        getGroundStationSuccess: (state, action) => {
            state.allGroundStations = action.payload
        },
        getGroundStationFailure: (state) => { },

    },
});

export const {
    getScenarioIdRequest,
    getScenarioIdSuccess,
    getScenarioIdFailure,

    getOrdersRequest,
    getOrdersSuccess,
    getOrdersFailure,

    createOrderRequest,
    createOrderSuccess,
    createOrderFailure,

    setShowMapBoxMap,
    setUploadedFeatures,
    resetUploadedFeatures,
    setOrdersSection,

    getConstellationRequest,
    getConstellationSuccess,
    getConstellationFailure,

    getGroundStationRequest,
    getGroundStationSuccess,
    getGroundStationFailure,

} = unifiedControlPlaneSlice.actions;

export default unifiedControlPlaneSlice.reducer;